import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles, fade } from "@material-ui/core/styles";
import { Box, Button, Grid, Fab, Checkbox, TextField,Select,MenuItem,InputLabel } from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "axios";
import EditIcon from '@material-ui/icons/Edit';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Header from "../header/Header";
import AlertDialog from "../components/Dialog";
import { moveTo, listOrder, pageCountOrder, currentPageOrder, hitsCountOrder } from "../actions";
import REQUEST_PATH from "../components/Common";
import Pagination from '@material-ui/lab/Pagination';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Cookies from "js-cookie";

import { Alert } from '@material-ui/lab';

class Component extends React.Component {
  state = {
    // 検索項目
    searchCompanyName:'',
    searchCompanyPersonName:'',
    searchProjectName:'',
    searchOrderNumber:'',
    searchUseStartDate:'',
    searchUseEndDate:'',
    searchOrderStatus:'',

    company_list:[],
    order_display_status_list:[],

    // ボタン状態
    registerLock: false,
    updateLock:true,
    deleteLock:true,
    priceLock:true,

    //エラーメッセージ
    headerErrorMessage:"",

    successMessage:'',
    /*****************ページング対応**************/
    currentPage:1,
    totalPageCount:0,
    /****************ページング対応END************/
  };

  constructor(props){
    super(props);

    // 表示用のリストを取得
    // 取引先
    this.getCompanyListMenus();
    this.getOrderListMenus();
    // 注文表示ステータス

    //ヘッダの検索から飛んできたときの処理
    if(props.location.state !== undefined){
      if(props.location.state.orderNumber !== undefined){
        this.state.searchOrderNumber=props.location.state.orderNumber;
      }
      if(props.location.state.projectName !== undefined){
        this.state.searchProjectName=props.location.state.projectName;
      }
      this.search();
    }

  }

  // render後、ボタン制御（遷移元からの復帰対策）
  componentDidMount() {
    this.checkState();
  }

  // 取引先の取得
  async getCompanyListMenus() {

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/company/list',{
        pageIndex: 0,
        pageSize: 1000,
      },{
        param,
        withCredentials: true,
      }
    ).then( response => {
      this.setState({company_list:response.data.list});
    }).catch(e =>{
      console.log(e);
    });
  }

  // 注文表示ステータスの取得
  async getOrderListMenus() {


    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/order-display-status/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then( response => {
      this.setState({order_display_status_list:response.data.list});
    }).catch(e =>{
      console.log(e);
    });
  }

  // 検索域のイベント
  onChangeSearchField(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  // 検索域のクリアボタン押下
  onClearSearchField() {

    this.setState(
      {
        //searchCompanyName:'',
        searchCompanyPersonName:'',
        searchProjectName:'',
        searchOrderNumber:'',
        searchUseStartDate:'',
        searchUseEndDate:'',
        searchOrderStatus:'',
      });
    //this.onChangeAutoCompletField(null);
    this.setState({searchUseDateCheck:false});
    this.forceUpdate();
  }

  
  ToAboutPage() {
    const { dispatch } = this.props;
    dispatch(moveTo.Sample());
  }

  handleCheckAll(evt) {
    const { model } = this.props;
    for (let i in model) {
      model[i].checked = evt.target.checked;
    }
    this.checkState();
    this.forceUpdate();
  }
  //チェックボックスにチェックを入れた時に呼び出される関数
  handleCheck(evt) {
    const { model } = this.props;

    for (let i in model) {
      model[i].checked = false;
    }

    const _model = model.filter(({ orderNumber: id }, index) => {
      return evt.target.name === id;
    })[0];
    if (_model) {
      _model.checked = evt.target.checked;
    }

    this.checkState();
    this.forceUpdate();
  }

  /*****************ページング対応**************/
  async pagenationFunction(obj,number){
    // await this.setState({currentPage:number});
    // TDOO
    const { dispatch } = this.props;
    dispatch(currentPageOrder(number));
    
    this.search(true);
  }
  /****************ページング対応END************/

  checkState() {
    const { model } = this.props;
    const checkItem = model.filter(_model => {
      return _model.checked;
    });

    // チェックが0の活性その他非活性
    if (checkItem && checkItem.length === 0) {
      this.setState({ registerLock:false });
      this.setState({ updateLock:true });
      this.setState({ deleteLock:true });
      this.setState({ priceLock:true });
    } 
    if (checkItem && checkItem.length === 1) {

      this.setState({ registerLock:true });
      this.setState({ updateLock:false });
      this.setState({ deleteLock:false });
      this.setState({ priceLock:false });

    }

    if (checkItem && checkItem.length  > 1) {
      this.setState({ registerLock:true });
      this.setState({ updateLock:true });
      this.setState({ deleteLock:false });
      this.setState({ priceLock:true });
    }
  }

  handleRemoveForm() {
    const fieldList = this.state.inputForm.slice();
    fieldList.pop();
    this.setState({ inputForm: fieldList });
    this.setState({ registerLock :true});
  }

  // 入力画面の遷移
  onClickResister() {
    const { model } = this.props;
    const checkItem = model.filter(_model => {
      return _model.checked;
    })[0];
    if(checkItem) {
      this.props.history.push({ pathname: '/orderRegist', state: { orderNumber: checkItem.orderNumber }});
    } else {
      this.props.history.push({ pathname: '/orderRegist', state: { orderNumber: '' }});
    }
  }

  getYearOptions = () => {

    const year_list = [];
    const year_current = new Date().getFullYear();
    const year_min = 1970;
    const year_max = year_current + 10;
    
    for(var i = year_min; i <year_max; i++){
      year_list.push(i);
    }
    const menuItems = [];

    for (const i in year_list) {
      menuItems.push(
        <MenuItem
          value={year_list[i]}
          key={year_list[i]}
          >
          {year_list[i]}
        </MenuItem>
      );
    }
    return menuItems;
  };

  // 注文ステータス
  getOrderStatusListOptions = () => {
    const statusList = this.state.order_display_status_list;
    const menuItems = [];
    for (const i in statusList) {
      menuItems.push(
        <MenuItem
          value={statusList[i].statusId}
          key={statusList[i].statusId}
          >
          {statusList[i].status}
        </MenuItem>
      );
    }
    return menuItems;
  };

  // 取引先名
  getCompanyListOptions = () => {
    const companyList = this.state.company_list;
    const menuItems = [];
    for (const i in companyList) {
      menuItems.push(
        <MenuItem
          value={companyList[i].companyId}
          key={companyList[i].companyId}
          >
          {companyList[i].companyId + ':' + companyList[i].companyName}
        </MenuItem>
      );
    }
    return menuItems;
  };

  /**       コンボボックス対応       */
  onChangeAutoCompletField(e,value) {
    
    if(value === null || value === undefined || value ===  ''){
      this.setState({searchCompanyName : ''});
    } else {
      this.setState({searchCompanyName:value.companyID});
    }
  }

  /***********************************************************************************************
  * 検索結果のリスト表示機能、帰ってきた行数分だけPushを繰り返す。
  ***********************************************************************************************/
   getMstItemList = () => {
    const list = [];
    const { classes, model } = this.props;
    const changeItem = this.state.changeForm;
    const company_list = this.state.company_list;
    const status_list = this.state.order_display_status_list;

    for (const i in model) {
      var companyName = null;
      var orderStatusName = null;

      // 原本データは飛ばす
      if(model[i].orderNumber.substr(0,2) === '00') {
        continue;
      }

      // 表示用の処理
      company_list.forEach((v) => {
        if (v.companyId == model[i].companyID) {
          companyName = v.companyName;
          return true;
        }
      });

      status_list.forEach( (v) => {
        if (v.statusId == model[i].orderDisplayStatus) {
          orderStatusName = v.status;
          return true;
        }
      });

      list.push(
        <Box
          key={i}
          display={`flex`}
          justifyContent={`space-between`}
          flexGrow={1}
        >
          <>
            <Box className={classes.mstTblItemFirst}>
              <Checkbox
                key={model[i].orderNumber}
                name={model[i].orderNumber}
                checked={model[i].checked || false}
                onChange={this.handleCheck.bind(this)}
                inputProps={{ "aria-label": "checkbox" }}
                classes={{
                  root: classes.root,
                  checked: classes.checked
                }}
              />
            </Box>
            <Box className={classes.mstTblItem}>{model[i].orderNumber}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{model[i].projectName}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft + ' ' + classes.companyNameCell}>{companyName}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{model[i].companyPerson}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.dateCell}>{model[i].useStartDate.replace( /-/g, '/' )}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.dateCell}>{model[i].useEndDate.replace( /-/g, '/' )}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft + ' ' + classes.orderStatusCell}>{orderStatusName}</Box>
          </>
        </Box>
      );
    }

    return <Box>{list}</Box>;
  };

  // タイトル域
  render() {
    const { classes } = this.props;
    const list = this.getMstItemList();
    const year_list = this.getYearOptions();
    const status_list = this.getOrderStatusListOptions();
    const company_list = this.getCompanyListOptions();

    /**       コンボボックス対応       */
    const _companyList = this.state.company_list;
    let companyOption = [];

    _companyList.forEach(v => {
      let tmp = {};
      tmp.companyID = v.companyId;
      tmp.companyName = v.companyName;
      companyOption.push(tmp);
    });
    
    const titleDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>
          <Box p={1} className={classes.titleHeaderColor}>
            注文一覧画面
          </Box>
        </Box>
      </Box>
    );

    //検索フォーム
    const searchDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>

        {/*display={`flex`}*/}
          <Box p={2} className={classes.searchTbl}>
            {/* 検索左側*/ }
            <Box className={classes.searchAreaLeft}>
              <Box>
                <Box className={classes.searchFirstElement } display='flex'>
                  <InputLabel className={classes.InputLabel} style={{width:'80px'}}>取引先名</InputLabel>
                  {/*<TextField
                    name="searchCompanyName"
                    variant="outlined"
                    size="small"
                    className={classes.searchInputText + ' ' + classes.searchInputCompanyName}
                    value={this.state.searchCompanyName}
                    onChange={this.onChangeSearchField.bind(this)}
                  />*/}

                  {/*{<Select
                    name="searchCompanyName"
                    variant="outlined"
                    size="small"
                    //className={classes.searchInputText + ' ' + classes.searchInputCompanyName}
                    className={classes.pullDown}
                    value={this.state.searchCompanyName}
                    MenuProps={{style:{
                      height:'600px'
                    }}}
                    style={{ backgroundColor: "#FFFFFF", width:'300px'}}
                    onChange={this.onChangeSearchField.bind(this)}
                    >
                    <MenuItem value="" key="">&ensp;</MenuItem>
                    {company_list}
                  </Select>}
                  */}
                  {/********コンボボックス対応**********/}
                  {
                    <Autocomplete
                      name="searchCompanyName"
                      id="combo-box-demo"
                      size='small'
                      options={companyOption}
                      ListboxProps={{style:{height:350 }}}
                      getOptionLabel={(option) => option.companyName}
                      renderInput={
                        (params) => 
                        <TextField 
                          name="searchCompanyName" 
                          className={classes.searchInputText + ' ' + classes.searchInputCompanyName} 
                          {...params} variant="outlined"
                      />
                    }
                    onChange={(event,value) => this.onChangeAutoCompletField(event,value)}
                  />}
                  {/**********************************/}
                </Box>

                <Box className={classes.searchElement + ' ' + classes.searchUnderZone} display='flex'>
                  <InputLabel className={classes.InputLabel} style={{width:'80px'}}>注文番号</InputLabel>
                  <TextField
                    name="searchOrderNumber"
                    variant="outlined"
                    size="small"
                    className={classes.searchInputText}
                    value={this.state.searchOrderNumber}
                    onChange={this.onChangeSearchField.bind(this)}
                  />
                </Box>
              </Box>
            </Box>

            <Box>
              <Box>
                <Box display='flex'>
                  <Box className={classes.searchElement} display='flex'>
                      <InputLabel className={classes.InputLabel} style={{width:'100px'}}>取引先担当</InputLabel>

                      <TextField
                      name="searchCompanyPersonName"
                      variant="outlined"
                      size="small"
                      className={classes.searchInputTextComapany}
                      value={this.state.searchCompanyPersonName}
                      onChange={this.onChangeSearchField.bind(this)}
                    />
                  </Box>
                </Box>

                <Box display='flex' className={classes.searchUnderZone}>
                  <Box display='flex'>
                    <InputLabel className={classes.InputLabel} style={{width:'100px'}}>使用期間</InputLabel>

                    {/*<Select
                      name="searchUseStartDate"
                      variant="outlined"
                      size={"small"}
                      className={classes.pullDown}　
                      value={this.state.searchUseStartDate}
                      onChange={this.onChangeSearchField.bind(this)}
                      style={{ backgroundColor: "#FFFFFF" }}
                      error={this.state.searchUseDateCheck}
                      >
                      <MenuItem value="" key="">&ensp;</MenuItem>
                      {year_list}
                    </Select>*/}
                    <TextField
                      type="date"
                      name="searchUseStartDate"
                      variant="outlined"
                      size="small"
                      className={classes.searchDateText}
                      value={this.state.searchUseStartDate}
                      error={this.state.searchUseDateCheck}
                      onChange={this.onChangeSearchField.bind(this)}
                    />
                    <Box className={classes.span}>&ensp;～&ensp;</Box>

                    {/*<Select
                      name="searchUseEndDate"
                      variant="outlined"
                      size={"small"}
                      className={classes.pullDown}　
                      value={this.state.searchUseEndDate}
                      onChange={this.onChangeSearchField.bind(this)}
                      style={{ backgroundColor: "#FFFFFF" }}
                      error={this.state.searchUseDateCheck}
                      >
                      <MenuItem value="" key="">&ensp;</MenuItem>
                      {year_list}
                    </Select>*/}

                    <TextField
                      type="date"
                      name="searchUseEndDate"
                      variant="outlined"
                      size="small"
                      className={classes.searchDateText}
                      value={this.state.searchUseEndDate}
                      error={this.state.searchUseDateCheck}
                      onChange={this.onChangeSearchField.bind(this)}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box>
              <Box className={classes.searchElement } display='flex'>
                <InputLabel className={classes.InputLabel} style={{width:'150px'}}>案件名</InputLabel>
                <TextField
                  name="searchProjectName"
                  variant="outlined"
                  size="small"
                  className={classes.searchInputText}
                  value={this.state.searchProjectName}
                  onChange={this.onChangeSearchField.bind(this)}
                    />
              </Box>

              <Box display='flex' className={classes.searchUnderZone}>
                    <InputLabel className={classes.InputLabel} style={{width:'150px'}}>注文ステータス</InputLabel>

                    <Select
                      name="searchOrderStatus"
                      variant="outlined"
                      size={"small"}
                      className={classes.pullDown}　
                      value={this.state.searchOrderStatus}
                      onChange={this.onChangeSearchField.bind(this)}
                      style={{ backgroundColor: "#FFFFFF", width:'300px'}}
                      error={this.state.searchOrderStatusError}
                      >
                      <MenuItem value="" key="">&ensp;</MenuItem>
                      {status_list}
                    </Select>
                  </Box>
            </Box>


            {/* 検索右側*/}
            <Box className={classes.searchAreaRight} style={{textAlign:'center'}}>

                <Box width={90} display='inline-block'>
                  <Fab
                    className={classes.searchFabColor}
                    onClick={this.search.bind(this,false)}
                  >
                    <SearchOutlinedIcon style={{ fontSize: 30 }} />
                  </Fab>
                </Box>
                <Box width={70} display='inline-block'>
                  <Fab
                    className={classes.searchFabColor}
                    onClick={this.onClearSearchField.bind(this)}
                  >
                    <CancelPresentationIcon style={{ fontSize: 30 }} />
                  </Fab>
                </Box>
                
            </Box>
          </Box>
        </Box>
      </Box>
    );

  /***********************************************************************************************
  * 画面のテーブルのヘッダ情報のJSXとpropsの設定です。
  * 検索結果を表示する1レコード文をlistとして宣言しており、(429行目)
  * propsにレコードの情報が入るようにmapしています。
  * 宣言のみなので、検索ボタンが押されてメソッドが走るまではヘッダ部分の描画になります。
  ***********************************************************************************************/
    const mstTbl = (
      <Box style={{ paddingBottom: "16px" }}>

        <Box
          style={{
            // border: `1px solid #525457`,
            borderBottom: `1px solid #525457`,
            borderRadius:'5px'
          }}
        >
          <Box display={`flex`} justifyContent={`space-between`} flexGrow={1}>
            <Box className={classes.mstTblColFirst}>
              <Checkbox
                checked={false}
                //onChange={this.handleCheckAll.bind(this)}
                style={{ color: "#4A4A4B" }}
              />
            </Box>
            <Box className={classes.mstTblCol}>注文番号</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>案件名</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft + ' ' + classes.companyNameCell}>取引先</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>取引先担当</Box>
            <Box className={classes.mstTblCol + ' ' + classes.dateCell}>開始日</Box>
            <Box className={classes.mstTblCol + ' ' + classes.dateCell}>終了日</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft + ' ' + classes.orderStatusCell}>注文ステータス</Box>
          
          </Box>
          {list}
        </Box>

          {/*****************ページング対応**************/}
          <Box display="flex" justifyContent="center" marginTop="15px">
            <Box>
              <Pagination 
                count={this.props.model_pageCount} 
                color="primary" 
                onChange={(event,page) => this.pagenationFunction(event,page)}
                page={this.props.model_currentPage}
                />
            </Box>
          </Box>
          {/****************ページング対応END************/}
      </Box>
    );

  /***********************************************************************************************
  * 画面下部の「登録」「変更」「削除」「単価入力入力」のJSXとpropsの設定のメソッドです。
  ***********************************************************************************************/
    const buttonDiv = (
      <Grid container spacing={3} /*justify="center"*/ style={{textAlign:'center'}}>
        <Grid item xs={4} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.onClickResister.bind(this)}
            endIcon={<PostAddIcon />}
            disabled={this.state.registerLock}
          >
            登録
          </Button>
        </Grid>
        <Grid item xs={4} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            //onClick={this.update.bind(this)}
            endIcon={<EditIcon />}
            disabled={this.state.updateLock}
            onClick={this.onClickResister.bind(this)}
          >
            変更
          </Button>
        </Grid>
        <Grid item xs={4} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.delete.bind(this), '削除しますか？')}
            endIcon={<DeleteIcon />}
            disabled={this.state.deleteLock}
          >
          
            削除
            
          </Button>
        </Grid>
      </Grid>
    );

    return (
      <Box style={{ height: "100vh", width: "100vw" }}>
        <Header value={this.state.headerErrorMessage}/>
        <Alert 
          id="successAlert"
          style={{display:'none'}}
          className={classes.successAlertStyle}
          severity="success">
            {this.state.successMessage}
        </Alert>
        <Box
          style={{
            height: "calc(100% - 96px)",
            width: "calc(100% - 32px)",
            padding: "16px",
            overflow: "auto"
          }}
        >
          <Box minWidth={975}>
            {titleDiv}
            {searchDiv}
            {/****  検索ヒット数対応  ****/}
            <Box  className={classes.searchResult}>
              <span>検索結果：</span>
              <span>{this.props.model_hitsCount}</span>
              <span> 件</span>
            </Box>
            {mstTbl}
            {buttonDiv}
          </Box>
          <AlertDialog/>
        </Box>
      </Box>
    );
  }
  opneDialog (func, message) {

    var Dialog = document.getElementById('dialogBox');
    var okButton = document.getElementById('dialogOkButton');
    var cancelButton = document.getElementById('dialogCancelButton');
 
    document.getElementById('dialogMessage').innerHTML= message;
 
    // ダイアログ表示
    Dialog.style.display = '';
    // OK 押下の処理
    okButton.onclick = func;
    // cancel　押下の処理
    cancelButton.onclick = function() {
     Dialog.style.display = 'none';
     };
   }

  /***********************************************************************************************
  * 検索ボタンがクリックされた時に実行されるメソッドです。
  * 一応検索フィールドに入力された際に入力チェックを行っており、入力謝りがあればreturnを返しています
  ***********************************************************************************************/
  async search(isPageNation) {

    const { dispatch } = this.props;

    // 検索条件の項目
    const _searchCompanyName = this.state.searchCompanyName || null;
    const _searchCompanyPersonName = this.state.searchCompanyPersonName || null;
    const _searchProjectName = this.state.searchProjectName || null;
    const _searchOrderNumber = this.state.searchOrderNumber || null;
    const _searchUseStartDate = this.state.searchUseStartDate || null;
    const _searchUseEndDate = this.state.searchUseEndDate || null;
    const _searchOrderStatus = this.state.searchOrderStatus || null;

    await this.setState({headerErrorMessage:""});

    const isFailed = this.validateUseDateRange(_searchUseStartDate, _searchUseEndDate);

    if(isFailed){
      return
    }

    let _currentPage = 0; 
    if(isPageNation) {
      _currentPage = this.props.model_currentPage -1;
    }
    else {
      //this.setState({currentPage:1});
      dispatch(currentPageOrder(1));
    }

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/order/list-normal',
      {
        //pageIndex: 0,
        /*****************ページング対応**************/
        pageIndex:_currentPage,
        /****************ページング対応END************/
        pageSize: 20,//100,
        companyID:_searchCompanyName,
        companyPerson:_searchCompanyPersonName,
        projectName:_searchProjectName,
        orderNumber:_searchOrderNumber,
        useStartDate:_searchUseStartDate,
        useEndDate:_searchUseEndDate,
     /*   useStartDate:_searchUseStartDate === null 
          ? _searchUseStartDate : new Date().setFullYear(_searchUseStartDate),
        useEndDate:_searchUseEndDate === null 
          ? _searchUseEndDate : new Date().setFullYear(_searchUseEndDate),*/
        orderDisplayStatus:_searchOrderStatus,
      },{
        param,
        withCredentials: true,
      }
    ).then( response => {
      /*****************ページング対応**************/
      dispatch(pageCountOrder(response.data.totalPageCount));
      /****************ページング対応END************/
      
      // ヒット件数
      dispatch(hitsCountOrder(response.data.totalCount));

      dispatch(listOrder(response.data.list)); 
    }).catch(e =>{
      console.log(e);
      this.setState({headerErrorMessage:"検索に失敗しました"});
      return;
    });
    this.checkState();
    this.forceUpdate();
  }
  
  // 先に注文データに紐づいてた見積データを削除。
  async delete() {
    const { model } = this.props;
    const deleteList = [];
    let isFailed = false;

    await this.setState({headerErrorMessage:""});

    // 選択している注文番号を取得
    for (let i in model) {
      if (model[i].checked) {
        deleteList.push(model[i].orderNumber);
      }
    }

    const param = Cookies.get('token');

    for (let i in deleteList) {
      await axios.delete(
        REQUEST_PATH + 'api/estimate/delete-estimate-all/' + deleteList[i],{
          param,
          withCredentials: true,
        }
      ).then( response => {

      }).catch(e =>{
        console.log(e);
        this.setState({headerErrorMessage:"見積データの削除に失敗しました"});
        isFailed = true;
      });
    }

    for (let i in deleteList) {
      await axios.delete(
        REQUEST_PATH + 'api/order/delete/' + deleteList[i],{
          param,
          withCredentials: true,
        }
      ).then( response => {
        
      }).catch(e =>{
        console.log(e);
        this.setState({headerErrorMessage:"注文データの削除に失敗しました"});
        isFailed = true;
      });
    }

    if(!isFailed) {
      this.openeSuccessAlert("削除しました");
    }

    document.getElementById('dialogBox').style.display = 'none';
    this.search(false);
  }

  validateUseDateRange(startDate ,endDate) {
    // 値があるかどうか
    var isSelectedStart = true;
    var isSelectedEnd = true;
    var isFailed = false;

    this.setState({searchUseDateCheck:false});

    // 選択されているかどうか
    if(startDate === null || startDate === ''  || startDate === undefined) {
      isSelectedStart = false;
    }

    if(endDate === null || endDate === ''  || endDate === undefined) {
      isSelectedEnd = false;
    }

    // 両方選択されていない場合
    if(!isSelectedStart && !isSelectedEnd){
      return false;
    } 

    // 両方選択されている場合
    if(isSelectedStart && isSelectedEnd){

      if(startDate > endDate){
        isFailed = true;
        this.setState({searchUseDateCheck:true});
      }
    } else { // 片方のみ選択されている場合 
      isFailed = true;
      this.setState({searchUseDateCheck:true});
    }

    return isFailed;
  }

  // 成功アラートの表示
  openeSuccessAlert(msg){

    document.getElementById('successAlert').style.display = '';
    this.setState({ successMessage: msg});

    // 2秒で消えるようにする。
    setTimeout(() => {
      document.getElementById('successAlert').style.display = 'none';
    },2000);
  }

  static get propTypes() {
    return {};
  }

  // Styleの設定
  static get styles() {
    return theme => ({

      searchInputText:{
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        lineheight: 2.5,
        width:'300px',
      },

      searchInputTextComapany:{
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        lineheight: 2.5,
        width:'380px',
      },

      searchDateText:{
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        lineheight: 2.5,
        width:'170px',
      },

      searchInputCompanyName:{
        //width:'400px',
      },

      mstTblItemFirst: {
        border: "1px solid #b0bec5",
        borderLeft: "1px solid #525457",
        // borderTop: "0",
        borderBottom: "0",
        textAlign: "center",
        width: 70,
        flexGrow: 1
      },
      mstTblItem: {
        border: "1px solid #b0bec5",
        borderLeft: "0",
        // borderTop: "0",
        borderBottom: "0",
        width: 280,
        flexGrow: 1,
        display: "flex",
        //textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },

      mstTblColorItem: {
        border: "1px solid #b0bec5",
        borderRight: "1px solid #525457",
        borderLeft: "0",
        // borderTop: "0",
        borderBottom: "0",
        width: 280,
        flexGrow: 1,
        textAlign: "center",
        color: "#4A4A4B",
        fontWeight: 500,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
      },
      addMstTblItemFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        width: 70,
        height: 80,
        flexGrow: 1
      },
      addMstTblItem: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: 280,
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      addMstTblColorItem: {
        border: "1px solid #525457",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: 280,
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      boxColor: {
        minHeight: 20,
        minWidth: 20,
        maxHeight: 20,
        maxWidth: 20,
        marginLeft: 15,
        marginRight: 6,
        border: "1px solid #4A4A4B",
        borderRadius: "6px"
      },
      root: {
        color: "#607d8b",
        "&$checked": {
          color: "#FF7200"
        }
      },
      checked: {},

      titleHeaderColor: {
        border: "1px solid #525457",
        backgroundColor: "#1976d2",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 20,
        fontWeight: "bold",
        borderRadius:"5px"
      },
      tblHeaderColor: {
        borderTop: "1px solid #525457",
        borderLeft: "0",
        borderRight: "1px solid #525457",
        backgroundColor: fade("#1565c0", 0.8),
        color: "#4A4A4B",
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold"
      },
      searchTbl: {
        border: "1px solid #525457",
        backgroundColor: "#bbdefb",
        color: "#4A4A4B",
        fontSize: 16,
        justifyContent: "space-between",
        display:'flex',
        //alignItems: "center",
        borderRadius:"5px"
      },
      mstTblColFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        textAlign: "center",
        width: 70,
        flexGrow: 1
      },
      mstTblCol: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },

      mstTblColDouble: {
        width: 560,
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        flexGrow: 1,
        textAlign: "center",
        justifyContent: "center",                                 
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
        },

      buttonColor: {
        width: 150,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        // color: "#E1E1E1"
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing:10
      },

      searchFabColor: {
        backgroundColor: "#2196f3",
        color: "#EEEEEE"
      },

      searchAreaLeft:{
        //display:'inline-block',
        //paddingTop:5,
        //display: 'flex',
        //justifyContent: 'space-between'
      },

      InputLabel:{
        //display:"inline",
        //marginRight:5
        paddingTop:'3%',
        textAlign:'right',
        marginRight:'10px',
      },

      searchElement:{
        //marginLeft:'5%',
      },

      searchFirstElement:{
        //marginLeft:15,
      },

      searchUnderZone:{
        marginTop:'15px',
      },

      pullDown:{
        backgroundColor: "#FFFFFF",
        width: 130,
        borderRadius: 5,
        height:40,
        textAlign:'center'
        //maxHeight: "28px"
      },
       span:{
        lineHeight: '35px',
        marginLeft:"5px",
        marginRight:"4px"
      },
      searchAreaRight:{
        marginTop:"21px"
      },
      searchResult:{
        textAlign:"left",
        marginBottom:"10px",
        paddingLeft:"5px",
        //fontSize:'1.5rem'
      },
      itemAlingnLeft:{
        justifyContent: "left",
        paddingLeft: '1%',
      },
      itemTitlePaddingLeft:{
        paddingLeft: '1%',
      },
      dateCell:{
        width:'200px'
      },
      companyNameCell:{
        width:'360px'
      },
      orderStatusCell:{
        width:'200px'
      },
      successAlertStyle: {
        position: 'relative',
        justifyContent: 'center',
        width: '100%',
        backgroundColor:'#80cbc4',
        fontSize:'1rem'
      },
    });
  }
}

// redux/index.js プロパティ
const mapStateToProps = ({ order: { list } }) => ({ model: list }) ;
const mapStateToProps2 = ({ order_pageCount: {totalPageCount} }) => ({model_pageCount:totalPageCount});
const mapStateToProps3 = ({ order_currentPage: {currentPage} }) => ({model_currentPage:currentPage});
const mapStateToProps4 = ({ order_hitsCount: {hitsCount} }) => ({model_hitsCount:hitsCount});

const mapDispatchToProps = dispatch => ({ dispatch });
const { styles } = Component;

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  connect(mapStateToProps2,mapDispatchToProps),
  connect(mapStateToProps3,mapDispatchToProps),
  connect(mapStateToProps4,mapDispatchToProps),
)(Component);
