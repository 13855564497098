import React from "react";
import { connect } from "react-redux";
import { withStyles, fade } from "@material-ui/core/styles";
import { compose } from "redux";

import { Box,TextField} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import moment from "moment"


class Component extends React.Component {

  state = {
    billingExcelDate: moment().format('YYYY-MM-DD'),
    billingExcelDateHelperText: '',
    billingExcelDateError: false
  };

  handleChange = ev => {
    this.setState({ billingExcelDate: ev.target.value });
    if(ev.target.value === undefined || ev.target.value === null || ev.target.value === ''){
      this.setState({ billingExcelDateHelperText: '有効日付を入力してください' });
      this.setState({ billingExcelDateError: true });
    }
    else{
      this.setState({ billingExcelDateHelperText: ''});
      this.setState({ billingExcelDateError: false });
    }
  };

  render() {

    return (
      <div>
        <Dialog id="totalBillingDialog" open={true} style={{display: 'none'}}>
          <DialogContent dividers style={{width: '300px'}}>
            <Box>
              <TextField
                onChange={this.handleChange}
                value={this.state.billingExcelDate}
                name="billingExcelDate"
                id="billingExcelDate"
                label="請求月"
                fullWidth
                size="small"
                type="date"
                helperText={this.state.billingExcelDateHelperText}
                error={this.state.billingExcelDateError}
                inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '12%',fontSize:20 } }}
              />
            </Box>
          </DialogContent>

          <DialogActions style={{justifyContent: 'center'}}>
            <Button id='outPutTatalBillingExcel'color="primary">
              請求書発行
            </Button>
            <Button id='outPutTatalBillingExcelCancel' color="primary" autoFocus>
              もどる
            </Button>
          </DialogActions>
          </Dialog>
      </div>
    );

  }
  static get propTypes() {
    return {};
  }

  static get styles() {
    return theme => ({
 
    });
  }

  static get mapStateToProps() {
    return ({ auth, user }) => ({ auth, user });
  }

  static get mapDispatchToProps() {
    return dispatch => ({ dispatch });
  }
}

// TODO 分割 ContainerとComponent
const { styles, mapStateToProps, mapDispatchToProps } = Component;
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
