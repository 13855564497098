import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
// import PropTypes from 'prop-types';

class Component extends React.Component {

  render() {
    const { classes, children } = this.props;
    return (
      <Box display={`flex`} flexDirection={`column`} className={classes.root}>{children}</Box>
    )
  }

  static get styles() {
    return (theme) => ({ root: { height: `100%` } });
  }
}

const { styles } = Component;
export default withStyles(styles)(Component);