import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

class Component extends React.Component {

  render() {
    return (
      <Box display={`flex`}>
        
      </Box>
    )
  }

  static get propTypes() {
    return {}
  }

  static get styles() {
    return (theme) => ({})
  }
}

const { styles } = Component;
export default withStyles(styles)(Component)