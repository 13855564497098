import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles, fade } from "@material-ui/core/styles";
import { Box, Button, Grid, Fab, Checkbox, TextField,InputLabel } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "axios";
import EditIcon from '@material-ui/icons/Edit';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Header from "../header/Header";
import AlertDialog from "../components/Dialog";
import { moveTo, listPickup } from "../actions";
import REQUEST_PATH from "../components/Common";
import { Alert } from '@material-ui/lab';
import Cookies from "js-cookie";

class Component extends React.Component {
  state = {
    // 検索項目
    searchPickupMethod:'',
    // フォーム
    inputForm: [],
    changeForm: null,
    // 非活性
    addLock: false,
    removeLock: true,
    registerLock: true,
    updateLock:true,
    deleteLock:true,
    // ヘルパーテキスト
    pickupIdHelperText:"",
    pickupMethodHelperText:"",
    // エラー
    pickupIdError:false,
    pickupMethodError:false,
    // エラーメッセージ
    errorMessage:'',
  };

  constructor(props){
    super(props);
  }
  // render後、ボタン制御（遷移元からの復帰対策）
  componentDidMount() {
    this.checkState();
  }

  // 検索域のイベント
  onChangeSearchField(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  // 検索域のクリアボタン押下
  onClearSearchField() {
    this.setState(
      {
        searchPickupMethod: "",
      });
    this.forceUpdate();
  }

  /***********************************************************************************************
  * 追加時のフィールドにイベントが発生した際呼び出される関数
  * ここにエラー時のhelperTextの内容を記述する
  ***********************************************************************************************/
  onChangeAddField(evt, index) {
    const changeForm = this.state.inputForm.slice()[index];
    const val = evt.target.value;
    switch (evt.target.name) {
      
      case "pickupId": {
        changeForm.pickupId = val;
        if(isNaN(changeForm.pickupId)){
          this.setState({pickupIdHelperText:"半角数字以外が入力されています"});
          this.setState({pickupIdError:true});
          break;
        }

        if(changeForm.pickupId.length > 0 && changeForm.pickupId.length < 2) {
          this.setState({pickupIdHelperText:"桁数が足りません"});
          this.setState({pickupIdError:true});
          break;
        }

        if(changeForm.pickupId.length > 2){
          this.setState({pickupIdHelperText:"桁数が長すぎます"});
          this.setState({pickupIdError:true});
          break
        }

        this.setState({pickupIdHelperText:""});
        this.setState({pickupIdError:false});
        break;
      }
      case "pickupMethod": {
        changeForm.pickupMethod = val;
      
        if(changeForm.pickupMethod.length > 8 ){
          this.setState({pickupMethodHelperText:"入力文字数が長すぎます"});
          this.setState({pickupMethodError:true});
        } else {
          this.setState({pickupMethodHelperText:""});
          this.setState({pickupMethodError:false});
        }
        break;
      }
    }
    this.forceUpdate();
  }

  //編集時にイベントが発生した際呼び出される関数
  onChangeField(e) {
    const changeItem = this.state.changeForm;
    const val = e.target.value;

    switch (e.target.name) {
      case "pickupMethod": {
        changeItem.pickupMethod = val;
        
        if(changeItem.pickupMethod.length > 8 ){
          this.setState({pickupMethodText:"入力文字数が長すぎます"});
          this.setState({pickupMethodError:true});
        } else {
          this.setState({pickupMethodHelperText:""});
          this.setState({pickupMethodError:false});
        }
        break;
      }
      default: {
        break;
      }
    }
    this.forceUpdate();
  }

  handleToAboutPage() {
    const { dispatch } = this.props;
    dispatch(moveTo.Sample());
  }

  handleCheckAll(evt) {
    const { model } = this.props;

    // Fab-ボタンが活性の場合、選択させない
    if(!this.state.removeLock) {
      return;
    }

    for (let i in model) {
      model[i].checked = evt.target.checked;
    }
    this.checkState();
    this.forceUpdate();
  }
  //チェックボックスにチェックを入れた時に呼び出される関数
  handleCheck(evt) {
    const { model } = this.props;

    // Fab-ボタンが活性の場合、選択させない
    if(!this.state.removeLock) {
      return;
    }

    const _model = model.filter(({ pickupId: id }, index) => {
      return evt.target.name === id;
    })[0];
    if (_model) {
      _model.checked = evt.target.checked;
    }
    this.checkState();
    this.forceUpdate();
  }

  checkState() {
    const { model } = this.props;
    const inputItemNum = this.state.inputForm.slice().length;
    const checkItem = model.filter(_model => {
      return _model.checked;
    });

    if (checkItem && checkItem.length === 1 && inputItemNum === 0) {
      this.setState({ setColor:checkItem[0].colorCode});
      this.setState({ changeForm: { ...checkItem[0] } });

      this.setState({ updateLock:false });
      this.setState({ deleteLock:false});

      this.setState({ addLock: true });
      this.setState({ removeLock: true });
    } else {
      this.setState({ changeForm: null });
    }
    
    if (checkItem && checkItem.length > 1) {
      
      this.setState({ deleteLock:false});
      this.setState({ updateLock:true });

      this.setState({ addLock: true });
      this.setState({ removeLock: true });
    }

    if (checkItem && checkItem.length === 0) {
      this.setState({ updateLock:true });
      this.setState({ deleteLock:true});
      this.setState({ registerLock:true});

      this.setState({ addLock: false });
      this.setState({ removeLock: true });
    }
  }

  handleAddForm() {
    const fieldList = this.state.inputForm.slice();
    fieldList.push({
      pickupId: "",
      pickupMethod: "",
    });

    this.setState({ registerLock :false});
    this.setState({ removeLock :false});
    this.setState({ addLock :true});
    this.setState({ inputForm: fieldList });
  }

  handleRemoveForm() {
    const fieldList = this.state.inputForm.slice();
    fieldList.pop();
    this.setState({ inputForm: fieldList });
    this.setState({ registerLock :true});
    this.setState({ removeLock :true});
    this.setState({ addLock :false});

    // エラー要素のリセット
    this.setState({pickupMethodHelperText:''});
    this.setState({pickupMethodError:false});
    this.setState({pickupIdHelperText:''});
    this.setState({pickupIdError:false});
  }

  // 入力行の追加
  addForm(pickup_id, pickup_method, i) {
    const { classes } = this.props;
    const form = this.state.inputForm.slice()[i];

    return (
      <Box
        key={i}
        display={`flex`}
        justifyContent={`space-between`}
        flexGrow={1}
      >
        <Box className={classes.addMstTblItemFirst}>
          <Checkbox
            checked={true}
            onChange={this.handleCheck.bind(this)}
            inputProps={{ "aria-label": "checkbox" }}
            classes={{
              root: classes.root,
              checked: classes.checked
            }}
          />
        </Box>
        <Box className={classes.addMstTblItem}>
          <TextField
            name="pickupId"
            label="輸送ID"
            variant="outlined"
            size={"small"}
            value={form.pickup_id}
            onChange={event => this.onChangeAddField(event, i)}
            style={{ backgroundColor: "#FFFFFF" }}
            error={this.state.pickupIdError}
            helperText={this.state.pickupIdHelperText}
          />
        </Box>
        <Box className={classes.addMstTblItem + ' ' + classes.itemAlingnLeft}>
          <TextField
            name="pickupMethod"
            label="輸送方法"
            variant="outlined"
            size={"small"}
            value={form.pickup_method}
            onChange={event => this.onChangeAddField(event, i)}
            error={this.state.pickupMethodError}
            style={{ backgroundColor: "#FFFFFF" }}
            helperText={this.state.pickupMethodHelperText}
          />
        </Box>
      </Box>
    );
  }

  /***********************************************************************************************
  * 検索結果のリスト表示機能、帰ってきた行数分だけPushを繰り返す。
  ***********************************************************************************************/
  getMstItemList = () => {
    const list = [];
    const { classes, model } = this.props;
    const changeItem = this.state.changeForm;

    for (const i in model) {
      const formIsSelected = changeItem && changeItem.pickupId === model[i].pickupId;
      list.push(
        <Box
          key={i}
          display={`flex`}
          justifyContent={`space-between`}
          flexGrow={1}
        >
          {formIsSelected ? (
            <>
              <Box className={classes.addMstTblItemFirst}>
                <Checkbox
                  key={model[i].pickupId}
                  name={model[i].pickupId}
                  checked={model[i].checked || false}
                  onChange={this.handleCheck.bind(this)}
                  inputProps={{ "aria-label": "checkbox" }}
                  classes={{
                    root: classes.root,
                    checked: classes.checked
                  }}
                />
              </Box>
              <Box className={classes.addMstTblItem}>{model[i].pickupId}</Box>
              <Box className={classes.addMstTblItem + ' ' + classes.itemAlingnLeft}>
                <TextField
                  name="pickupMethod"
                  label="輸送方法"
                  variant="outlined"
                  size={"small"}
                  value={changeItem.pickupMethod}
                  onChange={event => this.onChangeField(event)}
                  style={{ backgroundColor: "#FFFFFF" }}
                  error={this.state.pickupMethodError}
                  helperText={this.state.pickupMethodHelperText}
                />
              </Box>
            </>
          ) : (
            <>
              <Box className={classes.mstTblItemFirst}>
                <Checkbox
                  key={model[i].pickupId}
                  name={model[i].pickupId}
                  checked={model[i].checked || false}
                  onChange={this.handleCheck.bind(this)}
                  inputProps={{ "aria-label": "checkbox" }}
                  classes={{
                    root: classes.root,
                    checked: classes.checked
                  }}
                />
              </Box>
              <Box className={classes.mstTblItem}>{model[i].pickupId}</Box>
              <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{model[i].pickupMethod}</Box>
            </>
          )}
        </Box>
      );
    }
    return <Box>{list}</Box>;
  };

  // タイトル域
  render() {
    const { classes } = this.props;
    const list = this.getMstItemList();

    const titleDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>
          <Box p={1} className={classes.titleHeaderColor}>
            輸送方法管理画面
          </Box>
        </Box>
      </Box>
    );

    //検索フォーム
    const searchDiv = (
      <Box /*display={`flex`}*/ style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>
          <Box p={2} className={classes.searchTbl}>
            <Box>
              <Box p={1} style={{display:'flex'}}>
                <InputLabel ref="<Box>" className={classes.InputLabel}>&ensp;&ensp;輸送方法</InputLabel>
                <TextField
                  id="searchPickupMethod"
                  name="searchPickupMethod"
                  variant="outlined"
                  size="small"
                  className={classes.searchInputText}
                  value={this.state.searchPickupMethod}
                  onChange={this.onChangeSearchField.bind(this)}
                />
              </Box>
            </Box>

            <Box>
                <Box width={90} display='inline-block'>
                  <Fab
                    className={classes.searchFabColor}
                    onClick={this.search.bind(this)}
                  >
                    <SearchOutlinedIcon style={{ fontSize: 30 }} />
                  </Fab>
                </Box>
                <Box width={70} display='inline-block'>
                  <Fab
                    className={classes.searchFabColor}
                    onClick={this.onClearSearchField.bind(this)}
                  >
                    <CancelPresentationIcon style={{ fontSize: 30 }} />
                  </Fab>
                </Box>    
            </Box>
          </Box>
        </Box>
      </Box>
    );

  /***********************************************************************************************
  * 画面のテーブルのヘッダ情報のJSXとpropsの設定です。
  * 検索結果を表示する1レコード文をlistとして宣言しており、(429行目)
  * propsにレコードの情報が入るようにmapしています。
  * 宣言のみなので、検索ボタンが押されてメソッドが走るまではヘッダ部分の描画になります。
  ***********************************************************************************************/
    const mstTbl = (
      <Box style={{ paddingBottom: "16px" }}>

        <Box
          style={{
            borderBottom: `1px solid #525457`,
            borderRadius:'5px'
          }}
        >
          <Box display={`flex`} justifyContent={`space-between`} flexGrow={1}>
            <Box className={classes.mstTblColFirst}>
              <Checkbox
                onChange={this.handleCheckAll.bind(this)}
                style={{ color: "#4A4A4B" }}
              />
            </Box>
            <Box className={classes.mstTblCol}>輸送ID</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>輸送方法</Box>
          </Box>
          {list}
          {this.state.inputForm &&
            this.state.inputForm.map(
              ({ pickup_id,pickup_method }, i) =>
                this.addForm(pickup_id, pickup_method, i)
            )}
        </Box>
      </Box>
    );
  /***********************************************************************************************
  * 右下の追加削除ボタン
  * 関数名fubDivになっているが、FabDivの間違い？
  ***********************************************************************************************/
    const fubDiv = (
      <Box display={`flex`} flexGrow={1} justifyContent="flex-end" height={70}>
        <Box width={50}>
          <Fab
            size="small"
            className={classes.fabColor}
            onClick={this.handleAddForm.bind(this)}
            disabled={ this.state.addLock
              //this.state.inputForm.slice().length !== 0 || this.state.addLock
            }
          >
            <AddIcon />
          </Fab>
        </Box>
        <Box width={50}>
          <Fab
            size="small"
            className={classes.fabColor}
            onClick={this.handleRemoveForm.bind(this)}
            disabled={this.state.removeLock}//this.state.inputForm.slice().length === 0 || this.state.removeLock}
          >
            <RemoveIcon />
          </Fab>
        </Box>
      </Box>
    );

  /***********************************************************************************************
  * 画面下部の「登録」「変更」「削除」のJSXとpropsの設定のメソッドです。
  ***********************************************************************************************/
    const buttonDiv = (
      <Grid container spacing={3} justify="center" style={{textAlign:'center'}}>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.insert.bind(this), '登録しますか？')}
            endIcon={<PostAddIcon />}
            disabled={this.state.registerLock}
          >
            登録
          </Button>
        </Grid>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.update.bind(this), '更新しますか？')}
            endIcon={<EditIcon />}
            disabled={this.state.updateLock}
          >
            変更
          </Button>
        </Grid>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.delete.bind(this), '削除しますか？')}
            endIcon={<DeleteIcon />}
            disabled={this.state.deleteLock}
          >
          
            削除
            
          </Button>
        </Grid>
      </Grid>
    );

    return (
      <Box style={{ height: "100vh", width: "100vw" }}>
        <Header value={this.state.errorMessage}/>
        <Alert 
          id="successAlert"
          style={{display:'none'}}
          className={classes.successAlertStyle}
          severity="success">
            {this.state.successMessage}
        </Alert>
        <Box
          style={{
            height: "calc(100% - 96px)",
            width: "calc(100% - 32px)",
            padding: "16px",
            overflow: "auto"
          }}
        >
          <Box minWidth={975}>
            {titleDiv}
            {searchDiv}
            {mstTbl}
            {fubDiv}
            {buttonDiv}
          </Box>
          <AlertDialog/>
        </Box>
      </Box>
    );
  }

  opneDialog (func, message) {

   var Dialog = document.getElementById('dialogBox');
   var okButton = document.getElementById('dialogOkButton');
   var cancelButton = document.getElementById('dialogCancelButton');

   document.getElementById('dialogMessage').innerHTML= message;

   // ダイアログ表示
   Dialog.style.display = '';
   // OK 押下の処理
   okButton.onclick = func;
   // cancel　押下の処理
   cancelButton.onclick = function() {
    Dialog.style.display = 'none';
    };
  }

  /***********************************************************************************************
  * 検索ボタンがクリックされた時に実行されるメソッドです。
  * 一応検索フィールドに入力された際に入力チェックを行っており、入力謝りがあればreturnを返しています
  ***********************************************************************************************/
  async search() {

    const { dispatch } = this.props;

    // 検索条件の項目
    const _searchPickupMethod = this.state.searchPickupMethod || null;


    const param = Cookies.get('token');
    await this.setState({errorMessage:''});
    await axios.post(
      REQUEST_PATH + 'api/pickup/list',
      {
        pageIndex: 0,
        pageSize: 100,
        pickupMethod: _searchPickupMethod,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      dispatch(listPickup(response.data.list));
    }).catch(e =>{
      // 失敗時
      this.setState({errorMessage:'検索に失敗しました'});
      console.log(e);
    });
  }
  
  /***********************************************************************************************
  * 登録ボタンをクリックされた時に実行されるメソッド
  * ヘッダーのメッセージ欄にエラーメッセージを投げてreturnしてメソッドを終了するのが適切だと思います。
  ***********************************************************************************************/
  async insert() {
    const fieldList = this.state.inputForm.slice();
    const { model } = this.props;

    const param = Cookies.get('token');

    await this.setState({errorMessage:''});
    if(this.validate(fieldList[0].pickupId, fieldList[0].pickupMethod)) {
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }

    for (let i in fieldList) {

      await axios.post(REQUEST_PATH + 'api/pickup/create', {
        pickupId: fieldList[i].pickupId,
        pickupMethod: fieldList[i].pickupMethod,
      },{
        param,
        withCredentials: true,
      }).then( response => {
        model.push({
          pickupId: fieldList[i].pickupId,
          pickupMethod: fieldList[i].pickupMethod,
          checked: false
        });

        fieldList.pop();
        this.setState({ inputForm: fieldList });
        this.openeSuccessAlert("登録しました");
      }).catch(e =>{
        console.log(e)
        this.setState({errorMessage:'登録に失敗しました'});
      });
    }
    this.forceUpdate();
    this.checkState();
    document.getElementById('dialogBox').style.display = 'none';
  }

  /***********************************************************************************************
  * 変更ボタンをクリックされた時に実行されるメソッド
  ***********************************************************************************************/
  async update() {
    const changeItem = this.state.changeForm;
    const { model } = this.props;

    const param = Cookies.get('token');

    await this.setState({errorMessage:''});
    if(this.validate(changeItem.pickupId, changeItem.pickupMethod)) {
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }

    await axios.put(REQUEST_PATH + 'api/pickup/update', {
      pickupId: changeItem.pickupId,
      pickupMethod: changeItem.pickupMethod,
    },{
      param,
      withCredentials: true,
    }).then( response => {
      const _model = model.filter(({ pickupId: id }) => {
        return changeItem.pickupId === id;
      })[0];
      if (_model) {
        _model.pickupMethod = changeItem.pickupMethod;
        _model.checked = false;
      }
      this.checkState();
      this.setState({ changeForm: null });
      this.openeSuccessAlert("更新しました");
    }).catch(e =>{
      console.log(e)
      //this.state.errorMessage
      this.setState({errorMessage:'更新に失敗しました'});
    });
    document.getElementById('dialogBox').style.display = 'none';
    this.checkState();
  }

  /***********************************************************************************************
  * 削除ボタンをクリックされた時に実行されるメソッド
  ***********************************************************************************************/
  async delete() {
    const { model } = this.props;
    const deleteList = [];

    const param = Cookies.get('token');

    await this.setState({errorMessage:''});
    for (let i in model) {
      if (model[i].checked) {
        await axios.delete(
          REQUEST_PATH + 'api/pickup/delete/' + model[i].pickupId,{
            param,
            withCredentials: true,
          }
        ).then( response => {
          deleteList.push(model[i].pickupId);
        }).catch(e =>{
          console.log(e);
          this.setState({errorMessage:'削除に失敗しました'});
        });       
      }
    }

    if(deleteList.length > 0){
      deleteList.forEach(id => {
        const index = model.findIndex(v => v.pickupId === id);
        model.splice(index, 1);
      });
      this.openeSuccessAlert("削除しました");
    }

    this.checkState();
    this.forceUpdate();
    document.getElementById('dialogBox').style.display = 'none';
  }

  // 画面ごとに引数を返すこと
  validate(pickupId , pickupMethod) {
    var isFailed = false;
    this.setState({pickupMethodError:false});
    this.setState({pickupMethodError:false});

    if(pickupId === null || pickupId === '' || pickupId === 'undefined' || 
      isNaN(pickupId)) {
      this.setState({pickupIdHelperText:"入力に誤りがあります"});
      this.setState({pickupIdError:true});
      isFailed = true;
    }

    if(pickupMethod === null || pickupMethod === '' || pickupMethod === 'undefined') {

      this.setState({pickupMethodHelperText:"入力に誤りがあります"});
      this.setState({pickupMethodError:true});
      isFailed = true;
    }

    // 範囲チェック
    if(!isFailed) {
        if(!(pickupId.length === 2)) {
          this.setState({pickupIdHelperText:"入力に誤りがあります"});
          this.setState({pickupIdError:true});
          isFailed = true;
      }

      if(pickupMethod.length === 0 || pickupMethod.length > 8) {
        this.setState({pickupMethodHelperText:"入力に誤りがあります"});
        this.setState({pickupMethodError:true});
        isFailed = true;
      }
    }

    return isFailed;
  }

  // 成功アラートの表示
  openeSuccessAlert(msg){

    document.getElementById('successAlert').style.display = '';
    this.setState({ successMessage: msg});

    // 2秒で消えるようにする。
    setTimeout(() => {
      document.getElementById('successAlert').style.display = 'none';
    },2000);
  }

  static get propTypes() {
    return {};
  }

  // Styleの設定
  static get styles() {
    return theme => ({

      searchInputText:{
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        marginLeft: 5,
        width: 230,
        marginLeft:"10px"
      },

      mstTblItemFirst: {
        border: "1px solid #b0bec5",
        borderLeft: "1px solid #525457",
        borderBottom: "0",
        textAlign: "center",
        width: 70,
        flexGrow: 1
      },
      mstTblItem: {
        border: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      addMstTblItemFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        width: 70,
        height: 80,
        flexGrow: 1
      },
      addMstTblItem: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: 280,
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },

      boxColor: {
        minHeight: 20,
        minWidth: 20,
        maxHeight: 20,
        maxWidth: 20,
        marginLeft: 15,
        marginRight: 6,
        border: "1px solid #4A4A4B",
        borderRadius: "6px"
      },
      root: {
        color: "#607d8b",
        "&$checked": {
          color: "#FF7200"
        }
      },
      checked: {},

      titleHeaderColor: {
        border: "1px solid #525457",
        backgroundColor: "#1976d2",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 20,
        fontWeight: "bold",
        borderRadius:"5px"
      },
      tblHeaderColor: {
        borderTop: "1px solid #525457",
        borderLeft: "0",
        borderRight: "1px solid #525457",
        backgroundColor: fade("#1565c0", 0.8),
        color: "#4A4A4B",
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold"
      },
      searchTbl: {
        border: "1px solid #525457",
        backgroundColor: "#bbdefb",
        //color: "#4A4A4B",
        fontSize: 16,
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius:"5px"
      },
      mstTblColFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        textAlign: "center",
        width: 70,
        flexGrow: 1
      },
      mstTblCol: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      buttonColor: {
        width: 150,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        // color: "#E1E1E1"
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing:10
      },
      searchFabColor: {
        backgroundColor: "#2196f3",
        color: "#EEEEEE"
      },
      fabColor: {
        backgroundColor: "#26a69a",
        color: "#EEEEEE"
      },
      InputLabel:{
        paddingTop:'4%',
      },
      itemAlingnLeft:{
        justifyContent: "left",
        paddingLeft: '1%',
      },
      itemTitlePaddingLeft:{
        paddingLeft: '1%',
      },
      successAlertStyle: {
        position: 'relative',
        justifyContent: 'center',
        width: '100%',
        backgroundColor:'#80cbc4',
        fontSize:'1rem'
      },
    });
  }
}

// redux/index.js プロパティ
const mapStateToProps = ({ pickupMaster: { list } }) => ({ model: list });
const mapDispatchToProps = dispatch => ({ dispatch });
const { styles } = Component;

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
