import React, { Component } from "react";
import Calendar from "react-calendar/dist/entry.nostyle";
//import ClassNames from "classnames";
import PropTypes from "prop-types";

import OrderList from "./OrderList";
import axios from "axios";
import Cookies from "js-cookie";

import { Box, Button,TextField } from "@material-ui/core";

import moment from "moment";
import { saveAs } from 'file-saver';
import REQUEST_PATH from "../components/Common";

const propTypes = {
  calendar2: PropTypes.func,
  selectMonth: PropTypes.func,
  operating: PropTypes.func
};

export default class Calender extends Component {


  constructor(props){
    super(props);
    if(this.props.selectDate !== ""){
      this.state.viewDate = this.props.selectDate;
    }
    else{
      this.state.viewDate = moment().format("YYYY-MM-DD");
    }

  }
  state = {
    selectDate:moment().format("YYYY-MM-DD"),
    viewDate:"",
  }
  onChanged(event){

  }

  //日付のクラスを付与 (祝日用)
  getTileClass = ({ date, view }) => {
    // 月表示のときのみ
    if (view !== "month") {
      return "";
    }
    const day = date.getDay();

    return day === 6 ? "saturday" : "";
  };

  //日付の内容を出力
  _getTileContent = ({ date, view }) => {
    // 月表示のときのみ
    if (view !== "month") {
      return null;
    }
    const sun = moment(date).day(0);
    const sat = moment(date).day(6);

    const { orders, maxLineCount = 5 } = this.props;
    const list = orders
      .filter(({ start, end }) => end >= sun && sat >= start)
      .slice(0, maxLineCount)
      .reduce((list, { color, start, end }, i) => {
        const backgroundColor =
          start <= date && date <= end ? color : `transparent`;
        list.push(
          <li key={i} style={{ backgroundColor }}>
            {/*data[i].company*/}
            <br></br>
          </li>
        );
        return list;
      }, []);

    const shippingOrCollect = orders.reduce((result, { shipment, collect }) => {
      const d = +date;
      return result || d === shipment || d === collect;
    }, false);
    return (
      <div className="cell" style={{ position: `relative` }}>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <ul className="item">{list}</ul>
        {shippingOrCollect ? <div style={{ backgroundColor: `red`, position: `absolute`, top: 5, left: 5, right: 5, height: 25 }}></div> : null}
      </div>
    );
  };

  // 作業指示書発行
  async outPutOperatingExcel() {
    const param = Cookies.get('token');
    const userdata = JSON.parse(param);
    console.log(userdata);
    const _selectDate = new Date(this.state.selectDate);
    const _fileName = '作業指示書.xlsm';
    await axios.get(REQUEST_PATH + 'api/excel-operate/list/', {
      params: { 
        fileName    : _fileName,
        selectDate  : _selectDate
      },
      param,
      withCredentials: true,
      responseType: "blob",
    }).then(response => {

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      });
      // ファイル名デコード
      //const contentDisposition = response.headers["content-disposition"];
      //const fileName = this.getFileName(contentDisposition);
      // ファイルのダウンロード
      saveAs(blob, _fileName);
    }).catch(e => {
      console.log(e);
      return;
    });
  }

  // ファイル名のデコード 
  getFileName(contentDisposition) { 
    let fileName = contentDisposition.substring(
      contentDisposition.indexOf("''") + 2,
      contentDisposition.length
      );

    //デコードするとスペースが"+"になるのでスペースへ置換します
    fileName = decodeURI(fileName).replace(/\+/g, " ");

    return fileName;
  }

  clickButton() {
    return this.props.onDetail();
  }
  onClickMonth(event) {
    const date = event.toISOString('en-US');
    const selectMonth = moment(date).format('YYYY/MM/DD');
    return this.props.selectMonth(selectMonth);
  }

  onActiveDateChange(event) {
    const date = event.activeStartDate.toISOString('en-US');
    const selectMonth = moment(date).format('YYYY/MM/DD');
    return this.props.selectMonth(selectMonth);
  }

  render() {
    
    const { orders, onDoubleClick, moveToOrderRegist } = this.props;
    return (
      <Box display="flex" flexGrow={1}>
        <Box>
          <Calendar
            locale="ja-JP"
            calendarType="US"
            tileDisabled={({view}) => view === "month"}
            // 日付を渡す
            value={new Date(this.state.viewDate)}
            // value={new Date()}
            view="month"
            onClickMonth={event => this.onClickMonth(event)}
            onActiveDateChange={event => this.onActiveDateChange(event)}
            tileClassName={this.getTileClass}
            tileContent={this._getTileContent.bind(this)}
          />
          <Box p={3} paddingLeft={'10px'}>
            <Button
              variant={`contained`}
              onClick={() => {
                this.clickButton();
              }}
            >
              カレンダー詳細
            </Button>

            <Button
              variant={`contained`}
              style={{marginLeft:"10px"}}
              onClick={moveToOrderRegist}
            >
                注文入力
            </Button>
            
            <TextField
              style={{marginLeft:"10px", maxWidth:'170px'}}
              variant="outlined"
              size="small"
              type="date"
              value={this.state.selectDate}
              onChange={event => this.setState({selectDate:event.target.value})}
              inputProps={{ style: { textAlign: 'center'} }}
              />
            <Button
              variant={`contained`}
              onClick={() => {
                this.outPutOperatingExcel();
              }}
              style={{
                marginLeft:"10px"
              }}
            >
              作業指示発行
            </Button>
          </Box>
        </Box>
        
        <Box pl={2} flexGrow={1}>
          <OrderList orders={orders} onDoubleClick={onDoubleClick} />
        </Box>
      </Box>
    );
  }
}

Calender.propTypes = propTypes;
