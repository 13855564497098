import { handleAction } from 'redux-actions';
import { listSales, companyCountSales } from '../actions';

const defaultState = { list: [] };
// 社数対応
const defaultState2 = { companyCount: '－' };

export const salesReducer = handleAction(listSales, (state, { payload }) => ({ ...state, list: payload }), defaultState);
// 社数対応
export const salesReducerCompanyCount = handleAction(companyCountSales, (state, { payload }) => ({ ...state, companyCount: payload }), defaultState2);

