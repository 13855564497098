import { createAction } from 'redux-actions';

const LIST_USED_HISTORY = `LIST_USED_HISTORY`;
const PAGE_COUNT_USED_HISTORY = `PAGE_COUNT_USED_HISTORY`;
const CURRENT_PAGE_USED_HISTORY = `CURRENT_PAGE_USED_HISTORY`;

export const listUsedHistory = createAction(LIST_USED_HISTORY, (list) => (list));

// ページネーション対応
export const pageCountUsedHistory = createAction(PAGE_COUNT_USED_HISTORY, (totalPageCount) => (totalPageCount));
export const currentPageUsedHistory = createAction(CURRENT_PAGE_USED_HISTORY, (currentPage) => (currentPage));