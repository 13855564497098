import { createAction } from 'redux-actions';

const LIST_COMPANY = `LIST_COMPANY`;
const PAGE_COUNT_COMPANY = `PAGE_COUNT_COMPANY`;
const CURRENT_PAGE_COMPANY = `CURRENT_PAGE_COMPANY`;
const HITS_COUNT_COMPANY = `HITS_COUNT_COMPANY`;

export const listCompany = createAction(LIST_COMPANY, (list) => (list));
// ページネーション対応
export const pageCountCompany = createAction(PAGE_COUNT_COMPANY, (totalPageCount) => (totalPageCount));
export const currentPageCompany = createAction(CURRENT_PAGE_COMPANY, (currentPage) => (currentPage));
// ヒット件数対応
export const hitsCountCompany = createAction(HITS_COUNT_COMPANY, (hitsCount) => (hitsCount));