import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch, Redirect } from "react-router-dom";
import { signedIn } from "../actions";
import { getUser } from "../utils";
import { PAGES } from "../pages";

class Component extends React.Component {
  componentDidMount() {
    const token = Cookies.get(`token`);
    token && this.getUser(token);
  }

  render() {
    const { user } = this.props;
    return (
      user ? this.authenticated() : this.unauthenticated()
    );
  }

  getUser(token) {
    const { dispatch } = this.props;
    getUser(token)
      .then(user => dispatch(signedIn(user)))
      .catch(() => {
        Cookies.remove(`token`);
        dispatch(signedIn(null));
      });
  }

  authenticated() {
    return (
      <Switch>
        <Route
          path={PAGES.NotFound.path}
          component={PAGES.NotFound.component}
        />
        <Route exact path={PAGES.Top.path} component={PAGES.Top.component} />
        <Route path={PAGES.SampleOrder.path} component={PAGES.SampleOrder.component} />
        <Route path={PAGES.Signin.path} component={PAGES.Signin.component} />
        <Route path={PAGES.OrderRegist.path} component={PAGES.OrderRegist.component} />
        <Route path={PAGES.PersonMaster.path} component={PAGES.PersonMaster.component} />
        <Route path={PAGES.TypeMaster.path} component={PAGES.TypeMaster.component} />
        <Route path={PAGES.ModelMaster.path} component={PAGES.ModelMaster.component} />
        <Route path={PAGES.StatusMaster.path} component={PAGES.StatusMaster.component} />
        <Route path={PAGES.LicenseMaster.path} component={PAGES.LicenseMaster.component} />
        <Route path={PAGES.ProductStock.path} component={PAGES.ProductStock.component} />
        <Route path={PAGES.CompanyMaster.path} component={PAGES.CompanyMaster.component} />
        <Route path={PAGES.CompanyMasterRegist.path} component={PAGES.CompanyMasterRegist.component} />
        <Route path={PAGES.LicenseTypeMaster.path} component={PAGES.LicenseTypeMaster.component} />
        <Route path={PAGES.OrderDisplayStatusMaster.path} component={PAGES.OrderDisplayStatusMaster.component} />
        <Route path={PAGES.PickupMaster.path} component={PAGES.PickupMaster.component} />
        <Route path={PAGES.ProductInput.path} component={PAGES.ProductInput.component} />
        <Route path={PAGES.ProductList.path} component={PAGES.ProductList.component} />
        <Route path={PAGES.UsedHistory.path} component={PAGES.UsedHistory.component} />
        <Route path={PAGES.RepairHistory.path} component={PAGES.RepairHistory.component} />
        <Route path={PAGES.Order.path} component={PAGES.Order.component} />
        <Route path={PAGES.StatusInput.path} component={PAGES.StatusInput.component} />
        <Route path={PAGES.Sales.path} component={PAGES.Sales.component} />
        <Route path={PAGES.PasswordEdit.path} component={PAGES.PasswordEdit.component} />
        <Route path={PAGES.UserList.path} component={PAGES.UserList.component} />
        <Route path={PAGES.UserRegist.path} component={PAGES.UserRegist.component} />
        <Redirect to={PAGES.NotFound.path} />
      </Switch>
    );
  }

  unauthenticated() {
    return <Route exact component={PAGES.Signin.component} />;
  }

  static get propTypes() {
    return {};
  }

  static get styles() {
    return theme => ({});
  }

  static get mapStateToProps() {
    return ({ auth, user }) => ({ auth, user });
  }

  static get mapDispatchToProps() {
    return dispatch => ({ dispatch });
  }
}

// TODO 分割 ContainerとComponent
const { styles, mapStateToProps, mapDispatchToProps } = Component;
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
export const BASENAME = Component.BASENAME;
