import NotFoundPage from './containers/NotFoundPage';
import SigninPage from './containers/SigninPage';
import SamplePage from './containers/SampleTopPage';
import PersonMasterPage from './personmaster/PersonMaster';
import SampleOrderPage from './containers/SampleOrderPage';
import OrderRegistPage from './orders/OrderRegist';
import TypeMasterPage from './typeMaster/TypeMasterPage';
import ModelMasterPage from './modelMaster/ModelMasterPage';
import StatusMasterPage from './statusmaster/StatusMaster';
import LicenseMasterPage from './licensemaster/licenseMasterPage';
import ProductStockPage from './productStock/productStock';
import CompanyMasterPage from './companymaster/companyMasterPage';
import CompanyMasterRegistPage from './companymaster/companyMasterRegistPage';
import LicenseTypeMasterPage from './licenseTypeMaster/LicenseTypeMasterPage';
import OrderDisplayStatusMasterPage from './orderDisplayStatusMaster/OrderDisplayStatusMasterPage';
import PickupMasterPage from './pickupMaster/PickupMasterPage';
import ProductInputPage from './productInput/ProductInput';
import ProductListPage from './productInput/ProductList';
import UsedHistoryPage from './productInput/UsedHistory';
import RepairHistoryPage from './productInput/RepairHistory';
import OrderPage from './orders/OrderPage';
import StatusInputPage from './statusinput/StatusInput';
import SalesPage from './sales/SalesPage';
import PasswordEdit from './userManage/passEdit';
import UserRegist from './userManage/userRegist';
import UserList from './userManage/userList';


export const PAGES = {
  Signin: {
    path: `/signin`,
    component: SigninPage,
  },
  NotFound: {
    path: `/404`,
    component: NotFoundPage,
  },
  Top: {
    path: `/`,
    component: SamplePage,
  },
  SampleOrder: {
    path: `/sampleOrder`,
    component: SampleOrderPage,
  },
  ProductStock: {
    path: `/productStock`,
    component: ProductStockPage,
    children: {
      List: {
        path: `/list`,
      },
      Create: {
        path: `/create`,
      },
      Update: {
        path: `/update`,
      },
    },
  },
  PersonMaster:{
    path:'/personMaster',
    component: PersonMasterPage,
  },
  TypeMaster:{
    path:'/typeMaster',
    component: TypeMasterPage,
  },
  StatusMaster:{
    path:'/statusMaster',
    component: StatusMasterPage,
  },
  OrderRegist: {
    path: '/orderRegist',
    component:OrderRegistPage,
  },
  LicenseMaster: {
    path: '/licenseMaster',
    component: LicenseMasterPage,
  },
  CompanyMaster: {
    path: '/companyMaster',
    component: CompanyMasterPage,
  },
  CompanyMasterRegist: {
    path: '/companyMasterRegist',
    component: CompanyMasterRegistPage,
  },
  LicenseTypeMaster: {
    path: '/licenseTypeMaster',
    component: LicenseTypeMasterPage,
  },
  OrderDisplayStatusMaster: {
    path: '/orderDisplayStatusMaster',
    component: OrderDisplayStatusMasterPage,
  },
  PickupMaster: {
    path: '/pickupMaster',
    component: PickupMasterPage,
  },
  ProductInput: {
    path: '/productInput',
    component: ProductInputPage,
  },
  ProductList: {
    path: '/productList',
    component: ProductListPage,
  },
  UsedHistory: {
    path: '/usedHistory',
    component: UsedHistoryPage,
  },
  RepairHistory: {
    path: '/repairHistory',
    component: RepairHistoryPage,
  },
  Order: {
    path: '/order',
    component: OrderPage,
  },
  StatusInput: {
    path: '/statusInput',
    component: StatusInputPage,
  },
  Sales: {
    path: '/sales',
    component: SalesPage,
  },
  ModelMaster:{
    path:'/modelMaster',
    component: ModelMasterPage,
  },
  PasswordEdit:{
    path:'/passEdit',
    component: PasswordEdit,
  },
  UserRegist:{
    path:'/userRegist',
    component: UserRegist,
  },
  UserList:{
    path:'/userList',
    component: UserList,
  },
};
