import React from "react";
import { connect } from "react-redux";
import { withStyles, fade } from "@material-ui/core/styles";
import { compose } from "redux";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel'


class Component extends React.Component {

  state = { selected: "_ORDER_" };
  handleChange = ev => {
    this.setState({ selected: ev.target.value });
  };

  render() {

    return (
      <div>
        <Dialog id="outPutExcelDialogBox" open={true} style={{display: 'none'}}>
          <DialogContent 
          dividers style={{width: '230px'}}
          >
            <RadioGroup onChange={this.handleChange} value={this.state.selected}>
              <FormControlLabel
                value={'_ORDER_'}
                label={'注文書'}
                control={<Radio id='order_excel' name='_ORDER_' />}
              />
              <FormControlLabel 
                value={'_ESTIMATE_'}
                label={'見積書'}
                control={<Radio id='estimate_excel' name='_ESTIMATE_'/>}
              />
              <FormControlLabel
                value={'_BILLDING_'}
                label={'請求書'}
                control={<Radio id='billding_excel' name='_BILLING_'/>}
              />
                <FormControlLabel
                value={'_BILLDING_DETAIL'}
                label={'請求明細書'}
                control={<Radio id='billding_detail_excel' name='_BILLING_DETAIL_'/>}
              />
              <FormControlLabel
                value={'_DELIVERY_'}
                label={'納品書'}
                control={<Radio id='delivery_excel' name='_DELIVERY_'/>}
              />
            </RadioGroup>
          </DialogContent>

          <DialogActions style={{justifyContent: 'center'}}>
            <Button id='outPutExcel'color="primary">
              書類発行
            </Button>
            <Button id='outPutExcelCancel' color="primary" autoFocus>
              もどる
            </Button>
          </DialogActions>
          </Dialog>
      </div>
    );

  }
  static get propTypes() {
    return {};
  }

  static get styles() {
    return theme => ({
 
    });
  }

  static get mapStateToProps() {
    return ({ auth, user }) => ({ auth, user });
  }

  static get mapDispatchToProps() {
    return dispatch => ({ dispatch });
  }
}

// TODO 分割 ContainerとComponent
const { styles, mapStateToProps, mapDispatchToProps } = Component;
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
