import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, Checkbox, TextField,Select,MenuItem, } from "@material-ui/core";
import Header from "../header/Header";
import axios from "axios";
import { moveTo ,orderRegist} from "../actions";
import REQUEST_PATH from "../components/Common";

import ExitToApp from '@material-ui/icons/ExitToApp';
import AlertDialog from "../components/Dialog";
import Cookies from "js-cookie";

class Component extends React.Component {
  
    state = {
      //エラーメッセージ
      headerErrorMessage:"",

      //state
      terminalData:[],
      quantity:1,
      productStatus:"01",
      productData:[],
      shipmentData:[],
      statusMethod:"01",

      //注文データ用state
      orderNumber:"",
      projectName:"",
      companyName:"",
      useStartDate:"",
      useEndDate:"",
      orderStatus:"",

      //画面非活性制御
      //画面の左下
      statusUpdateDisabled:false,
      //画面右側
      orderStatusUpdateDisabled:false,

      // 商品状態マスタ
      status_list:[],

      // チェックで使用する見積データ
      estimateData:[],

      colorCode : [
        {
          'default':'#FFFFFF',
          'failed' : '#e57373',
          'success':'#b9f6ca'
        }
      ],
    };

    constructor(props){
        super(props);
        this.getProductStatusListMenus();
        const rows = this.state.terminalData;
        for(let i = 0; i < 15 ;i++){
          rows.push("");
        }
        this.state.terminalData = rows;
        // 注文番号
        console.log(this.props.location);
         if(!(this.props.location.state === undefined)) {
            if(!(this.props.location.state.orderNumber === undefined)) {
             this.state.orderNumber = this.props.location.state.orderNumber;
             this.state.statusUpdateDisabled = false;
             this.search();
             this.getShipmentProductList();
             // 見積データの取得
             this.getEstimateData();

            }
            if(!(this.props.location.state.productNumber === undefined)) {
              this.state.productData = this.props.location.state.productNumber;
              console.log(this.props.location.state.productNumber);
              this.state.orderStatusUpdateDisabled = true;
            }
         }else{
          this.state.orderStatusUpdateDisabled = true;
         }
    }

    componentWillUnmount(){
      this.state.headerErrorMessage = "";
    }

    backOrderRegist() {
      this.props.history.push({ pathname: '/orderRegist', state: { orderNumber: this.state.orderNumber }});
    }

    async getEstimateData() {

      const param = Cookies.get('token');

      // 注文番号を分解
      await axios.post(
        REQUEST_PATH + 'api/estimate/list/' + this.state.orderNumber,
        {
          pageIndex: 0,
          pageSize: 100,
        },{
          param,
          withCredentials: true,
        }
      ).then(response => {
        // 成功時  
        this.setState({ estimateData: response.data.list });
      }).catch(e => {
        console.log(e);
      });
    }

    // 商品状態マスタ取得
    async getProductStatusListMenus() {

      const param = Cookies.get('token');

      await axios.post(
        REQUEST_PATH + 'api/product-status/list',
        {
          pageIndex: 0,
          pageSize: 100,
        },{
          param,
          withCredentials: true,
        }
      ).then( response => {
        this.setState({status_list:response.data.list});
      }).catch(e =>{
        console.log(e);
      });
    }

    //注文データ取得
    async search() {

      const param = Cookies.get('token');
  
      await axios.post(
        REQUEST_PATH + 'api/order/list',
        {
          pageIndex: 0,
          pageSize: 100,
          orderNumber: this.state.orderNumber,
        },{
          param,
          withCredentials: true,
        }
      ).then(response => {
        // 成功時
        const orderData = response.data.list[0];
        console.log(orderData);
        this.setState({ projectName: orderData.projectName});                           // 案件名
        this.setState({ companyName: orderData.company});                               // 取引先名
        this.setState({ useStartDate: orderData.useStartDate});                         // 使用日開始
        this.setState({ useEndDate: orderData.useEndDate});                             // 使用日終了
        this.setState({ orderStatus:orderData.orderDisplayStatusName});                 // 注文ステータス
        this.forceUpdate();
      }).catch(e => {
        console.log(e);
      });
      
    }

    async getShipmentProductList(){
      let checkData = [];
      const shipmentData = [];
      const param = Cookies.get('token');
      await axios.post(REQUEST_PATH + 'api/collect-check/list', {
          orderNumber:this.state.orderNumber
        },{
          param,
          withCredentials: true,
        }).then( response => {
          checkData = response.data;
          if(checkData.length > 0){
            for(const i in checkData){
              shipmentData.push({
                index: checkData[i].index,
                orderNumber: this.state.orderNumber,
                productNumber: checkData[i].productNumber,
                type: checkData[i].productNumber.substr(0,2),
                model: checkData[i].productNumber.substr(2,2),
                spareNumber: checkData[i].productNumber.substr(4,2),
                number: checkData[i].productNumber.substr(6),
                hasError: this.state.colorCode[0].default,
                checked:false
              });
            }
        }

        const shipingDataSorted = shipmentData.sort( (a,b) =>{
          return a.model - b.model;
        });

        this.setState({shipmentData:shipingDataSorted});
      }).catch(e =>{
        console.log(e);
        // this.setState({headerErrorMessage:"見積データの登録に失敗しました"});
        return;
      });

      this.forceUpdate();
    }

    //出荷商品リスト更新
    async updateShippingProductList() {

      await this.setState({headerErrorMessage:""});
      await this.errorClear();

      if(this.state.statusMethod === '01') {
        
        const result = await this.checkShipingData();
        if(result) {
          const _shipingData = this.state.shipmentData;

          _shipingData.forEach(v => {
            v.hasError = this.state.colorCode[0].success;
          });
          
          this.setState({shipingData:_shipingData});
          this.insertHistoryData();
        }
      }
      else if(this.state.statusMethod === '02') {

        const result = await　this.checkCollectDataAll();
        
        if(!result){
          // 回収処理を実施
          await this.collectMethod();
        }

      }
      else if(this.state.statusMethod === '03') {
        await this.checkCollectDataSeparate();
      }
    }

    // 使用履歴の追加
    async insertHistoryData() {
      const _shipingData = this.state.shipmentData;
      const _orderNumber = this.state.orderNumber;
      const _useStartDate = this.state.useStartDate;
      const _useEndDate = this.state.useEndDate;
      let targetShipingData = [];

      _shipingData.forEach(v => {
        targetShipingData.push(v.type + v.model + v.spareNumber + v.number );
      });

      const sendData = targetShipingData.filter(v => v.substr(6,4) !== '0000');

      const param = Cookies.get('token');

      for(let i in sendData){
        await axios.post(REQUEST_PATH + 'api/history/create', {
          orderNumber   : _orderNumber,
          productNumber : sendData[i],
          startDate     : _useStartDate,
          endDate       : _useEndDate
        },{
          param,
          withCredentials: true,
        }).then( response => {
        }).catch(e =>{
          console.log(e);
          this.setState({headerErrorMessage:"使用履歴の追加に失敗しました"});
          return false;
        });
      }
    }

    // 出荷時のチェック
    async checkShipingData () {

      const shipmentData = this.state.shipmentData;
      const estimateData = this.state.estimateData;

      if(shipmentData.length <= 0) {
        this.setState({headerErrorMessage:"出荷データがありません"});
        return false;
      }

      let checkData_s = [];
      let checkData_e = [];

      shipmentData.forEach(v => {
        if(checkData_s.indexOf(v.model) < 0) {
          checkData_s.push(v.model);
        }
      });

      estimateData.forEach(v => {
        if(checkData_e.indexOf(v.model) < 0 && v.model !== '00') {
          checkData_e.push(v.model);
        }
      });

      // 全機種そろっているか
      if(checkData_s.length !== checkData_e.length) {
        this.setState({headerErrorMessage:"見積データと個数一致しません"});
        return false;
      }

      const resultTypeModel = this.checkTypeModelUnit(shipmentData);

      // 個数チェック失敗
      if(resultTypeModel){
        this.setState({headerErrorMessage:"見積データと個数一致しません"});
        return false;
      }
      
      // リース中にする処理
      const insertList = shipmentData.filter(({number}) => number !== "0000");
      const setStatus = "04"; //リース中
      const sendData = [];
      const param = Cookies.get('token');
      for(let i in insertList){
        sendData.push({productNumber:insertList[i].type+insertList[i].model+insertList[i].spareNumber+insertList[i].number});
      }
      for(let i in sendData){
        await axios.put(REQUEST_PATH + 'api/status-input/update', {
          productNumber:sendData[i].productNumber,
          status:setStatus
        },{
          param,
          withCredentials: true,
        }).then( response => {
        }).catch(e =>{
          console.log(e);
          this.setState({headerErrorMessage:"ステータス更新に失敗しました"});
          return false;
        });
      }

      return true;
    }

    // 一括回収
    async checkCollectDataAll() {

      let isFailed = false;
      const _productData = this.state.productData;
      const _shipingData = this.state.shipmentData;
      let collectData = [];
      let targetShipingData = [];

      // 商品データ、出荷データを比較する
      _productData.forEach(v => {
        collectData.push(v.type + v.model + v.spareNumber + v.number );
      });
      
      _shipingData.forEach(v => {
        targetShipingData.push(v.type + v.model + v.spareNumber + v.number );
      });

      if(collectData.length === 0) {
        this.setState({headerErrorMessage:"商品データがありません"});
        isFailed = true;
        return isFailed;
      }

      if(collectData.length !== targetShipingData.length) {
        isFailed = true;
        //this.setState({headerErrorMessage:"商品データと出荷データの個数が一致しません"});
      }
      // 付属品と本体を仕分け
      const _collectDataFirterBody = collectData.filter(v => v.substr(6,4) !== '0000');
      const _collectDataFirterAccessory = collectData.filter(v => v.substr(6,4) === '0000');
      const _shipingDataFirterBody = targetShipingData.filter(v => v.substr(6,4) !== '0000');
      const _shipingDataFirterAccessory = targetShipingData.filter(v => v.substr(6,4) === '0000');

      let failedList = [];
      let successList = [];
      let shortItem = [];
      let failedListAccessory = [];
      let successListAccessory = [];
      let shortItemAccessory = [];

      // 各々比較indexOfで判別し、消していく
      _collectDataFirterBody.forEach(c_v => {
        
        // 商品データにあって、出荷データにない
        if(_shipingDataFirterBody.indexOf(c_v) < 0) {
          failedList.push(c_v);
          isFailed = true;
        } else {
          successList.push(c_v);
        }
      });

      let successItem = []; 
      _shipingDataFirterBody.forEach(v => {

        if(successList.indexOf(v) < 0) {
          shortItem.push(v);
          isFailed = true;
        }
        else {
          successItem.push(v);
        }
      });

      _shipingData.forEach(v => {
        const target = v.type + v.model + v.spareNumber + v.number;
        if(!(successItem.indexOf(target) < 0)) {
          v.hasError =　this.state.colorCode[0].success;
        }
      });

      // 付属品
      _collectDataFirterAccessory.forEach(v => {

        if(_shipingDataFirterAccessory.indexOf(v) < 0){
          failedListAccessory.push(v);
          isFailed = true;
        } else {
          successListAccessory.push(v);
        }
      });

      // 付属品の個数があっているか
      let accessoryList = [];
      _shipingDataFirterAccessory.forEach(v => {
        if(accessoryList.indexOf(v) < 0 ){
          accessoryList.push(v);
        }
      });

      let successItemAccessory = [];
      accessoryList.forEach(v => {

        let tagetAccesoryCount_s = 0;
        let tagetAccesoryCount_c = 0;
        
        _shipingDataFirterAccessory.forEach(s_v => {
          if(s_v === v){
            tagetAccesoryCount_s += 1;
          }
        });

        _collectDataFirterAccessory.forEach(c_v => {
          if(c_v === v){
            tagetAccesoryCount_c += 1;
          }
        });

        // 付属品の個数一致していない
        if(tagetAccesoryCount_s !== tagetAccesoryCount_c) {
          isFailed = true;
          shortItemAccessory.push(v);
        } else {
          successItemAccessory.push(v);
        }
      });

      _shipingData.forEach(v => {
        const target = v.type + v.model + v.spareNumber + v.number;
          if(!(successItemAccessory.indexOf(target) < 0)) {
          v.hasError =　this.state.colorCode[0].success;
        }
      });

      // 出荷データにない項目が赤くなる
      if(isFailed) {
        _productData.forEach(v => {
          const target = v.type + v.model + v.spareNumber + v.number;
          if(!(failedList.indexOf(target) < 0)) {
            v.hasError =　this.state.colorCode[0].failed; 
          }

          if(!(failedListAccessory.indexOf(target) < 0)) {
            v.hasError =　this.state.colorCode[0].failed; 
          }

          if(!(shortItemAccessory.indexOf(target) < 0)) {
            v.hasError =　this.state.colorCode[0].failed;
          }
        });

        _shipingData.forEach(v => {
          const target = v.type + v.model + v.spareNumber + v.number;
          if(!(shortItem.indexOf(target) < 0)) {
            v.hasError =　this.state.colorCode[0].failed;
          }

          if(!(shortItemAccessory.indexOf(target) < 0)) {
            v.hasError =　this.state.colorCode[0].failed;
          }
        });

        this.setState({headerErrorMessage:"商品が一致しません"});
      }

      this.setState({productData:_productData});
      this.setState({shipmentData:_shipingData});
      return isFailed;
    }

    // 分割改修チェック
    async checkCollectDataSeparate() {

      let isFailed = false;
      const _productData = this.state.productData;
      const _shipingData = this.state.shipmentData;
      let collectData = [];
      let targetShipingData = [];

      // 商品データ、出荷データを比較する
      _productData.forEach(v => {
        collectData.push(v.type + v.model + v.spareNumber + v.number );
      });
      
      _shipingData.forEach(v => {
        targetShipingData.push(v.type + v.model + v.spareNumber + v.number );
      });

      if(collectData.length === 0) {
        this.setState({headerErrorMessage:"商品データがありません"});
        isFailed = true;
        return isFailed;
      }

      const _collectDataFirterBody = collectData.filter(v => v.substr(6,4) !== '0000');
      const _collectDataFirterAccessory = collectData.filter(v => v.substr(6,4) === '0000');
      const _shipingDataFirterBody = targetShipingData.filter(v => v.substr(6,4) !== '0000');
      const _shipingDataFirterAccessory = targetShipingData.filter(v => v.substr(6,4) === '0000');

      let successItem = [];
      let failedItem = [];
      let successIndexList = [];
      let failedItemAccessory = [];
      let successItemAccessory = [];
      let tmpSuccessItemAccessory = [];
      let successIndexListAccessory = [];

      // 出荷データに存在するもの、しないものを抽出
      _collectDataFirterBody.forEach(c_v => {

        _shipingDataFirterBody.forEach(s_v => {
          if(c_v === s_v) {
            successItem.push(c_v);
          }
        });

        if(_shipingDataFirterBody.indexOf(c_v) < 0) {
          failedItem.push(c_v);
          isFailed = true;
        }
      });

      // 付属品に存在するものを抽出
      _collectDataFirterAccessory.forEach(c_v => {

        _shipingDataFirterAccessory.forEach(s_v => {
        
          if(s_v === c_v) {
            
            if(successItemAccessory.indexOf(c_v) < 0){
              successItemAccessory.push(c_v)
            }
          }
        });

        if(_shipingDataFirterAccessory.indexOf(c_v) < 0) {
          failedItemAccessory.push(c_v);
          isFailed = true;
        }
      });

      // 付属品の個数確認
      let unMatchAccessory = [];
      successItemAccessory.forEach(v => {

        let accessoryCount_s = 0;
        let accessoryCount_c = 0;
        _shipingDataFirterAccessory.forEach(s_v => {
          if(v === s_v) {
            accessoryCount_s++;
          }
        });

        _collectDataFirterAccessory.forEach(s_v => {
          if(v === s_v) {
            accessoryCount_c++;
          }
        });

        // 出荷データ、商品データの付属の個数比較
        if(accessoryCount_s < accessoryCount_c) { // 商品データの方が多い場合
          isFailed = true;
          unMatchAccessory.push(v);
        } else {
          tmpSuccessItemAccessory.push(v);
        }
      });

      // 誤りがある
      if(isFailed) {

        _productData.forEach(v => {
          const target =  v.type + v.model + v.spareNumber + v.number;
          if(!(unMatchAccessory.indexOf(target) < 0)) {
            v.hasError = this.state.colorCode[0].failed;
          }

          if(!(failedItemAccessory.indexOf(target) < 0)) {
            v.hasError = this.state.colorCode[0].failed;
          }

          if(!(failedItem.indexOf(target) < 0)) {
            v.hasError = this.state.colorCode[0].failed;
          }
        });

        this.setState({headerErrorMessage:"商品が一致しません"});
        this.setState({productData:_productData});
        return isFailed;
      }

      // 各主キーを取得
      /*_shipingData.forEach(v => {
        if(!(tmpSuccessItemAccessory.indexOf(v.productNumber) < 0)) {
          v.hasError = this.state.colorCode[0].success;
          successIndexListAccessory.push(v.index);
        }
      });
      */

      let successAccessoryProduct = []
      tmpSuccessItemAccessory.forEach(v => {

        _productData.forEach(p_v => {
          const target =  p_v.type + p_v.model + p_v.spareNumber + p_v.number;
          if(target === v){
            successAccessoryProduct.push(target);
          }
        })
      });

      // 商品番号と個数をオブジェクトとしてまとめる
      let targetAccessoryObject = [];
      tmpSuccessItemAccessory.forEach(v => {

        const target = successAccessoryProduct.filter(f_v => {return v === f_v});
        const tagetObject = { productNumber: v , count: target.length }
        targetAccessoryObject.push(tagetObject);
      })

      // カウントと比較し、キーを取得
      targetAccessoryObject.forEach(v => {
        let pushCount = 0;
        _shipingData.forEach(s_v => {

          if(s_v.productNumber === v.productNumber && pushCount !== v.count) {
            s_v.hasError = this.state.colorCode[0].success;
            successIndexListAccessory.push(s_v.index);
            pushCount++;
          }
        });
      });

      _shipingData.forEach(v => {
        if(!(successItem.indexOf(v.productNumber) < 0)) {
          v.hasError = this.state.colorCode[0].success;
          successIndexList.push(v.index);
        }
      });


      // ダイアログ表示
      this.opneDialog(successIndexList, successIndexListAccessory);
      //await this.collectSeparateMethod(successIndexList,successIndexListAccessory);
      // 使用履歴への追加
      this.setState({shipmentData : _shipingData});
      this.setState({productData:_productData});
    }

    // 分割改修
    async collectSeparateMethod(shipingDataIndex, shipingDataAccessoryIndex) {

      // 出荷データをリース待ちに更新
      const _shipmentData = this.state.shipmentData;      
      const updateList = _shipmentData.filter(({index}) => shipingDataIndex.indexOf(index) !== -1);      
      const setStatus = "01"; //在庫中
      const sendData = [];

      for(let i in updateList){
        sendData.push({productNumber:updateList[i].type+updateList[i].model+updateList[i].spareNumber+updateList[i].number});
      }

      const param = Cookies.get('token');

      for(let i in sendData){
        await axios.put(REQUEST_PATH + 'api/status-input/update', {
          productNumber:sendData[i].productNumber,
          status:setStatus
        },{
          param,
          withCredentials: true,
        }).then( response => {
        }).catch(e =>{
          console.log(e);
          this.setState({headerErrorMessage:"ステータス更新に失敗しました"});
          return;
        });
      }
      
      let deleteData = [];

      shipingDataIndex.forEach(v => {
        deleteData.push(v);
      });

      shipingDataAccessoryIndex.forEach(v => {
        deleteData.push(v);
      });

      for(let i in deleteData){
        await axios.delete(REQUEST_PATH + `api/shipment/delete/${deleteData[i]}`,{
          param,
          withCredentials: true,
        }).then( response => {

        }).catch(e =>{
          console.log(e);
          this.setState({headerErrorMessage:"出荷情報削除中にエラーが発生しました。"});
          return;
        });
      }

      this.getShipmentProductList();
      document.getElementById('dialogBox').style.display = 'none';
    }

    // 各欄の状態を初期化
    async errorClear() {

      const _shipingData = this.state.shipmentData;
      const _productData = this.state.productData;

      _shipingData.forEach(v => {
        v.hasError =　this.state.colorCode[0].default;
      });

      _productData.forEach(v => {
        v.hasError =　this.state.colorCode[0].default 
      });

      this.setState({shipmentData : _shipingData});
      this.setState({productData:_productData});
    }

    async collectMethod() {

      // 出荷データをリース待ちに更新
      const _shipmentData = this.state.shipmentData;
      const updateList = _shipmentData.filter(({number}) => number !== "0000");
      const setStatus = "01"; //在庫中
      const sendData = [];

      const param = Cookies.get('token');

      for(let i in updateList){
        sendData.push({productNumber:updateList[i].type+updateList[i].model+updateList[i].spareNumber+updateList[i].number});
      }
      for(let i in sendData){
        await axios.put(REQUEST_PATH + 'api/status-input/update', {
          productNumber:sendData[i].productNumber,
          status:setStatus
        },{
          param,
          withCredentials: true,
        }).then( response => {
        }).catch(e =>{
          console.log(e);
          this.setState({headerErrorMessage:"ステータス更新に失敗しました"});
          return;
        });
      }

      // TODO
      const deleteData = this.state.shipmentData.slice();
      let beanList = [];

      deleteData.forEach(v => {
        //beanList.push({shipmentProductIndex:v.index});
        const _productNumber = v.type + v.model + v.spareNumber + v.number;
        beanList.push({shipmentProductIndex:v.index,productNumber:_productNumber,orderNumber:this.state.orderNumber });
      });

      console.log(beanList);
      await axios.post(REQUEST_PATH + 'api/shipment/delete2',
        beanList,{
          param,
          withCredentials: true,
        }
      ).then( response => {

      }).catch(e =>{
        console.log(e);
        this.setState({headerErrorMessage:"出荷情報削除中にエラーが発生しました。"});
        return;
      });
      /*
      for(let i in deleteData){
        await axios.delete(REQUEST_PATH + `api/shipment/delete/${deleteData[i].index}`, {
          param,
          withCredentials: true,
        }).then( response => {

        }).catch(e =>{
          console.log(e);
          this.setState({headerErrorMessage:"出荷情報削除中にエラーが発生しました。"});
          return;
        });
      }

      */

      this.getShipmentProductList();
    }

    async deleteButtonClick(){

      const param = Cookies.get('token');

      const shipmentData = this.state.shipmentData.slice();
      const deleteData = shipmentData.filter(({checked,index}) => checked === true && index !== null);
      console.log(deleteData);
      for(let i in deleteData){
        console.log(deleteData[i].index);
        await axios.delete(REQUEST_PATH + `api/shipment/delete/${deleteData[i].index}`,{
          param,
          withCredentials: true,
        }).then( response => {

        }).catch(e =>{
          console.log(e);
          this.setState({headerErrorMessage:"出荷情報削除中にエラーが発生しました。"});
          return;
        });
      }
      this.getShipmentProductList();
    }

    //リストメニュー描画
    // 商品状態マスタ
    getProductStatusOptions = () => {
      const status_list = this.state.status_list;
      const menuItems = [];
      for (const i in status_list) {
        menuItems.push(
          <MenuItem
            value={status_list[i].status}
            key={status_list[i].status}
            >
            {status_list[i].statusName}
          </MenuItem>
        );
      }
      return menuItems;
    };

    //商品データの描画
    productDataRow(rows,i){

      // TODO
      console.log(this.state.productData[i]);
      return (
          <Box display="flex" alignItems="center" borderBottom="1px solid #bdbdbd" textAlign="left" key={i} style={{backgroundColor:this.state.productData[i].hasError}}>
            <Box width="30px" textAlign="center" style={{paddingTop:"5px",paddingBottom:"5px"}}>{i+1}</Box>
            <Box width="30px" textAlign="center" style={{borderRight:"1px solid #bdbdbd",borderLeft:"1px solid #bdbdbd",paddingRight:"9px"}}>
              <Checkbox
               color='primary'
               size='small'
               checked={this.state.productData[i].checked}
               onChange={event => this.handleCheckProductData(event,i)}
               />
            </Box>
            <Box style={{paddingLeft:"10px"}}>
            {this.state.productData[i].type}-{this.state.productData[i].model}-{this.state.productData[i].spareNumber}-{this.state.productData[i].number}</Box>
          </Box>
        )
      }
    //出荷データの描画
    shipmentDataRow(rows,i){

      return (
          <Box display="flex" alignItems="center" borderBottom="1px solid #bdbdbd" textAlign="left" key={i} style={{backgroundColor:this.state.shipmentData[i].hasError}}>
            <Box width="30px" textAlign="center" style={{paddingTop:"5px",paddingBottom:"5px"}}>{i+1}</Box>
            <Box width="30px" textAlign="center" style={{borderRight:"1px solid #bdbdbd",borderLeft:"1px solid #bdbdbd",paddingRight:"9px"}}>
              <Checkbox
               color='primary'
               checked={this.state.shipmentData[i].checked}
               onChange={event => this.handleCheck(event,i)}/>
            </Box>
            <Box style={{paddingLeft:"10px"}}>
            {this.state.shipmentData[i].type}-{this.state.shipmentData[i].model}-{this.state.shipmentData[i].spareNumber}-{this.state.shipmentData[i].number}</Box>
          </Box>
        )
      }
    //ターミナルデータの描画
    renderCell(rows,i){
      return(
        <Box display="flex" alignItems="center" borderBottom="1px solid #bdbdbd" key={i}>
          <Box width="30px" textAlign="center" >{i+1}</Box>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            name={"rowNum_"+i}
            id={"rowNum_"+i}
            onKeyDown={event => this.onKeyDownCell(event,i)}
          />
        </Box>
      )
    }

    terminalDataReading(){
      const readingData = this.state.productData.slice();
      const terminalData = this.state.terminalData;

      // TODO
      for(const i in terminalData){
        if(terminalData[i]!==""){
          if(isNaN(this.state.quantity)||this.state.quantity===""){
            readingData.push({type: terminalData[i].substr(0,2),
                              model: terminalData[i].substr(2,2),
                              spareNumber: terminalData[i].substr(4,2),
                              number: terminalData[i].substr(6),
                              hasError: this.state.colorCode[0].default,
                              checked : false
              });
          }else{
            const max = Number.parseInt(this.state.quantity);
            for(let j = 0;j<max;j++){
              readingData.push({type: terminalData[i].substr(0,2),
                                model: terminalData[i].substr(2,2),
                                spareNumber: terminalData[i].substr(4,2),
                                number: terminalData[i].substr(6),
                                haseError: this.state.colorCode[0].default,
                                checked : false
              });
            }
          }
        }
      }
      console.log(readingData);
      this.setState({productData:readingData});
      // ターミナルデータのクリア
      this.cellClear();
      // フォーカスを先頭にする
      this.terminalHeaderClicked();

    }


    //ターミナルヘッダクリック時
    terminalHeaderClicked(){
      let cell = document.getElementById("rowNum_0");
      cell.focus();
    }

    productHeaderClicked(){

      const _productData = this.state.productData;
      let isAllCheck = false;
      _productData.forEach(v => {
        // 全選択
        if(!v.checked){
          isAllCheck = true;
        }
      });

      _productData.forEach(v => {
        v.checked = isAllCheck;
      });

      this.setState({productData:_productData});
    }


    //キー押下時
    onKeyDownCell(event,rowNum){
      //エンター(13)のみ
      if(event.keyCode === 13){
        let currentCell = document.getElementById("rowNum_"+(rowNum));
        const tempData = this.state.terminalData.slice();
        tempData[rowNum] = currentCell.value;
        let cell = document.getElementById("rowNum_"+(rowNum+1));
        cell.focus();
        tempData.push("");
        this.setState({terminalData:tempData});
      }
    }
    handleCheck(event,i){
      const tempData = this.state.shipmentData.slice();
      tempData[i].checked = event.target.checked;
      this.setState({shipmentData:tempData});
    }

    // TODO
    handleCheckProductData(event,i){
      const tempData = this.state.productData.slice();
      tempData[i].checked = event.target.checked;
      this.setState({productData:tempData});
    }

    onChangeCell(event,i){
      const rows = this.state.terminalData.slice();
      rows[i] = event.target.value;
      this.setState({terminalData:rows});
    }
    //クリアボタン
    cellClear(){
      const rows = [];
        for(let i = 0; i < 15 ;i++){
          rows.push("");
        }
        this.setState({terminalData:rows});
        for(let i = 0; i < 15 ;i++){
          let currentCell = document.getElementById("rowNum_"+(i));
          currentCell.value ="";
        }

    }
    productDataClear(){
      //const rows = [];

      const _productData = this.state.productData;
      
      const filterItem = _productData.filter(v => { return !v.checked })


      this.setState({productData:filterItem});
    }
    //更新ボタン
  async  updateButtonClick(){
    //更新データが存在しない場合はそのままリターン
      if(this.state.productData.length <= 0){
        return;
      }
      const productList = this.state.productData;
      const updateList = productList.filter(({number}) => number !== "0000");
      const setStatus = this.state.productStatus;
      const sendData = [];
      for(let i in updateList){
        sendData.push({productNumber:updateList[i].type+updateList[i].model+updateList[i].spareNumber+updateList[i].number,
                        status:setStatus
        });
      }

      let successList = [];

      const param = Cookies.get('token');

      for(let i in sendData){
        await axios.put(REQUEST_PATH + 'api/status-input/update', {
          productNumber:sendData[i].productNumber,
          status:sendData[i].status
        },{
          param,
          withCredentials: true,
        }).then( response => {
          successList.push(sendData[i].productNumber);
        }).catch(e =>{
          console.log(e);
          //this.setState({headerErrorMessage:"ステータスの更新に失敗しました"});
          return;
        });
      }

      if(successList.length > 0) {
        productList.forEach(v => {

          const orderNumber = v.type + v.model + v.spareNumber + v.number;

          if(successList.indexOf(orderNumber) !== -1){
            v.hasError = this.state.colorCode[0].success;
          }
        });
  
        this.setState({productData:productList});
      }
    }

    // TODO
    async clearHasErrorProductData(){

      const _productData = this.state.productData;

      _productData.forEach(p_v => {
          p_v.hasError = this.state.colorCode[0].default;
      });

      this.setState({productData:_productData});

    }

    // データ登録ボタン押下
    async registerDataButtonClick() {

      await this.setState({headerErrorMessage:""});

      //照合データが存在しない場合はそのままリターン
      if(this.state.productData.length <= 0) {
        this.setState({headerErrorMessage:"登録する商品データがありません"});
        return;
      }

      await this.clearHasErrorProductData();

      // データ形式不正
      const resultNuberFormat = await this.checkNuberFormat();
      if(resultNuberFormat) {
        this.setState({headerErrorMessage:"商品データが不正です"});
        return
      }
      

      const tempProductData = this.state.productData;
      const tempEstimateData = this.state.estimateData;
      let checkPData = [];
      let checkEData = [];

      // 機種単位かどうか
      tempProductData.forEach(v => {
        if(checkPData.indexOf(v.model) < 0) {
          checkPData.push(v.model);
        }
      });

      if(checkPData.length !== 1) {
        this.setState({headerErrorMessage:"機種単位で登録してください"});
        return;
      }

      // 重複端末チェック（端末重複）
      const  resultDuplicationIndividual = await this.checkDuplicationIndividualData();
      if(resultDuplicationIndividual) {
        this.setState({headerErrorMessage:"商品が重複しています"});
        return
      }


      // 重複チェック（出荷データに登録されているか）
      const  resultDuplication = await this.checkDuplicationData();
      if(resultDuplication) {
        this.setState({headerErrorMessage:"商品が既に登録されています"});
        return
      }

      // TODO 存在チェック
      const  resultExist = await this.checkExistData();
      if(resultExist){
        this.setState({headerErrorMessage:"登録されていない商品があります"});
        return
      }
      

      // メンテナンス済チェック
      const resultCompletedMaintenance = await this.checkProductStatus();
      if(resultCompletedMaintenance) {
        this.setState({headerErrorMessage:"メンテナンス済みでない商品があります"});
        return;
      }


      // 見積の機種単位
      tempEstimateData.forEach(v => {
        if(checkEData.indexOf(v.model) < 0) {
          checkEData.push(v.model);
        }
      });

      // 機種：見積 < 商品の不整合
      if(checkEData.length < checkPData.length) {
        this.setState({headerErrorMessage:"商品データに誤りがあります"});
        return;
      }

      // 一括登録かどうか
      const filterModelProductData = tempProductData.filter(v => v.model === checkPData[0]);
      const filterModelEstimateData = tempEstimateData.filter(v => v.model === checkPData[0]);
      let filterTypeData = [];

      filterModelEstimateData.forEach(v => {
        if(filterTypeData.indexOf(v.productType) < 0) {
          filterTypeData.push(v.productType);
        }
      });

      let totalModel = 0;

      // 同種別の最大値で計算
      filterTypeData.forEach(v => {
        
        let c = 0;
        filterModelEstimateData.forEach(estimate_v =>{

          if(estimate_v.productType === v){
            if(c < estimate_v.quantity) {
              c = estimate_v.quantity;
            }
          }
        });
        totalModel += c;
      });

      if(filterModelProductData.length !== totalModel) {
        this.setState({headerErrorMessage:"見積データと個数一致しません"});
        return;
      }

      // 種別機種単位でのチェック
      const resultTypeModel = this.checkTypeModelUnit(tempProductData);

      // 個数チェック失敗
      if(resultTypeModel){
        this.setState({headerErrorMessage:"見積データと個数一致しません"});
        return;
      }

      // 出荷商品にinsert
      // TODO
      let beanList = [];
      for(let i in tempProductData) {
        const _productNumber = tempProductData[i].type + tempProductData[i].model + tempProductData[i].spareNumber + tempProductData[i].number;
          beanList.push({productNumber:_productNumber,orderNumber:this.state.orderNumber });
      }

     const param = Cookies.get('token');

      /*for(let i in tempProductData) {
        const _productNumber = tempProductData[i].type + tempProductData[i].model + tempProductData[i].spareNumber + tempProductData[i].number;

        await axios.post(REQUEST_PATH + 'api/shipment/create', {
          productNumber:_productNumber,
          orderNumber:this.state.orderNumber
        },{
          param,
          withCredentials: true,
        }).then( response => {
        }).catch(e =>{
          console.log(e);
          this.setState({headerErrorMessage:"出荷データの追加に失敗しました"});
          return;
        });
      }*/

      await axios.post(REQUEST_PATH + 'api/shipment/create',
      beanList,{
        param,
        withCredentials: true,
      }
      ).then( response => {

      }).catch(e =>{
        console.log(e);
        this.setState({headerErrorMessage:"出荷データの追加に失敗しました"});
        return;
      });
      
      this.getShipmentProductList();
      this.productDataClear();

      // 出荷データをリース待ちに更新
      const insertList = tempProductData.filter(({number}) => number !== "0000");
      const setStatus = "03"; //リース待ち
      const sendData = [];
      for(let i in insertList){
        sendData.push({productNumber:insertList[i].type+insertList[i].model+insertList[i].spareNumber+insertList[i].number});
      }
      for(let i in sendData){
        await axios.put(REQUEST_PATH + 'api/status-input/update', {
          productNumber:sendData[i].productNumber,
          status:setStatus
        },{
          param,
          withCredentials: true,
        }).then( response => {
        }).catch(e =>{
          console.log(e);
          this.setState({headerErrorMessage:"ステータス更新に失敗しました"});
          return;
        });
      }
    }

    // 存在チェック
    async checkExistData() {
      let isFailed = false;

      let sendData = [];

      const _productData = this.state.productData;
      const param = Cookies.get('token');

      _productData.forEach(v => {
        sendData.push(v.type + v.model + v.spareNumber + v.number);
      })

      let failedData = [];
      for(let i in sendData){
        await axios.post(REQUEST_PATH + 'api/status-input/exist', {
          productNumber:sendData[i]
        },{
          param,
          withCredentials: true,
        }).then( response => {

          // 商品が存在しない場合
          if(response.data){
            failedData.push(sendData[i]);
            isFailed = true;
          }

        }).catch(e =>{
          console.log(e);
          this.setState({headerErrorMessage:"存在チェックに失敗しました"});
          return;
        });
      }

      if(failedData.length > 0){
        _productData.forEach(v => {
          const target = v.type + v.model + v.spareNumber + v.number;

          if(failedData.indexOf(target) !== -1){
            v.hasError = this.state.colorCode[0].failed;
          }
        });

        this.setState({productData:_productData});
      }

      return isFailed
    }

    // 商品データ数値形式チェック
    async checkNuberFormat() {

      const _productData = this.state.productData;
      let isFailed = false;
      let failedData = [];

      _productData.forEach(v => {

        if(v.type.length !== 2){
          isFailed = true;
        }
        
        if(v.model.length !== 2){
          isFailed = true;
        }

        if(v.spareNumber.length !== 2){
          isFailed = true;
        }

        if(v.number.length !== 4){
          isFailed = true;
        }

        if(isFailed){
          failedData.push(v.type + v.model + v.spareNumber + v.number);
        }
      })

      // 不正データあり
      if(isFailed) {
        _productData.forEach(p_v => {
          const target = p_v.type + p_v.model + p_v.spareNumber + p_v.number
          if(failedData.indexOf(target) !== -1) {
            p_v.hasError = this.state.colorCode[0].failed;
          }
        });
  
        this.setState({productData:_productData});
      }
      
      return isFailed
    }

    // TODO
    async checkDuplicationIndividualData(){
      const _productData = this.state.productData;
      let isFailed = false;
      let failedData = [];

      const filterIndividualData = _productData.filter(v => { return v.number !== '0000' })
      
      let individualNumberList = []
      filterIndividualData.forEach(v => {
        const target = v.type + v.model + v.spareNumber + v.number
        individualNumberList.push(target);
      });

      individualNumberList.forEach(v =>{

        var firstIndex = individualNumberList.indexOf(v);
        var lastIndex = individualNumberList.lastIndexOf(v);

        // 重複
        if(firstIndex !== lastIndex && failedData.indexOf(v) === -1){
          failedData.push(v);
        }
      })

      if(failedData.length > 0){
        isFailed = true;

        _productData.forEach(p_v => {
          const target = p_v.type + p_v.model + p_v.spareNumber + p_v.number
          if(failedData.indexOf(target) !== -1) {
            p_v.hasError = this.state.colorCode[0].failed;
          }
        });
  
        this.setState({productData:_productData});
      }

      return isFailed;
    }

    // 重複チェック メソッド
    async checkDuplicationData() {
      
      // 先に出荷データにデータがあるかどうか
      let isFailed = false;
      const _shipingData = this.state.shipmentData;
      const _productData = this.state.productData;
      console.log(_shipingData);
      if(_shipingData.length === 0) {
        console.log("出荷データなしだから重複チェック回避");
        return isFailed;
      }

      let duplicationData = [];
      _shipingData.forEach(s_v => {

        _productData.forEach(p_v => {
          const target = p_v.type + p_v.model + p_v.spareNumber + p_v.number

          if(s_v.productNumber === target) {
            duplicationData.push(target);
            isFailed = true;
          }
        });
      });

      // 重複あり
      if(isFailed) {
        _productData.forEach(p_v => {
          const target = p_v.type + p_v.model + p_v.spareNumber + p_v.number
          if(duplicationData.indexOf(target) !== -1) {
            p_v.hasError = this.state.colorCode[0].failed;
          }
        });
  
        this.setState({productData:_productData});
      }

      return isFailed;
    }

    // 商品の状態チェック
    async checkProductStatus() {

      const _productData = this.state.productData;
      const tagetList = _productData.filter(({number}) => number !== "0000");
      const sendData = [];
      let productData = [];
      let isFailed = false;

      const param = Cookies.get('token');

      for(let i in tagetList){
        sendData.push({productNumber:tagetList[i].type+tagetList[i].model+tagetList[i].spareNumber+tagetList[i].number});
      }

      for(let i in sendData){
        await axios.post(REQUEST_PATH + 'api/product/list', {
          pageIndex: 0,
          pageSize: 100,
          productNumber:sendData[i].productNumber,
        },{
          param,
          withCredentials: true,
        }).then( response => {
          productData.push(response.data.list[0]);
        }).catch(e =>{
          console.log(e);
          this.setState({headerErrorMessage:"ステータス更新に失敗しました"});
          return true;
        });
      }

      const notCompletedMaintenance = productData.filter(v => v.status !== '02');

      // メンテナンス済み出ない商品がある
      if(notCompletedMaintenance.length > 0) {
        isFailed = true;

        notCompletedMaintenance.forEach(v => {
          _productData.forEach(p_v => {
            const target =  p_v.type + p_v.model + p_v.spareNumber + p_v.number;
  
            if(v.productNumber === target) {
              p_v.hasError = this.state.colorCode[0].failed;
            }
          });
        })
        this.setState({productData:_productData});
      }

      return isFailed;
    }

    // チェック処理を実施
    // 種別機種単位チェック
    checkTypeModelUnit(productData) {
      var isFailed = false;
      const self = this;
      var modelList = [];

      // 商品データから各機種の取得
      productData.forEach(v => {
        if(modelList.indexOf(v.model) < 0) {
          modelList.push(v.model);
        }
      });

      // 機種単位で周す
      modelList.forEach(model_v => {

        var typeList = [];
          // 種別ごとに実施
        productData.forEach(v => {
          if(typeList.indexOf(v.type) < 0 && v.model === model_v ) {
            typeList.push(v.type);
          }
        });

        // 各機種の個数を抽出し、チェック処理を実施
        typeList.forEach(type_v => {

          const filterType = productData.filter(v => v.type === type_v);
          const modelCount = filterType.filter(v => v.model === model_v);
          const result = self.checkTypeModelUnitCount(type_v, model_v, modelCount.length);

          if(result) {
            console.log("種別:" + type_v + " 機種:" + model_v +" 失敗");
            isFailed = true;
          }
          else {
            console.log("種別:" + type_v + " 機種:" + model_v +" 成功"); 
          }
        });
      });

      return isFailed;
    }

    // 個数(種別、機種単位)チェック
    checkTypeModelUnitCount(type, model, modelCount) {

      var isFailed = false;
      const _estimateData = this.state.estimateData;
      const _estimateFilterType = _estimateData.filter(v => v.productType === type);
      const _estimateFilterModel = _estimateFilterType.filter(v => v.model === model && v.model !== '00');
      var _quantity = 0;

      // 数量を取得
      _estimateFilterModel.forEach(v => {
        if(_quantity < v.quantity) {
          _quantity = v.quantity;
        }
      });

      // 見積データと商品データの個数チェックを実施
      if(_quantity === modelCount) {
        console.log("種別:" + type + '　機種:' + model + "　個数一致:" + _quantity);
      }
      else {
        console.log("種別:" + type + "  見積：" + _quantity + ' 商品データ：' + modelCount);
        isFailed = true;
      }

      return isFailed;
    }

    opneDialog (successIndexList, successIndexListAccessory) {

      var Dialog = document.getElementById('dialogBox');
      var okButton = document.getElementById('dialogOkButton');
      var cancelButton = document.getElementById('dialogCancelButton');
   
      document.getElementById('dialogMessage').innerHTML= '一致した商品を回収しますか？';

      const self = this;
      // ダイアログ表示
      Dialog.style.display = '';
      // OK 押下の処理
      okButton.onclick = function() {
        self.collectSeparateMethod(successIndexList, successIndexListAccessory);
      }

      // cancel　押下の処理
      cancelButton.onclick = function() {
       Dialog.style.display = 'none';
       };
     }
    
    onChangeNumberInput(e){

      if(isNaN(e.target.value)){
        this.setState({quantity:0})
        return;
      }

      if(e.target.value === '') {
        this.setState({quantity:1})
        return
      }

      if(!(e.target.value.match(/[0-9]/))){
        return
      }

      if(e.target.value > 0){
        this.setState({quantity:e.target.value.replace(/[^0-9]+/i,'')})
      }
      else {
        this.setState({quantity:1})
      }
    }

    //画面描画
    render() {
        const { classes } = this.props;
        const productStatusListMenu = this.getProductStatusOptions();

        //サブヘッダ
        const titleDiv = (
          <Box display={`flex`} style={{ paddingBottom: "16px",minWidth:"1411px"}}   >
            <Box flexGrow={1}>
              <Box p={1} className={classes.titleHeaderColor}>
                スキャン画面
              </Box>
            </Box>
          </Box>
        );
        //スキャン画面
        const scanData = (
          <Box display={'flex'} style={{marginBottom:"1%"}}>
               <Box flexGrow={1} style={{width:"48%",borderRadius:"5px",border:"1px solid #bdbdbd"}} marginRight={'2%'} padding={'10px'}>
               <Box display={'flex'}>
                  <Box style={{width:"40%"}} marginLeft={'5%'}>
                    <Box p={1} className={classes.terminalHeaderColor}
                      onClick={this.terminalHeaderClicked.bind(this)}
                    >
                      ターミナルデータ
                    </Box>
                    <Box style={{borderRadius:"5px",border:"1px solid #bdbdbd",height:"400px",marginTop:"10px",overflow:"scroll"}}>
                      {this.state.terminalData &&
                      this.state.terminalData.map(({rowValue},i) => this.renderCell(rowValue,i))}
                    </Box>
                    個数 
                    <Box display={'flex'} width={'100%'}>
                      <Box minWidth={60}>
                        <TextField
                          size="small"
                          variant="outlined"
                          type="number"
                          value={this.state.quantity}
                          //onChange={event =>　event.target.value >= 0 ? this.setState({quantity:event.target.value}) : this.setState({quantity:0})}
                          onChange={this.onChangeNumberInput.bind(this)}
                        />
                      </Box>
                      <Box minWidth={180} style={{marginLeft:"20px"}}>
                        <Button
                          variant={`contained`}
                          className={classes.buttonColor}
                          fullWidth
                          onClick={this.terminalDataReading.bind(this)}
                        >
                          データ読込
                        </Button>
                      </Box>
                    </Box>
                    <Box textAlign="center" marginTop={'20px'} marginLeft={'5%'} width={'90%'}>
                      <Button 
                        variant={`contained`}
                        className={classes.buttonColor}
                        fullWidth
                        onClick={this.cellClear.bind(this)} 
                      >
                        クリア
                      </Button>
                    </Box>
                  </Box>
                  <Box style={{width:"40%"}} marginLeft={'10%'} justifyContent={'center'}>
                    <Box p={1} className={classes.productHeaderColor}
                      onClick={this.productHeaderClicked.bind(this)}
                    >
                      商品データ
                    </Box>
                    <Box style={{borderRadius:"5px",border:"1px solid #bdbdbd",height:"400px",marginTop:"10px",overflow:"scroll"}}>
                      {this.state.productData &&
                      this.state.productData.map(({rowValue},i) => this.productDataRow(rowValue,i))}
                    </Box>
                    &nbsp;
                    <Box textAlign="center" marginTop={'4px'} marginLeft={'5%'} width={'90%'}>
                      <Button 
                        variant={`contained`}
                        className={classes.buttonColor}
                        fullWidth
                        onClick={this.registerDataButtonClick.bind(this)}                  
                        disabled={this.state.orderStatusUpdateDisabled}
                      >
                        データ登録
                      </Button>
                    </Box>
                    <Box textAlign="center" marginTop={'20px'} marginLeft={'5%'} width={'90%'}>
                      <Button 
                        variant={`contained`}
                        className={classes.buttonColor}
                        fullWidth
                        onClick={this.productDataClear.bind(this)}
                      >
                        クリア
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box display={'flex'} marginTop={'30px'}marginBottom={'20px'} paddingLeft={'5%'} paddingRight={'5%'} alignItems="center">
                  <Select 
                      id="selectStatus"
                      name="selectStatus"
                      fullWidth
                      variant="outlined"
                      value={this.state.productStatus}
                      onChange={event => this.setState({productStatus:event.target.value})}
                      //disabled={this.state.statusUpdateDisabled}
                      disabled={!this.state.orderStatusUpdateDisabled}
                    >
                      {productStatusListMenu}
                    </Select>
                  <Box minWidth={180} style={{paddingLeft:"5%",paddingRight:"3%"}}>
                    <Button
                      variant={`contained`}
                      className={classes.buttonColor}
                      fullWidth
                      //disabled={this.state.statusUpdateDisabled}
                      disabled={!this.state.orderStatusUpdateDisabled}
                      style={{opacity:this.state.statusUpdateDisabled ? "0.5" : ""}}
                      onClick={this.updateButtonClick.bind(this)}
                    >
                      更新
                    </Button>
                  </Box>
                </Box>
               </Box>
               <Box flexGrow={1} style={{width:"48%",borderRadius:"5px",border:"1px solid #bdbdbd",opacity:this.state.orderStatusUpdateDisabled ? "0.5" : ""}} padding={'10px'}>
               <Box display={'flex'}>
                  <Box style={{width:"40%"}} marginLeft={'5%'}>
                    <Box marginTop={'34px'}>
                    注文番号
                    <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          disabled
                          value={this.state.orderNumber}
                    />
                    </Box>
                    <Box marginTop={'40px'}>
                    案件名
                    <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          disabled
                          value={this.state.projectName}
                    />
                    </Box>
                    <Box marginTop={'20px'}>
                    取引先名
                    <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          disabled
                          value={this.state.companyName}
                    />
                    </Box>
                    <Box marginTop={'20px'}>
                    レンタル期間開始
                    <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          disabled
                          value={this.state.useStartDate}
                    />
                    </Box>
                    <Box marginTop={'20px'}>
                    レンタル期間終了
                    <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          disabled
                          value={this.state.useEndDate}
                    />
                    </Box>
                    <Box marginTop={'20px'}>
                    注文ステータス
                    <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          disabled
                          value={this.state.orderStatus}
                    />
                    </Box>
                  </Box>
                  <Box style={{width:"40%"}} marginLeft={'10%'} justifyContent={'center'}>
                    <Box p={1} className={classes.shipmentHeaderColor}>
                      出荷データ
                    </Box>
                    <Box style={{borderRadius:"5px",border:"1px solid #bdbdbd",height:"400px",marginTop:"10px",overflow:"scroll"}}>
                      {this.state.shipmentData &&
                        this.state.shipmentData.map(({rowValue},i) => this.shipmentDataRow(rowValue,i))}
                    </Box>
                    &nbsp;
                    <Box textAlign="center" marginTop={'4px'} marginLeft={'5%'} width={'90%'}>
                      <Button 
                        variant={`contained`}
                        className={classes.buttonColor}
                        fullWidth
                        disabled={this.state.orderStatusUpdateDisabled}
                        onClick={this.deleteButtonClick.bind(this)}
                      >
                        削除
                      </Button>
                    </Box>
                    
                  </Box>
                  
                </Box>
                <Box display={'flex'} marginTop={'80px'}marginBottom={'20px'} paddingLeft={'5%'} paddingRight={'5%'} alignItems="center">
                  <Select 
                      id="selectStatus"
                      name="selectStatus"
                      fullWidth
                      variant="outlined"
                      defaultValue="01"
                      disabled={this.state.orderStatusUpdateDisabled}
                      value={this.state.statusMethod}
                      onChange={event => this.setState({statusMethod:event.target.value})}
                    >
                      <MenuItem value="01">出荷</MenuItem>
                      <MenuItem value="02">一括回収</MenuItem>
                      <MenuItem value="03">分割回収</MenuItem>
                    </Select>
                  <Box minWidth={180} style={{paddingLeft:"5%",paddingRight:"2%"}}>
                    <Button
                      variant={`contained`}
                      className={classes.buttonColor}
                      fullWidth
                      disabled={this.state.orderStatusUpdateDisabled}
                      onClick={this.updateShippingProductList.bind(this)}
                    >
                      更新
                    </Button>
                  </Box>
                  <Box minWidth={180} style={{paddingLeft:"2%",paddingRight:"3%"}}>
                    <Button
                      variant={`contained`}
                      className={classes.buttonColor}
                      fullWidth
                      disabled={this.state.orderStatusUpdateDisabled}
                      onClick={this.backOrderRegist.bind(this)}
                      endIcon={<ExitToApp />}
                    >
                      戻る
                    </Button>
                  </Box>
                </Box>
               </Box>
          </Box>
        )
    
        const buttonDiv = (
          <Box></Box>
        );

        return (
          <Box style={{ height: "100vh", width: "100vw" }}>
            <Header value={this.state.headerErrorMessage}/>

            <Box
              style={{
                height: "calc(100% - 96px)",
                width: "calc(100% - 32px)",
                padding: "16px",
                overflow: "auto"
              }}
            >
            
              <Box minWidth={1400}>
                {titleDiv}
                {scanData}
                {buttonDiv}
              </Box>
              <AlertDialog/>
            </Box>
          </Box>
        );
    }

  //JSS Style記述
  static get styles() {
      return theme => ({
        titleHeaderColor: {
          border: "1px solid #525457",
          backgroundColor: "#1976d2",
          color: "#FFFFFF",
          textAlign: "left",
          fontSize: 20,
          fontWeight: "bold",
          borderRadius:"5px"
        },
        terminalHeaderColor: {
          border: "1px solid #bdbdbd",
          background: "linear-gradient(180deg, #f0f4c3 0%, #c0ca33 100%)",
          textAlign: "left",
          fontSize: 20,
          borderRadius:"5px",
          textAlign:"center"
        },
        productHeaderColor: {
          border: "1px solid #bdbdbd",
          background: "linear-gradient(180deg, #EAEDE8 0%, #F2DE0E 100%)",
          //backgroundColor: "#ffeb3b",
          textAlign: "left",
          fontSize: 20,
          borderRadius:"5px",
          textAlign:"center"
        },
        shipmentHeaderColor: {
          border: "1px solid #bdbdbd",
          backgroundColor: "#ffc107",
          textAlign: "left",
          fontSize: 20,
          borderRadius:"5px",
          textAlign:"center"
        },
        buttonColor: {
          fontSize: 18,
          fontWeight: "bold",
          background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
          // color: "#E1E1E1"
          color: "#FFFFFF",
          borderRadius: 50
        },
      });
    }
}
const { styles } = Component;
//const mapStateToProps = ({ orderRegist: { list } }) => ({ model: list });
const mapDispatchToProps = dispatch => ({ dispatch });
export default compose(
  withStyles(styles),
//  connect(mapStateToProps, mapDispatchToProps)
    connect(mapDispatchToProps)
)(Component);