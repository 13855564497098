import { handleAction } from 'redux-actions';
import { listRepairHistory, pageCountRepairHistory, currentPageRepairHistory } from '../actions';

const defaultState = { list: [] };
const defaultState2 = { totalPageCount:0 };
const defaultState3 = { currentPage:1 };

export const repairHistoryReducer = handleAction(listRepairHistory, (state, { payload }) => ({ ...state, list: payload }), defaultState);

// ページネーション対応
export const repairHistoryReducerPageCount = handleAction(pageCountRepairHistory, (state, { payload }) => ({ ...state, totalPageCount: payload }), defaultState2);
export const repairHistoryReducerCurrentPage = handleAction(currentPageRepairHistory, (state, { payload }) => ({ ...state, currentPage: payload }), defaultState3);