import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles, fade } from "@material-ui/core/styles";
import { Box, Button, Grid, Fab, Checkbox, TextField,Select,MenuItem,InputLabel } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import RemoveIcon from "@material-ui/icons/Remove";
import ColorLensOutlinedIcon from "@material-ui/icons/ColorLensOutlined";
import { TwitterPicker } from "react-color";
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "axios";
import EditIcon from '@material-ui/icons/Edit';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Header from "../header/Header";
import AlertDialog from "../components/Dialog";
import { moveTo, listProductModel } from "../actions";
import Cookies from "js-cookie";

import REQUEST_PATH from "../components/Common";
import { Alert } from '@material-ui/lab';

class Component extends React.Component {
  state = {
    // 検索項目
    searchModelName: "",
    searchLicenseType: "",
    // フォーム
    inputForm: [],
    changeForm: null,
    // colorパレット
    displayColorPicker: false,
    setColor: "#FFFFFF",
    // ボタン
    addLock: false,
    removeLock: true,
    registerLock: true,
    updateLock:true,
    deleteLock:true,
    // ヘルパーテキスト
    modelHelperText:"",
    modelNameHelperText:"",
    // エラー
    modelError:false,
    modelNameError:false,

    // エラーメッセージ
    errorMessage:'',
    // 免許種別リスト
    license_list: [],

    successMessage:'',
  };

  constructor(props){
    super(props);
    this.getLinsenceListTable();
  }

  // render後、ボタン制御（遷移元からの復帰対策）
  componentDidMount() {
    this.checkState();
  }

  // 免許種別の取得
  async getLinsenceListTable() {

    const param = Cookies.get('token');

    const { data } = await axios.post(
      REQUEST_PATH + 'api/license-type/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    );
    this.setState({license_list:data.list});
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };
  handleClose(index) {
    this.setState({ displayColorPicker: false });
  }

  handleChange = color => {
    this.setState({ setColor: color.hex });
  };

  onChangeSearchField(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  onClearSearchField() {
    this.setState({ searchModelName: "", searchLicenseType: "" });
    this.forceUpdate();
  }

  /***********************************************************************************************
  * 追加時のフィールドにイベントが発生した際呼び出される関数
  * ここにエラー時のhelperTextの内容を記述する
  ***********************************************************************************************/
  onChangeAddField(evt, index) {
    const changeForm = this.state.inputForm.slice()[index];
    const val = evt.target.value;
    switch (evt.target.name) {
      case "model": {
        changeForm.model = val;

        if(isNaN(changeForm.model)){
          this.setState({modelHelperText:"半角数字以外が入力されています"});
          this.setState({modelError:true});
          break;
        }

        if(changeForm.model.length > 0 && changeForm.model.length < 2) {
          this.setState({modelHelperText:"桁数が足りません"});
          this.setState({modelError:true});
          break;
        }

        if(changeForm.model.length > 2){
          this.setState({modelHelperText:"桁数が長すぎます"});
          this.setState({modelError:true});
          break
        }

        this.setState({modelHelperText:""});
        this.setState({modelError:false});
        break;
      }
      case "modelName": {
        changeForm.modelName = val;
        if(changeForm.modelName.length > 16 ){
          this.setState({modelNameHelperText:"入力文字数が長すぎます"});
          this.setState({modelNameError:true});
        } else {
          this.setState({modelNameHelperText:""});
          this.setState({modelNameError:false});
        }
        break;
      }
      case "licenseType": {
        changeForm.licenseType = val;
        break;
      }
      case "colorCode": {
        changeForm.colorCode = val;
        break;
      }
      default: {
        break;
      }
    }
    this.forceUpdate();
  }

  //編集時にイベントが発生した際呼び出される関数
  onChangeField(e) {
    const changeItem = this.state.changeForm;
    const val = e.target.value;

    switch (e.target.name) {
      case "modelName": {
        changeItem.modelName = val;

        if(changeItem.modelName.length > 16 ){
          this.setState({modelNameHelperText:"入力文字数が長すぎます"});
          this.setState({modelNameError:true});
        } else {
          this.setState({modelNameHelperText:""});
          this.setState({modelNameError:false});
        }
        break;
      }
      case "licenseType": {
        changeItem.licenseType = val;
        break;
      }
      case "colorCode": {
        changeItem.colorCode = val;
        break;
      }
      default: {
          break;
        }
      }
    this.forceUpdate();
  }

  handleToAboutPage() {
    const { dispatch } = this.props;
    dispatch(moveTo.Sample());
  }

  handleCheckAll(evt) {
    const { model } = this.props;
    
    // Fab-ボタンが活性の場合、選択させない
    if(!this.state.removeLock) {
      return;
    }

    for (let i in model) {
      model[i].checked = evt.target.checked;
    }

    this.checkState();
    this.forceUpdate();
  }

  //チェックボックスにチェックを入れた時に呼び出される関数
  handleCheck(evt) {
    const { model } = this.props;

    // Fab-ボタンが活性の場合、選択させない
    if(!this.state.removeLock) {
      return;
    }

    const _model = model.filter(({ model: id }, index) => {
      return evt.target.name === id;
    })[0];
    if (_model) {
      _model.checked = evt.target.checked;
      this.setState({setColor: _model.colorCode });
    }

    if (!_model.checked) {
      // エラーリセット
      this.setState({modelHelperText:""});
      this.setState({modelNameHelperText:""});
      this.setState({modelError:false});
      this.setState({modelNameError:false});
    }
    this.checkState();
    this.forceUpdate();
  }

  checkState() {
    const { model } = this.props;
    const inputItemNum = this.state.inputForm.slice().length;
    const checkItem = model.filter(_model => {
      return _model.checked;
    });

    if (checkItem && checkItem.length === 1 && inputItemNum === 0) {
      this.setState({ setColor:checkItem[0].colorCode});
      this.setState({ changeForm: { ...checkItem[0] } });

      this.setState({ updateLock:false });
      this.setState({ deleteLock:false});

      this.setState({ addLock: true });
      this.setState({ removeLock: true });
    } else {
      this.setState({ changeForm: null });
    }
    
    if (checkItem && checkItem.length > 1) {
      
      this.setState({ deleteLock:false});
      this.setState({ updateLock:true });

      this.setState({ addLock: true });
      this.setState({ removeLock: true });
    }

    if (checkItem && checkItem.length === 0) {
      this.setState({ updateLock:true });
      this.setState({ deleteLock:true});
      this.setState({ registerLock:true});

      this.setState({ addLock: false });
      this.setState({ removeLock: true });
    }
  }

  handleAddForm() {
    const fieldList = this.state.inputForm.slice();
    fieldList.push({
      model: "",
      modelName: "",
      licenseType: "00",
      colorCode: "#FFFFFFF"
    });
    this.setState({ setColor:"#FFFFFF"});

    this.setState({ registerLock :false});
    this.setState({ removeLock :false});
    this.setState({ addLock :true});
    this.setState({ inputForm: fieldList });
  }

  handleRemoveForm() {
    const fieldList = this.state.inputForm.slice();
    fieldList.pop();
    this.setState({ inputForm: fieldList });
    this.setState({ registerLock :true});
    this.setState({ removeLock :true});
    this.setState({ addLock :false});

    // エラーリセット
    this.setState({modelHelperText:""});
    this.setState({modelNameHelperText:""});
    this.setState({modelError:false});
    this.setState({modelNameError:false});
  }

  getLisenceTypeOptions = () => {
    const licenseList = this.state.license_list;
    const menuItems = [];
    for (const i in licenseList) {
      menuItems.push(
        <MenuItem
          value={licenseList[i].licenseType}
          key={licenseList[i].licenseType}
          >
          {licenseList[i].licenseType+":"+licenseList[i].licenseTypeName}
        </MenuItem>
      );
    }
    return menuItems;
  };

  addForm(model, modelName, licenseType, colorCode, i) {
    const { classes } = this.props;
    const form = this.state.inputForm.slice()[i];
    const licenseListMenu = this.getLisenceTypeOptions();
    
    return (
      <Box
        key={i}
        display={`flex`}
        justifyContent={`space-between`}
        flexGrow={1}
      >
        <Box className={classes.addMstTblItemFirst}>
          <Checkbox
            checked={true}
            onChange={this.handleCheck.bind(this)}
            inputProps={{ "aria-label": "checkbox" }}
            classes={{
              root: classes.root,
              checked: classes.checked
            }}
          />
        </Box>
        <Box className={classes.addMstTblItem}>
          <TextField
            id="model"
            name="model"
            label="機種"
            variant="outlined"
            size={"small"}
            value={form.model}
            onChange={event => this.onChangeAddField(event, i)}
            style={{ backgroundColor: "#FFFFFF" }}
            error={this.state.modelError}
            helperText={this.state.modelHelperText}
          />
        </Box>
        <Box className={classes.addMstTblItem + ' ' + classes.itemAlingnLeft}>
          <TextField
            id="modelName"
            name="modelName"
            label="機種名"
            variant="outlined"
            size={"small"}
            value={form.modelName}
            onChange={event => this.onChangeAddField(event, i)}
            style={{ backgroundColor: "#FFFFFF" }}
            error={this.state.modelNameError}
            helperText={this.state.modelNameHelperText}
          />
        </Box>
        <Box className={classes.addMstTblItem + ' ' + classes.itemAlingnLeft}>
          <Select
            labelid="licenseType_label"
            id="licenseType"
            name="licenseType"
            variant="outlined"
            size={"small"}
            value={form.licenseType}
            onChange={event => this.onChangeAddField(event, i)}
            style={{ backgroundColor: "#FFFFFF" }}
          >
            {licenseListMenu}
          </Select>
        </Box>
        <Box className={classes.addMstTblColorItem}>{this.colorPicker()}</Box>
      </Box>
    );
  }

  colorPicker = () => {
    return (
      <Box style={{ position: "relative" }}>
        <Box>
          <Fab
            style={{
              background: `${this.state.setColor}`
            }}
            onClick={this.handleClick}
            size={"small"}
          >
            <ColorLensOutlinedIcon />
          </Fab>
        </Box>

        {this.state.displayColorPicker ? (
          <Box
            style={{
              position: "absolute",
              zIndex: "2",
              right: "0",
              top: "45px"
            }}
          >
            <Box
              style={{
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px"
              }}
              onClick={this.handleClose}
            />
            <TwitterPicker
              color={this.state.setColor}
              triangle={"top-right"}
              onChange={this.handleChange.bind(this)}
            />
          </Box>
        ) : null}
      </Box>
    );
  };
  /***********************************************************************************************
  * 検索結果のリスト表示機能、帰ってきた行数分だけPushを繰り返す。
  ***********************************************************************************************/
  getMstItemList = () => {
    const list = [];
    const { classes, model } = this.props;
    const changeItem = this.state.changeForm;
    const licenseListMenu = this.getLisenceTypeOptions();

    //表示用に免許種別名を設定
    for (const i in model){
        for(const j in this.state.license_list){
          if(model[i].licenseType === this.state.license_list[j].licenseType){
            model[i].licenseTypeName = this.state.license_list[j].licenseTypeName;
            break;
          }
        }
    }
    for (const i in model) {
      const formIsSelected = changeItem && changeItem.model === model[i].model;
      list.push(
        <Box
          key={i}
          display={`flex`}
          justifyContent={`space-between`}
          flexGrow={1}
        >
          {formIsSelected ? (
            <>
              <Box className={classes.addMstTblItemFirst}>
                <Checkbox
                  key={model[i].model}
                  name={model[i].model}
                  checked={model[i].checked || false}
                  onChange={this.handleCheck.bind(this)}
                  inputProps={{ "aria-label": "checkbox" }}
                  classes={{
                    root: classes.root,
                    checked: classes.checked
                  }}
                />
              </Box>
              <Box className={classes.addMstTblItem}>{model[i].model}</Box>
              <Box className={classes.addMstTblItem  + ' ' + classes.itemAlingnLeft}>
                <TextField
                  id="modelName"
                  name="modelName"
                  label="機種名"
                  variant="outlined"
                  size={"small"}
                  value={changeItem.modelName}
                  onChange={event => this.onChangeField(event)}
                  style={{ backgroundColor: "#FFFFFF" }}
                  error={this.state.modelNameError}
                  helperText={this.state.modelNameHelperText}
                />
              </Box>
              <Box className={classes.addMstTblItem  + ' ' + classes.itemAlingnLeft}>
                <Select
                   id="licenseType"
                   name="licenseType"
                   label="免許種別"
                   variant="outlined"
                   size={"small"}
                   value={changeItem.licenseType}
                   onChange={event => this.onChangeField(event)}
                   style={{ backgroundColor: "#FFFFFF" }}
                  >
                {licenseListMenu}
                </Select>
              </Box>
              <Box className={classes.addMstTblColorItem}>
                <TextField
                  type={"hidden"}
                  id="colorCode"
                  name="colorCode"
                  value={this.state.setColor}
                  onChange={event => this.onChangeField(event)}
                />
                {this.colorPicker()}
              </Box>
            </>
          ) : (
            <>
              <Box className={classes.mstTblItemFirst}>
                <Checkbox
                  key={model[i].model}
                  name={model[i].model}
                  checked={model[i].checked || false}
                  onChange={this.handleCheck.bind(this)}
                  inputProps={{ "aria-label": "checkbox" }}
                  classes={{
                    root: classes.root,
                    checked: classes.checked
                  }}
                />
              </Box>
              <Box className={classes.mstTblItem}>{model[i].model}</Box>
              <Box className={classes.mstTblItem  + ' ' + classes.itemAlingnLeft}>{model[i].modelName}</Box>
                <Box className={classes.mstTblItem  + ' ' + classes.itemAlingnLeft}>{model[i].licenseTypeName}</Box> 
              <Box className={classes.mstTblColorItem}>
                <Box
                  className={classes.boxColor}
                  style={{ backgroundColor: model[i].colorCode }}
                />
                {model[i].colorCode}
              </Box>
            </>
          )}
        </Box>
      );
    }
    return <Box>{list}</Box>;
  };

  render() {
    const { classes } = this.props;
    const list = this.getMstItemList();
    const licenseListMenu = this.getLisenceTypeOptions();
    const titleDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>
          <Box p={1} className={classes.titleHeaderColor}>
            商品機種管理画面
          </Box>
        </Box>
      </Box>
    );

    //検索フォーム
    const searchDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>
          <Box display={`flex`} p={2} className={classes.searchTbl}>
            {/*　左 */}
            <Box display={`flex`}>
              <Box display={`flex`}>
                <InputLabel className={classes.searchLabel}>機種名</InputLabel>
                  <TextField
                    name="searchModelName"
                    variant="outlined"
                    size="small"
                    className={classes.searchModelName}
                    value={this.state.searchModelName}
                    onChange={this.onChangeSearchField.bind(this)}
                  />
              </Box>

              <Box display={`flex`} className={classes.secondBox}>
                <InputLabel className={classes.searchLabel}>免許種別</InputLabel>
                <Select
                  name="searchLicenseType"
                  variant="outlined"
                  size={"small"}
                  className={classes.pullDown}
                  value={this.state.searchLicenseType}
                  onChange={this.onChangeSearchField.bind(this)}
                  style={{ backgroundColor: "#FFFFFF" }}
                  >
                  <MenuItem value="" key="">全て</MenuItem>
                  {licenseListMenu}
                </Select>
              </Box>
            </Box>

            {/* 右 */}
            <Box>
              <Box display={`flex`} flexGrow={1} justify="left">
                <Box width={90}>
                  <Fab
                    className={classes.searchFabColor}
                    onClick={this.search.bind(this)}
                  >
                    <SearchOutlinedIcon style={{ fontSize: 30 }} />
                  </Fab>
                </Box>
                
                <Box width={70}>
                  <Fab
                    className={classes.searchFabColor}
                    onClick={this.onClearSearchField.bind(this)}
                  >
                    <CancelPresentationIcon style={{ fontSize: 30 }} />
                  </Fab>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );

  /***********************************************************************************************
  * 画面のテーブルのヘッダ情報のJSXとpropsの設定です。
  * 検索結果を表示する1レコード文をlistとして宣言しており、(429行目)
  * propsにレコードの情報が入るようにmapしています。
  * 宣言のみなので、検索ボタンが押されてメソッドが走るまではヘッダ部分の描画になります。
  ***********************************************************************************************/
    const mstTbl = (
      <Box style={{ paddingBottom: "16px" }}>
        <Box
          style={{
            // border: `1px solid #525457`,
            borderBottom: `1px solid #525457`,
            borderRadius:'5px'
          }}
        >
          <Box display={`flex`} justifyContent={`space-between`} flexGrow={1}>
            <Box className={classes.mstTblColFirst}>
              <Checkbox
                onChange={this.handleCheckAll.bind(this)}
                style={{ color: "#4A4A4B" }}
              />
            </Box>
            <Box className={classes.mstTblCol}>機種</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>機種名</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>免許種別</Box>
            <Box className={classes.mstTblColLast}>色コード</Box>
          </Box>
          {list}
          {this.state.inputForm &&
            this.state.inputForm.map(
              ({ model, modelName, licenseType, colorCode }, i) =>
                this.addForm(model, modelName, licenseType, colorCode, i)
            )}
        </Box>
      </Box>
    );
  /***********************************************************************************************
  * 右下の追加削除ボタン
  * 関数名fubDivになっているが、FabDivの間違い？
  ***********************************************************************************************/
    const fubDiv = (
      <Box display={`flex`} flexGrow={1} justifyContent="flex-end" height={70}>
        <Box width={50}>
          <Fab
            size="small"
            className={classes.fabColor}
            onClick={this.handleAddForm.bind(this)}
            disabled={this.state.addLock}
          >
            <AddIcon />
          </Fab>
        </Box>
        <Box width={50}>
          <Fab
            size="small"
            className={classes.fabColor}
            onClick={this.handleRemoveForm.bind(this)}
            disabled={this.state.removeLock}
          >
            <RemoveIcon />
          </Fab>
        </Box>
      </Box>
    );

  /***********************************************************************************************
  * 画面下部の「登録」「変更」「削除」のJSXとpropsの設定のメソッドです。
  ***********************************************************************************************/
    const buttonDiv = (
      <Grid container spacing={3} justify="center" style={{textAlign:'center'}}>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.insert.bind(this), '登録しますか？')}
            endIcon={<PostAddIcon />}
            disabled={this.state.registerLock}
          >
            登録
          </Button>
        </Grid>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.update.bind(this), '更新しますか？')}
            endIcon={<EditIcon />}
            disabled={this.state.updateLock}
          >
            変更
          </Button>
        </Grid>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.delete.bind(this), '削除しますか？')}
            endIcon={<DeleteIcon />}
            disabled={this.state.deleteLock}
          >
          
            削除
            
          </Button>
        </Grid>
      </Grid>
    );

    return (
      <Box style={{ height: "100vh", width: "100vw" }}>
        <Header value={this.state.errorMessage}/>
        <Alert 
          id="successAlert"
          style={{display:'none'}}
          className={classes.successAlertStyle}
          severity="success">
            {this.state.successMessage}
        </Alert>
        <Box
          style={{
            height: "calc(100% - 96px)",
            width: "calc(100% - 32px)",
            padding: "16px",
            overflow: "auto"
          }}
        >
          <Box minWidth={975}>
            {titleDiv}
            {searchDiv}
            {mstTbl}
            {fubDiv}
            {buttonDiv}
          </Box>
          <AlertDialog/>
        </Box>
      </Box>
    );
  }
  /***********************************************************************************************
  * 検索ボタンがクリックされた時に実行されるメソッドです。
  * 一応検索フィールドに入力された際に入力チェックを行っており、入力謝りがあればreturnを返しています
  ***********************************************************************************************/
  async search() {

    const { dispatch } = this.props;
    const _searchModelName = this.state.searchModelName || null;
    const _searchLicenseType = this.state.searchLicenseType || null;

    await this.setState({errorMessage:''});

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/product-model/list',
      {
        pageIndex: 0,
        pageSize: 100,
        modelName: _searchModelName,
        licenseType: _searchLicenseType
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      dispatch(listProductModel(response.data.list));
    }).catch(e =>{
      // 失敗時
      this.setState({errorMessage:'検索に失敗しました'});
      console.log(e);
    });
  }
  
  /***********************************************************************************************
  * 登録ボタンをクリックされた時に実行されるメソッド
  * /api/product-model/createにJSON形式でデータを投げています
  * ここで入力フィールドチェックを行ってください。
  * 2020年2月5日時点では入力フォームが一つしかでないので入力謝りがあった際にはcontinue文抜けています
  * ヘッダーのメッセージ欄にエラーメッセージを投げてreturnしてメソッドを終了するのが適切だと思います。
  ***********************************************************************************************/
  async insert() {
    const fieldList = this.state.inputForm.slice();
    const color = this.state.setColor;
    const { model } = this.props;

    await this.setState({errorMessage:''});

    const checkResult = this.validate(
      fieldList[0].model, 
      fieldList[0].modelName, 
      fieldList[0].licenseType,
      color)

    if(checkResult) {
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }

    const param = Cookies.get('token');

    for (let i in fieldList) {

      await axios.post(REQUEST_PATH + 'api/product-model/create', {
          model: fieldList[i].model,
          modelName: fieldList[i].modelName,
          licenseType: fieldList[i].licenseType,
          colorCode: color
        },{
          param,
          withCredentials: true,
        }).then( response => {
          
          model.push({
            model: fieldList[i].model,
            modelName: fieldList[i].modelName,
            licenseType: fieldList[i].licenseType,
            colorCode: color,
            checked: false
          });
          
          fieldList.pop();
          this.setState({ inputForm: fieldList });

          this.openeSuccessAlert("登録しました");
      }).catch(e =>{
        this.setState({errorMessage:'登録に失敗しました'});
        console.log(e)
      });
    }

    this.forceUpdate();
    this.checkState();
    document.getElementById('dialogBox').style.display = 'none';
    
  }
  /***********************************************************************************************
  * 変更ボタンをクリックされた時に実行されるメソッド
  * /api/product-model/updateにJSON形式でデータを投げています
  * ここでも入力フィールドチェックを行ってください。
  ***********************************************************************************************/
  async update() {
    const changeItem = this.state.changeForm;
    const color = this.state.setColor;
    const { model } = this.props;

    await this.setState({errorMessage:''});

    const checkResult = this.validate(
      changeItem.model, 
      changeItem.modelName, 
      changeItem.licenseType,
      color)

    if(checkResult) {
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }

    const param = Cookies.get('token');

    await axios.put(REQUEST_PATH + 'api/product-model/update', {
        model: changeItem.model,
        modelName: changeItem.modelName,
        licenseType: changeItem.licenseType,
        colorCode: color
      }
      ,{
        param,
        withCredentials: true,
      }).then( response => {

        const _model = model.filter(({ model: id }) => {
          return changeItem.model === id;
        })[0];
        if (_model) {
          _model.modelName = changeItem.modelName;
          _model.licenseType = changeItem.licenseType;
          _model.colorCode = color;
          _model.checked = false;
        }
        this.checkState();
        this.setState({ changeForm: null });

        this.openeSuccessAlert("更新しました");
    }).catch(e =>{

      this.setState({errorMessage:'更新に失敗しました'});
      console.log(e);
    });

    document.getElementById('dialogBox').style.display = 'none';
    this.checkState();
  }

  /***********************************************************************************************
  * 削除ボタンをクリックされた時に実行されるメソッド
  ***********************************************************************************************/

  async delete() {
    const { model } = this.props;
    const deleteList = [];

    await this.setState({errorMessage:''});

    const param = Cookies.get('token');

    for (let i in model) {
      if (model[i].checked) {
        await axios.delete(
          REQUEST_PATH + 'api/product-model/delete/' + model[i].model
          ,{
            param,
            withCredentials: true,
          }
        ).then( response => {
          deleteList.push(model[i].model);
        }).catch(e =>{
          console.log(e);
          this.setState({errorMessage:'削除に失敗しました'});
        });       
      }
    }

    if(deleteList.length > 0){
      deleteList.forEach(id => {
        const index = model.findIndex(v => v.model === id);
        model.splice(index, 1);
      });
      this.openeSuccessAlert("削除しました");
    }
    this.forceUpdate();
    this.checkState();
    
   document.getElementById('dialogBox').style.display = 'none';
  }

  // ダイアログ表示
  opneDialog (func, message) {

    var Dialog = document.getElementById('dialogBox');
    var okButton = document.getElementById('dialogOkButton');
    var cancelButton = document.getElementById('dialogCancelButton');
 
    document.getElementById('dialogMessage').innerHTML= message;
 
    // ダイアログ表示
    Dialog.style.display = '';
    // OK 押下の処理
    okButton.onclick = func;
    // cancel　押下の処理
    cancelButton.onclick = function() {
     Dialog.style.display = 'none';
     };
  }

  // 入力値チェック
  validate(model , modelName, licenseType, colorCode) {

    var isFailed = false;
    this.setState({modelError:false});
    this.setState({modelNameError:false});

    if(model === null || model === '' || model === 'undefined' || 
      isNaN(model)) {
      this.setState({modelHelperText:"入力に誤りがあります"});
      this.setState({modelError:true});
      isFailed = true;
    }
    if(modelName === null || modelName === '' || modelName === 'undefined') {
      this.setState({modelNameHelperText:"入力に誤りがあります"});
      this.setState({modelNameError:true});
      isFailed = true;
    }

    if(licenseType === null || licenseType === '' || licenseType === 'undefined') {
      isFailed = true;
    }

    if(colorCode === null || colorCode === '' || colorCode === 'undefined') {
      isFailed = true;
    }

    // 範囲チェック
    if(!isFailed) {
      isFailed =  this.validateRange(model , modelName);
    }

    return isFailed;
  }

  // 範囲チェック
  validateRange(model , modelName) {
    var isFailed = false;

    if(model.length > 0 && model.length < 2) {
      this.setState({modelHelperText:"桁数が足りません"});
      this.setState({modelError:true});
      isFailed = true;
    }

    if(model.length > 2){
      this.setState({modelHelperText:"桁数が長すぎます"});
      this.setState({modelError:true});
      isFailed = true;
    }
  
    if(modelName.length > 16 ){
      this.setState({modelNameHelperText:"入力文字数が長すぎます"});
      this.setState({modelNameError:true});
      isFailed = true;
    }

    return isFailed;
  }

  // 成功アラートの表示
  openeSuccessAlert(msg){

    document.getElementById('successAlert').style.display = '';
    this.setState({ successMessage: msg});

    // 2秒で消えるようにする。
    setTimeout(() => {
      document.getElementById('successAlert').style.display = 'none';
    },2000);
  }

  static get propTypes() {
    return {};
  }

  // Styleの設定
  static get styles() {
    return theme => ({

      searchLicenseType: {
        backgroundColor: "#FFFFFF",
        marginRight: 50,
        width: 300,
        borderRadius: 5,
        height:40
      },
      mstTblItemFirst: {
        border: "1px solid #b0bec5",
        borderLeft: "1px solid #525457",
        // borderTop: "0",
        borderBottom: "0",
        textAlign: "center",
        width: 70,
        flexGrow: 1
      },
      mstTblItem: {
        border: "1px solid #b0bec5",
        borderLeft: "0",
        // borderTop: "0",
        borderBottom: "0",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },

      mstTblColorItem: {
        border: "1px solid #b0bec5",
        borderRight: "1px solid #525457",
        borderLeft: "0",
        // borderTop: "0",
        borderBottom: "0",
        width: 280,
        flexGrow: 1,
        textAlign: "center",
        color: "#4A4A4B",
        fontWeight: 500,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
      },
      addMstTblItemFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        width: 70,
        height: 80,
        flexGrow: 1
      },
      addMstTblItem: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: 280,
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      addMstTblColorItem: {
        border: "1px solid #525457",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: 280,
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      boxColor: {
        minHeight: 20,
        minWidth: 20,
        maxHeight: 20,
        maxWidth: 20,
        marginLeft: 15,
        marginRight: 6,
        border: "1px solid #4A4A4B",
        borderRadius: "6px"
      },
      root: {
        color: "#607d8b",
        "&$checked": {
          color: "#FF7200"
        }
      },
      checked: {},

      titleHeaderColor: {
        border: "1px solid #525457",
        backgroundColor: "#1976d2",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 20,
        fontWeight: "bold",
        borderRadius:"5px"
      },
      tblHeaderColor: {
        borderTop: "1px solid #525457",
        borderLeft: "0",
        borderRight: "1px solid #525457",
        backgroundColor: fade("#1565c0", 0.8),
        color: "#4A4A4B",
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold"
      },
      searchTbl: {
        border: "1px solid #525457",
        backgroundColor: "#bbdefb",
        color: "#4A4A4B",
        fontSize: 16,
        justifyContent:`space-between`,
        alignItems: "center",
        borderRadius:"5px"
      },
      mstTblColFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        textAlign: "center",
        width: 70,
        flexGrow: 1
      },
      mstTblCol: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      mstTblColLast: {
        border: "1px solid #525457",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      buttonColor: {
        width: 150,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        // color: "#E1E1E1"
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing:10
      },
      buttonDisableColor: {
        width: 150,
        fontSize: 18,
        fontWeight: "bold",
        background: "#BDBDBD",
        // color: "#E1E1E1"
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing:10
      },
      searchFabColor: {
        backgroundColor: "#2196f3",
        color: "#EEEEEE"
      },
      fabColor: {
        backgroundColor: "#26a69a",
        color: "#EEEEEE"
      },
      searchLabel:{
        paddingTop:'4%'
      },
      secondBox:{
        marginLeft:'20px',
      },
      searchModelName: {
        backgroundColor: "#FFFFFF",
        marginLeft:5,
        width: 250,
        borderRadius: 5
      },

      pullDown:{
        backgroundColor: "#FFFFFF",
        width: 250,
        borderRadius: 5,
        height:40,
        marginLeft:5
        //maxHeight: "28px"
      },
      itemAlingnLeft:{
        justifyContent: "left",
        paddingLeft: '1%',
      },
      itemTitlePaddingLeft:{
        paddingLeft: '1%',
      },
      successAlertStyle: {
        position: 'relative',
        justifyContent: 'center',
        width: '100%',
        backgroundColor:'#80cbc4',
        fontSize:'1rem'
      },
    });
  }
}

const mapStateToProps = ({ productModel: { list } }) => ({ model: list });
const mapDispatchToProps = dispatch => ({ dispatch });
const { styles } = Component;

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
