import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/ja";
import axios from "axios";
import { Box } from "@material-ui/core";
import Cookies from "js-cookie";

import "../App.css";

import Calendar from "./Calendar";
import { listCalendar, moveTo } from "../actions";
import REQUEST_PATH from "../components/Common";

import PropTypes from "prop-types";

const propTypes = {
  calendar2: PropTypes.func
};

moment.locale("ja");

class ScheduleApp extends React.Component {
  state = {
    CalanderDate:""
  }
  constructor(props){
    super(props);
    this.state.CalanderDate = this.props.date
  }

  clickButton() {
    if(this.state.CalanderDate !== "" ){
    return this.props.calendar2(this.state.CalanderDate);
    }else{
      const date = moment().format("YYYY/MM/01");
      return this.props.calendar2(date);
    }
  }
  
  doubleClickOrder({ orderNumber }) {
    const { dispatch } = this.props;
    //dispatch(moveTo.SampleOrder({ orderNumber }));
    dispatch(moveTo.OrderRegist({ orderNumber }));
  }
  
  getMonth(event){
    this.setState({CalanderDate:event});
    this.refreshCalendar(event);
  }
  
  moveToOrderRegist() {
    const { dispatch } = this.props;
    dispatch(moveTo.OrderRegist( '' ));
  }

  getOperatingInstructions(event){
    console.log("作業指示発行ボタンクリック")
    console.log("日付:"+event);
  }

  async refreshCalendar(event){
    const { dispatch } = this.props;
    const param = Cookies.get('token');
    const now = moment(event);
    const { data } = await axios.get(
      REQUEST_PATH+`api/calendar/list?year=${now.format(
        `YYYY`
      )}&month=${now.format(`MM`)}`,
      {
        withCredentials: true,
        param
      }
    );

    if (Array.isArray(data)) {
      data.sort(({ start: a }, { start: b }) => a - b);
      const list = data.map(
        (
          {
            orderNumber,
            company: customer,
            companyPerson,
            person: name,
            start,
            end,
            orderDisplayStatus,
            orderDisplayStatusName: status,
            shippingDate,
            collectDate,
            color,
            shipment,
            collect
          },
          index
        ) => {
          return {
            orderNumber,
            customer,
            companyPerson,
            name,
            start,
            end,
            orderDisplayStatus,
            status,
            color,
            shippingDate,
            collectDate,
            shipment,
            collect
          };
        }
      );
      dispatch(listCalendar(list));
    }
  }

  render() {
    // const { orders } = this.state;
    const { orders } = this.props;

    const titleDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>
          <Box
            p={1}
            style={{
              border: "1px solid #525457",
              backgroundColor: "#2196f3",
              color: "#FFFFFF",
              textAlign: "left",
              fontSize: 20,
              fontWeight: "bold",
              minWidth:1393,
            }}
          >
            カレンダー画面
          </Box>
        </Box>
      </Box>
    );
    return (
      <Box>
        {titleDiv}
        <Box className="App">
          <Calendar
            orders={orders}
            selectDate={this.props.date}
            selectMonth={this.getMonth.bind(this)}
            onDetail={() => {
              this.clickButton();
            }}
            operating={this.getOperatingInstructions.bind(this)}
            onDoubleClick={this.doubleClickOrder.bind(this)}
            moveToOrderRegist={this.moveToOrderRegist.bind(this)}
          />
        </Box>
      </Box>
    );
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    const now = this.props.date !== "" ? moment(this.props.date) : moment();
    const param = Cookies.get('token');
    const { data } = await axios.get(
      REQUEST_PATH+`api/calendar/list?year=${now.format(
        `YYYY`
      )}&month=${now.format(`MM`)}`,{
      withCredentials: true,
      param
    }
    );

    if (Array.isArray(data)) {
      data.sort(({ start: a }, { start: b }) => a - b);
      const list = data.map(
        (
          {
            orderNumber,
            company: customer,
            companyPerson,
            person: name,
            start,
            end,
            orderDisplayStatus,
            orderDisplayStatusName: status,
            shippingDate,
            collectDate,
            color,
            shipment,
            collect
          },
          index
        ) => {
          return {
            orderNumber,
            customer,
            companyPerson,
            name,
            start,
            end,
            orderDisplayStatus,
            status,
            color,
            shippingDate,
            collectDate,
            shipment,
            collect
          };
        }
      );
      dispatch(listCalendar(list));
    }
  }
}
ScheduleApp.propTypes = propTypes;
// export default ScheduleApp;

const mapStateToProps = ({ calendar: { list } }) => ({ orders: list });
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleApp);
