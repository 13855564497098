import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles, fade } from "@material-ui/core/styles";
import { Box, Button, Grid, Fab, Checkbox, TextField, InputLabel } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "axios";
import EditIcon from '@material-ui/icons/Edit';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Header from "../header/Header";
import AlertDialog from "../components/Dialog";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { moveTo, listTypeMaster } from "../actions";
import REQUEST_PATH from "../components/Common";

import { Alert } from '@material-ui/lab';
import Cookies from "js-cookie";

class Component extends React.Component {
  state = {
    // 検索項目
    searchProductName: "",
    // フォーム
    inputForm: [],
    changeForm: null,
    // 非活性
    addLock: false,
    removeLock: true,
    registerLock: true,
    updateLock:true,
    deleteLock:true,
     // ヘルパーテキスト
    productNameHelperText:"",
    productTypeHelperText:"",
    deliveryNoteShowHelperText:"",
    isInTaxHelperText:"",
    // エラー
    productNameError:false,
    productTypeError:false,
    deliveryNoteShowError:false,
    isInTaxError:false,
     //リスト
     deliveryNoteShow_list: [],
     isInTax_list: [],
    //ラジオボタン
    deliveryNoteShow:false,
    isInTax:false,
    deliveryNoteShowNot:true,
    isInTaxNot:true,
    // エラーメッセージ
    errorMessage:'',
    successMessage:'',
  };

  constructor(props){
    super(props);
    
  }
  // render後、ボタン制御（遷移元からの復帰対策）
  componentDidMount() {
    this.checkState();
  }
   // 検索域のイベント
  onChangeSearchField(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  onClearSearchField() {
    this.setState(
      { 
        searchProductName: "" ,
      });
    this.forceUpdate();
  }

  /***********************************************************************************************
  * 追加時のフィールドにイベントが発生した際呼び出される関数
  * ここにエラー時のhelperTextの内容を記述する
  ***********************************************************************************************/
  onChangeAddField(evt, index) {
    const changeForm = this.state.inputForm.slice()[index];
    const val = evt.target.value;
    switch (evt.target.name) {

      case "productType": {
        changeForm.productType = val;

        if(isNaN(changeForm.productType)){
          this.setState({productTypeHelperText:"半角数字以外が入力されています"});
          this.setState({productTypeError:true});
          break;
        }
      
        if(changeForm.productType.length > 0 && changeForm.productType.length < 2) {
          this.setState({productTypeHelperText:"桁数が足りません"});
          this.setState({productTypeError:true});
          break;
        }
      
        if(changeForm.productType.length > 2){
          this.setState({productTypeHelperText:"桁数が長すぎます"});
          this.setState({productTypeError:true});
          break
        }
      
        this.setState({productTypeHelperText:""});
        this.setState({productTypeError:false});
        break;
      }
      case "productName": {
        changeForm.productName = val;
        if(changeForm.productName.length > 16 ){
          this.setState({productNameHelperText:"入力文字数が長すぎます"});
          this.setState({productNameError:true});
        } else {
          this.setState({productNameHelperText:""});
          this.setState({productNameError:false});
        }
        break;
       }
       case "deliveryNoteShow": {
        changeForm.deliveryNoteShow = '1';
        this.setState({ deliveryNoteShow: true });
        break;
      }
      case "deliveryNoteShowNot": {
        this.setState({ deliveryNoteShow: false });
        changeForm.deliveryNoteShow = '0';
        break;
      }
      case "isInTax": {
        changeForm.isInTax = '1';
        this.setState({ isInTax: true });
        break;
      }
      case "isInTaxNot": {
        changeForm.isInTax = '0';
        this.setState({ isInTax: false });
        break;
      }
      default: {
        break;
      }
    }
    this.forceUpdate();
  }

  //編集時にイベントが発生した際呼び出される関数
  onChangeField(e) {
    const changeItem = this.state.changeForm;
    const val = e.target.value;

  switch (e.target.name) {
    case "productType": {
      changeItem.productType = val;

      if(changeItem.productType.length > 2 ){
        this.setState({productTypeHelperText:"入力文字数が長すぎます"});
        this.setState({productTypeError:true});
      } else {
        this.setState({productTypeHelperText:""});
        this.setState({productTypeError:false});
      }
      break;
    }
    case "productName": {
      changeItem.productName = val;

      if(changeItem.productName.length > 16 ){
        this.setState({productNameHelperText:"入力文字数が長すぎます"});
        this.setState({productNameError:true});
      } else {
        this.setState({productNameHelperText:""});
        this.setState({productNameError:false});
      }
      break;
    }
    case "deliveryNoteShow": {
      changeItem.deliveryNoteShow = '1';
      this.setState({ deliveryNoteShow: true });
      break;
    }
    case "deliveryNoteShowNot": {
      changeItem.deliveryNoteShow = '0';
      this.setState({ deliveryNoteShow: false });
      break;
    }
    case "isInTax": {
      changeItem.isInTax = '1';
      this.setState({ isInTax: true });
      break;
    }
    case "isInTaxNot": {
      changeItem.isInTax = '0';
      this.setState({ isInTax: false });
      break;
    }
    default: {
        break;
      }
    }
  this.forceUpdate();
}
  
  handleToAboutPage() {
    const { dispatch } = this.props;
    dispatch(moveTo.Sample());
  }

  handleCheckAll(evt) {
    const { model } = this.props;

    // Fab-ボタンが活性の場合、選択させない
    if(!this.state.removeLock) {
      return;
    }

    for (let i in model) {
      model[i].checked = evt.target.checked;
    }
    this.checkState();
    this.forceUpdate();
  }

 //チェックボックスにチェックを入れた時に呼び出される関数
 handleCheck(evt) {
  const { model } = this.props;

  
  // Fab-ボタンが活性の場合、選択させない
  if(!this.state.removeLock) {
    return;
  }

  const _model = model.filter(({ productType: id }, index) => {
    return evt.target.name === id;
  })[0];
  if (_model) {
    _model.checked = evt.target.checked;
    this.setState({deliveryNoteShow:_model.deliveryNoteShow === '1' ? true:false});
    this.setState({isInTax:_model.isInTax === '1' ? true:false});
  }

  if (!_model.checked) {
    // エラーリセット
    this.setState({productTypeHelperText:""});
    this.setState({productNameHelperText:""});
    this.setState({productTypeError:false});
    this.setState({productNameError:false});
  }
  this.checkState();
  this.forceUpdate();
}


checkState() {
  const { model } = this.props;
  const inputItemNum = this.state.inputForm.slice().length;
  const checkItem = model.filter(_model => {
    return _model.checked;
  });

  if (checkItem && checkItem.length === 1 && inputItemNum === 0) {
    this.setState({ setColor:checkItem[0].colorCode});
    this.setState({ changeForm: { ...checkItem[0] } });

    this.setState({ updateLock:false });
    this.setState({ deleteLock:false});

    this.setState({ addLock: true });
    this.setState({ removeLock: true });
  } else {
    this.setState({ changeForm: null });
  }
  
  if (checkItem && checkItem.length > 1) {
    
    this.setState({ deleteLock:false});
    this.setState({ updateLock:true });

    this.setState({ addLock: true });
    this.setState({ removeLock: true });
  }

  if (checkItem && checkItem.length === 0) {
    this.setState({ updateLock:true });
    this.setState({ deleteLock:true});
    this.setState({ registerLock:true});

    this.setState({ addLock: false });
    this.setState({ removeLock: true });
  }
}

  handleAddForm() {
    const fieldList = this.state.inputForm.slice();
    fieldList.push({
      productType: "",
      productName: "",
      deliveryNoteShow: "",
      isInTax: "",
    });
    this.setState({ registerLock :false});
    this.setState({ removeLock :false});
    this.setState({ addLock :true});
    this.setState({ inputForm: fieldList });
  }

  handleRemoveForm() {
    const fieldList = this.state.inputForm.slice();
    fieldList.pop();
    this.setState({ inputForm: fieldList });
    this.setState({ registerLock :true});
    this.setState({ removeLock :true});
    this.setState({ addLock :false});
    // エラー要素のリセット
    this.setState({personIdHelperText:''});
    this.setState({personIdError:false});
    this.setState({personNameHelperText:''});
    this.setState({personNameError:false});
  }


  // 入力行の追加
  addForm( productType,productName,deliveryNoteShow,isInTax, i) {
    const { classes } = this.props;
    const form = this.state.inputForm.slice()[i];
    
    return (
      <Box
        key={i}
        display={`flex`}
        justifyContent={`space-between`}
        flexGrow={1}
      >
        <Box className={classes.addMstTblItemFirst}>
          <Checkbox
            checked={true}
            onChange={this.handleCheck.bind(this)}
            inputProps={{ "aria-label": "checkbox" }}
            classes={{
              root: classes.root,
              checked: classes.checked
            }}
          />
        </Box>

        <Box className={classes.addMstTblItem}>
          <TextField
          //productType
            id="productType"
            name="productType"
            label="商品種別"
            variant="outlined"
            size={"small"}
            value={form.productType}
            onChange={event => this.onChangeAddField(event, i)}
            style={{ backgroundColor: "#FFFFFF" }}
            error={this.state.productTypeError}
            helperText={this.state.productTypeHelperText}
          />
        </Box>

        <Box className={classes.addMstTblItem  + ' ' + classes.itemAlingnLeft}>
          <TextField
          //productName
            id="productName"
            name="productName"
            label="商品名"
            variant="outlined"
            size={"small"}
            value={form.productName}
            onChange={event => this.onChangeAddField(event, i)}
            error={this.state.productNameError}
            style={{ backgroundColor: "#FFFFFF" }}
            helperText={this.state.productNameHelperText}
          />
        </Box>

        {/* 納品書表示 */}
        <Box className={classes.addMstTblItemDelivery}>
          <FormControl component="fieldset">
            <RadioGroup
              //name="deliveryNoteShow" 
              row
              aria-label="position"
              name="position" 
              defaultValue="top"
              value={form.deliveryNoteShow}
              onChange={event => this.onChangeAddField(event, i)}
            >
              <FormControlLabel
                id="deliveryNoteShow"
                name="deliveryNoteShow"
                label="有"
                control={<Radio color="primary" />}
                labelPlacement="start"
                checked={this.state.deliveryNoteShow}
              /> 
          　　<FormControlLabel
                id="deliveryNoteShowNot"
                name="deliveryNoteShowNot"
                label="無"
                control={<Radio color="primary" />}
                labelPlacement="start"
                checked={!this.state.deliveryNoteShow}
              />
            </RadioGroup>
          </FormControl>
        </Box>


      {/* 消費税表示 */}
      <Box className={classes.addMstTblItemTax}>
        <FormControl component="fieldset">
         <RadioGroup 
          //name="isInTax" 
          row
          aria-label="position"
          name="position" 
          defaultValue="top"
          value={form.isInTax}
          onChange={event => this.onChangeAddField(event, i)}
          //value={this.state.isInTax}
          >
          <FormControlLabel
            name="isInTax"
            label="有"
            control={<Radio color="primary" />}
            labelPlacement="start"
            checked={this.state.isInTax}
          />
          <FormControlLabel
            name="isInTaxNot"
            label="無"
            control={<Radio color="primary" />}
            onChange={event => this.onChangeAddField(event, i)}
            labelPlacement="start"
            checked={!this.state.isInTax}
          />
        </RadioGroup>
       </FormControl>
      </Box>
    </Box>
    );
  }

  /***********************************************************************************************
  * 検索結果のリスト表示機能、帰ってきた行数分だけPushを繰り返す。
  ***********************************************************************************************/
  getMstItemList = () => {
    const list = [];
    const { classes, model } = this.props;
    const changeItem = this.state.changeForm;

    for (const i in model) {
      const formIsSelected = changeItem && changeItem.productType === model[i].productType;
      var deliveryNoteShowDis = "無";
      var isInTaxDis = "無";
      
      // 表示用の処理
      if (model[i].deliveryNoteShow === '1'){
        deliveryNoteShowDis = "有";
      }
        
      if (model[i].isInTax === '1') {
        isInTaxDis = "有";
      }

      list.push(
        <Box
          key={i}
          display={`flex`}
          justifyContent={`space-between`}
          flexGrow={1}
        >
          {formIsSelected ? (
            <>
              <Box className={classes.addMstTblItemFirst}>
                <Checkbox
                  key={model[i].productType}
                  name={model[i].productType}
                  checked={model[i].checked || false}
                  onChange={this.handleCheck.bind(this)}
                  inputProps={{ "aria-label": "checkbox" }}
                  classes={{
                    root: classes.root,
                    checked: classes.checked
                  }}
                />
              </Box>
              <Box className={classes.addMstTblItem}>{model[i].productType}</Box>
              <Box className={classes.addMstTblItem + ' ' + classes.itemAlingnLeft}>
                <TextField
                  id="productName"
                  name="productName"
                  label="商品名"
                  variant="outlined"
                  size={"small"}
                  value={changeItem.productName}
                  onChange={event => this.onChangeField(event)}
                  style={{ backgroundColor: "#FFFFFF" }}
                  error={this.state.productNameError}
                />
              </Box>

              {/* 納品書表示 */}
              <Box className={classes.addMstTblItemDelivery}>
                <FormControl component="fieldset">
                  <RadioGroup 
                    name="deliveryNoteShow" 
                    row
                    aria-label="position" 
                    name="position" 
                    defaultValue="top"
                    value={changeItem.deliveryNoteShow}
                    onChange={event => this.onChangeField(event)}
                    >

                    <FormControlLabel
                      id="deliveryNoteShow"
                      name="deliveryNoteShow"
                      label="有"
                      //value={this.state.deliveryNoteShow}
                      control={<Radio color="primary" />}
                      labelPlacement="start"
                      checked={this.state.deliveryNoteShow}
                      />
                      
                　　<FormControlLabel
                      id="deliveryNoteShowNot"
                      name="deliveryNoteShowNot"
                      label="無"
                      //value={this.state.deliveryNoteShowNot}
                      control={<Radio color="primary" />}
                      labelPlacement="start"
                      checked={!this.state.deliveryNoteShow}
                      />
                  </RadioGroup>
                </FormControl>
              </Box>

            {/* 消費税表示 */}
             <Box className={classes.addMstTblItemTax}>
              <FormControl component="fieldset">
                  <RadioGroup 
                    //name="isInTax" 
                    row
                    aria-label="position"
                    name="position" 
                    defaultValue="top"
                    value={changeItem.isInTax}
                    onChange={event => this.onChangeField(event)}
                  //value={this.state.isInTax}
                  >
                    <FormControlLabel
                      name="isInTax"
                      label="有"
                      checked={this.state.isInTax}
                      control={<Radio color="primary" />}
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      name="isInTaxNot"
                      label="無"
                      checked={!this.state.isInTax}
                      control={<Radio color="primary" />}
                      labelPlacement="start"
                    />
                </RadioGroup> 
              </FormControl>
             </Box>
            </>
          ) : (
            <>
              <Box className={classes.mstTblItemFirst}>
                <Checkbox
                  key={model[i].productType}
                  name={model[i].productType}
                  checked={model[i].checked || false}
                  onChange={this.handleCheck.bind(this)}
                  inputProps={{ "aria-label": "checkbox" }}
                  classes={{
                    root: classes.root,
                    checked: classes.checked
                  }}
                />
              </Box>
              <Box className={classes.mstTblItem}>{model[i].productType}</Box>
              <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{model[i].productName}</Box>
              <Box className={classes.mstTblItem}>{deliveryNoteShowDis}</Box>
              <Box className={classes.mstTblItem}>{isInTaxDis}</Box>
            </>
          )}
        </Box>
      );
    }
    return <Box>{list}</Box>;
  };


  //タイトル域
  render() {
    const { classes } = this.props;
    const list = this.getMstItemList();

    const titleDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>
          <Box p={1} className={classes.titleHeaderColor}>
            商品種別管理画面
          </Box>
        </Box>
      </Box>
    );

    //検索フォーム
    const searchDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>

          <Box display={`flex`} p={2} className={classes.searchTbl}>

            <Box className={classes.searchProductNameLabel}>
              <InputLabel ref="<Box>"id="search_product_name_label" className={classes.InputLabel} >商品名</InputLabel>
                <TextField
                  labelId="search_product_name_label"
                  id="search_product_name"
                  name="searchProductName"
                  variant="outlined"
                  size="small"
                  className={classes.searchProductName}
                  value={this.state.searchProductName}
                  onChange={this.onChangeSearchField.bind(this)}
                />
            </Box>

            <Box flexGrow={1}></Box>

            <Box>
              <Box display={`flex`} flexGrow={1} justify="left">
                <Box width={90}>
                  <Fab
                    className={classes.searchFabColor}
                    onClick={this.search.bind(this)}
                  >
                    <SearchOutlinedIcon style={{ fontSize: 30 }} />
                  </Fab>
                </Box>
                <Box width={70}>
                  <Fab
                    className={classes.searchFabColor}
                    onClick={this.onClearSearchField.bind(this)}
                  >
                    <CancelPresentationIcon style={{ fontSize: 30 }} />
                  </Fab>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );

    // 表
    const mstTbl = (
      <Box style={{ paddingBottom: "16px" }}>
        <Box
          style={{
            // border: `1px solid #525457`,
            borderBottom: `1px solid #525457`,
            borderRadius:'5px'
          }}
        >
          <Box display={`flex`} justifyContent={`space-between`} flexGrow={1}>
            <Box className={classes.mstTblColFirst}>
              <Checkbox
                onChange={this.handleCheckAll.bind(this)}
                style={{ color: "#4A4A4B" }}
              />
            </Box>
            <Box className={classes.mstTblCol}>商品種別</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>商品名</Box>
            <Box className={classes.mstTblCol}>納品書表示</Box>
            <Box className={classes.mstTblCol}>消費税有無</Box>
          </Box>
          {list}
          {this.state.inputForm &&
            this.state.inputForm.map(
              ({ productType, productName,deliveryNoteShow,isInTax }, i) =>
                this.addForm(productType, productName,deliveryNoteShow,isInTax, i)
            )}
        </Box>
      </Box>
    );
  /***********************************************************************************************
  * 右下の追加削除ボタン
  ***********************************************************************************************/
    const fubDiv = (
      <Box display={`flex`} flexGrow={1} justifyContent="flex-end" height={70}>
        <Box width={50}>
          <Fab
            size="small"
            className={classes.fabColor}
            onClick={this.handleAddForm.bind(this)}
            disabled={
              this.state.inputForm.slice().length !== 0 || this.state.addLock
            }
          >
            <AddIcon />
          </Fab>
        </Box>
        <Box width={50}>
          <Fab
            size="small"
            className={classes.fabColor}
            onClick={this.handleRemoveForm.bind(this)}
            disabled={this.state.inputForm.slice().length === 0}
          >
            <RemoveIcon />
          </Fab>
        </Box>
      </Box>
    );

  /***********************************************************************************************
  * 画面下部の「登録」「変更」「削除」のJSXとpropsの設定のメソッドです。
  ***********************************************************************************************/
    const buttonDiv = (
      <Grid container spacing={3} justify="center" style={{textAlign:'center'}}>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.insert.bind(this), '登録しますか？')}
            endIcon={<PostAddIcon />}
            disabled={this.state.registerLock}
          >
            登録
          </Button>
        </Grid>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.update.bind(this), '更新しますか？')}
            endIcon={<EditIcon />}
            disabled={this.state.updateLock}
          >
            変更
          </Button>
        </Grid>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.delete.bind(this), '削除しますか？')}
            endIcon={<DeleteIcon />}
            disabled={this.state.deleteLock}
          >
          
            削除
            
          </Button>
        </Grid>
      </Grid>
    );

    return (
      <Box style={{ height: "100vh", width: "100vw" }}>
        <Header value={this.state.errorMessage} />
        <Alert 
          id="successAlert"
          style={{display:'none'}}
          className={classes.successAlertStyle}
          severity="success">
            {this.state.successMessage}
        </Alert>
        <Box
          style={{
            height: "calc(100% - 96px)",
            width: "calc(100% - 32px)",
            padding: "16px",
            overflow: "auto"
          }}
        >
          <Box minWidth={975}>
            {titleDiv}
            {searchDiv}
            {mstTbl}
            {fubDiv}
            {buttonDiv}
          </Box>
          <AlertDialog/>
        </Box>
      </Box>
    );
  }

  // ダイアログ表示及び設定
  opneDialog (func, message) {

    var Dialog = document.getElementById('dialogBox');
    var okButton = document.getElementById('dialogOkButton');
    var cancelButton = document.getElementById('dialogCancelButton');
 
    document.getElementById('dialogMessage').innerHTML= message;
 
    // ダイアログ表示
    Dialog.style.display = '';
    // OK 押下の処理
    okButton.onclick = func;
    // cancel　押下の処理
    cancelButton.onclick = function() {
     Dialog.style.display = 'none';
     };
   }

  /***********************************************************************************************
  * 検索ボタンがクリックされた時に実行されるメソッドです。
  ***********************************************************************************************/
  async search() {

    const { dispatch } = this.props;

    // 検索条件の項目
    const _searchProductName = this.state.searchProductName || null;
    
    this.setState({errorMessage:''});

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/product-type/list',
      {
        pageIndex: 0,
        pageSize: 100,
        productName: _searchProductName
      },{
        param,
        withCredentials: true,
      }
      ).then(response => {
        dispatch(listTypeMaster(response.data.list));
      }).catch(e =>{
        // 失敗時
        this.setState({errorMessage:'検索に失敗しました'});
        console.log(e);
      });
    }
  
  /***********************************************************************************************
  * 登録ボタンをクリックされた時に実行されるメソッド
  ***********************************************************************************************/
  async insert() {
    const fieldList = this.state.inputForm.slice();
    const { model } = this.props;
    
    await this.setState({errorMessage:''});

    if(this.validate(fieldList[0].productType, fieldList[0].productName, fieldList[0].deliveryNoteShow, fieldList[0].isInTax)) {
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }

    const param = Cookies.get('token');

    for (let i in fieldList) {

      await axios.post(REQUEST_PATH + 'api/product-type/create', {
        productType: fieldList[i].productType,  
        productName: fieldList[i].productName,
        deliveryNoteShow: fieldList[i].deliveryNoteShow,
        isInTax: fieldList[i].isInTax,
      },{
        param,
        withCredentials: true,
      }).then( response => {
        model.push({
        productType: fieldList[i].productType,  
        productName: fieldList[i].productName,
        deliveryNoteShow: fieldList[i].deliveryNoteShow,
        isInTax: fieldList[i].isInTax,
          checked: false
        });

        fieldList.pop();
        this.setState({ inputForm: fieldList });

        this.openeSuccessAlert("登録しました");
      }).catch(e =>{
        this.setState({errorMessage:'登録に失敗しました'});
        console.log(e)
      });
    }
    this.forceUpdate();
    
    document.getElementById('dialogBox').style.display = 'none';
    this.checkState();
  }

  /***********************************************************************************************
  * 変更ボタンをクリックされた時に実行されるメソッド
  ***********************************************************************************************/
  async update() {
    const changeItem = this.state.changeForm;
    const { model } = this.props;

    await this.setState({errorMessage:''});

    if(this.validate(changeItem.productType, changeItem.productName,changeItem.deliveryNoteShow, changeItem.isInTax)) {
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }

    const param = Cookies.get('token');

    await axios.put(REQUEST_PATH + 'api/product-type/update', {
      productType: changeItem.productType,
      productName: changeItem.productName,
      deliveryNoteShow: changeItem.deliveryNoteShow,
      isInTax: changeItem.isInTax,
    },{
      param,
      withCredentials: true,
    }).then( response => {
      const _model = model.filter(({ productType: id }) => {
        return changeItem.productType === id;
      })[0];
      if (_model) {
        _model.productName = changeItem.productName;
        _model.deliveryNoteShow = changeItem.deliveryNoteShow;
        _model.isInTax = changeItem.isInTax;
        _model.checked = false;
      }
      this.checkState();
      this.setState({ changeForm: null });

      this.openeSuccessAlert("更新しました");
    }).catch(e =>{
      this.setState({errorMessage:'更新に失敗しました'});
      console.log(e)
    });
    document.getElementById('dialogBox').style.display = 'none';
    this.checkState();
  }

  /***********************************************************************************************
    * 削除ボタンをクリックされた時に実行されるメソッド
    ***********************************************************************************************/
  async delete() {
    const { model } = this.props;
    const deleteList = [];
    
    await this.setState({errorMessage:''});

    const param = Cookies.get('token');

    for (let i in model) {
      if (model[i].checked) {
        await axios.delete(
          REQUEST_PATH + 'api/product-type/delete/' + model[i].productType,{
            param,
            withCredentials: true,
          }
        ).then( response => {
          deleteList.push(model[i].productType);
        }).catch(e =>{
          this.setState({errorMessage:'削除に失敗しました'});
          console.log(e)
        });       
      }
    }
    if(deleteList.length > 0){
      deleteList.forEach(id => {
        const index = model.findIndex(v => v.productType === id);
        model.splice(index, 1);
      });
      this.openeSuccessAlert("削除しました");
    }

    this.checkState();
    this.forceUpdate();
    document.getElementById('dialogBox').style.display = 'none';
  }

  validate(productType , productName) {
    var isFailed = false;
    this.setState({productNameError:false});

    if(productType === null || productType === '' || productType === 'undefined' || 
      isNaN(productType)) {
      this.setState({productTypeHelperText:"入力に誤りがあります"});
      this.setState({productTypeError:true});
      isFailed = true;
    }

    if(productName === null || productName === '' || productName === 'undefined') {
      this.setState({productNameHelperText:"入力に誤りがあります"});
      this.setState({productNameError:true});
      isFailed = true;
    }
    return isFailed;
  }

  openeSuccessAlert(msg){

    document.getElementById('successAlert').style.display = '';
    this.setState({ successMessage: msg});

    // 2秒で消えるようにする。
    setTimeout(() => {
      document.getElementById('successAlert').style.display = 'none';
    },2000);
  }

static get propTypes() {
  return {};
}

  // Styleの設定
static get styles() {
  return theme => ({
      searchProductName: {
        backgroundColor: "#FFFFFF",
        textAlign: "center",
        width: 300,
        borderRadius: 5,
        lineheight: 2.5,
        marginLeft:"10px"
      },
      mstTblItemFirst: {
        border: "1px solid #b0bec5",
        borderLeft: "1px solid #525457",
        // borderTop: "0",
        borderBottom: "0",
        textAlign: "center",
        width: "10%",
        flexGrow: 1
      },
      mstTblItem: {
        border: "1px solid #b0bec5",
        borderLeft: "0",
        // borderTop: "0",
        borderBottom: "0",
        width: "22.5%",
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },

      mstTblColorItem: {
        border: "1px solid #b0bec5",
        borderRight: "1px solid #525457",
        borderLeft: "0",
        // borderTop: "0",
        borderBottom: "0",
        width: 280,
        flexGrow: 1,
        textAlign: "center",
        color: "#4A4A4B",
        fontWeight: 500,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
      },
      addMstTblItemFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        width: "10%",
        height: 80,
        flexGrow: 1
      },
      addMstTblItem: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: "22.5%",
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      addMstTblItemDelivery: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: "22.5%",
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      addMstTblItemTax: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: "22.5%",
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      addMstTblColorItem: {
        border: "1px solid #525457",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: 280,
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      boxColor: {
        minHeight: 20,
        minWidth: 20,
        maxHeight: 20,
        maxWidth: 20,
        marginLeft: 15,
        marginRight: 6,
        border: "1px solid #4A4A4B",
        borderRadius: "6px"
      },
      root: {
        color: "#607d8b",
        "&$checked": {
          color: "#FF7200"
        }
      },
      checked: {},

      titleHeaderColor: {
        border: "1px solid #525457",
        backgroundColor: "#1976d2",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 20,
        fontWeight: "bold",
        borderRadius:"5px"
      },
      tblHeaderColor: {
        borderTop: "1px solid #525457",
        borderLeft: "0",
        borderRight: "1px solid #525457",
        backgroundColor: fade("#1565c0", 0.8),
        color: "#4A4A4B",
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold"
      },
      searchTbl: {
        border: "1px solid #525457",
        backgroundColor: "#bbdefb",
        color: "#4A4A4B",
        fontSize: 16,
        justifyContent: "left",
        alignItems: "center",
        borderRadius:"5px"
      },
      mstTblColFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        textAlign: "center",
        width: "10%",
        flexGrow: 1
      },
      mstTblCol: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: "22.5%",
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      mstTblColLast: {
        border: "1px solid #525457",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      buttonColor: {
        width: 150,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        // color: "#E1E1E1"
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing:10
      },
      searchFabColor: {
        backgroundColor: "#2196f3",
        color: "#EEEEEE"
      },
      fabColor: {
        backgroundColor: "#26a69a",
        color: "#EEEEEE"
      },
      searchProductNameLabel:{
        marginLeft:"1%",
        display: "flex",
        //width:"20%",
      },
      InputLabel:{
        paddingTop:"4%",
        //marginRight:"0.5%",
      },
      itemAlingnLeft:{
        justifyContent: "left",
        paddingLeft: '1%',
      },
      itemTitlePaddingLeft:{
        paddingLeft: '1%',
      },
      successAlertStyle: {
        position: 'relative',
        justifyContent: 'center',
        width: '100%',
        backgroundColor:'#80cbc4',
        fontSize:'1rem'
      },
    });
  }
}

const mapStateToProps = ({ typeMaster: { list } }) => ({ model: list });
const mapDispatchToProps = dispatch => ({ dispatch });
const { styles } = Component;

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
