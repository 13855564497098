import React, { Component } from "react";
import { Box, Grid, List, ListItem, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as Colors from "@material-ui/core/colors";
import moment from "moment";

const useStyles = makeStyles({
  container: {
    height: "100%",
    width: "600px"
  }
});
export default class OrderList extends Component {

  getListContents = () => {
    const classes = useStyles();
    const list = [];
    const data = this.state.order_list;
    console.log(data);
    for (const i in data) {
      list.push(
        <ListItem>
          <Grid spacing={1} className={classes.container} container>
            <Grid item xs={1.5}>
              {data[i].company}
              <br />
              {data[i].companyPerson}
            </Grid>
            <Grid item xs={1.5}>
              {data[i].orderNumber}
            </Grid>
            <Grid item xs={4}>
              {data[i].start_date + " - " + data[i].end_date}
            </Grid>
            <Grid item xs={2.5}>
              {data[i].person}
            </Grid>
            <Grid item xs={1}>
              <Paper>{data[i].status}</Paper>
            </Grid>
          </Grid>
        </ListItem>
      );
    }
    return <List>{list}</List>;
  };

  render() {
    // return <Box>{this.getListContents}</Box>;
    const { orders = [], onDoubleClick = () => {} } = this.props;
    return (
      <Box style={{ border: `1px solid grey` }}>
        {orders.map(
          ({ customer,projectName,companyPerson, start, end, status, orderNumber, color, name,orderDisplayStatus,collectDate }, i) => (
            <Box
              display={`flex`}
              key={i}
              style={{ borderTop: `1px solid grey` }}
              onDoubleClick={() => onDoubleClick({ orderNumber })}
            >
              <Box
                p={2}
                width={150}
                style={{
                  backgroundColor: color,
                  minWidth: 350,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis"
                }}
              ><Box>{customer}</Box>
                
                
                <Box marginLeft="20px">{companyPerson}</Box>
              </Box>
              <Box
                width={200}
                p={2}
                style={{ minWidth: 200 ,textAlign:'center', fontSize:'1.1em'}}
              >{`${orderNumber}`}</Box>
              <Box
                p={2}
                display={`flex`}
                justifyContent={`space-between`}
                flexGrow={1}
              >
                <Box width={220} style={{ minWidth: 200, textAlign:'center',fontSize:'1.1em'}}>{`${moment(
                  start
                ).format(`YYYY/MM/DD`)} - ${moment(end).format(
                  `YYYY/MM/DD`
                )}`}</Box>
                <Box
                  width={200}
                  style={{
                    minWidth: 200,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    textAlign:'center',
                  }}
                >{`${name}`}</Box>
                <Box
                  border={`1px solid grey`}
                  p={1}
                  width={50}
                  style={{
                    backgroundColor:
                      parseInt(orderDisplayStatus) >= 6
                        ? Colors.grey.A100 
                        : moment().isAfter(moment(collectDate)) ? Colors.red[500] : Colors.grey.A100 ,
                    minWidth: 50,
                    width:120,
                    textAlign:"center"
                  }}
                >{`${status}`}</Box>
              </Box>
            </Box>
          )
        )}
      </Box>
    );
  }
}
