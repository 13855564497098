import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles, fade } from "@material-ui/core/styles";
import { Box, Button, Grid, Fab, Checkbox, TextField,Select,MenuItem,InputLabel } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import RemoveIcon from "@material-ui/icons/Remove";
import ColorLensOutlinedIcon from "@material-ui/icons/ColorLensOutlined";
import { TwitterPicker } from "react-color";
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "axios";
import EditIcon from '@material-ui/icons/Edit';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Header from "../header/Header";
import AlertDialog from "../components/Dialog";
import { moveTo } from "../actions";

import REQUEST_PATH from "../components/Common";
import Cookies from "js-cookie";

class Component extends React.Component {
  state = {
    // 検索項目
    searchModelName: "",
    searchLicenseType: "",
    // フォーム
    inputForm: [],
    changeForm: null,
    // ボタン
    addLock: false,
    removeLock: true,
    registerLock: false,
    updateLock:true,
    deleteLock:true,
    // ヘルパーテキスト
    modelHelperText:"",
    modelNameHelperText:"",
    // エラー
    modelError:false,
    modelNameError:false,

    // エラーメッセージ
    errorMessage:'',
    // 免許種別リスト
    userList: []
  };

  constructor(props){
    super(props);
    this.getUsers();
  }

  ToRegistPage() {
    const { dispatch } = this.props;
    dispatch(moveTo.UserRegist());
  }

  ToPasswordEdit() {
    const user = this.state.userList.slice();
    const checkItem = user.filter(_user => {
      return _user.checked;
    })[0];
    if(checkItem) {
      this.props.history.push({ pathname: '/passEdit', state: { passEditUser: checkItem.userId,user:checkItem.username }});
    } else {
      
    }
  }

  handleCheckAll(evt) {
    const user = this.state.userList.slice();
    
    // Fab-ボタンが活性の場合、選択させない
    if(!this.state.removeLock) {
      return;
    }

    for (let i in user) {
        user[i].checked = evt.target.checked;
    }
    this.setState({userList:user});
    this.checkState();
    this.forceUpdate();
  }

  handleCheck(evt) {
    const user = this.state.userList.slice();

    for (let i in user) {
        if(user[i].userId === evt.target.name){
            user[i].checked = evt.target.checked;
        }
    }
    this.setState({userList:user});
    this.checkState();
    this.forceUpdate();
  }
  checkState() {
    const user = this.state.userList.slice();
    const checkItem = user.filter(_user => {
      return _user.checked;
    });

    // チェックが0の活性その他非活性
    if (checkItem && checkItem.length === 0) {
      this.setState({ registerLock:false });
      this.setState({ updateLock:true });
      this.setState({ deleteLock:true });
      this.setState({ priceLock:true });
    } 
    if (checkItem && checkItem.length === 1) {

      this.setState({ registerLock:true });
      this.setState({ updateLock:false });
      this.setState({ deleteLock:false });
      this.setState({ priceLock:false });

    }

    if (checkItem && checkItem.length  > 1) {
      this.setState({ registerLock:true });
      this.setState({ updateLock:true });
      this.setState({ deleteLock:false });
      this.setState({ priceLock:true });
    }
  }

  // 免許種別の取得
  async getUsers() {
    const param = Cookies.get('token');
    const { data } = await axios.post(
      REQUEST_PATH + 'api/user/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    );
    this.setState({userList:data.list});
  };
  getMstItemList = () => {
    const list = [];
    const { classes } = this.props;
    const users = this.state.userList;
    //表示用に免許種別名を設定
    for (const i in users) {
      list.push(
        <Box
          key={i}
          display={`flex`}
          justifyContent={`space-between`}
          flexGrow={1}
        >
            <>
              <Box className={classes.mstTblItemFirst}>
                <Checkbox
                  key={users[i].userId}
                  name={users[i].userId}
                  checked={users[i].checked || false}
                  onChange={this.handleCheck.bind(this)}
                  inputProps={{ "aria-label": "checkbox" }}
                  classes={{
                    root: classes.root,
                    checked: classes.checked
                  }}
                />
              </Box>
              <Box className={classes.mstTblItem}>{users[i].userId}</Box>
              <Box className={classes.mstTblItem}>{users[i].user}</Box>
            </>
        </Box>
      );
    }
    return <Box>{list}</Box>;
  };

  render() {
    const { classes } = this.props;
    const list = this.getMstItemList();
    const titleDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>
          <Box p={1} className={classes.titleHeaderColor}>
            ユーザー管理画面
          </Box>
        </Box>
      </Box>
    );

    const mstTbl = (
      <Box style={{ paddingBottom: "16px" }}>
        <Box
          style={{
            // border: `1px solid #525457`,
            borderBottom: `1px solid #525457`,
            borderRadius:'5px'
          }}
        >
          <Box display={`flex`} justifyContent={`space-between`} flexGrow={1}>
            <Box className={classes.mstTblColFirst}>
              <Checkbox
                onChange={this.handleCheckAll.bind(this)}
                style={{ color: "#4A4A4B" }}
              />
            </Box>
            <Box className={classes.mstTblCol}>ユーザID</Box>
            <Box className={classes.mstTblColLast}>ユーザ名</Box>
          </Box>
          {list}
        </Box>
      </Box>
    );

    const buttonDiv = (
      <Grid container spacing={3} justify="center" style={{textAlign:'center'}}>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.ToRegistPage.bind(this)}
            endIcon={<PostAddIcon />}
            disabled={this.state.registerLock}
          >
            登録
          </Button>
        </Grid>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.ToPasswordEdit.bind(this)}
            endIcon={<EditIcon />}
            disabled={this.state.updateLock}
          >
            変更
          </Button>
        </Grid>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.delete.bind(this), '削除しますか？')}
            endIcon={<DeleteIcon />}
            disabled={this.state.deleteLock}
          >
          
            削除
            
          </Button>
        </Grid>
      </Grid>
    );

    return (
      <Box style={{ height: "100vh", width: "100vw" }}>
        <Header value={this.state.errorMessage}/>
        <Box
          style={{
            height: "calc(100% - 96px)",
            width: "calc(100% - 32px)",
            padding: "16px",
            overflow: "auto"
          }}
        >
          <Box minWidth={975}>
            {titleDiv}
            {mstTbl}
            
            {buttonDiv}
          </Box>
          <AlertDialog/>
        </Box>
      </Box>
    );
  }

  /***********************************************************************************************
  * 削除ボタンをクリックされた時に実行されるメソッド
  ***********************************************************************************************/

  async delete() {
    const user = this.state.userList.slice();
    const deleteList = [];

    this.setState({errorMessage:''});

    const param = Cookies.get('token');

    for (let i in user) {
      if (user[i].checked) {
        await axios.delete(
          REQUEST_PATH + 'api/user/delete/' + user[i].userId,{
            param,
            withCredentials: true,
          }
        ).then( response => {
          deleteList.push(user[i].userId);
        }).catch(e =>{
          console.log(e);
          this.setState({errorMessage:'削除に失敗しました'});
        });       
      }
    }

    if(deleteList.length > 0){
      deleteList.forEach(id => {
        const index = user.findIndex(v => v.userId === id);
        user.splice(index, 1);
      });
    }
    this.setState({userList:user});
    this.forceUpdate();
    this.checkState();
   document.getElementById('dialogBox').style.display = 'none';
  }

  // ダイアログ表示
  opneDialog (func, message) {

    var Dialog = document.getElementById('dialogBox');
    var okButton = document.getElementById('dialogOkButton');
    var cancelButton = document.getElementById('dialogCancelButton');
 
    document.getElementById('dialogMessage').innerHTML= message;
 
    // ダイアログ表示
    Dialog.style.display = '';
    // OK 押下の処理
    okButton.onclick = func;
    // cancel　押下の処理
    cancelButton.onclick = function() {
     Dialog.style.display = 'none';
     };
  }

  // 入力値チェック
  validate(model , modelName, licenseType, colorCode) {

    var isFailed = false;
    this.setState({modelError:false});
    this.setState({modelNameError:false});

    if(model === null || model === '' || model === 'undefined' || 
      isNaN(model)) {
      this.setState({modelHelperText:"入力に誤りがあります"});
      this.setState({modelError:true});
      isFailed = true;
    }
    if(modelName === null || modelName === '' || modelName === 'undefined') {
      this.setState({modelNameHelperText:"入力に誤りがあります"});
      this.setState({modelNameError:true});
      isFailed = true;
    }

    if(licenseType === null || licenseType === '' || licenseType === 'undefined') {
      isFailed = true;
    }

    if(colorCode === null || colorCode === '' || colorCode === 'undefined') {
      isFailed = true;
    }

    // 範囲チェック
    if(!isFailed) {
      isFailed =  this.validateRange(model , modelName);
    }

    return isFailed;
  }

  // 範囲チェック
  validateRange(model , modelName) {
    var isFailed = false;

    if(model.length > 0 && model.length < 2) {
      this.setState({modelHelperText:"桁数が足りません"});
      this.setState({modelError:true});
      isFailed = true;
    }

    if(model.length > 2){
      this.setState({modelHelperText:"桁数が長すぎます"});
      this.setState({modelError:true});
      isFailed = true;
    }
  
    if(modelName.length > 16 ){
      this.setState({modelNameHelperText:"入力文字数が長すぎます"});
      this.setState({modelNameError:true});
      isFailed = true;
    }

    return isFailed;
  }

  static get propTypes() {
    return {};
  }

  // Styleの設定
  static get styles() {
    return theme => ({

      searchLicenseType: {
        backgroundColor: "#FFFFFF",
        marginRight: 50,
        width: 300,
        borderRadius: 5,
        height:40
      },
      mstTblItemFirst: {
        border: "1px solid #b0bec5",
        borderLeft: "1px solid #525457",
        // borderTop: "0",
        borderBottom: "0",
        textAlign: "center",
        width: 70,
        flexGrow: 1
      },
      mstTblItem: {
        border: "1px solid #b0bec5",
        borderLeft: "0",
        // borderTop: "0",
        borderBottom: "0",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },

      mstTblColorItem: {
        border: "1px solid #b0bec5",
        borderRight: "1px solid #525457",
        borderLeft: "0",
        // borderTop: "0",
        borderBottom: "0",
        width: 280,
        flexGrow: 1,
        textAlign: "center",
        color: "#4A4A4B",
        fontWeight: 500,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
      },
      addMstTblItemFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        width: 70,
        height: 80,
        flexGrow: 1
      },
      addMstTblItem: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: 280,
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      addMstTblColorItem: {
        border: "1px solid #525457",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: 280,
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      boxColor: {
        minHeight: 20,
        minWidth: 20,
        maxHeight: 20,
        maxWidth: 20,
        marginLeft: 15,
        marginRight: 6,
        border: "1px solid #4A4A4B",
        borderRadius: "6px"
      },
      root: {
        color: "#607d8b",
        "&$checked": {
          color: "#FF7200"
        }
      },
      checked: {},

      titleHeaderColor: {
        border: "1px solid #525457",
        backgroundColor: "#1976d2",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 20,
        fontWeight: "bold",
        borderRadius:"5px"
      },
      tblHeaderColor: {
        borderTop: "1px solid #525457",
        borderLeft: "0",
        borderRight: "1px solid #525457",
        backgroundColor: fade("#1565c0", 0.8),
        color: "#4A4A4B",
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold"
      },
      searchTbl: {
        border: "1px solid #525457",
        backgroundColor: "#bbdefb",
        color: "#4A4A4B",
        fontSize: 16,
        justifyContent:`space-between`,
        alignItems: "center",
        borderRadius:"5px"
      },
      mstTblColFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        textAlign: "center",
        width: 70,
        flexGrow: 1
      },
      mstTblCol: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      mstTblColLast: {
        border: "1px solid #525457",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      buttonColor: {
        width: 150,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        // color: "#E1E1E1"
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing:10
      },
      buttonDisableColor: {
        width: 150,
        fontSize: 18,
        fontWeight: "bold",
        background: "#BDBDBD",
        // color: "#E1E1E1"
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing:10
      },
      searchFabColor: {
        backgroundColor: "#2196f3",
        color: "#EEEEEE"
      },
      fabColor: {
        backgroundColor: "#26a69a",
        color: "#EEEEEE"
      },
      searchLabel:{
        paddingTop:'4%'
      },
      secondBox:{
        marginLeft:'20px',
      },
      searchModelName: {
        backgroundColor: "#FFFFFF",
        marginLeft:5,
        width: 250,
        borderRadius: 5
      },

      pullDown:{
        backgroundColor: "#FFFFFF",
        width: 250,
        borderRadius: 5,
        height:40,
        marginLeft:5
        //maxHeight: "28px"
      }
    });
  }
}

const mapStateToProps = ({ orderRegist: { list } }) => ({ users: list });
const mapDispatchToProps = dispatch => ({ dispatch });
const { styles } = Component;

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
