
export const authenticate = async({ user, password }) => {
  // TODO
  return await new Promise((resolve, reject) => {
    const token = `aaaaaaaaaa.bbbbbbbbbbbbbbbb.ccccccccccccccccc`;
    resolve({ token });
  });
}

export const getUser = async (token) => {
  // TODO
  return await new Promise((resolve) => {
    setTimeout(() => resolve({}), 500);
  });
}