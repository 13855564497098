import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, CircularProgress } from "@material-ui/core";
import holiday_jp from "@holiday-jp/holiday_jp";
// import { CircularProgress } from '@material-ui/icons';
import {
  green,
  blue,
  red,
  yellow,
  purple,
  grey
} from "@material-ui/core/colors";
import moment from "moment";
import {
  MultiGrid,
  CellMeasurer,
  AutoSizer,
  CellMeasurerCache
} from "react-virtualized";

import "react-virtualized/styles.css";

const styles = theme => ({
  td: {
    fontSize: theme.typography.pxToRem(12),
    minWidth: 32,
    whiteSpace: "nowrap",
    borderTop: `1px solid ${grey[300]}`,
    borderLeft: `1px solid ${grey[300]}`
  },
  title: {
    fontWeight: theme.typography.fontWeightBold
  },
  green: {
    backgroundColor: green.A100,
    color: theme.palette.getContrastText(green.A100)
  },
  blue: {
    backgroundColor: blue.A100,
    color: theme.palette.getContrastText(blue.A100)
  },
  red: {
    backgroundColor: red.A100,
    color: theme.palette.getContrastText(red.A100)
  },
  yellow: {
    backgroundColor: yellow.A100,
    color: theme.palette.getContrastText(yellow.A100)
  },
  purple: {
    backgroundColor: purple.A100,
    color: theme.palette.getContrastText(purple.A100)
  },
  grey: {
    backgroundColor: grey.A100,
    color: theme.palette.getContrastText(grey.A100)
  }
});

const STYLE = {
  // border: '1px solid #ddd',
};
const STYLE_BOTTOM_LEFT_GRID = {
  // borderRight: '2px solid #aaa',
  // backgroundColor: '#f7f7f7',
};
const STYLE_TOP_LEFT_GRID = {
  // borderBottom: '2px solid #aaa',
  // borderRight: '2px solid #aaa',
  // fontWeight: 'bold',
};
const STYLE_TOP_RIGHT_GRID = {
  // borderBottom: '2px solid #aaa',
  // fontWeight: 'bold',
};

class Component extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this._cache = new CellMeasurerCache({
      defaultHeight: 20,
      defaultWidth: 20,
      fixedHeight: false,
      fixedWidth: false
    });
    this.state = {
      products: [],
      orders: []
    };
    this.timeout = null;
  }

  createCols() {
    const cols = [];
    for (let i = this.props.start; i <= this.props.end; i += 60 * 60 * 24 * 1000) {
      cols.push(moment(i));
    }
    return cols;
  }

  createHeader(cols) {
    const { onList } = this.props;
    return [
      <ListButtonCell onList={onList} />,
      ...cols.map((d, i) => <DateCell date={d} />)
    ];
  }

  createSchedule(cols, all,internalAll,outsideAll, { modelName, stockNumber, color }, orders) {
    const { onDetail, classes } = this.props;
    const t = [];
    const outside = [];
    const internal = [];
    if (orders.length === 0) {
      orders.push({ customer: `` });
    }

    const orderSchedule = orders.map(({ orderNumber,projectName,customer, person,start, end,orderStart,orderEnd,quantity,shipment,delivery,collection,outsourcing,hidden,orderFormal}) => {
      //カレンダー非表示の場合
      if(!hidden){
        return;
      }
      //同一の注文をフィルタリング
      const filterdOrders = orders.filter(x => x.orderNumber === orderNumber && x.outsourcing === outsourcing);
      //最大出荷数格納用変数
      let maxQuantity = 0;
      //機種はわかっているので、注文番号を比較して同一の配列があればその要素数分消す（種別が別のやつが入るとバグりそう）
      //要素を消すことで行を作らせない
      if(filterdOrders.length > 1){
        let resultIndex = orders.findIndex(({orderNumber}) => orderNumber === orderNumber);
        orders.splice(resultIndex,filterdOrders.length -1)
      }
      const s = cols.reduce((res, d, i) => {
        let rented = false;
        //表示が●になるか→になるかはrentedで判別、フィルタリングしたやつから大小比較して出荷数の最大値をとる。
        //rentedをtrueにしても次の配列でfalseが入ったら意味ないので|| rented === tureを入れてあげる。
        for(const i in filterdOrders){
          rented = filterdOrders[i].start <= d && d <= filterdOrders[i].end || rented === true;
          maxQuantity = filterdOrders[i].quantity > maxQuantity ? filterdOrders[i].quantity : maxQuantity; 
        }
        const date = new Date(d);
        const holidays = holiday_jp.between(date,date);

        const orderd = orderStart <=d && d<= orderEnd;
        const shipping = shipment === d._i;
        const deliver = delivery === d._i;
        const collect = collection === d._i;
        const isHoliday = d.format(`dddd`).slice(0, 1)==='日' || d.format(`dddd`).slice(0, 1)==='土' || holidays.length > 0;
        if(shipping){
        }
        res.push(orderd && !orderFormal ? <TentativeCell isHoliday={isHoliday} /> : //仮注文
                 shipping ? <ShipmentCell isHoliday={isHoliday} status={rented ? '●' : ''} quantity={orderFormal ? maxQuantity : ''}/> ://出荷
                 deliver ? <DeliveryCell isHoliday={isHoliday} status={rented ? '●' : ''} quantity={orderFormal ? maxQuantity : ''}/> ://納品
                 collect ? <CollectCell isHoliday={isHoliday} status={rented ? '●' : ''} quantity={orderFormal ? maxQuantity : ''}/> ://回収
                 rented ? <OrderCell isHoliday={isHoliday} status={'●'} quantity={orderFormal ? maxQuantity : ''} /> : //使用日
                 orderd ?  <OrderCell isHoliday={isHoliday} status={'→'} quantity={orderFormal ? maxQuantity : ''} /> : //矢印
                 <Box width={'100%'} height={'100%'} style={{backgroundColor:isHoliday ? "#ffebee" :"" }}></Box>);  //それ以外
        internal[i] = orderd  && orderFormal && !outsourcing ? (internal[i] || 0) + maxQuantity : internal[i] || 0;
        outside[i] = orderd && orderFormal && outsourcing ? (outside[i] || 0) + maxQuantity : outside[i] || 0;
        internalAll[i] = orderd && orderFormal && !outsourcing ? (internalAll[i] || 0) + maxQuantity : internalAll[i] || 0;
        outsideAll[i] = orderd && orderFormal && outsourcing ? (outsideAll[i] || 0) + maxQuantity : outsideAll[i] || 0;
        t[i] = {internal:internal[i],
                outside:outside[i]}
        all[i] = {internal:internalAll[i],
                  outside:outsideAll[i]}
        //cols.reduceのreturn
        return res;
      }, []);
      //orders.mapのreturn
      return [
        <CustomerCell
          name={customer}
          person={person}
          quantity={maxQuantity}
          orderNumber={orderNumber}
          onDetail={onDetail}
        />,
        ...s
      ];
    });

    return [
      [
        <ProductCell
          name={modelName}
          color={color}
          onDetail={onDetail}
        />,
        ...t.map(({internal,outside}) => (
          <TotalCell
            shipmentA={internal + outside}
            shipmentB={internal}
            rest={stockNumber - internal}
            color={color}
          />
        ))
      ],
      ...orderSchedule
    ];
  }

  createMatrix() {
    const { classes } = this.props;
    const { products, orders } = this.state;

    const cols = this.createCols();
    const all = [];
    const internalAll = [];
    const outsideAll = [];
    let totals = 0;
    const schedules = products.reduce((res, product) => {
      const { model, stockNumber } = product;
      const productOrders = orders.filter(({ modelId }) => model === modelId);
      res = [...res, ...this.createSchedule(cols, all,internalAll,outsideAll, product, productOrders)];
      totals += stockNumber;
      return res;
    }, []);;
    return [
      
      this.createHeader(cols),
      [
        <TotalOrderCell className={classes.grey} />,
        ...all.map(({internal,outside}) => (
          <TotalCell
            shipmentA={internal + outside}
            shipmentB={internal}
            rest={totals - internal}
            className={classes.grey}
          />
        ))
      ],
      ...schedules
    ];
  }

  componentDidMount() {
    const { products, orders } = this.props;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.setState({ products, orders }));
  }

  componentDidUpdate() {
    const { products, orders } = this.props;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.setState({ products, orders }));

  }

  render() {
    this.a = new Date();
    const matrix = this.createMatrix();
    this.b = new Date();
    
    return (
      <>
        <Box flexGrow={1} p={1}>
          <AutoSizer>
            {({ width, height }) => (
              <MultiGrid
                fixedColumnCount={1}
                fixedRowCount={2}
                scrollToColumn={0}
                scrollToRow={0}
                cellRenderer={this._cellRenderer.bind(this, matrix)}
                noContentRenderer={this._noContentRenderer.bind(this)}
                columnWidth={this._cache.columnWidth}
                deferredMeasurementCache={this._cache}
                columnCount={matrix[0].length}
                enableFixedColumnScroll
                enableFixedRowScroll
                height={height}
                rowHeight={this._cache.rowHeight}
                rowCount={matrix.length}
                style={STYLE}
                styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
                styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
                styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
                width={width}
                hideTopRightGridScrollbar
                hideBottomLeftGridScrollbar
              />
            )}
          </AutoSizer>
        </Box>
      </>
    );
  }

  _cellRenderer(matrix, { columnIndex, key, parent, rowIndex, style }) {
    const row = matrix[rowIndex] || [];
    const cell = row[columnIndex];
    const { classes } = this.props;

    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <Box
          key={key}
          display={`flex`}
          style={{
            ...style
          }}
          className={`${classes.td} ${
            columnIndex === 0 || rowIndex === 0 ? classes.title : ``
          }`}
        >
          {cell}
        </Box>
      </CellMeasurer>
    );
  }

  _noContentRenderer() {
    return (
      <Box display={`flex`} justifyContent={`center`} p={1}>
        <CircularProgress variant="indeterminate" />
      </Box>
    );
  }
}

export default withStyles(styles)(Component);

/***********************************************************************************************
 * テーブルの左上
 ***********************************************************************************************/
const ListButtonCell = ({ onList }) => (
  <Box p={2} flexGrow={1} justifyContent={`center`} display={`flex`}>
    <Button onClick={onList} variant={`outlined`}>
      カレンダー一覧
    </Button>
  </Box>
);

const TotalOrderCell = ({ className }) => (
  <Box
    display={`flex`}
    flexGrow={1}
    justifyContent={`space-between`}
    pl={1}
    pr={1}
    className={className}
  >
    <Box flexGrow={1}>総発注</Box>
    <Box minWidth={30}>
      <Box textAlign={`right`} style={{ fontWeight: `normal` }}>
        出荷計A
      </Box>
      <Box textAlign={`right`} style={{ fontWeight: `normal` }}>
        出荷計B
      </Box>
      <Box textAlign={`right`} style={{ fontWeight: `normal` }}>
        総在庫
      </Box>
    </Box>
  </Box>
);

/***********************************************************************************************
 * テーブルの右上
 ***********************************************************************************************/
const DateCell = ({ date }) => (
  <Box
    flexGrow={1}
    justifyContent={`flex-end`}
    display={`flex`}
    flexDirection={`column`}
    style={{backgroundColor:holiday_jp.isHoliday(new Date(date)) ? "#ffebee" : date.format(`dddd`).slice(0, 1)==='日' ? "#ffebee" : date.format(`dddd`).slice(0, 1)==='土' ? "#ffebee" :"#FFFFFF" }}
  >
    {date.date() === 1 ? (
      <Box textAlign={`center`}>{date.format(`MMM`)}</Box>
    ) : (
      <></>
    )}
    <Box textAlign={`center`}>{date.format(`D`)}</Box>
    <Box textAlign={`center`}>{date.format(`dddd`).slice(0, 1)}</Box>
  </Box>
);

/***********************************************************************************************
 * テーブルの左下
 ***********************************************************************************************/
const ProductCell = ({ name, className, onDetail,color }) => (
  <Box
    display={`flex`}
    flexGrow={1}
    onDoubleClick={onDetail.bind(this, { name, type: `product` })}
    justifyContent={`space-between`}
    pl={1}
    pr={1}
    className={className}
    style={{backgroundColor:color}}
  >
    <Box flexGrow={1}>{name}</Box>
    <Box>
    </Box>
    <Box minWidth={30}>
      <Box textAlign={`right`} style={{ fontWeight: `normal` }} height={'18px'}>
        出荷計A
      </Box>
      <Box textAlign={`right`} style={{ fontWeight: `normal` }} height={'18px'}>
        出荷計B
      </Box>
      <Box textAlign={`right`} style={{ fontWeight: `normal` }} height={'18px'}>
        在庫
      </Box>
    </Box>
  </Box>
);

const CustomerCell = ({ name, person, quantity,orderNumber, onDetail }) => (
  <Box
    display={`flex`}
    flexGrow={1}
    onDoubleClick={onDetail.bind(this, { name:orderNumber, type: `customer` })}
    justifyContent={`space-between`}
    pl={1}
    pr={1}
  >
    <Box flexGrow={1}>
      <Box>{name}</Box>
      <Box marginLeft="20px">{person}</Box>
    </Box>
    <Box>
    </Box>
    <Box minWidth={30}>
      <Box textAlign={`right`} style={{ fontWeight: `normal` }}>
        台数
      </Box>
      <Box textAlign={`right`} style={{ fontWeight: `normal` }}>
        {quantity}
      </Box>
    </Box>
  </Box>
);

/***********************************************************************************************
 * テーブルの右下
 ***********************************************************************************************/
const OrderCell = ({ status, quantity ,isHoliday}) => (
  <Box flexGrow={1} style={{backgroundColor:isHoliday ? "#ffebee" :"" }}>
    <Box textAlign={`center`}>{status}</Box>
    <Box textAlign={`center`}>{quantity}</Box>
  </Box>
);

const TotalCell = ({ shipmentA,shipmentB, rest, className,color,isHoliday }) => (
  <Box flexGrow={1} className={className} style={{backgroundColor:isHoliday ? "#ffebee" : color+"80"}}>
    <Box textAlign={`center`} height={'18px'}>{shipmentA}</Box>
    <Box textAlign={`center`} height={'18px'}>{shipmentB}</Box>
    <Box textAlign={`center`} height={'18px'}>{rest}</Box>
  </Box>
);
const ShipmentCell = ({ status , quantity,isHoliday }) => (
  <Box width={'100%'}height={'100%'} flexGrow={1} style={{backgroundColor:isHoliday ? "#ffebee" :"" }}>
    <Box textAlign={`center`} height={'50%'} style={{backgroundColor:"#f44336"}}>{status}</Box>
<Box textAlign={`center`} height={'50%'} >{quantity}</Box>
  </Box>
);
const DeliveryCell = ({ status , quantity ,isHoliday}) => (
  <Box width={'100%'}height={'100%'} flexGrow={1} style={{backgroundColor:isHoliday ? "#ffebee" :"" }}>
    <Box textAlign={`center`} height={'50%'} style={{backgroundColor:"#8bc34a"}}>{status}</Box>
    <Box textAlign={`center`} height={'50%'} >{quantity}</Box>
  </Box>
);
const CollectCell = ({ status , quantity,isHoliday}) => (
  <Box width={'100%'}height={'100%'} flexGrow={1} style={{backgroundColor:isHoliday ? "#ffebee" :"" }}>
    <Box textAlign={`center`} height={'50%'} style={{backgroundColor:"#2196f3"}}>{status}</Box>
    <Box textAlign={`center`} height={'50%'} >{quantity}</Box>
  </Box>
);
const TentativeCell = ({ status,isHoliday}) => (
  <Box width={'100%'}height={'100%'} flexGrow={1} style={{backgroundColor:isHoliday ? "#ffebee" :"" }}>
    <Box textAlign={`center`} height={'50%'} style={{backgroundColor:"#d500f9"}}></Box>
    <Box textAlign={`center`} height={'50%'} >{}</Box>
  </Box>
);