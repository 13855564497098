import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles　} from "@material-ui/core/styles";
import { Box, Button, TextField } from "@material-ui/core";
import Header from "../header/Header";
import axios from "axios";
import { moveTo} from "../actions";
import AlertDialog from "../components/Dialog";
import REQUEST_PATH from "../components/Common";
import ExitToApp from '@material-ui/icons/ExitToApp';
import EditIcon from '@material-ui/icons/Edit';
import Cookies from "js-cookie";

class Component extends React.Component {

    state = {
        headerErrorMessage:"",
        username:"",
        userId:"",
        currentPassword:"",
        newPassword:"",
        newPasswordConfirm:"",
        helperText1:"",
        helperText2:"",
        helperText3:"",
    }

    constructor(props) {
        super(props);
        if(!(this.props.location.state === undefined)) {
          if(!(this.props.location.state.passEditUser === undefined)) {
           this.state.userId = this.props.location.state.passEditUser;
           console.log(this.state.userId);
          }
          if(!(this.props.location.state.user === undefined)) {
            this.state.username = this.props.location.state.user;
            console.log(this.state.username);
           }
        }else{
        const { dispatch } = this.props;
        dispatch(moveTo.Top());
       }
    }

    backButton(){
      const { dispatch } = this.props;
      dispatch(moveTo.Top());
    }

    onChangePassword(event){
      switch(event.target.name){
        case "currentPassword" :{
          this.setState({currentPassword:event.target.value})
          break;
        }
        case "newPassword" :{
          
          if(event.target.value.length === 0){
            this.setState({helperText2:""});
          }
          else if(event.target.value.match(/^(?=.*?[a-z])(?=.*?\d)[a-zA-Z\d]{8,12}$/g) === null &&
          event.target.value.match(/^(?=.*?[a-z])(?=.*?[!-\/:-@[-`{-~])[!-~]{8,12}$/g) === null){
            this.setState({helperText2:"パスワードの条件を満たしていません。"});
          }
          else{
            this.setState({helperText2:""});
          }
          this.setState({newPassword:event.target.value})
          break;
        }
        case "newPasswordConfirm" :{
          if(event.target.value.length === 0){
            this.setState({helperText3:""});
          }
          else if(event.target.value.match(/^(?=.*?[a-z])(?=.*?\d)[a-zA-Z\d]{8,12}$/g) === null &&
          event.target.value.match(/^(?=.*?[a-z])(?=.*?[!-\/:-@[-`{-~])[!-~]{8,12}$/g) === null){
            this.setState({helperText3:"パスワードの条件を満たしていません。"});
          }
          else{
            this.setState({helperText3:""});
          }
          this.setState({newPasswordConfirm:event.target.value})
          break;
        }
      }
      console.log(event.target.value.match(/^(?=.*?[a-z])(?=.*?\d)[a-zA-Z\d]{8,12}$/g));
      console.log(event.target.value.match(/^(?=.*?[a-z])(?=.*?[!-\/:-@[-`{-~])[!-~]{8,12}$/g));
      this.forceUpdate();
    }

    async passEdit(){

      const param = Cookies.get('token');

      if(this.state.newPassword !== this.state.newPasswordConfirm){
        this.setState({headerErrorMessage:"新規パスワードが一致しません。"});
        return;
      }
      if(this.state.helperText2.length > 0 || this.state.helperText3.length > 0 ){
        this.setState({headerErrorMessage:"パスワードの条件を満たしていません。"});
        return;
      }
      let result;
      await axios.post(REQUEST_PATH+'api/user/list', {
        userId: this.state.userId,
        user: this.state.username,
        password: window.btoa(this.state.username+":"+this.state.currentPassword)
      },{
        param,
        withCredentials: true,
      }).then( response => {
        result = response.data.list;
    }).catch(e =>{
     
      console.log(e)
    });

    if(result.length !== 1){
      this.setState({headerErrorMessage:"現在のパスワードが違います。"});
      return;
    }

    await axios.put(REQUEST_PATH+'api/user/update', {
      userId: this.state.userId,
      user: this.state.username,
      password: window.btoa(this.state.username+":"+this.state.newPasswordConfirm)
    },{
      param,
      withCredentials: true,
    }).then( response => {

    }).catch(e =>{
   
    console.log(e)
    return;
    });

    const { dispatch } = this.props;
    dispatch(moveTo.Top());

    }
    
    render(){

    const { classes } = this.props;
    //サブヘッダ
    const titleDiv = (
        <Box display={`flex`} style={{ paddingBottom: "16px", minWidth: "1411px" }}   >
          <Box flexGrow={1}>
            <Box p={1} className={classes.titleHeaderColor}>
              パスワード変更画面
            </Box>
          </Box>
        </Box>
      );
    
    const buttonDiv = (
          <Box textAlign="center">
          <Box display="flex" marginLeft="40%" marginRight="40%">
            <Button
              variant={`contained`}
              className={classes.buttonColor}
              endIcon={<EditIcon />}
              onClick={this.passEdit.bind(this)}
            >
              変更
            </Button>
            <Box width="20%">

            </Box>
            <Button
              variant={`contained`}
              className={classes.buttonColor}
              endIcon={<ExitToApp />}
              onClick={this.backButton.bind(this)}
            >
              戻る
            </Button>
          </Box>
          </Box>
      );
    
    const inputDiv = (
        <Box flexGrow={1} style={{borderRadius: "5px", border: "1px solid #bdbdbd" }} padding={'10px'} >
            <Box width="60%" marginLeft="20%" marginRight="20%" marginTop="20px">
                <Box display={'flex'} alignItems="center">
                    <Box width="40%">
                        パスワード
                    </Box>
                    <Box width="10%">
                        ：
                    </Box>
                    <Box width="50%">
                    <TextField
                      className={classes.inputStyle}
                      type="password"
                      id="currentPassword"
                      name="currentPassword"
                      label="currentPassword"
                      variant="outlined"
                      size={"medium"}
                      fullWidth
                      value={this.state.currentPassword}
                      onChange={event => this.onChangePassword(event)}
                    />
                    </Box>
                </Box>
            </Box>
            <Box width="60%" marginLeft="20%" marginRight="20%" marginTop="20px">
                <Box display={'flex'} alignItems="center">
                    <Box width="40%">
                        新しいパスワード
                    </Box>
                    <Box width="10%">
                        ：
                    </Box>
                    <Box width="50%">
                    <TextField
                      className={classes.inputStyle}
                      type="password"
                      id="newPassword"
                      name="newPassword"
                      label="newPassword"
                      variant="outlined"
                      size={"medium"}
                      fullWidth
                      value={this.state.newPassword}
                      onChange={event => this.onChangePassword(event)}
                      helperText={this.state.helperText2}
                      error={this.state.helperText2.length > 0}
                    />
                    </Box>
                </Box>
            </Box>
            <Box width="60%" marginLeft="20%" marginRight="20%"　marginTop="20px" marginBottom="20px">
                <Box display={'flex'} alignItems="center">
                    <Box width="40%">
                        新しいパスワード（再入力）
                    </Box>
                    <Box width="10%">
                        ：
                    </Box>
                    <Box width="50%">
                    <TextField
                      className={classes.inputStyle}
                      type="password"
                      id="newPasswordConfirm"
                      name="newPasswordConfirm"
                      label="newPasswordConfirm"
                      variant="outlined"
                      size={"medium"}
                      fullWidth
                     value={this.state.newPasswordConfirm}
                     onChange={event => this.onChangePassword(event)}
                     helperText={this.state.helperText3}
                     error={this.state.helperText3.length > 0}
                    />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
    
    return (
    <Box style={{ height: "100vh", width: "100vw" }}>
        <Header value={this.state.headerErrorMessage} />
        <Box
          style={{
            height: "calc(100% - 96px)",
            width: "calc(100% - 32px)",
            padding: "16px",
            overflow: "auto"
          }}
        >
          <Box minWidth={975}>
            {titleDiv}
            <Box style={{ display: 'flex' }} minWidth={975}>
            </Box>
            <Box minWidth={975} >
                {inputDiv}
                {buttonDiv}
            </Box>
          </Box>
          <AlertDialog />
        </Box>
    </Box >
    )
    }

      //JSS Style記述
  static get styles() {
    return theme => ({
      sideItem: {
        backgroundColor: "#FFFFFF",
        width: "24.8%",
        borderRadius: 5,
        lineheight: 2.5,
        alignItems: "center"
      },
      productItem: {
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        lineheight: 2.5,
        alignItems: "center"
      },
      groupItem: {
        backgroundColor: "#FFFFFF",
        alignItems: "center"
      },
      titleHeaderColor: {
        border: "1px solid #525457",
        backgroundColor: "#1976d2",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 20,
        fontWeight: "bold",
        borderRadius: "5px"
      },
      buttonColor: {
        width: 160,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing: 10,
        marginTop: 20
      },
      slctItem: {
        height: "30%",
      },
    });
  }
    
}
const { styles } = Component;
const mapStateToProps = ({ orderRegist: { list } }) => ({ model: list });
const mapDispatchToProps = dispatch => ({ dispatch });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);