import React from "react";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { withStyles, fade } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import { compose } from "redux";
//import icon from '../header/Lenabon.png'
import icon from '../header/lenabon_new.png'

import {
  Drawer,
  ListItemText,
  List,
  IconButton,
  Toolbar,
  AppBar,
  InputBase,
  // Typography,
  ListItem,
  Collapse,
  Fab,
  Box
} from "@material-ui/core";
//削除予定
import AddIcon from "@material-ui/icons/Add";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import { moveTo } from "../actions";
import { lenabon18 } from "./Lenabon18.png"

class Component extends React.Component {

  constructor(props) {
    super(props);
    this.state.username = this.props.user.name;
    this.state.userId = this.props.user.id;
    this.toggleDrawer = this.toggleDrawer.bind(this);

    const param = Cookies.get('token');
    if(param === undefined){
      this.handleToAboutPage();
    }else if(this.props.user.name === undefined){
      const data = JSON.parse(param);
      this.state.username = data.auth.username;
    }
  }
  
  state = {
    left: false,
    mstOpen: false,
    bizOpen: false,
    calcOpen: false,
    userOpen: false,
    showMessageBox: false,
    logout: false,
    display: "none",
    errorMessage: "",
    username:"",
    userId:"",
    orderSearch:"",
  };

  componentDidUpdate(prevProps) {
    if (this.props.value === undefined) {

    } else {
      if (prevProps.value !== this.props.value) {
        if (this.props.value === "") {
          this.setState({
            display: "none",
            showMessageBox: false
          });
        } else {
          this.setState({
            display: "auto",
            showMessageBox: true,
            errorMessage: this.props.value
          });
        }
      }
    }
  }

  orderSearch(event){
    if(event.charCode === 13){
      if(this.state.orderSearch.match(/^[0-9]*$/) !== null){
        //console.log("注文番号検索");
        //this.props.history.push({ pathname: '/order', state: { orderNumber: this.state.orderSearch }});
        const { dispatch } = this.props;
        dispatch(moveTo.Order({orderNumber: this.state.orderSearch}));
      }else
      {
        //console.log("案件名検索");
        //this.props.history.push({ pathname: '/order', state: { projectName: this.state.orderSearch }});
        const { dispatch } = this.props;
        dispatch(moveTo.Order({projectName: this.state.orderSearch}));
      }
    }
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
    if (side === "showMessageBox") {
      if (open) {
        this.setState({
          display: "auto",
          showMessageBox: true
        });
      } else {
        this.setState({
          display: "none",
          showMessageBox: false
        });
      }
    }
  }

  handleToAboutPage() {
    const { dispatch } = this.props;
    Cookies.remove(`token`);
    dispatch(moveTo.Signin());
  }

  setMstOpen = open => () => {
    this.setState({
      mstOpen: open,
      bizOpen: false,
      calcOpen: false,
      userOpen: false
    });
  };
  setBizOpen = open => () => {
    this.setState({
      mstOpen: false,
      bizOpen: open,
      calcOpen: false,
      userOpen: false
    });
  };
  setCalcOpen = open => () => {
    this.setState({
      mstOpen: false,
      bizOpen: false,
      calcOpen: open,
      userOpen: false
    });
  };
  setUserOpen = open => () => {
    this.setState({
      mstOpen: false,
      bizOpen: false,
      calcOpen: false,
      userOpen: open
    });
  };

  ToPasswordEdit(props) {
      console.log(this)
      props.history.push({ pathname: '/passEdit', state: { passEditUser: this.state.userId }});
  }

  //ユーザーごとにフラグ持つとかの方がいいと思う
  setUserMenus(){
    const { classes } = this.props;
    //rootユーザーだった場合
    if(this.state.username === "root"){
      return(
        <>
          <ListItem button onClick={this.setUserOpen(!this.state.userOpen)}>
            {this.state.userOpen ? (
              <ExpandLess className={classes.listItemIcon} />
            ) : (
                <ExpandMore className={classes.listItemIcon} />
              )}
            <ListItemText
              primary="ユーザー管理"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        <Collapse in={this.state.userOpen} timeout="auto" unmountOnExit>
          <ListItem button className={classes.sabListItem}
           
           style={{}}
           >
              <Link to="/userList" className={classes.titleStr}>
                <ListItemText
                  primary="ユーザー管理"
                  classes={{ primary: classes.listItemText }}
                  
                />
              </Link>
        </ListItem>
        </Collapse>
        </>
      )
    }
  }

  render() {
    const { classes } = this.props;

    const userMenus = this.setUserMenus();

    const sideList = (
      <div>
        <List className={classes.list}>
          <ListItem button onClick={this.setMstOpen(!this.state.mstOpen)}>
            {this.state.mstOpen ? (
              <ExpandLess className={classes.listItemIcon} />
            ) : (
                <ExpandMore className={classes.listItemIcon} />
              )}
            <ListItemText
              primary="マスタ管理"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <Collapse in={this.state.mstOpen} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              onClick={this.toggleDrawer("left", false)}
              onKeyDown={this.toggleDrawer("left", false)}
            >
              <ListItem button className={classes.sabListItem}>
                <Link to="/personMaster" className={classes.titleStr}>
                  <ListItemText
                    primary="担当者マスター"
                    classes={{ primary: classes.listItemText }}
                  />
                </Link>
              </ListItem>
              <ListItem button className={classes.sabListItem}>
                <Link to="/typeMaster" className={classes.titleStr}>
                  <ListItemText
                    primary="商品種別マスター"
                    classes={{ primary: classes.listItemText }}
                  />
                </Link>
              </ListItem>
              <ListItem button className={classes.sabListItem}>
                <Link to="/modelMaster" className={classes.titleStr}>
                  <ListItemText
                    primary="商品機種マスター"
                    classes={{ primary: classes.listItemText }}
                  />
                </Link>
              </ListItem>
              <ListItem button className={classes.sabListItem}>
                <Link to="/statusMaster" className={classes.titleStr}>
                  <ListItemText
                    primary="商品状態マスター"
                    classes={{ primary: classes.listItemText }}
                  />
                </Link>
              </ListItem>
              <ListItem button className={classes.sabListItem}>
                <Link to="/productStock" className={classes.titleStr}>
                  <ListItemText
                    primary="商品在庫数マスター"
                    classes={{ primary: classes.listItemText }}
                  />
                </Link>
              </ListItem>
              <ListItem button className={classes.sabListItem}>
                <Link to="/licenseTypeMaster" className={classes.titleStr}>
                  <ListItemText
                    primary="免許種別マスター"
                    classes={{ primary: classes.listItemText }}
                  />
                </Link>
              </ListItem>
              <ListItem button className={classes.sabListItem}>
                <Link to="/licenseMaster" className={classes.titleStr}>
                  <ListItemText
                    primary="免許名マスター"
                    classes={{ primary: classes.listItemText }}
                  />
                </Link>
              </ListItem>
              <ListItem button className={classes.sabListItem}>
                <Link to="/pickupMaster" className={classes.titleStr}>
                  <ListItemText
                    primary="輸送方法マスター"
                    classes={{ primary: classes.listItemText }}
                  />
                </Link>
              </ListItem>
              <ListItem button className={classes.sabListItem}>
                <Link to="/orderDisplayStatusMaster" className={classes.titleStr}>
                  <ListItemText
                    primary="注文表示ステータスマスター"
                    classes={{ primary: classes.listItemText }}
                  />
                </Link>
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={this.setBizOpen(!this.state.bizOpen)}>
            {this.state.bizOpen ? (
              <ExpandLess className={classes.listItemIcon} />
            ) : (
                <ExpandMore className={classes.listItemIcon} />
              )}
            <ListItemText
              primary="業務管理"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <Collapse in={this.state.bizOpen} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              onClick={this.toggleDrawer("left", false)}
              onKeyDown={this.toggleDrawer("left", false)}
            >
              <ListItem button className={classes.sabListItem}>
              <Link to="/order" className={classes.titleStr}>
                <ListItemText
                  primary="注文データ"
                  classes={{ primary: classes.listItemText }}
                />
              </Link>
              </ListItem>
              <ListItem button className={classes.sabListItem}>
                <Link to="/productList" className={classes.titleStr}>
                  <ListItemText
                    primary="商品データ"
                    classes={{ primary: classes.listItemText }}
                  />
                </Link>
              </ListItem>

              <ListItem button className={classes.sabListItem}>
                <Link to="/companyMaster" className={classes.titleStr}>
                  <ListItemText
                    primary="取引先データ"
                    classes={{ primary: classes.listItemText }}
                  />
                </Link>
              </ListItem>
              <ListItem button className={classes.sabListItem}>
                <Link to="/statusInput" className={classes.titleStr}>
                  <ListItemText
                    primary="ステータス入力"
                    classes={{ primary: classes.listItemText }}
                  />
                </Link>
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={this.setCalcOpen(!this.state.calcOpen)}>
            {this.state.calcOpen ? (
              <ExpandLess className={classes.listItemIcon} />
            ) : (
                <ExpandMore className={classes.listItemIcon} />
              )}
            <ListItemText
              primary="集計管理"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <Collapse in={this.state.calcOpen} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              onClick={this.toggleDrawer("left", false)}
              onKeyDown={this.toggleDrawer("left", false)}
            >
              <ListItem button className={classes.sabListItem}>
                <Link to="/sales" className={classes.titleStr}>
                  <ListItemText
                    primary="売上データ検索"
                    classes={{ primary: classes.listItemText }}
                  />
                </Link>
              </ListItem>
            </List>
          </Collapse>
          {userMenus}
        </List>
      </div>
    );

    return (
      <div className={classes.root}>
        <AppBar position="relative" className={classes.appBar}>
          <Toolbar>
            <Link to="/">
              {/* ロゴ */}
              <Box>
                <img className={classes.iconImage} src={`${icon}`} />
              </Box>
              {/*
              <Fab >
                <SvgIcon className={classes.iconImage} />
              </Fab>*/}
            </Link>

            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={this.toggleDrawer("left", true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              open={this.state.left}
              onClose={this.toggleDrawer("left", false)}
              classes={{
                paper: classes.drawer
              }}
            >
              <div tabIndex={0} role="button">
                {sideList}
              </div>
            </Drawer>
            <div className={classes.searchDiv}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  value={this.state.orderSeach}
                  onChange={event => this.setState({orderSearch:event.target.value})}
                  onKeyPress={event => this.orderSearch(event)}
                />
              </div>
            </div>
            <Box p={2}>{this.state.username}</Box>
            <Fab
              className={classes.logout}
              onClick={this.toggleDrawer("logout", true)}
            >
              <ExitToAppIcon />
            </Fab>

          </Toolbar>

        </AppBar>
        <Drawer
          // variant="persistent"
          anchor="top"
          open={this.state.showMessageBox}
          onClose={this.toggleDrawer("showMessageBox", false)}
          classes={{
            paper: classes.messageBox
          }}

        >
          <Box justifyContent="center" display="flex" alignItems="center" textAlign="center">
            <List>
              <ListItem onClick={this.toggleDrawer("showMessageBox", false)}>
                <RemoveCircleIcon />
                &nbsp;{this.state.errorMessage}
              </ListItem>
            </List>

          </Box>
        </Drawer>
        <Drawer
          anchor="top"
          open={this.state.logout}
          onClose={this.toggleDrawer("logout", false)}
          classes={{
            paper: classes.user
          }}
          
         >
           <ListItem button className={classes.sabListItem}
           
           style={{}}
           >
              <Link to={{pathname: '/passEdit', state: { passEditUser: this.state.userId,user:this.state.username }}} className={classes.titleStr}>
                <ListItemText
                  primary="パスワード変更"
                  classes={{ primary: classes.userListItemText }}
                  
                />
              </Link>
          </ListItem>
           <ListItem button className={classes.sabListItem}
           onClick={this.handleToAboutPage.bind(this)}
           style={{}}
           >
              <Link to="/" className={classes.titleStr}>
                <ListItemText
                  primary="ログアウト"
                  classes={{ primary: classes.userListItemText }}
                  
                />
              </Link>
              </ListItem>
         </Drawer>
      </div>
    );
  }
  static get propTypes() {
    return {};
  }

  static get styles() {
    return theme => ({
      a: {
        color: "#000000",
      },
      root: {
        flexGrow: 1,
        position: "sticky",
        top: 0
      },
      list: {
        width: 250,
        padding: theme.spacing(2, 2, 2, 2)
      },
      listItemText: {
        flexGrow: 1,
        color: "#ffffff",
        fontSize: 18
      },
      listItemIcon: {
        color: "#ffffff",
        fontSize: 35
      },
      sabListItem: {
        paddingLeft: theme.spacing(7)
      },
      menuButton: {
        marginRight: theme.spacing(2)
      },
      title: {
        flexGrow: 0,
        display: "none",
        [theme.breakpoints.up("sm")]: {
          display: "block"
        }
      },
      titleStr: {
        boxShadow: "none",
        textDecoration: "none",
        color: "inherit",
        fontFamily: "Montserrat, sans-serif"
      },
      userListItemText: {
        flexGrow: 1,
        color: "#000000",
        fontSize: 18
      },
      searchDiv: {
        flexGrow: 1
      },
      search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
          backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "70%",
        [theme.breakpoints.up("sm")]: {
          marginLeft: theme.spacing(3),
          width: "70%"
        }
      },
      searchIcon: {
        width: theme.spacing(7),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
      inputRoot: {
        color: "inherit"
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          width: 120,
          "&:focus": {
            width: 200
          }
        }
      },
      logout: {
        marginRight: theme.spacing(1),
        marginLeft: 0,
        minWidth: 20
      },
      error: {
        marginRight: theme.spacing(1),
        marginLeft: 0,
        Width: 10
      },

      drawer: {
        width: "auto",
        height: "auto",
        overflow: "auto",
        background: fade("#0d47a1", 0.9),
        [theme.breakpoints.up("md")]: {
          overflow: "auto",
          width: "30%",
          position: "relative",
          height: "100%"
        }
      },

      messageBox: {
        width: "auto",
        height: "auto",
        background: "#e57373",
        zIndex: 900,
        position: "relative",
      },

      appBar: {
        backgroundColor: "#0d47a1"
      },

      iconImage :{
        //backgroundImage:`url(${icon})`
        minWidth: 20,
        maxWidth: 60,
        paddingTop: 4,
      },

      user: {
        width: "260px",
        height: "auto",
        overflow: "auto",
        background: "#e0e0e0",
        margin:"0 0 0 auto"
      },

      // drawer: {
      //   width: "auto",
      //   height: "auto",
      //   overflow: "auto",
      //   background: fade("#607d8b", 0.9),
      //   [theme.breakpoints.up("md")]: {
      //     overflow: "auto",
      //     width: "20%",
      //     position: "relative",
      //     height: "100%"
      //   }
      // },
      // appBar: {
      //   backgroundColor: fade("#607d8b", 0.8)
      // }
    });
  }

  static get mapStateToProps() {
    return ({ auth, user }) => ({ auth, user });
  }

  static get mapDispatchToProps() {
    return dispatch => ({ dispatch });
  }
}

// TODO 分割 ContainerとComponent
const { styles, mapStateToProps, mapDispatchToProps } = Component;
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
