import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, Checkbox, Grid} from "@material-ui/core";
import axios from "axios";
import ExitToApp from '@material-ui/icons/ExitToApp';
import DeleteIcon from '@material-ui/icons/Delete';

import Header from "../header/Header";
import { listUsedHistory, pageCountUsedHistory, currentPageUsedHistory } from "../actions";
import REQUEST_PATH from "../components/Common";
import AlertDialog from "../components/Dialog";
import { Alert } from '@material-ui/lab';
import Pagination from '@material-ui/lab/Pagination';
import Cookies from "js-cookie";

class Component extends React.Component {
  state = {
    deleteLock: true,
    productNumber:'',
    errorMessage: '',
    successMessage:'',
  };

  constructor(props) {
    super(props);
    this.forceUpdate();

    const pram = this.props.location.state.productNumber;
    if (!(pram === null) && !(pram === 'undefined') && !(pram === '')) {
      this.getHistoryMenu(pram, false);
      this.state.productNumber = pram;
    }
  }

  // 履歴の取得
  async getHistoryMenu(productNumber, isPageNation) {

    const {dispatch} = this.props;

    let _currentPage = 0; 
    if(isPageNation) {
      _currentPage = this.props.model_currentPage -1;
    }
    else {
      dispatch(currentPageUsedHistory(1));
    }

    const param = Cookies.get('token');
    await axios.post(
      REQUEST_PATH + 'api/history/list',
      {
        pageIndex:_currentPage,
        pageSize: 20,
        productNumber: productNumber
      }, {
        param,
        withCredentials: true,
      }
    ).then( response => {

      dispatch(pageCountUsedHistory(response.data.totalPageCount));
      dispatch(listUsedHistory(response.data.list));
    }).catch(e =>{
      console.log(e);
      this.setState({errorMessage:"検索に失敗しました"});
    });

    this.forceUpdate();
  };

  // ページネーション押下時
  async pagenationFunction(obj,number){

    const { dispatch } = this.props;

    await dispatch(currentPageUsedHistory(number));
    this.getHistoryMenu(this.state.productNumber, true);
  }

  handleCheckAll(evt) {
    const { model } = this.props;
    for (let i in model) {
      model[i].checked = evt.target.checked;
    }
    this.checkState();
    this.forceUpdate();
  }

  //チェックボックスにチェックを入れた時に呼び出される関数
  handleCheck(evt) {
    const { model } = this.props;

    const _model = model.filter(({ productHistoryIndex: id }, index) => {
      return evt.target.name === id + ''; // 文字列比較
    })[0];
    if (_model) {
      _model.checked = evt.target.checked;
    }

    this.checkState();
    this.forceUpdate();
  }

  checkState() {
    const { model } = this.props;
    const checkItem = model.filter(_model => {
      return _model.checked;
    });
    
    if (checkItem.length !== 0) {
      this.setState({ deleteLock: false });
    } else {
      this.setState({ deleteLock: true });
    }
  }

  //画面遷移
  moveToProductInput() {
    this.props.history.push({ pathname: '/ProductInput', state: { productNumber: this.state.productNumber } });
  }

  /***********************************************************************************************
  * 検索結果のリスト表示機能、帰ってきた行数分だけPushを繰り返す。
  ***********************************************************************************************/
  getHistoryItemList = () => {
    const list = [];
    const { classes, model } = this.props;

    for (const i in model) {
      list.push(
        <Box
          key={i}
          display={`flex`}
          justifyContent={`space-between`}
          flexGrow={1}
        >
          <>
            <Box className={classes.mstTblFirstItem}>
              <Checkbox
                key={model[i].productHistoryIndex}
                name={model[i].productHistoryIndex}
                checked={model[i].checked || false}
                onChange={this.handleCheck.bind(this)}
                inputProps={{ "aria-label": "checkbox" }}
                classes={{
                  root: classes.root,
                  checked: classes.checked
                }}
              />
            </Box>
            <Box className={classes.mstTblItem}>{model[i].productNumber}</Box>
            <Box className={classes.mstTblItem}>{model[i].orderNumber}</Box>
            <Box className={classes.mstTblItem}>{model[i].startDate.replace(/-/g, "/")}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.mstTblItemEnd}>{model[i].endDate.replace(/-/g, "/")}</Box>
           
          </>
        </Box>
      );
    }
    return <Box>{list}</Box>;
  };

  // タイトル域
  render() {
    const { classes } = this.props;
    const historyList = this.getHistoryItemList();

    const titleDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>
          <Box p={1} className={classes.titleHeaderColor}>
            使用履歴一覧画面
          </Box>
        </Box>
      </Box>
    );

    //使用履歴
    const mstTbl = (
      <Box style={{ paddingBottom: "16px" }}>

        <Box
          style={{
            marginTop:'15px',
            borderBottom: `1px solid #525457`,
            borderRadius: '5px'
          }}
        >
          <Box className={classes.mstTblList}>使用履歴一覧</Box>  
          <Box display={`flex`} justifyContent={`space-between`} flexGrow={1}>
            <Box className={classes.mstTblColFirst}>
              <Checkbox
                onChange={this.handleCheckAll.bind(this)}
                style={{ color: "#4A4A4B" }}
              />
            </Box>
            <Box className={classes.mstTblCol}>商品番号</Box>
            <Box className={classes.mstTblCol}>注文番号</Box>
            <Box className={classes.mstTblCol}>使用開始日</Box>
            <Box className={classes.mstTblCol + ' ' + classes.mstTblColEnd}>使用終了日</Box>
            
          </Box>
          {historyList}
        </Box>

        {/*****************ページング対応**************/}
        <Box display="flex" justifyContent="center" marginTop="15px">
          <Box>
            <Pagination 
              count={this.props.model_pageCount} 
              color="primary" 
              onChange={(event,page) => this.pagenationFunction(event,page)}
              page={this.props.model_currentPage}
              />
          </Box>
        </Box>
        {/****************ページング対応END************/}
      </Box>
    );

    const buttonDiv = (
      <Grid container spacing={2} justify="center" style={{ textAlign: 'center' }}>
        <Grid item xs={3} justify='flex-end'>
          <Button
            
            variant={`contained`}
            className={classes.buttonColor}
            endIcon={<ExitToApp />}
            onClick={this.moveToProductInput.bind(this)}
          >
            戻る
          </Button>
        </Grid>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            endIcon={<DeleteIcon />}
            disabled={this.state.deleteLock}
            onClick={this.opneDialog.bind(this, this.delete.bind(this), '削除しますか？')}
          >
            削除
          </Button>
        </Grid>
      </Grid>
    );

    return (
      <Box style={{ height: "100vh", width: "100vw" }}>
        <Header value={this.state.errorMessage}/>
        <Alert 
          id="successAlert"
          style={{display:'none'}}
          className={classes.successAlertStyle}
          severity="success">
            {this.state.successMessage}
        </Alert>
        <Box
          style={{
            height: "calc(100% - 96px)",
            width: "calc(100% - 32px)",
            padding: "16px",
            overflow: "auto"
          }}
        >
          <Box minWidth={975}>
            {titleDiv}
            {mstTbl}
            {buttonDiv}
          </Box>
          <AlertDialog/>
        </Box>
      </Box>
    );
  }

  // 確認ダイアログ表示
  opneDialog (func, message) {

    var Dialog = document.getElementById('dialogBox');
    var okButton = document.getElementById('dialogOkButton');
    var cancelButton = document.getElementById('dialogCancelButton');
  
    document.getElementById('dialogMessage').innerHTML= message;
  
    // ダイアログ表示
    Dialog.style.display = '';
    // OK 押下の処理
    okButton.onclick = func;
    // cancel　押下の処理
    cancelButton.onclick = function() {
      Dialog.style.display = 'none';
      };
  }

  async delete() {

    const { model } = this.props;
    const target = [];

    model.forEach(v => {
      if(v.checked) {
        target.push(v.productHistoryIndex);
      }
    });

    await this.setState({errorMessage:""});
    const param = Cookies.get('token');
    // TODO 複数削除
    await axios.delete(
      REQUEST_PATH + 'api/history/delete/' + target[0]
      ,{
        param,
        withCredentials: true,
      }
    ).then( response => {
      this.openeSuccessAlert("削除しました");
    }).catch(e =>{
      console.log(e);
      this.setState({errorMessage:"削除に失敗しました"});
    });

    document.getElementById('dialogBox').style.display = 'none';
    await this.getHistoryMenu(this.state.productNumber);
    this.checkState();
  }

  // 成功アラートの表示
  openeSuccessAlert(msg){

    document.getElementById('successAlert').style.display = '';
    this.setState({ successMessage: msg});

    // 2秒で消えるようにする。
    setTimeout(() => {
      document.getElementById('successAlert').style.display = 'none';
    },2000);
  }

  static get propTypes() {
    return {};
  }

  // Styleの設定
  static get styles() {
    return theme => ({

      mstTblFirstItem: {
        height:'40px',
        border: "1px solid #b0bec5",
        borderLeft: "1px solid #525457",
        borderBottom: "0px solid #b0bec5",
        width:"5%",
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      mstTblItem: {
        border: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        height:'40px',
        width: "20%",
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      mstTblItemEnd: {
        borderRight: "1px solid #525457",
      },
      root: {
        color: "#607d8b",
        "&$checked": {
          color: "#FF7200"
        }
      },
      checked: {},
      titleHeaderColor: {
        border: "1px solid #525457",
        backgroundColor: "#1976d2",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 20,
        fontWeight: "bold",
        borderRadius: "5px"
      },
      mstTblColFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderLeft: "1px solid #525457",
        borderBottom: "0px solid #b0bec5",
        backgroundColor: "#C8E6C9",
        textAlign: "center",
        width: "5%",
        flexGrow: 1,
        color: "#4A4A4B"
      },
      mstTblList: {
        border: `1px solid #525457`,
        borderRight: "1px solid #525457",
        borderLeft: "1px solid #525457",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B",
        height: '30px'
      },
      mstTblCol: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: "20%",
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        color: "#4A4A4B"
      },
      mstTblColEnd: {
        borderRight: "1px solid #525457",
      },
      buttonColor: {
        width: 150,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing: 10
      },
      successAlertStyle: {
        position: 'relative',
        justifyContent: 'center',
        width: '100%',
        backgroundColor:'#80cbc4',
        fontSize:'1rem'
      },
    });
  }
}

// redux/index.js プロパティ
const mapStateToProps = ({ usedHistory: { list } }) => ({ model: list });
const mapStateToProps2 = ({ usedHistory_pageCount: {totalPageCount} }) => ({model_pageCount:totalPageCount});
const mapStateToProps3 = ({ usedHistory_currentPage: {currentPage} }) => ({model_currentPage:currentPage});

const mapDispatchToProps = dispatch => ({ dispatch });
const { styles } = Component;

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  connect(mapStateToProps2,mapDispatchToProps),
  connect(mapStateToProps3,mapDispatchToProps),
)(Component);
