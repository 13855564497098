import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles, fade } from "@material-ui/core/styles";
import { Box, Button, Grid, Fab, Checkbox, TextField, Select, MenuItem, InputLabel } from "@material-ui/core";
import SportsTennis from "@material-ui/icons/SportsTennis";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "axios";
import EditIcon from '@material-ui/icons/Edit';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Pagination from '@material-ui/lab/Pagination';

import Header from "../header/Header";
import AlertDialog from "../components/Dialog";

import { moveTo, listProductList, pageCountProduct, currentPageProduct, hitsCountProduct} from "../actions";
import REQUEST_PATH from "../components/Common";
import { Alert } from '@material-ui/lab';
import Cookies from "js-cookie";
import Autocomplete from '@material-ui/lab/Autocomplete';

class Component extends React.Component {
  state = {
    // 検索項目
    searchProductNumber: "",
    searchGroupNumber: "",
    searchLicenseId: "",
    searchStatus: "",
    searchType: "",
    searchModel: "",

    // フォーム
    changeForm: null,
    // 非活性
    addLock: false,
    registerLock: false,
    updateLock: true,
    deleteLock: true,
    // ヘルパーテキスト
    modelHelperText: "",
    modelNameHelperText: "",
    productNumberHelperText: "",
    groupNumberHelperText: "",
    licenseIdHelperText: "",
    statusHelperText: "",
    //リスト
    license_list: [],
    status_list: [],
    type_list: [],
    model_list: [],
    // エラーメッセージ
    errorMessage: '',

    successMessage:'',
  };

  constructor(props) {
    super(props);

    // 表示用のリストを取得
    // 免許名
    this.getlicenseListMenu();
    this.getStatusListMenu();
    this.getModelListMenu();
    this.getTypeListMenu();
  }

  // render後、ボタン制御（遷移元からの復帰対策）
  componentDidMount() {
    this.checkState();
  }

  // 免許名の取得
  async getlicenseListMenu() {
    const param = Cookies.get('token');
    await axios.post(
      REQUEST_PATH + 'api/license/list',
      {
        pageIndex: 0,
        pageSize: 10000,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ license_list: response.data.list });
    }).catch(e => {
      console.log(e);
    });
  }

  // 状態ステータスの取得
  async getStatusListMenu() {
    const param = Cookies.get('token');
    await axios.post(
      REQUEST_PATH + 'api/product-status/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ status_list: response.data.list });
    }).catch(e => {
      console.log(e);
    });
  }

  // 種別の取得
  async getTypeListMenu() {
    const param = Cookies.get('token');
    await axios.post(
      REQUEST_PATH + 'api/product-type/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ type_list: response.data.list });
    }).catch(e => {
      console.log(e);
    });
  }

  // 機種の取得
  async getModelListMenu() {
    const param = Cookies.get('token');
    await axios.post(
      REQUEST_PATH + 'api/product-model/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ model_list: response.data.list });
    }).catch(e => {
      console.log(e);
    });
  }

  //画面遷移
  onClickInput() {
    const { model } = this.props;
    const checkItem = model.filter(_model => {
      return _model.checked;
    })[0];
    if (checkItem) {
      this.props.history.push({ pathname: '/ProductInput', state: { productNumber: checkItem.productNumber } });
    } else {
      this.props.history.push({ pathname: '/ProductInput', state: { productNumber: '' } });
    }
  }

  onClickStatusInput(){
    const{ model } = this.props;
    const selectedModel=[];
    const checkItem = model.filter(_model => {
      return _model.checked;
    });

    for(let i in checkItem){
      selectedModel.push({type:checkItem[i].productNumber.substr(0,2),
                          model:checkItem[i].productNumber.substr(2,2),
                          spareNumber:checkItem[i].productNumber.substr(4,2),
                          number:checkItem[i].productNumber.substr(6)
      });
    }
    
    if (checkItem) {
      this.props.history.push({ pathname: '/statusInput', state: { productNumber: selectedModel } });
    } else {
      this.props.history.push({ pathname: '/statusInput', state: { productNumber: '' } });
    }
  }

  handleChange = color => {
    this.setState({ setColor: color.hex });
  };

  onChangeSearchField(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  onClearSearchField() {
    this.setState(
      { 
        searchProductNumber : "", 
        searchGroupNumber   : "", 
        //searchLicenseId     : "", 
        searchStatus        : "" ,
        searchType          : "",
        searchModel         : ""
      }
    );

    this.forceUpdate();
  }

  //編集時にイベントが発生した際呼び出される関数
  onChangeField(e) {
    const changeItem = this.state.changeForm;
    const val = e.target.value;

    switch (e.target.name) {
      case "modelName": {
        changeItem.modelName = val;
        break;
      }
      case "licenseType": {
        changeItem.licenseType = val;
        break;
      }
      case "colorCode": {
        changeItem.colorCode = val;
        break;
      }
      default: {
        // ignore
        break;
      }
    }
    this.forceUpdate();
  }

  /**       コンボボックス対応       */
  onChangeAutoCompletField(e,value) {
  
    if(value === null || value === undefined || value ===  ''){
      this.setState({searchLicenseId : ''});
    } else {
      this.setState({searchLicenseId:value.licenseId});
    }
  }

  handleToAboutPage() {
    const { dispatch } = this.props;
    dispatch(moveTo.Sample());
  }

  handleCheckAll(evt) {
    const { model } = this.props;
    for (let i in model) {
      model[i].checked = evt.target.checked;
    }
    this.checkState();
    this.forceUpdate();
  }
  //チェックボックスにチェックを入れた時に呼び出される関数
  handleCheck(evt) {
    const { model } = this.props;

    const _model = model.filter(({ productNumber: id }, index) => {
      return evt.target.name === id;
    })[0];
    if (_model) {
      _model.checked = evt.target.checked;
    }

    this.checkState();
    this.forceUpdate();
  }

  /*****************ページング対応**************/
  async pagenationFunction(obj,number){
    const { dispatch } = this.props;
    await dispatch(currentPageProduct(number));

    this.search(true);
    await this.checkState();
  }
  /****************ページング対応END************/

  checkState() {
    const { model } = this.props;
    const checkItem = model.filter(_model => {
      return _model.checked;
    });

    // チェックが0の活性その他非活性
    if (checkItem && checkItem.length === 0) {
      this.setState({ registerLock: false });
      this.setState({ updateLock: true });
      this.setState({ deleteLock: true });
      this.setState({ priceLock: true });
    }
    if (checkItem && checkItem.length === 1) {

      this.setState({ registerLock: true });
      this.setState({ updateLock: false });
      this.setState({ deleteLock: false });
      this.setState({ priceLock: false });

    }

    if (checkItem && checkItem.length > 1) {
      this.setState({ registerLock: true });
      this.setState({ updateLock: true });
      this.setState({ deleteLock: false });
      this.setState({ priceLock: true });
    }
  }

  getLisenceTypeOptions = () => {
    const licenseList = this.state.license_list;
    const menuItems = [];
    for (const i in licenseList) {
      menuItems.push(
        <MenuItem
          value={licenseList[i].license_type}
          key={licenseList[i].license_type}
        >
          {licenseList[i].license_type + ":" + licenseList[i].license_type_name}
        </MenuItem>
      );
    }
    return menuItems;
  };

  getTypeOptions = () => {
    const typeList = this.state.type_list;
    const menuItems = [];
    for (const i in typeList) {
      menuItems.push(
        <MenuItem
          value={typeList[i].productType}
          key={typeList[i].productType}
        >
          {typeList[i].productType + ":" +typeList[i].productName}
        </MenuItem>
      );
    }
    return menuItems;
  };

  getModelOptions = () => {
    const modelList = this.state.model_list;
    const menuItems = [];
    for (const i in modelList) {
      menuItems.push(
        <MenuItem
          value={modelList[i].model}
          key={modelList[i].model}
        >
          {modelList[i].model + ":" +modelList[i].modelName}
        </MenuItem>
      );
    }
    return menuItems;
  };

  /***********************************************************************************************
  * 検索結果のリスト表示機能、帰ってきた行数分だけPushを繰り返す。
  ***********************************************************************************************/
  getMstItemList = () => {
    const list = [];
    const { classes, model } = this.props;
    const changeItem = this.state.changeForm;
    const license_list = this.state.license_list;
    const status_list = this.state.status_list;
    const type_list = this.state.type_list;
    const model_list = this.state.model_list;

    for (const i in model) {
      var licenseName = null;
      var statusName = null;
      var typeName = null;
      var modelName = null;

      // 表示用の処理
      license_list.forEach((v) => {
        if (v.licenseId == model[i].licenseId) {
          licenseName = v.licenseName;
          return true;
        }
      });

      status_list.forEach((v) => {
        if (v.status == model[i].status) {
          statusName = v.statusName;
          return true;
        }
      });

      type_list.forEach((v) => {
        if (v.productType == model[i].type) {
          typeName = v.productName;
          return true;
        }
      });

      model_list.forEach((v) => {
        if (v.model == model[i].model) {
          modelName = v.modelName;
          return true;
        }
      });

      list.push(
        <Box
          key={i}
          display={`flex`}
          justifyContent={`space-between`}
          flexGrow={1}
        >
          <>
            <Box className={classes.mstTblItemFirst}>
              <Checkbox
                key={model[i].productNumber}
                name={model[i].productNumber}
                checked={model[i].checked || false}
                onChange={this.handleCheck.bind(this)}
                inputProps={{ "aria-label": "checkbox" }}
                classes={{
                  root: classes.root,
                  checked: classes.checked
                }}
              />
            </Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{typeName}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{modelName}</Box>
            <Box className={classes.mstTblItem}>{model[i].productNumber}</Box>
            <Box className={classes.mstTblItem}>{model[i].groupNumber}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{model[i].productName}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{licenseName}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{statusName}</Box>
          </>
        </Box>
      );
    }
    return <Box>{list}</Box>;
  };

  getLicenseIdOptions = () => {
    const license = this.state.license_list;
    const menuItems = [];
    for (const i in license) {
      menuItems.push(
        <MenuItem
          value={license[i].licenseId}
          key={license[i].licenseId}
        >
          {license[i].licenseId + ':' + license[i].licenseName}
        </MenuItem>
      );
    }
    return menuItems;
  };

  getStatusOptions = () => {
    const status = this.state.status_list;
    const menuItems = [];
    for (const i in status) {
      menuItems.push(
        <MenuItem
          value={status[i].status}
          key={status[i].status}
        >
          {status[i].status + ':' + status[i].statusName}
        </MenuItem>
      );
    }
    return menuItems;
  };


  //画面描画
  render() {
    const { classes } = this.props;
    const list = this.getMstItemList();
    const licenseIdMenu = this.getLicenseIdOptions();
    const statusMenu = this.getStatusOptions();
    const typeMenu = this.getTypeOptions();
    const modelMenu = this.getModelOptions();

    /**       コンボボックス対応       */
    const _licenseList = this.state.license_list;
    let licenseOption = [];

    _licenseList.forEach(v => {
      let tmp = {};
      tmp.licenseId = v.licenseId;
      tmp.licenseName = v.licenseName;
      licenseOption.push(tmp);
    });



    const titleDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>
          <Box p={1} className={classes.titleHeaderColor}>
            商品一覧管理画面
          </Box>
        </Box>
      </Box>
    );

    //検索フォーム
    const searchDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>

          <Box display={`flex`} p={2} className={classes.searchTbl}>

            <Box flexGrow={1}>
              <Box display={`flex`}>
                <InputLabel ref="<Box>" id="search_product_number_label" className={classes.InputLabelProduct}>商品番号</InputLabel>
                <TextField
                  labelId="search_product_number_label"
                  id="search_product_number"
                  name="searchProductNumber"
                  variant="outlined"
                  size="small"
                  className={classes.searchProductNumber}
                  value={this.state.searchProductNumber}
                  onChange={this.onChangeSearchField.bind(this)}
                />
              </Box>

              <Box display={`flex`} style={{ paddingTop: "15px" }}>
                <InputLabel ref="<Box>" id="search_group_number_label" className={classes.InputLabelGroup}>所属番号</InputLabel>
                <TextField
                  labelId="search_group_number_label"
                  id="search_group_number"
                  name="searchGroupNumber"
                  variant="outlined"
                  size="small"
                  className={classes.searchGroupNumber}
                  value={this.state.searchGroupNumber}
                  onChange={this.onChangeSearchField.bind(this)}
                />
              </Box>
            </Box>

            <Box flexGrow={1}>
              <Box display={`flex`}>
                <InputLabel id="search_type_label" className={classes.selectLabel}>種別</InputLabel>
                <Select
                  labelId="search_type_label"
                  id="search_type"
                  name="searchType"
                  variant="outlined"
                  size="small"
                  className={classes.searchSelect}
                  value={this.state.searchType}
                  onChange={this.onChangeSearchField.bind(this)}
                >
                  {typeMenu}
                </Select>
              </Box>

              <Box display={`flex`} style={{ paddingTop: "15px" }}>
                <InputLabel id="search_model_label" className={classes.selectLabel}>機種</InputLabel>
                <Select
                  labelId="search_model_label"
                  id="search_model"
                  name="searchModel"
                  variant="outlined"
                  size="small"
                  className={classes.searchSelect}
                  value={this.state.searchModel}
                  onChange={this.onChangeSearchField.bind(this)}
                >
                  {modelMenu}
                </Select>
              </Box>
            </Box>

            <Box>
              <Box display={`flex`}>
                <InputLabel ref="<Box>" id="search_license_id_label" className={classes.selectLabel2}>免許名</InputLabel>
                {/*<Select
                  labelId="search_license_id_label"
                  id="search_license_id"
                  name="searchLicenseId"
                  variant="outlined"
                  size="small"
                  className={classes.searchSelect}
                  value={this.state.searchLicenseId}
                  onChange={this.onChangeSearchField.bind(this)}
                  MenuProps={{style:{
                    height:'600px'
                  }}}
                >
                  {licenseIdMenu}
                </Select>
                */}


                {<Autocomplete
                  name="searchLicenseId"
                  id="search_license_id"
                  size='small'
                  options={licenseOption}
                  ListboxProps={{style:{height:350 }}}
                  getOptionLabel={(option) => option.licenseName}
                  renderInput={
                    (params) => 
                    <TextField 
                      name="searchLicenseId" 
                      className={classes.searchSelect} 
                      {...params} variant="outlined"
                      />}
                  onChange={(event,value) => this.onChangeAutoCompletField(event,value)}
                  />}
              </Box>

              <Box display={`flex`} style={{ paddingTop: "15px" }}>
                <InputLabel ref="<Box>" id="search_status_label" className={classes.selectLabel2}>状態　</InputLabel>
                <Select
                  labelId="search_status_label"
                  id="search_status"
                  name="searchStatus"
                  variant="outlined"
                  size="small"
                  className={classes.searchSelect}
                  value={this.state.searchStatus}
                  onChange={this.onChangeSearchField.bind(this)}
                >
                  {statusMenu}
                </Select>
              </Box>
            </Box>

            <Box flexGrow={1}>
              <Box>
                <Box display={`flex`} flexGrow={1} justify="left" className={classes.search}>
                  <Box width={90}>
                    <Fab
                      className={classes.searchFabColor}
                      onClick={this.search.bind(this,false)}
                    >
                      <SearchOutlinedIcon style={{ fontSize: 30 }} />
                    </Fab>
                  </Box>
                  <Box width={70}>
                    <Fab
                      className={classes.searchFabColor}
                      onClick={this.onClearSearchField.bind(this)}
                    >
                      <CancelPresentationIcon style={{ fontSize: 30 }} />
                    </Fab>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>



    );

    /***********************************************************************************************
    * 画面のテーブルのヘッダ情報のJSXとpropsの設定です。
    * 検索結果を表示する1レコード文をlistとして宣言しており、(429行目)
    * propsにレコードの情報が入るようにmapしています。
    * 宣言のみなので、検索ボタンが押されてメソッドが走るまではヘッダ部分の描画になります。
    ***********************************************************************************************/
    const mstTbl = (
      <Box style={{ paddingBottom: "16px" }}>
        {/* <Box flexGrow={1}>
          <Box p={1} className={classes.tblHeaderColor}>
            商品一覧マスター 一覧
          </Box>
        </Box> */}
        <Box
          style={{
            // border: `1px solid #525457`,
            borderBottom: `1px solid #525457`,
            borderRadius: '5px'
          }}
        >
          <Box display={`flex`} justifyContent={`space-between`} flexGrow={1}>
            <Box className={classes.mstTblColFirst}>
              <Checkbox
                onChange={this.handleCheckAll.bind(this)}
                style={{ color: "#4A4A4B" }}
              />
            </Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>種別</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>機種</Box>
            <Box className={classes.mstTblCol}>商品番号</Box>
            <Box className={classes.mstTblCol}>所属番号</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>商品名</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>免許名</Box>
            <Box className={classes.mstTblColLast + ' ' + classes.itemTitlePaddingLeft}>状態</Box>
          </Box>
          {list}
        </Box>
        {/*****************ページング対応**************/}
        <Box display="flex" justifyContent="center" marginTop="15px">
          <Box>
            <Pagination 
              count={this.props.model_pageCount}
              color="primary" 
              onChange={(event,page) => this.pagenationFunction(event,page)}
              page={this.props.model_currentPage}
              />
          </Box>
        </Box>
        {/****************ページング対応END************/}
      </Box>
    );


    /***********************************************************************************************
    * 画面下部の「登録」「更新」「削除」のJSXとpropsの設定のメソッドです。
    ***********************************************************************************************/
    const buttonDiv = (
      <Grid container spacing={3} justify="center" style={{ textAlign: 'center' }}>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            endIcon={<SportsTennis />}
            onClick={this.onClickStatusInput.bind(this)}
            style={{letterSpacing: 0,width:"240px"}}
          >
            ステータス入力
          </Button>
        </Grid>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            endIcon={<PostAddIcon />}
            disabled={this.state.registerLock}
            onClick={this.onClickInput.bind(this)}

          >
            登録
          </Button>
        </Grid>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            endIcon={<EditIcon />}
            disabled={this.state.updateLock}
            onClick={this.onClickInput.bind(this)}
          >
            更新
          </Button>
        </Grid>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.delete.bind(this), '削除しますか？')}
            endIcon={<DeleteIcon />}
            disabled={this.state.deleteLock}
          >

            削除

          </Button>
        </Grid>
      </Grid>
    );

    return (
      <Box style={{ height: "100vh", width: "100vw" }}>
        <Header value={this.state.errorMessage} />
        <Alert 
          id="successAlert"
          style={{display:'none'}}
          className={classes.successAlertStyle}
          severity="success">
            {this.state.successMessage}
        </Alert>
        <Box
          style={{
            height: "calc(100% - 96px)",
            width: "calc(100% - 32px)",
            padding: "16px",
            overflow: "auto"
          }}
        >
          <Box minWidth={975}>
            {titleDiv}
            {searchDiv}
            {/****  検索ヒット数対応  ****/}
            <Box  className={classes.searchResult}>
              <span>検索結果：</span>
              <span>{this.props.model_hitsCount}</span>
              <span> 件</span>
            </Box>
            {mstTbl}
            {buttonDiv}
          </Box>
          <AlertDialog/>
        </Box>
      </Box>
    );
  }

  opneDialog (func, message) {

    var Dialog = document.getElementById('dialogBox');
    var okButton = document.getElementById('dialogOkButton');
    var cancelButton = document.getElementById('dialogCancelButton');
 
    document.getElementById('dialogMessage').innerHTML= message;
 
    // ダイアログ表示
    Dialog.style.display = '';
    // OK 押下の処理
    okButton.onclick = func;
    // cancel　押下の処理
    cancelButton.onclick = function() {
     Dialog.style.display = 'none';
     };
  }
  /***********************************************************************************************
  * 検索ボタンがクリックされた時に実行されるメソッドです。
  * 一応検索フィールドに入力された際に入力チェックを行っており、入力謝りがあればreturnを返しています
  ***********************************************************************************************/
  async search(isPageNation) {

    const { dispatch } = this.props;
    const _searchProductNumber = this.state.searchProductNumber || null;
    const _searchGroupNumber = this.state.searchGroupNumber || null;
    const _searchLicenseId = this.state.searchLicenseId || null;
    const _searchStatus = this.state.searchStatus || null;
    const _searchType = this.state.searchType || null;
    const _searchModel = this.state.searchModel || null;

    /*****************ページング対応**************/
    let _currentPage = 0; 
    if(isPageNation) {
      _currentPage = this.props.model_currentPage -1;
    }
    else {
      dispatch(currentPageProduct(1));
    }
    /****************ページング対応END************/

    await this.setState({ errorMessage: '' });

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/product/list',
      {
        /*****************ページング対応**************/
        pageIndex: _currentPage,
        /****************ページング対応END************/
        pageSize      : 20,
        productNumber : _searchProductNumber,
        groupNumber   : _searchGroupNumber,
        licenseId     : _searchLicenseId,
        status        : _searchStatus,
        type          : _searchType,
        model         : _searchModel,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      /*****************ページング対応**************/
      dispatch(pageCountProduct(response.data.totalPageCount));

      /****************ページング対応END************/
      dispatch(listProductList(response.data.list));

      // ヒット件数対応
      dispatch(hitsCountProduct(response.data.totalCount));

    }).catch(e => {
      // 失敗時
      this.setState({ errorMessage: '検索に失敗しました' });
      console.log(e);
    });
    

    this.checkState();
  }

  /***********************************************************************************************
    * 削除ボタンをクリックされた時に実行されるメソッド
  ***********************************************************************************************/
  async delete() {
    const { model } = this.props;
    const deleteList = [];
    let isFailed = false;

    await this.setState({ errorMessage: '' });

    // 選択している商品番号を取得
    for (let i in model) {
      if (model[i].checked) {
        deleteList.push(model[i].productNumber);
      }
    }


    const param = Cookies.get('token');
    // 修理履歴の削除
    for (let i in deleteList) {
      await axios.delete(
        REQUEST_PATH + 'api/repair/delete-history/' + deleteList[i],{
          param,
          withCredentials: true,
        }
      ).then( response => {

      }).catch(e =>{
        console.log(e);
        isFailed = true;
        this.setState({headerErrorMessage:"削除に失敗しました"});
      });
    }

    // 商品データ削除
    for (let i in deleteList) {
      await axios.delete(
        REQUEST_PATH + 'api/product/delete/' + deleteList[i],{
          param,
          withCredentials: true,
        }
      ).then( response => {

      }).catch(e =>{
        console.log(e);
        isFailed = true;
        this.setState({headerErrorMessage:"削除に失敗しました"});
      });
    }

    if (!isFailed) {
      this.openeSuccessAlert("削除しました");
    }

    document.getElementById('dialogBox').style.display = 'none';
    this.search();
  }


  validate(model, modelName) {
    var isFailed = false;
    this.setState({ modelNameError: false });

    if (model === null || model === '' || model === 'undefined' ||
      isNaN(model)) {
      this.setState({ modelHelperText: "入力に誤りがあります" });
      this.setState({ modelError: true });
      isFailed = true;
    }
    
    if (modelName === null || modelName === '' || modelName === 'undefined') {

      this.setState({ modelNameHelperText: "入力に誤りがあります" });
      this.setState({ modelNameError: true });
      isFailed = true;
    }

    return isFailed;
  }

  // 成功アラートの表示
  openeSuccessAlert(msg){

    document.getElementById('successAlert').style.display = '';
    this.setState({ successMessage: msg});

    // 2秒で消えるようにする。
    setTimeout(() => {
      document.getElementById('successAlert').style.display = 'none';
    },2000);
  }

  static get propTypes() {
    return {};
  }

  // Styleの設定
  static get styles() {
    return theme => ({
      searchProductNumber: {
        backgroundColor: "#FFFFFF",
        //marginRight: 50,
        width: 250,
        borderRadius: 5,
        marginLeft: 10,
      },
      searchGroupNumber: {
        backgroundColor: "#FFFFFF",
        //marginRight: 20,
        width: 250,
        //marginRight: 1,
        borderRadius: 5,
        height: 40,
        marginLeft: 10
      },
      searchSelect: {
        backgroundColor: "#FFFFFF",
        //marginRight: 50,
        width: 250,
        borderRadius: 5,
        height: 40,
        marginLeft: 10
      },
      mstTblItemFirst: {
        border: "1px solid #b0bec5",
        borderLeft: "1px solid #525457",
        // borderTop: "0",
        borderBottom: "0",
        textAlign: "center",
        width: 70,
        flexGrow: 1
      },
      mstTblItem: {
        border: "1px solid #b0bec5",
        borderLeft: "0",
        // borderTop: "0",
        borderBottom: "0",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },

      mstTblColorItem: {
        border: "1px solid #b0bec5",
        borderRight: "1px solid #525457",
        borderLeft: "0",
        // borderTop: "0",
        borderBottom: "0",
        width: 280,
        flexGrow: 1,
        textAlign: "center",
        color: "#4A4A4B",
        fontWeight: 500,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
      },
      addMstTblItemFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        width: 70,
        height: 80,
        flexGrow: 1
      },
      addMstTblItem: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: 280,
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      addMstTblColorItem: {
        border: "1px solid #525457",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: 280,
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      boxColor: {
        minHeight: 20,
        minWidth: 20,
        maxHeight: 20,
        maxWidth: 20,
        marginLeft: 15,
        marginRight: 6,
        border: "1px solid #4A4A4B",
        borderRadius: "6px"
      },
      root: {
        color: "#607d8b",
        "&$checked": {
          color: "#FF7200"
        }
      },
      checked: {},

      titleHeaderColor: {
        border: "1px solid #525457",
        backgroundColor: "#1976d2",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 20,
        fontWeight: "bold",
        borderRadius: "5px"
      },
      tblHeaderColor: {
        borderTop: "1px solid #525457",
        borderLeft: "0",
        borderRight: "1px solid #525457",
        backgroundColor: fade("#1565c0", 0.8),
        color: "#4A4A4B",
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold"
      },
      searchTbl: {
        border: "1px solid #525457",
        backgroundColor: "#bbdefb",
        color: "#4A4A4B",
        fontSize: 16,
        justifyContent: "left",
        alignItems: "center",
        borderRadius: "5px"
      },
      mstTblColFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        textAlign: "center",
        width: 70,
        flexGrow: 1
      },
      mstTblCol: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      mstTblColLast: {
        border: "1px solid #525457",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      buttonColor: {
        width: 150,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        // color: "#E1E1E1"
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing: 10
      },
      searchFabColor: {
        backgroundColor: "#2196f3",
        color: "#EEEEEE"
      },
      InputLabelProduct: {
        paddingTop: '2%',
        marginLeft: 10
      },
      InputLabelGroup: {
        paddingTop: '2%',
        marginLeft: 10
      },
      selectLabel: {
        paddingTop: '2%',
      },
      selectLabel2: {
        paddingTop: '3%',
      },
      search: {
        //textAlign: "left",
        //marginLeft:"60%"
        justifyContent: 'flex-end',
      },
      searchResult:{
        textAlign:"left",
        marginBottom:"10px",
        paddingLeft:"5px",
        //fontSize:'1.5rem'
      },
      itemAlingnLeft:{
        justifyContent: "left",
        paddingLeft: '1%',
      },
      itemTitlePaddingLeft:{
        paddingLeft: '1%',
      },
      successAlertStyle: {
        position: 'relative',
        justifyContent: 'center',
        width: '100%',
        backgroundColor:'#80cbc4',
        fontSize:'1rem'
      },
    });
  }
}

const mapStateToProps = ({ productList: { list } }) => ({ model: list });
const mapStateToProps2 = ({ productList_pageCount: {totalPageCount} }) => ({model_pageCount:totalPageCount});
const mapStateToProps3 = ({ productList_currentPage: {currentPage} }) => ({model_currentPage:currentPage});
const mapStateToProps4 = ({ productList_hitsCount: {hitsCount} }) => ({model_hitsCount:hitsCount});

const mapDispatchToProps = dispatch => ({ dispatch });
const { styles } = Component;

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  connect(mapStateToProps2,mapDispatchToProps),
  connect(mapStateToProps3,mapDispatchToProps),
  connect(mapStateToProps4,mapDispatchToProps)
)(Component);
