import { createAction } from 'redux-actions';

const LIST_ORDER = `LIST_ORDER`;
const PAGE_COUNT_ORDER = `PAGE_COUNT_ORDER`;
const CURRENT_PAGE_ORDER = `CURRENT_PAGE_ORDER`;
const HITS_COUNT_ORDER = `HITS_COUNT_ORDER`;

export const listOrder = createAction(LIST_ORDER, (list) => (list));

// ページネーション対応
export const pageCountOrder = createAction(PAGE_COUNT_ORDER, (totalPageCount) => (totalPageCount));
export const currentPageOrder = createAction(CURRENT_PAGE_ORDER, (currentPage) => (currentPage));

// ヒット件数対応
export const hitsCountOrder = createAction(HITS_COUNT_ORDER, (hitsCount) => (hitsCount));
