import { handleAction } from 'redux-actions';
import { listCompany, pageCountCompany, currentPageCompany, hitsCountCompany } from '../actions';

const defaultState = { list: [] };
const defaultState2 = { totalPageCount:0 };
const defaultState3 = { currentPage:1 };
const defaultState4 = { hitsCount:0 };

export const companyMasterReducer = handleAction(listCompany, (state, { payload }) => ({ ...state, list: payload }), defaultState);

// ページネーション対応
export const companyMasterReducerPageCount = handleAction(pageCountCompany, (state, { payload }) => ({ ...state, totalPageCount: payload }), defaultState2);
export const companyMasterReducerCurrentPage = handleAction(currentPageCompany, (state, { payload }) => ({ ...state, currentPage: payload }), defaultState3);
// ヒット件数対応
export const companyMasterReducerHitsCount = handleAction(hitsCountCompany, (state, { payload }) => ({ ...state, hitsCount: payload }), defaultState4);
