import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles} from "@material-ui/core/styles";
import { Box, Button, Grid,TextField,Select,MenuItem,InputLabel} from "@material-ui/core";
import axios from "axios";
import PostAddIcon from '@material-ui/icons/PostAdd';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Header from "../header/Header";
import AlertDialog from "../components/Dialog";
import { moveTo} from "../actions";
import { TwitterPicker } from "react-color";
import REQUEST_PATH from "../components/Common";
import { Alert } from '@material-ui/lab';
import Cookies from "js-cookie";

class Component extends React.Component {
  state = {
    // 入力項目
    companyId:'',
    companyName:'',
    companyPersonName:'',
    postalNumber:'',
    residence:'',
    building:'',
    phoneNumber:'',
    faxNumber:'',
    mail:'',
    colorCode:'',
    paymentSite:'',
    remarks:'',
    setColor: "#FFFFFF",
    displayColorPicker:false,
    
    // エラー処理
    companyIdError:false,
    companyNameError:false,
    companyPersonError:false,
    postalNumberError:false,
    residenceError:false,
    buildingError:false,
    phoneNumberError:false,
    faxNumberError:false,
    colorCodeError:false,
    paymentSiteError:false,
    remarksError:false,
    setColorError: false,
    isSuccess:false,
    
    // 登録変更フラグ
    isUpdate:false,
    //エラーメッセージ
    headerErrorMessage: "",
    
    successMessage:'',
  };

  constructor(props){
    super(props);
    const pram = this.props.location.state.companyId;
    if(!(pram === null) && !(pram === 'undefined') && !(pram === '')) {
      this.search(pram);
    }
  }

  // 入力項目のステート更新
  onChangeField(evt) {
    this.setState({ [evt.target.name]: evt.target.value });

    const val = evt.target.value;

    switch (evt.target.name) {
      case 'companyId': {
        this.setState({ companyId: val });

        if (isNaN(val)) {
          this.setState({ companyIdHelperText: '例:001' });
          this.setState({ companyIdError: true });
          break;
        }

        if (val.length > 0 && val.length < 3) {
          this.setState({ companyIdHelperText: '例:001' });
          this.setState({ companyIdError: true });
          break;
        }

        if (val.length > 3) {
          this.setState({ companyIdHelperText: '例:001' });
          this.setState({ companyIdError: true });
          break;
        }

        this.setState({ companyIdError: false });
        this.setState({ companyIdHelperText: '' });
        break;
      }
      case 'companyName': {
        this.setState({ companyName: val });

        if (val.length > 128) {
          this.setState({ companyNameHelperText: '文字数が多いです' });
          this.setState({ companyNameError: true });
          break;
        }

        this.setState({ companyNameHelperText: '' });
        this.setState({ companyNameError: false });
        break;
      }
      case 'companyPersonName': {
        this.setState({ companyPersonName: val });

        if (val.length > 32) {
          this.setState({ companyPersonNameHelperText: '文字数が多いです' });
          this.setState({ companyPersonNameError: true });
          break;
        }

        this.setState({ companyPersonNameHelperText: '' });
        this.setState({ companyPersonNameError: false });
        break;
      }
      case 'paymentSite': {
        this.setState({ paymentSite: val });
        this.setState({ paymentSiteError: false });
        break;
      }
      case 'postalNumber': {
        this.setState({ postalNumber: val });

        if (val.length > 12) {
          this.setState({ postalNumberHelperText: '文字数が多いです' });
          this.setState({ postalNumberError: true });
          break;
        }

        this.setState({ postalNumberHelperText: '' });
        this.setState({ postalNumberError: false });
        break;
      }
      case 'residence': {
        this.setState({ residence: val });

        if (val.length > 128) {
          this.setState({ residenceHelperText: '文字数が多いです' });
          this.setState({ residenceError: true });
          break;
        }

        this.setState({ residenceHelperText: '' });
        this.setState({ residenceError: false });
        break;
      }
      case 'phoneNumber': {
        this.setState({ phoneNumber: val });

        if (val.length > 16) {
          this.setState({ phoneNumberHelperText: '文字数が多いです' });
          this.setState({ phoneNumberError: true });
          break;
        }

        this.setState({ phoneNumberHelperText: '' });
        this.setState({ phoneNumberError: false });
        break;
      }
      case 'building': {
        this.setState({ building: val });

        if (val.length >  128) {
          this.setState({ buildingHelperText: '文字数が多いです' });
          this.setState({ buildingError: true });
          break;
        }

        this.setState({ buildingHelperText: '' });
        this.setState({ buildingError: false });
        break;
      }
      case 'faxNumber': {
        this.setState({ faxNumber: val });

        if (val.length >  16) {
          this.setState({ faxNumberHelperText: '文字数が多いです' });
          this.setState({ faxNumberError: true });
          break;
        }

        this.setState({ faxNumberHelperText: '' });
        this.setState({ faxNumberError: false });
        break;
      }
      case 'mail': {
        this.setState({ mail: val });

        if (val.length >  128) {
          this.setState({ mailHelperText: '文字数が多いです' });
          this.setState({ mailError: true });
          break;
        }

        this.setState({ mailHelperText: '' });
        this.setState({ mailError: false });
        break;
      }
      case 'remarks': {
        this.setState({ remarks: val });

        if (val.length >  128) {
          this.setState({ remarksHelperText: '文字数が多いです' });
          this.setState({ remarksError: true });
          break;
        }

        this.setState({ remarksHelperText: '' });
        this.setState({ remarksError: false });
        break;
      }
      default:
        break;
    }
  }

  handleToAboutPage() {
    const { dispatch } = this.props;
    dispatch(moveTo.Sample());
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };
  handleClose(index) {
    this.setState({ displayColorPicker: false });
  }

  handleChange = color => {
    this.setState({ setColor: color.hex });
  };

  getPaymentOptions = () => {

    const options = [];
    const menuItems = [];

    options.push(30);
    options.push(45);
    options.push(60);
    options.push(75);

    for (const i in options) {
      menuItems.push(
        <MenuItem
          value={options[i]}
          key={options[i]}
          >
          {options[i]}
        </MenuItem>
      );
    }

    return menuItems;
  };

  // タイトル域
  render() {
    const { classes } = this.props;

    const list = this.getPaymentOptions();
    const titleDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>
          <Box p={1} className={classes.titleHeaderColor}>
            取引先入力画面
          </Box>
        </Box>
      </Box>
    );

    // 入力域
    const inputDiv = (
      <Box display={`flex`}>
        <Box flexGrow={1}>

          <Box p={2} className={classes.inputField}>

            <Grid container spacing={2}>

            <Grid item xs={2}>
                <Box>
                  <InputLabel ref="<Box>"id="company_person_id_label">取引先ID</InputLabel>
                  <TextField
                    labelId="company_id_label"
                    id="companyId"
                    name="companyId"
                    variant="outlined"
                    size="small"
                    className={classes.inputText}
                    value={this.state.companyId}
                    error={this.state.companyIdError}
                    onChange={this.onChangeField.bind(this)}
                    disabled={this.state.isUpdate} 
                    InputProps={
                      {
                        classes: {
                          input: classes.inputTextfontSize,
                        },
                      }
                    }
                    helperText={this.state.companyIdHelperText}
                    // helperTextStyle
                    FormHelperTextProps={
                      {
                        style: {  
                          backgroundColor: '#bbdefb',
                          margin:0,
                          borderBottom:0,
                          paddingTop:5,
                          paddingLeft:5,
                          fontSize:15
                        }
                      }
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box>
                  <InputLabel ref="<Box>" id="company_name_label">取引先名</InputLabel>
                  <TextField
                    labelId="company_name_label"
                    id="companyNameLabel"
                    name="companyName"
                    variant="outlined"
                    size="small"
                    className={classes.inputText}
                    value={this.state.companyName}
                    error={this.state.companyNameError}
                    helperText={this.state.companyNameHelperText}
                    onChange={this.onChangeField.bind(this)}
                    InputProps={{
                      classes: {
                        input: classes.inputTextfontSize,
                      },
                    }}
                    // helperTextStyle
                    FormHelperTextProps={
                      {
                        style: {  
                          backgroundColor: '#bbdefb',
                          margin:0,
                          borderBottom:0,
                          paddingTop:5,
                          paddingLeft:5,
                          fontSize:15
                        }
                      }
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <InputLabel ref="<Box>"id="company_person_name_label">担当者名</InputLabel>
                  <TextField
                    labelId="company_person_name_label"
                    id="companyPersonName"
                    name="companyPersonName"
                    variant="outlined"
                    size="small"
                    className={classes.inputText}
                    value={this.state.companyPersonName}
                    error={this.state.companyPersonNameError}
                    helperText={this.state.companyPersonNameHelperText}
                    onChange={this.onChangeField.bind(this)}
                    InputProps={{
                      classes: {
                        input: classes.inputTextfontSize,
                      },
                    }}
                    // helperTextStyle
                    FormHelperTextProps={
                      {
                        style: {  
                          backgroundColor: '#bbdefb',
                          margin:0,
                          borderBottom:0,
                          paddingTop:5,
                          paddingLeft:5,
                          fontSize:15
                        }
                      }
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box>
                  <InputLabel ref="<Box>"id="payment_site_label">入金サイト</InputLabel>
                  <Select
                    labelId="payment_site_label"
                    id="paymentSite"
                    name="paymentSite"
                    variant="outlined"
                    size="small"
                    value={this.state.paymentSite}
                    error={this.state.paymentSiteError}
                    onChange={this.onChangeField.bind(this)}
                    className={classes.pullDown}
                    style={{ backgroundColor: "#FFFFFF" }}>
                    <MenuItem value="" key="">&ensp;</MenuItem>
                    {list}
                </Select>
                </Box>
              </Grid>

              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>

              <Grid item xs={2}>
                <Box>
                  <InputLabel ref="<Box>"id="postal_number_label">郵便番号</InputLabel>
                  <TextField
                    labelId="postal_number_label"
                    id="postalNumber"
                    name="postalNumber"
                    variant="outlined"
                    size="small"
                    className={classes.inputText}
                    value={this.state.postalNumber}
                    error={this.state.postalNumberError}
                    helperText={this.state.postalNumberHelperText}
                    onChange={this.onChangeField.bind(this)}
                    InputProps={{
                      classes: {
                        input: classes.inputTextfontSize,
                      },
                    }}
                    // helperTextStyle
                    FormHelperTextProps={
                      {
                        style: {  
                          backgroundColor: '#bbdefb',
                          margin:0,
                          borderBottom:0,
                          paddingTop:5,
                          paddingLeft:5,
                          fontSize:15
                        }
                      }
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box>
                  <InputLabel ref="<Box>"id="residence_label">住所</InputLabel>
                  <TextField
                    labelId="residence_label"
                    id="residence"
                    name="residence"
                    variant="outlined"
                    size="small"
                    className={classes.inputText}
                    value={this.state.residence}
                    error={this.state.residenceError}
                    helperText={this.state.residenceHelperText}
                    onChange={this.onChangeField.bind(this)}
                    InputProps={{
                      classes: {
                        input: classes.inputTextfontSize,
                      },
                    }}
                    // helperTextStyle
                    FormHelperTextProps={
                      {
                        style: {  
                          backgroundColor: '#bbdefb',
                          margin:0,
                          borderBottom:0,
                          paddingTop:5,
                          paddingLeft:5,
                          fontSize:15
                        }
                      }
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box>
                  <InputLabel ref="<Box>"id="building_label">建物</InputLabel>
                  <TextField
                    labelId="building_label"
                    id="building"
                    name="building"
                    variant="outlined"
                    size="small"
                    className={classes.inputText}
                    value={this.state.building}
                    error={this.state.buildingError}
                    helperText={this.state.buildingHelperText}
                    onChange={this.onChangeField.bind(this)}
                    InputProps={{
                      classes: {
                        input: classes.inputTextfontSize,
                      },
                    }}
                    // helperTextStyle
                    FormHelperTextProps={
                      {
                        style: {  
                          backgroundColor: '#bbdefb',
                          margin:0,
                          borderBottom:0,
                          paddingTop:5,
                          paddingLeft:5,
                          fontSize:15
                        }
                      }
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={2}>
                <InputLabel ref="<Box>"id="color_code_label">色コード</InputLabel>
                {this.colorPicker()}
              </Grid>

              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>

              <Grid item xs={2}>
                <Box>
                  <InputLabel ref="<Box>"id="phone_number_label">電話番号</InputLabel>
                  <TextField
                    labelId="phone_number_label"
                    id="phoneNumberLabel"
                    name="phoneNumber"
                    variant="outlined"
                    size="small"
                    className={classes.inputText}
                    value={this.state.phoneNumber}
                    error={this.state.phoneNumberError}
                    helperText={this.state.phoneNumberHelperText}
                    onChange={this.onChangeField.bind(this)}
                    InputProps={{
                      classes: {
                        input: classes.inputTextfontSize,
                      },
                    }}
                    // helperTextStyle
                    FormHelperTextProps={
                      {
                        style: {  
                          backgroundColor: '#bbdefb',
                          margin:0,
                          borderBottom:0,
                          paddingTop:5,
                          paddingLeft:5,
                          fontSize:15
                        }
                      }
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box>
                  <InputLabel ref="<Box>"id="fax_number_label">FAX番号</InputLabel>
                  <TextField
                    labelId="fax_number_label"
                    id="faxNumberLabel"
                    name="faxNumber"
                    variant="outlined"
                    size="small"
                    className={classes.inputText}
                    value={this.state.faxNumber}
                    error={this.state.faxNumberError}
                    helperText={this.state.faxNumberHelperText}
                    onChange={this.onChangeField.bind(this)}
                    InputProps={{
                      classes: {
                        input: classes.inputTextfontSize,
                      },
                    }}
                    // helperTextStyle
                    FormHelperTextProps={
                      {
                        style: {  
                          backgroundColor: '#bbdefb',
                          margin:0,
                          borderBottom:0,
                          paddingTop:5,
                          paddingLeft:5,
                          fontSize:15
                        }
                      }
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={8}>
               
                  <InputLabel ref="<Box>"id="mail_label">メールアドレス</InputLabel>
                  <TextField
                    labelId="mail_label"
                    id="mail"
                    name="mail"
                    variant="outlined"
                    size="small"
                    className={classes.inputText}
                    value={this.state.mail}
                    error={this.state.mailError}
                    helperText={this.state.mailHelperText}
                    onChange={this.onChangeField.bind(this)}
                    InputProps={{
                      classes: {
                        input: classes.inputTextfontSize,
                      },
                    }}
                    // helperTextStyle
                    FormHelperTextProps={
                      {
                        style: {  
                          backgroundColor: '#bbdefb',
                          margin:0,
                          borderBottom:0,
                          paddingTop:5,
                          paddingLeft:5,
                          fontSize:15
                        }
                      }
                    }
                  />
             
              </Grid>
              
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <Box>
                  <InputLabel ref="<Box>"id="remarks_label">備考</InputLabel>
                  <TextField
                    labelId="remarks_label"
                    id="remarks"
                    name="remarks"
                    variant="outlined"
                    size="small"
                    className={classes.inputText}
                    value={this.state.remarks}
                    error={this.state.remarksError}
                    helperText={this.state.remarksHelperText}
                    onChange={this.onChangeField.bind(this)}
                    InputProps={{
                      classes: {
                        input: classes.inputTextfontSize,
                      },
                    }}
                    // helperTextStyle
                    FormHelperTextProps={
                      {
                        style: {
                          backgroundColor: '#bbdefb',
                          margin:0,
                          borderBottom:0,
                          paddingTop:5,
                          paddingLeft:5,
                          fontSize:15
                        }
                      }
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      
    );

  /***********************************************************************************************
  * 画面下部の「登録」「キャンセル」のJSXとpropsの設定のメソッドです。
  ***********************************************************************************************/
    const buttonDiv = (
      <Grid container spacing={3} /*justify="center"*/ style={{textAlign:'center',marginTop:'15px'}}>
        <Grid item xs={6} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            endIcon={<PostAddIcon />}
            onClick={this.opneDialog.bind(this, this.state.isUpdate ? this.update.bind(this) : this.insert.bind(this), '登録しますか？')}
          >
            登録
          </Button>
        </Grid>

        <Grid item xs={6} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            endIcon={<ExitToApp />}
            onClick={this.cancel.bind(this)}
          >
            戻る
          </Button>
        </Grid>
      </Grid>
    );

    return (
      <Box style={{ height: "100vh", width: "100vw" }}>
        <Header value={this.state.headerErrorMessage}/>
        <Alert 
          id="successAlert"
          style={{display:'none'}}
          className={classes.successAlertStyle}
          severity="success">
            {this.state.successMessage}
        </Alert>
        <Box
          style={{
            height: "calc(100% - 96px)",
            width: "calc(100% - 32px)",
            padding: "16px",
            overflow: "auto"
          }}
        >
          <Box minWidth={975}>
            {titleDiv}
            {inputDiv}
            {buttonDiv}
          </Box>
          <AlertDialog />
        </Box>
      </Box>
    );
  }

// ダイアログ表示
opneDialog(func, message) {

  var Dialog = document.getElementById('dialogBox');
  var okButton = document.getElementById('dialogOkButton');
  var cancelButton = document.getElementById('dialogCancelButton');

  document.getElementById('dialogMessage').innerHTML = message;

  // ダイアログ表示
  Dialog.style.display = '';
  // OK 押下の処理
  okButton.onclick = func;
  // cancel　押下の処理
  cancelButton.onclick = function () {
    Dialog.style.display = 'none';
  };
}

  colorPicker = () => {
    const { classes } = this.props;
    return (
      <Box style={{ position: "relative",}}>
        <Box display='flex'>
          <Box display='flex' className={classes.colorBox}>
            <Box className={classes.boxColor}
            style={{ backgroundColor: this.state.setColor }}
            ></Box>
            <Box
                labelId="color_code_label"
                id="colorCode"
                name="colorCode"
                variant="outlined"
                size="small"
                className={classes.inputText + ' ' + classes.colorText}
                onClick={this.handleClick}        
            >
            {this.state.setColor}
            </Box>
          </Box>
        </Box>

        {this.state.displayColorPicker ? (
          <Box
            style={{
              position: "absolute",
              zIndex: "2",
              right: "0",
              top: "45px"
            }}
          >
            <Box
              style={{
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px"
              }}
              onClick={this.handleClose}
            />
            <TwitterPicker
              color={this.state.setColor}
              triangle={"top-right"}
              onChange={this.handleChange.bind(this)}
            />
          </Box>
        ) : null}
      </Box>
    );
  };

  /***********************************************************************************************
  * 取引先一覧画面の変更ボタンから遷移した場合
  ***********************************************************************************************/
  async search(pram) {

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/company/list',
      {
        pageIndex: 0,
        pageSize: 100,
        companyId: pram,
      },
      {
        param,
        withCredentials: true,
      }
    ).then(response => {
      // 成功時
      const result = response.data.list[0];

      this.setState({ companyId: result.companyId });
      this.setState({ companyName: result.companyName });
      this.setState({ companyPersonName: result.personName });
      this.setState({ postalNumber: result.postalNumber });
      this.setState({ residence: result.residence });
      this.setState({ building: result.building });
      this.setState({ phoneNumber: result.phoneNumber });
      this.setState({ mail: result.mailAddress });
      this.setState({ setColor: result.colorCode });
      this.setState({ paymentSite: result.paymentSite });
      this.setState({ faxNumber: result.faxNumber });
      this.setState({ remarks: result.remarks });
      

      this.setState({ isUpdate: true});
      this.forceUpdate();
    }).catch(e =>{
      console.log(e);
      this.setState({ headerErrorMessage: "取引先データの登録に失敗しました" });
    });;
  }

    /***********************************************************************************************
  * 取引先一覧画面の登録ボタンから遷移した場合
  ***********************************************************************************************/
  async insert() {
    // 検索条件の項目
    const _companyId = this.state.companyId || null;
    const _companyName = this.state.companyName || null;
    const _companyPerson = this.state.companyPersonName || null;
    const _postalNumber = this.state.postalNumber || null;
    const _residence = this.state.residence || null;
    const _building = this.state.building || null;
    const _phoneNumber = this.state.phoneNumber || null;
    const _faxNumber = this.state.faxNumber || null;
    const _mail = this.state.mail || null;
    const _colorCode = this.state.setColor || null;
    const _paymentSite = this.state.paymentSite || null;
    const _remarks = this.state.remarks || null;

    await this.errorStateClear();
    const isFailed = this.validate(
      _companyId,
      _companyName,
      _companyPerson,
      _postalNumber,
      _residence,
      _building,
      _phoneNumber,
      _mail,
      _colorCode,
      _paymentSite
    );

    if(isFailed){
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }
    const param = Cookies.get('token');

    axios.post(REQUEST_PATH + 'api/company/create',
      {
        pageIndex: 0,
        pageSize: 100,
        companyId: _companyId,
        companyName:_companyName,
        personName:_companyPerson,
        postalNumber:_postalNumber,
        residence:_residence,
        building:_building,
        phoneNumber:_phoneNumber,
        faxNumber:_faxNumber,
        mailAddress:_mail,
        colorCode:_colorCode,
        paymentSite:_paymentSite,
        remarks:_remarks,
      },
      {
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ isUpdate: true});
      this.openeSuccessAlert("登録しました");
      this.forceUpdate();
    }).catch(e =>{
      // 失敗時
      console.log(e);
      this.setState({ headerErrorMessage: "取引先データの登録に失敗しました" });
      return;
    });
    document.getElementById('dialogBox').style.display = 'none';
  };

  /***********************************************************************************************
  * 変更ボタンをクリックされた時に実行されるメソッド
  ***********************************************************************************************/
  async update() {
    
    const _companyId = this.state.companyId || null;
    const _companyName = this.state.companyName || null;
    const _companyPerson = this.state.companyPersonName || null;
    const _postalNumber = this.state.postalNumber || null;
    const _residence = this.state.residence || null;
    const _building = this.state.building || null;
    const _phoneNumber = this.state.phoneNumber || null;
    const _faxNumber = this.state.faxNumber || null;
    const _mail = this.state.mail || null;
    const _colorCode = this.state.setColor || null;
    const _paymentSite = this.state.paymentSite || null;
    const _remarks = this.state.remarks || null;

    this.errorStateClear();
    const isFailed = this.validate(
      _companyId,
      _companyName,
      _companyPerson,
      _postalNumber,
      _residence,
      _building,
      _phoneNumber,
      _mail,
      _colorCode,
      _paymentSite
    );
    
    if(isFailed){
      return;
    }
    const param = Cookies.get('token');

    await axios.put(REQUEST_PATH + 'api/company/update', 
    {
      companyId: _companyId,
      companyName:_companyName,
      personName:_companyPerson,
      postalNumber:_postalNumber,
      residence:_residence,
      building:_building,
      phoneNumber:_phoneNumber,
      faxNumber:_faxNumber,
      mailAddress:_mail,
      colorCode:_colorCode,
      paymentSite:_paymentSite,
      remarks:_remarks,
    },
    {
      param,
      withCredentials: true,
    }).then(response => {
      // 成功時
      this.openeSuccessAlert("更新しました");
      this.forceUpdate();
    }).catch(e =>{
      // 失敗時
      console.log(e);
      this.setState({ headerErrorMessage: "取引先データの更新に失敗しました" });
      return;
    });
    document.getElementById('dialogBox').style.display = 'none';
  }
  
  // キャンセルボタン押下時 TODO
  cancel() {
    const { dispatch } = this.props;
    //dispatch(moveTo.CompanyMaster());
    const a = this.props.location.state.currentPage;
    const b = this.props.location.state.totalPageCount;
    this.props.history.push({ pathname: '/companyMaster', state: {currentPage:a,totalPageCount:b }});
  }
  // エラーステート初期化
  async errorStateClear(){
    this.setState({companyIdError:false});
    this.setState({companyIdHelperText:''});
    this.setState({companyNameError:false});
    this.setState({companyNameEHelperText:''});
    this.setState({companyPersonError:false});
    this.setState({companyPersonHelperText:''});
    this.setState({postalNumberError:false});
    this.setState({postalNumberHelperText:''});
    this.setState({residenceError:false});
    this.setState({residenceHelperText:''});
    this.setState({phoneNumberError:false});
    this.setState({phoneNumberHelperText:''});
    this.setState({mailError:false});
    this.setState({mailHelperText:''});
    this.setState({colorCodeError:false});
    this.setState({paymentSiteError:false});
  }

  // 必須チェック処理
  validate(companyId,companyName,companyPerson,postalNumber,residence,building,phoneNumber,mail,colorCode,paymentSite) {
    var isFailed = false;

    if(companyId === null || companyId ==='undefined'||companyId ==='' || isNaN(companyId)) {
      isFailed = true;
      this.setState({companyIdError:true});
      this.setState({ companyIdHelperText: '例:001' });
    }
    if(companyName=== null || companyName ==='undefined'||companyName ==='') {
      isFailed = true;
      this.setState({companyNameError:true});
      this.setState({ companyNameHelperText: '取引先名を入力してください' });
    }
    if(companyPerson=== null || companyPerson ==='undefined'||companyPerson ==='') {
      isFailed = true;
      this.setState({companyPersonNameError:true});
      this.setState({companyPersonNameHelperText: '取引先担当を入力してください' });
    }
    if(postalNumber === null || postalNumber === 'undefined'||postalNumber === '') {
      isFailed = true;
      this.setState({postalNumberError:true});
      this.setState({postalNumberHelperText: '郵便番号を入力してください' });
    }
    if(residence === null || residence ==='undefined' || residence ==='') {
      isFailed = true;
      this.setState({residenceError:true});
      this.setState({residenceHelperText: '住所を入力してください' });
    }
    if(building === null || building ==='undefined' || building ==='') {
      //isFailed = true;
      //this.setState({buildingError:true});
      //this.setState({buildingHelperText: '建物名を入力してください' });
      this.setState({building:' '});
    }

    if(phoneNumber === null || phoneNumber === 'undefined' || phoneNumber === '') {
      isFailed = true;
      this.setState({phoneNumberError:true});
      this.setState({phoneNumberHelperText: '電話番号を入力してください' });
    }
    if(mail === null || mail === 'undefined' || mail ==='') {
      isFailed = true;
      this.setState({mailError:true});
      this.setState({mailHelperText: '電話番号を入力してください' });
    }
    if(colorCode === null || colorCode ==='undefined' || colorCode === '') {
      isFailed = true;
      this.setState({colorCodeError:true});
    }
    if(paymentSite === null || paymentSite ==='undefined' || paymentSite === '') {
      isFailed = true;
      this.setState({paymentSiteError:true});
    }

    // 範囲チェック
    if(!isFailed){
      // 呼び出し
      isFailed = this.validateRange(companyId,companyName,companyPerson,postalNumber,residence,building,phoneNumber,mail);
    }

    return isFailed;
  }

  validateRange(companyId,companyName,companyPerson,postalNumber,residence,building,phoneNumber,mail) {
    var isFailed = false;
    if(companyId.length !== 3) {
      isFailed = true;
      this.setState({companyIdError:true});
      this.setState({ companyIdHelperText: '例:001' });
    }
    if(companyName.length > 128) {
      isFailed = true;
      this.setState({companyNameError:true});
      this.setState({ companyNameHelperText: '文字数が多いです' });
    }
    if(companyPerson.length > 32) {
      isFailed = true;
      this.setState({companyPersonNameError:true});
      this.setState({companyPersonNameHelperText: '文字数が多いです' });
    }
    if(postalNumber.length > 12) {
      isFailed = true;
      this.setState({postalNumberError:true});
      this.setState({postalNumberHelperText: '文字数が多いです' });
    }
    if(residence.length > 128) {
      isFailed = true;
      this.setState({residenceError:true});
      this.setState({residenceHelperText: '文字数が多いです' });
    }
    if(building.length > 128) {
      isFailed = true;
      this.setState({buildingError:true});
      this.setState({buildingHelperText: '文字数が多いです' });
    }

    if(phoneNumber.length > 16) {
      isFailed = true;
      this.setState({phoneNumberError:true});
      this.setState({phoneNumberHelperText: '文字数が多いです' });
    }
    if(mail.length > 128) {
      isFailed = true;
      this.setState({mailError:true});
      this.setState({mailHelperText: '文字数が多いです' });
    }
    return isFailed;
  }

  
  // 成功アラートの表示
  openeSuccessAlert(msg){

    document.getElementById('successAlert').style.display = '';
    this.setState({ successMessage: msg});

    // 2秒で消えるようにする。
    setTimeout(() => {
      document.getElementById('successAlert').style.display = 'none';
    },2000);
  }

  static get propTypes() {
    return {};
  }

  // Styleの設定
  static get styles() {
    return theme => ({

      inputText:{
        width:'100%', 
        boxSizing:'border-box',
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
      },

      colorText:{
        width:'150px', 
        fontSize:'20px',
        paddingTop: '4%'
      },

      inputTextfontSize:{
        fontSize:'20px', 
      },

      titleHeaderColor: {
        border: "1px solid #525457",
        backgroundColor: "#1976d2",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 20,
        fontWeight: "bold",
        borderRadius:"5px"
      },

      inputField: {
        border: "1px solid",// #bbdefb",
        backgroundColor: "#bbdefb",
        color: "#4A4A4B",
        //fontSize: 16,
        justifyContent: "space-between",
        display:'flex',
        //alignItems: "center",
        borderRadius:"5px"
      },

      buttonColor: {
        width: 150,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        // color: "#E1E1E1"
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing:10
      },
      pullDown:{
        backgroundColor: "#FFFFFF",
        width: 200,
        borderRadius: 5,
        height:40,
        fontSize:'20px', 
        //maxHeight: "28px"
      },
      boxColor: {
        minHeight: 20,
        minWidth: 20,
        maxHeight: 20,
        maxWidth: 20,
        marginLeft: 15,
        marginRight: 6,
        marginTop:'6%',
        border: "1px solid #4A4A4B",
        borderRadius: "6px"
      },
      colorBox:{
        backgroundColor:'#FFFFFF',
        justifyContent: "space-between",
        borderRadius:"5px",
        height:'45px',
      },
      successAlertStyle: {
        position: 'relative',
        justifyContent: 'center',
        width: '100%',
        backgroundColor:'#80cbc4',
        fontSize:'1rem'
      },
    });
  }
}

// redux/index.js プロパティ
const mapStateToProps = ({ companyMasterRegist: { list } }) => ({ model: list });
const mapDispatchToProps = dispatch => ({ dispatch });
const { styles } = Component;

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
