import React from "react";
import moment from "moment";
import "moment/locale/ja";
import { connect } from "react-redux";
import axios from 'axios';
import "../App.css";
import { Box, Button } from "@material-ui/core";

import Schedule from "./Schedule";
import REQUEST_PATH from "../components/Common";
import { moveTo } from "../actions";

import PropTypes from "prop-types";
import Cookies from "js-cookie";

const propTypes = {
  calendar1: PropTypes.func
};

moment.locale("ja");

class ScheduleApp extends React.Component {
  constructor(props) {
    super(props);
    const date = moment(this.props.date,'YYYY/MM/DD');
    var startdate = Number(date.format('x'));
    var enddate = Number(date.add(1,'M').add(15,'d').format('x'));
    this.state = {
      start: startdate,
      end: enddate,
      // products: sampleProducts,
      // orders: sampleOrders,
      date:this.props.date,
      products: [],
      orders: [],
    };
  }

  async componentDidMount() {
    const date = moment(this.state.date,'YYYY/MM/DD');
    const param = Cookies.get('token');
    const [{ data: products }, { data: orders }] = await Promise.all([axios.post(
      REQUEST_PATH+`api/product-shedule/list?date=${date.format('YYYY-MM-DD')}`,
      {
        pageIndex: 0,
        pageSize: 100
      },{
        param,
        withCredentials: true,
      }
    ), axios.get(REQUEST_PATH+`api/schedule/list?year=${date.format(
      `YYYY`
    )}&month=${date.format(`MM`)}`,{
      param,
      withCredentials: true,
    })]);
    const productList = products.list;
    this.setState({ products: productList, orders:orders })
  }

  clickButton() {
    return this.props.calendar1(this.state.date);
  }
  clickButton2() {
    return this.props.calendar2(this.state.date);
  }

  doubleClickOrder( orderNumber ) {
    const { dispatch } = this.props;
    //dispatch(moveTo.SampleOrder({ orderNumber }));
    dispatch(moveTo.OrderRegist({ orderNumber }));
  }
  async prevMonth() {
    var date = moment(this.state.date,'YYYY/MM/DD');
    var newDate = date.add(-1,'M').format("YYYY/MM/DD");
    var startdate = Number(date.format('x'));
    var enddate = Number(date.add(1,'M').add(15,'d').format('x'));
    await this.setState({ date: newDate });
    await this.clickButton2();
  }
  async nextMonth() {
    var date = moment(this.state.date,'YYYY/MM/DD');
    var newDate = date.add(1,'M').format("YYYY/MM/DD");
    var startdate = Number(date.format('x'));
    var enddate = Number(date.add(1,'M').add(15,'d').format('x'));
    await this.setState({ date: newDate });
    await this.clickButton2();
  }

  render() {
    const { start, end, products, orders } = this.state;
    const date = moment(this.state.date);
    return (
      <Box>
        <Box  display={`flex`} justifyContent={`center`} alignItems="center" display={`flex`}>
          <Button variant={`outlined`} onClick={this.prevMonth.bind(this)}>
            ＜
          </Button>
          <Box marginLeft="20px" marginRight="20px" fontSize="1.3em">
            {date.format("YYYY")}年{date.format("MM")}月
          </Box>
          <Button variant={`outlined`} onClick={this.nextMonth.bind(this)}>
            ＞
          </Button>
        </Box>
      <div className="App">
        
        <Schedule
          na
          start={start}
          end={end}
          products={products}
          orders={orders}
          onList={() => {
            this.clickButton();
          }}
          onDetail={({ name, type }) =>
            
                type === `product` ? null : this.doubleClickOrder(name)
              
          }
        />
      </div>
      </Box>
    );
  }
}
ScheduleApp.propTypes = propTypes;

const mapStateToProps = ({ calendar: { list } }) => ({ orders: list });
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleApp);
