import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import Cookies from "js-cookie";

import { signedIn } from '../actions';
import { authenticate, getUser } from '../utils';
import { moveTo } from "../actions";
import base64 from "base64-js";
import axios from "axios";
import REQUEST_PATH from "../components/Common";
import icon from '../containers/lenabon_src.png'

class Component extends React.Component {

  state = {
    username:"",
    password:"",
    // ログイン失敗時対応
    usernameError:false,
    passwordError:false,
    errorrHelperText:"",
  }

  // async signin() {
  //   const { dispatch } = this.props;
  //   const auth = await authenticate({ user: this.state.username, password: this.state.password });
  //   const { token } = auth;
  //   Cookies.set(`token`, token);
  //   // dispatch(authenticated(auth));
  //   const user = await getUser(token);
  //   user.name = this.state.username;
  //   dispatch(signedIn(user));
  //   dispatch(moveTo.Top());
  // }

  async signin() {

    const params = new URLSearchParams();

    params.append('username', this.state.username);
    params.append('password', window.btoa(this.state.username+":"+this.state.password));

    let getLogin;
    let userId;

    await axios.post(
      REQUEST_PATH + 'api/login',
      params,
      {
        withCredentials: true
      }
    ).then(response => {
      getLogin = response.data;
      console.log(response);
      /*-----------------------------ここら辺あとでちゃんと使う-----------------------------*/
      const { dispatch } = this.props;
      const auth = authenticate({ user: this.state.username, password: this.state.password });
      const { token } = auth;
      const authdata = {
        auth:{
          username:this.state.username,
          password:window.btoa(this.state.username+":"+this.state.password)
        }
      }
      const data = {
        auth:{
          username:this.state.username,
          password:window.btoa(this.state.username+":"+this.state.password)
        }
      }
      Cookies.set('token', data);
      // dispatch(authenticated(auth));
      const user = getUser(token);
      /*-----------------------------ここら辺あとでちゃんと使う-----------------------------*/
      user.name = this.state.username;
      user.id = userId;
      const param = Cookies.get('token');
      dispatch(signedIn(params));
      dispatch(moveTo.Top());
    }).catch(e => {
      console.log(e);     
      this.setState({
        usernameError:true,
        passwordError:true,
        errorrHelperText:"ユーザ名またはパスワードが違います"
      });
    });
  }

  onChangeField(evt) {

    const str = evt.target.value;
    let result = false;
    for(var i=0; i < str.length; i++){
        /* 1文字ずつ文字コードをエスケープし、その長さが4文字以上なら全角 */
        var len = escape(str.charAt(i)).length;
        if(len >= 4) {
            result = true;
        }
    }

    if(!result){
      this.setState({username:evt.target.value, usernameError:false, passwordError:false});
    }
  }

  render() {
    const token = Cookies.get(`token`);
    const { classes } = this.props;

    return !token ? (
      <div className={classes.signinBody}>
        {/*<h1 className={classes.title}>Lenabon</h1>*/}
        <div>
          <img style={{width:600}} src={`${icon}`} />
        </div>

        <div style={{marginTop:30}}>
          <div>
            <TextField
              className={classes.inputStyle}
              type="text"
              name="user"
              ref="user"
              label="user"
              variant="outlined"
              size={"medium"}
              value={this.state.username}
              onChange={this.onChangeField.bind(this)}
              //onChange={event => this.setState({username:event.target.value, usernameError:false, passwordError:false})}
              error={this.state.usernameError}
            />
          </div>
          <div className={classes.fieldPass}>
            <TextField
              className={classes.inputStyle}
              type="password"
              ref="pass"
              label="Password"
              variant="outlined"
              size={"medium"}
              value={this.state.password}
              onChange={event => this.setState({password:event.target.value, usernameError:false, passwordError:false})}
              error={this.state.passwordError}
            />
          </div>

          <div className={classes.fieldButton}>
            <Button 
              variant="contained"
              size="large"
              color="primary"
              className={classes.buttonStyle}
              onClick={this.signin.bind(this)}>
                ログイン
            </Button>
          </div>

          <p className={classes.failedMessage}>
            {this.state.errorrHelperText}
          </p>

        </div>
      </div>
    ) : null;
  }
  static get propTypes() {
    return {};
  }

  static get styles() {
      // Styleの設定
    return theme => ({

      signinBody:{
        width: '600px',
        margin: 'auto',
        marginTop: '6%',
        textAlign: 'center',
      },
      title:{
        color: '#42a5f5',
        fontSize: 50,
      },
      fieldPass:{
        marginTop: 20
      },
      fieldButton:{
        marginTop: 25
      },
      inputStyle:{
        width: 430,
        
      },
      buttonStyle: {
        width: 430,
        height: 60,
        fontSize:22,
        borderRadius: 10,
      },
      failedMessage: {
        marginTop:'15px',
        borderBottom:0,
        color:'red'
      }
    });
  }

  static get mapStateToProps() {
    return (state) => ({});
  }

  static get mapDispatchToProps() {
    return (dispatch) => ({ dispatch });
  }
}

// TODO 分割 ContainerとComponent
const { styles, mapStateToProps, mapDispatchToProps } = Component;
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
