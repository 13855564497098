import { createAction } from 'redux-actions';

const LIST_LICENSE = `LIST_LICENSE`;
const PAGE_COUNT_LICENSE = `PAGE_COUNT_LICENSE`;
const CURRENT_PAGE_LICENSE = `CURRENT_PAGE_LICENSE`;
const HITS_COUNT_LICENSE = `HITS_COUNT_LICENSE`;

export const listLicense = createAction(LIST_LICENSE, (list) => (list));
// ページネーション対応
export const pageCountLicense = createAction(PAGE_COUNT_LICENSE, (totalPageCount) => (totalPageCount));
export const currentPageLicense = createAction(CURRENT_PAGE_LICENSE, (currentPage) => (currentPage));
// ヒット件数対応
export const hitsCountLicense = createAction(HITS_COUNT_LICENSE, (hitsCount) => (hitsCount));