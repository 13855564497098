import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { sampleReducer as sample } from "./sample";
import { user } from "./auth";
import { calendarReducer as calendar } from "./calendar";
import { productModelReducer as productModel } from "./productModel";
import { orderRegistReducer as orderRegist } from "./orderRegist";
import { personMasterReducer as personMaster } from "./personMaster";
import { typeMasterReducer as typeMaster } from "./typeMaster";
import { statusMasterReducer as statusMaster } from "./statusMaster";
import { licenseMasterReducer as licenseMaster } from "./licenseMaster";
import { productStockReducer as productStock } from "./productStock";
import { companyMasterReducer as companyMaster } from "./companyMaster";
import { companyMasterRegistReducer as companyMasterRegist } from "./companyMasterRegist";
import { licenseTypeMasterReducer as licenseTypeMaster } from "./licenseTypeMaster";
import { orderDisplayStatusMasterReducer as orderDisplayStatusMaster } from "./orderDisplayStatusMaster";
import { pickupMasterReducer as pickupMaster } from "./pickupMaster";
import { productInputReducer as productInput } from "./productInput";
import { productListReducer as productList } from "./productList";
import { usedHistoryReducer as usedHistory } from "./usedHistory";
import { repairHistoryReducer as repairHistory } from "./repairHistory";
import { orderReducer as order } from "./order";
import { salesReducer as sales } from "./sales";
import { userManageReducer as users } from "./userManage";

// ページネーション対応
import { orderReducerPageCount as order_pageCount } from "./order";
import { orderReducerCurrentPageOrder as order_currentPage } from "./order";
import { companyMasterReducerPageCount as companyMaster_pageCount } from "./companyMaster";
import { companyMasterReducerCurrentPage as companyMaster_currentPage } from "./companyMaster";
import { ProductListReducerPageCount as productList_pageCount } from "./productList";
import { ProductListReducerCurrentPage as productList_currentPage } from "./productList";
import { licenseMasterReducerPageCount as licenseMaster_pageCount } from "./licenseMaster";
import { licenseMasterReducerCurrentPage as licenseMaster_currentPage } from "./licenseMaster";

import { usedHistoryReducerPageCount as usedHistory_pageCount } from "./usedHistory";
import { usedHistoryReducerCurrentPage as usedHistory_currentPage } from "./usedHistory";
import { repairHistoryReducerPageCount as repairHistory_pageCount } from "./repairHistory";
import { repairHistoryReducerCurrentPage as repairHistory_currentPage } from "./repairHistory";

// ヒット件数対応
import { ProductListReducerHitsCount as productList_hitsCount } from "./productList";
import { companyMasterReducerHitsCount as companyMaster_hitsCount } from "./companyMaster";
import { licenseMasterReducerHitsCount as licenseMaster_hitsCount } from "./licenseMaster";
import { orderReducerHitsCountOrder as order_hitsCount } from "./order";

// 社数対応
import { salesReducerCompanyCount as sales_companyCount } from "./sales";

const reducers = history =>
  combineReducers({
    router: connectRouter(history),
    sample,
    user,
    calendar,
    productModel,
    productStock,
    personMaster,
    typeMaster,
    statusMaster,
    orderRegist,
    licenseMaster,
    companyMaster,
    companyMasterRegist,
    licenseTypeMaster,
    orderDisplayStatusMaster,
    pickupMaster,
    productInput,
    companyMasterRegist,
    productList,
    usedHistory,
    repairHistory,
    order,
    sales,
    users,
    // ページネーション対応
    order_pageCount,
    order_currentPage,
    companyMaster_pageCount,
    companyMaster_currentPage,
    licenseMaster_pageCount,
    licenseMaster_currentPage,
    productList_pageCount,
    productList_currentPage,
    usedHistory_pageCount,
    usedHistory_currentPage,
    repairHistory_pageCount,
    repairHistory_currentPage,

    // ヒット件数対応
    productList_hitsCount,
    companyMaster_hitsCount,
    order_hitsCount,
    licenseMaster_hitsCount,
    // 社数対応
    sales_companyCount
  });

export default reducers;
