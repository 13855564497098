import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles} from "@material-ui/core/styles";
import { Box, Fab, Checkbox, TextField, Select, MenuItem, InputLabel } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import axios from "axios";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Header from "../header/Header";
import moment from "moment";

import { moveTo, listSales, companyCountSales } from "../actions";
import REQUEST_PATH from "../components/Common";
import Cookies from "js-cookie";
import Autocomplete from '@material-ui/lab/Autocomplete';



class Component extends React.Component {
  state = {
    // 検索項目
    searchFiscal:4,
    searchStartYear: moment().year(),
    searchEndYear: moment().year(),
    searchCompanyName: '',
    searchCompanyId: '',
    searchCompanyPersonName:'',
    searchPersonName:'',
    searchStartYearMonth: moment().year(),
    searchStartMonth:1,
    searchEndYearMonth: moment().year(),
    searchEndMonth:1,
    
    // チェックボックスのステータスステータス
    checkboxYear:true,
    checkboxMonth:false,
    checkboxCompany:false,

    // 選択項目の活性非活性
    searchCompanyNameDisabled:false,
    searchCompanyPersonDisabled:false,
    searchPersonDisabled:false,

    company_list:[],
    person_list:[],
    order_display_status_list:[],
    //エラーメッセージ
    headerErrorMessage:"",

    // 初回表示時のフラグ
    isfirst:true,
    currentTbl:'tblYear',
    title:'',
  };

  constructor(props){
    super(props);

    // 取引先
    this.getCompanyListMenus();
    this.getPersonListMenus();
    this.getOrderListMenus();
  }

  // 取引先の取得
  async getCompanyListMenus() {

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/company/list',
      {
        pageIndex: 0,
        pageSize: 1000,
      },{
        param,
        withCredentials: true,
      }
    ).then( response => {
      this.setState({company_list:response.data.list});
    }).catch(e =>{
      console.log(e);
    });
  }

  async getPersonListMenus() {

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/person/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then( response => {
      this.setState({person_list:response.data.list});
    }).catch(e =>{
      console.log(e);
    });
  }

  // 注文表示ステータスの取得
  async getOrderListMenus() {

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/order-display-status/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then( response => {
      this.setState({order_display_status_list:response.data.list});
    }).catch(e =>{
      console.log(e);
    });
  }

  // 検索域のイベント
  onChangeSearchField(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

    /**       コンボボックス対応       */
    onChangeAutoCompletField(e,value) {
    
      if(value === null || value === undefined || value ===  ''){
        this.setState({searchCompanyName : ''});
      } else {
        this.setState({searchCompanyName:value.companyID});
      }
    }

  // チェックボックス押下時
  onChangeCheckBox(evt) {
    const targetName = evt.target.name;
    const _chekedYear = this.state.checkboxYear;
    const _chekedMonth = this.state.checkboxMonth;
    const _chekedCompany = this.state.checkboxCompany;

    switch(targetName){

      case 'checkBoxYear': {
        if(!_chekedYear)  {
          this.setState({checkboxYear:true});
          this.setState({checkboxMonth:false});
          this.setState({checkboxCompany:false});
          
          this.setState({
            searchCompanyNameDisabled:false,
            searchCompanyPersonDisabled:false,
          });      
        }
        break;
      }
      case 'checkBoxMonth': {
        if(!_chekedMonth)  {
          this.setState({checkboxYear:false});
          this.setState({checkboxMonth:true});
          this.setState({checkboxCompany:false});

          this.setState({
            searchCompanyNameDisabled:false,
            searchCompanyPersonDisabled:false,
          }); 
        }
        break;
      }
      case 'checkBoxCompany': {
        if(!_chekedCompany)  {
          this.setState({checkboxYear:false});
          this.setState({checkboxMonth:false});
          this.setState({checkboxCompany:true});

          this.setState({
            searchCompanyNameDisabled:true,
            searchCompanyPersonDisabled:true,
          });

          this.setState(
            {
              searchCompanyName: '',
              searchCompanyPersonName:'',
            });
        }
        break;
      }
      default:{
        break;
      }
    }

    this.forceUpdate();
  }

  // 検索域のクリアボタン押下
  onClearSearchField() {
    this.setState(
      {
        //searchCompanyName: '',
        searchCompanyPersonName:'', 
        searchPersonName:'',
      });
    this.forceUpdate();
  }

  handleToAboutPage() {
    const { dispatch } = this.props;
    dispatch(moveTo.Sample());
  }


  getYearOptions = () => {

    const year_list = [];
    const year_current = new Date().getFullYear();
    const year_min = 1970;
    const year_max = year_current + 10;
    
    for(var i = year_min; i <year_max; i++){
      year_list.push(i);
    }
    const menuItems = [];

    for (const i in year_list) {
      menuItems.push(
        <MenuItem
          value={year_list[i]}
          key={year_list[i]}
          >
          {year_list[i]}
        </MenuItem>
      );
    }
    return menuItems;
  };

  getFiscalOptions = () => {

    const fisical_list = [];
    const fisical_min = 1;
    const fisical_max = 12;
    
    for(var i = fisical_min; i <=fisical_max; i++){
      fisical_list.push(i);
    }

    const menuItems = [];

    for (const i in fisical_list) {
      menuItems.push(
        <MenuItem
          value={fisical_list[i]}
          key={fisical_list[i]}
          >
          {fisical_list[i]+'月'}
        </MenuItem>
      );
    }
    return menuItems;
  };

  // 注文ステータス
  getOrderStatusListOptions = () => {
    const statusList = this.state.order_display_status_list;
    const menuItems = [];
    for (const i in statusList) {
      menuItems.push(
        <MenuItem
          value={statusList[i].statusId}
          key={statusList[i].statusId}
          >
          {statusList[i].status}
        </MenuItem>
      );
    }
    return menuItems;
  };

  // 取引先名
  getCompanyListOptions = () => {
    const companyList = this.state.company_list;
    const menuItems = [];
    for (const i in companyList) {
      menuItems.push(
        <MenuItem
          value={companyList[i].companyId}
          key={companyList[i].companyId}
          >
          {companyList[i].companyId+'：' + companyList[i].companyName}
        </MenuItem>
      );
    }
    return menuItems;
  };

  // 受注担当
  getPersonListOptions = () => {
    const personList = this.state.person_list;
    const menuItems = [];
    for (const i in personList) {
      menuItems.push(
        <MenuItem
          value={personList[i].personId}
          key={personList[i].personId}
          >
          {personList[i].personName}
        </MenuItem>
      );
    }
    return menuItems;
  };


  /***********************************************************************************************
  * 検索結果のリスト表示機能、帰ってきた行数分だけPushを繰り返す。
  ***********************************************************************************************/
  // 年別のリスト
  getMstYearItemList = () => {
    const self = this;
    const { classes, model } = self.props;
    const list = [];
    const company_list = self.state.company_list;
    const person_list = self.state.person_list;

    // パターンで分ける表示を分ける
    for (const i in model) {
      var _companyName = '－';
      var _person = '－';
      var _companyPerson =  '－' ;

      // 表示用の処理
      // 取引先名
      if(!(model[i].companyID === '') && !(model[i].companyID === null ) 
          && !(model[i].companyID === undefined)) {

        company_list.forEach((v) => {
          if (v.companyId == model[i].companyID) {
            _companyName = v.companyName;
            return true;
          }
        });
      }

      // 受注担当
      if(!(model[i].personID === '') && !(model[i].personID === null) && !(model[i].personID === undefined)) {
        person_list.forEach((v) => {
          if (v.personId == model[i].personID) {
            _person = v.personName;
            return true;
          }
        });
      }
      // 取引先担当
      if(!(model[i].companyPerson === '') && !(model[i].companyPerson === null) 
        && !(model[i].companyPerson === undefined)) {
          _companyPerson = model[i].companyPerson;
      }

      list.push(
        <Box
          key={i}
          display={`flex`}
          justifyContent={`space-between`}
          flexGrow={1}
        >
          <>
            <Box className={classes.mstTblItemFirst}>{model[i].year}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{_companyName}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{_companyPerson}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{_person}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnRight}>{model[i].total.toLocaleString()}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnRight}>{model[i].count}</Box>
          </>
        </Box>
      );
    }
    return <Box>{list}</Box>;
  };

  // 月別のリスト
  getMstMonthItemList = () => {
    const self = this;
    const { classes, model ,model_totalAmount, model_orderCount , dispatch} = self.props;
    const list = [];
    const company_list = self.state.company_list;
    const person_list = self.state.person_list;

    for (const i in model) {
      var _companyName = '－';
      var _person = '－';
      var _companyPerson =  '－' ;

      // 表示用の処理
      if(!(model[i].companyID === '') && !(model[i].companyID === null ) 
          && !(model[i].companyID === undefined)) {

        company_list.forEach((v) => {
          if (v.companyId == model[i].companyID) {
            _companyName = v.companyName;
            return true;
          }
        });
      }

      // 受注担当
      if(!(model[i].personID === '') && !(model[i].personID === null) && !(model[i].personID === undefined)) {
        person_list.forEach((v) => {
          if (v.personId == model[i].personID) {
            _person = v.personName;
            return true;
          }
        });
      }
      // 取引先担当
      if(!(model[i].companyPerson === '') && !(model[i].companyPerson === null) 
        && !(model[i].companyPerson === undefined)) {
          _companyPerson = model[i].companyPerson;
      }

      list.push(
        <Box
          key={i}
          display={`flex`}
          justifyContent={`space-between`}
          flexGrow={1}
        >
          <>
            <Box className={classes.mstTblItemFirst}>{model[i].year === undefined ? model[i].month : model[i].year + '/'+ model[i].month}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{_companyName}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{_companyPerson}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{_person}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnRight}>{model[i].total.toLocaleString()}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnRight}>{model[i].count}</Box>
          </>
        </Box>
      );
    }

    return <Box>{list}</Box>;
  };

  // 取引先のリスト
  getMstCompanyItemList = () => {
    const self = this;
    const { classes, model } = self.props;
    const list = [];
    const company_list = self.state.company_list;
    const person_list = self.state.person_list;

    for (const i in model) {
      var _companyName = '－';
      var _person = '－';
    
      // 表示用の処理
      if(!(model[i].companyID === '') && !(model[i].companyID === null ) 
          && !(model[i].companyID === undefined)) {

        company_list.forEach((v) => {
          if (v.companyId == model[i].companyID) {
            _companyName = v.companyName;
            return true;
          }
        });
      }

      // 受注担当
      if(!(model[i].personID === '') && !(model[i].personID === null) && !(model[i].personID === undefined)) {
        person_list.forEach((v) => {
          if (v.personId == model[i].personID) {
            _person = v.personName;
            return true;
          }
        });
      }

      list.push(
        <Box
          key={i}
          display={`flex`}
          justifyContent={`space-between`}
          flexGrow={1}
        >
          <>
            <Box className={classes.mstTblItemFirst}>{model[i].year}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{_companyName}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{_person}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnRight}>{model[i].total.toLocaleString()}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnRight}>{model[i].count}</Box>
          </>
        </Box>
      );
    }

    return <Box>{list}</Box>;
  };

  // チェックボックス選択による中央のパターン
  getCurrentSearchCenter() {

    const year_list = this.getYearOptions();
    const fiscal_list =  this.getFiscalOptions();
    const { classes, model } = this.props;

    const _chekedYearPattern = (
      <Box display='flex'>
        <InputLabel className={classes.InputLabel} style={{width:'100px'}}>期間</InputLabel>

        <Select
          name="searchStartYear"
          variant="outlined"
          size={"small"}
          className={classes.pullDown}　
          value={this.state.searchStartYear}
          onChange={this.onChangeSearchField.bind(this)}
          style={{ backgroundColor: "#FFFFFF" }}
          error={this.state.searchUseDateCheck}
          >
          {year_list}
        </Select>

        <Box className={classes.span}>&ensp;～&ensp;</Box>

        <Select
          name="searchEndYear"
          variant="outlined"
          size={"small"}
          className={classes.pullDown}　
          value={this.state.searchEndYear}
          onChange={this.onChangeSearchField.bind(this)}
          style={{ backgroundColor: "#FFFFFF" }}
          error={this.state.searchUseDateCheck}
          >
          {year_list}
        </Select>
      </Box>
    );

    const _chekedMonthPattern = (
      
      <Box display='flex'>
        <InputLabel className={classes.InputLabel} style={{width:'100px'}}>開始期間</InputLabel>
        <Select
          name="searchStartYearMonth"
          variant="outlined"
          size={"small"}
          className={classes.monthPullDown}　
          value={this.state.searchStartYearMonth}
          onChange={this.onChangeSearchField.bind(this)}
          style={{ backgroundColor: "#FFFFFF" }}
          >
          {year_list}
        </Select>
        
        <Select
            name="searchStartMonth"
            variant="outlined"
            size={"small"}
            className={classes.monthPullDown}　
            onChange={this.onChangeSearchField.bind(this)}
            value={this.state.searchStartMonth}
            style={{ backgroundColor: "#FFFFFF" , marginLeft:'15px'}}
            >
            {fiscal_list}
          </Select>  
      </Box>
    );

    const _chekedCompanyPattern = (
      
      <Box display='flex'>
        <InputLabel className={classes.InputLabel} style={{width:'100px'}}>期間</InputLabel>
        <Select
          name="searchStartYearMonth"
          variant="outlined"
          size={"small"}
          className={classes.pullDown}　
          value={this.state.searchStartYearMonth}
          onChange={this.onChangeSearchField.bind(this)}
          style={{ backgroundColor: "#FFFFFF" }}
          >
          {year_list}
        </Select>
      </Box>
    );

    if(this.state.checkboxYear){
      return _chekedYearPattern;
    }
    if(this.state.checkboxMonth){
      return _chekedMonthPattern;
    }
    if(this.state.checkboxCompany){
      return _chekedCompanyPattern;
    }
  }

  // チェックボックス選択による右側のパターン
  getCurrentSearchRight() {

    const fiscal_list =  this.getFiscalOptions();
    const year_list = this.getYearOptions();
    const { classes } = this.props;

    const _patternMonth = (
      <Box display='flex'>
        <InputLabel className={classes.InputLabel} style={{width:'150px'}}>終了期間</InputLabel> 
        <Select
          name="searchEndYearMonth"
          variant="outlined"
          size={"small"}
          className={classes.monthPullDown}　
          value={this.state.searchEndYearMonth}
          onChange={this.onChangeSearchField.bind(this)}
          style={{ backgroundColor: "#FFFFFF" }}
          >
          {year_list}
        </Select>               
        <Select
          name="searchEndMonth"
          variant="outlined"
          size={"small"}
          className={classes.monthPullDown}　
          onChange={this.onChangeSearchField.bind(this)}
          value={this.state.searchEndMonth}
          style={{ backgroundColor: "#FFFFFF", marginLeft:'15px' }}
          >
          {fiscal_list}
        </Select>                
        </Box>
    );

    const _patternDefault = (
      <Box display='flex'>
        <InputLabel className={classes.InputLabel} style={{width:'150px'}}>決算</InputLabel>
                      
          <Select
            name="searchFiscal"
            variant="outlined"
            size={"small"}
            className={classes.pullDown}　
            onChange={this.onChangeSearchField.bind(this)}
            value={this.state.searchFiscal}
            style={{ backgroundColor: "#FFFFFF" }}
            >
            {fiscal_list}
          </Select>                
        </Box>
    );

    if(this.state.checkboxYear){
      return _patternDefault;
    }
    if(this.state.checkboxMonth){
      return _patternMonth;
    }
    if(this.state.checkboxCompany){
      return _patternDefault;
    }
  }

  // 年別の表のレイアウト
  getMstTblYear() {

    const {classes} = this.props;
    const list = this.getMstYearItemList();
    const startYear = this.state.searchStartYear;
    const endYear = this.state.searchEndYear;
    //const startMonth = this.state.searchStartMonth;
    //const endMonth = this.state.searchEndMonth;
    const fiscal = this.state.searchFiscal;
    //const initTitle = '年別売上額一覧表' + startYear +'年～' + endYear + '年 全社合計（取引先担当：－／受注担当：－）';
    const initTitle = startYear + '年' + (fiscal+1) + '月' + '　～　' + (endYear * 1 + 1) + '年' + fiscal + '月' + '　全社合計';
    const currentTitle = this.state.title || null;

    return (
      <Box style={{ paddingBottom: "16px" }}>
        <Box
          style={{
            // border: `1px solid #525457`,
            borderBottom: `1px solid #525457`,
            borderRadius:'5px'
          }}
        >
          <Box className={classes.mstTblColDouble} >年別売上額一覧表</Box>
          <Box className={classes.mstTblColDouble} >{currentTitle === null ? initTitle:currentTitle}</Box>
          <Box display={`flex`} justifyContent={`space-between`} flexGrow={1}>
            <Box className={classes.mstTblFirst}>年次</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>取引先名</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>取引先担当</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>受注担当</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingRight}>売上額</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingRight}>注文数</Box>      
          </Box>
          {list}
        </Box>
      </Box>
    );
  }

  // 月別の表のレイアウト
  getMstTblMonth() {
    const {classes} = this.props;
    const list = this.getMstMonthItemList();

    //const initTitle = '月別売上額一覧表 ××××年×月～××××年×月 全社合計（取引先担当：－／受注担当：－）';
    const initTitle = '××××年×月～××××年×月 全社合計（取引先担当：－／受注担当：－）';
    const currentTitle = this.state.title || null;
    return(
      <Box style={{ paddingBottom: "16px" }}>

        <Box
          style={{
            // border: `1px solid #525457`,
            borderBottom: `1px solid #525457`,
            borderRadius:'5px'
          }}
        >
          <Box className={classes.mstTblColDouble} >月別売上額一覧表</Box>
          <Box className={classes.mstTblColDouble} >{currentTitle === null ? initTitle:currentTitle}</Box>
          <Box display={`flex`} justifyContent={`space-between`} flexGrow={1}>
            <Box className={classes.mstTblFirst}>月次</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>取引先名</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>取引先担当</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>受注担当</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingRight}>売上額</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingRight}>注文数</Box>      
          </Box>
          {list}
        </Box>
      </Box>
    );
  }

  // 取引先別の表のレイアウト
  getMstTblCompany() {
    const {classes} = this.props;
    const list = this.getMstCompanyItemList();

    const initTitle = '××××年××××年×月 全社合計（受注担当：－）';
    const currentTitle = this.state.title || null;
    
    return(
      <Box style={{ paddingBottom: "16px" }}>

        <Box
          style={{
            // border: `1px solid #525457`,
            borderBottom: `1px solid #525457`,
            borderRadius:'5px'
          }}
        >
          <Box className={classes.mstTblColDouble} >取引先別売上額一覧表</Box>
          <Box className={classes.mstTblColDouble} >{currentTitle === null ? initTitle:currentTitle}</Box>
          <Box display={`flex`} justifyContent={`space-between`} flexGrow={1}>
            <Box className={classes.mstTblFirst}>期間</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>取引先名</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>受注担当</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingRight}>売上額</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingRight}>注文数</Box>      
          </Box>
          {list}
        </Box>
      </Box>
    );
  }

  // 選択されているパターンのテーブル返す
  getCurrentMstTbl() {

    // 初回
    if(this.state.isfirst && this.state.checkboxYear) {
      this.setState({isfirst:false});
      this.setState({currentTbl:'tblYear'});
      return this.getMstTblYear();
    }

    if(this.state.isfirst && this.state.checkboxMonth){
      this.setState({isfirst:false});
      this.setState({currentTbl:'tblMonth'});
      return this.getMstTblMonth();
    }

    if(this.state.isfirst && this.state.checkboxCompany){
      this.setState({isfirst:false});
      this.setState({currentTbl:'tblCompany'});
      return this.getMstTblCompany();
    }

    // 2回目以降
    return this.getCurrentMstTblSecond();
  }

  // 2回目以降
  getCurrentMstTblSecond() {

    var _currentTbl = null;

    switch(this.state.currentTbl) {

      case 'tblYear': {
        _currentTbl = this.getMstTblYear();
        break;
      }
      case 'tblMonth': {
        _currentTbl = this.getMstTblMonth();
        break;
      }
      case 'tblCompany': {
        _currentTbl = this.getMstTblCompany();
        break;
      }
      default : {
        break;
      }
    }

    return _currentTbl;
  }

  // タイトル域
  render() {
    const { classes } = this.props;
    //const company_list = this.getCompanyListOptions();
    const person_list = this.getPersonListOptions();

    /**       コンボボックス対応       */
    const _companyList = this.state.company_list;
    let companyOption = [];

    _companyList.forEach(v => {
      let tmp = {};
      tmp.companyID = v.companyId;
      tmp.companyName = v.companyName;
      companyOption.push(tmp);
    });

    const titleDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>
          <Box p={1} className={classes.titleHeaderColor}>
            売上額一覧画面
          </Box>
        </Box>
      </Box>
    );

    //検索フォーム
    const searchDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>

        {/*display={`flex`}*/}
          <Box p={2} className={classes.searchTbl}>
            {/* 検索左側*/ }
            <Box>
              <Box>
                <Box>
                  <Box display='flex' height={40}>
                    <FormControlLabel
                      name='checkBoxYear'
                      checked={this.state.checkboxYear}
                      control={<Checkbox color="primary"
                                icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 30 }} />}
                                checkedIcon={<CheckBoxIcon style={{ fontSize: 30 }} />}
                                />}
                      label="年別"
                      labelPlacement="start"
                      onChange={this.onChangeCheckBox.bind(this)}
                    />

                    <FormControlLabel
                      name='checkBoxMonth'
                      checked={this.state.checkboxMonth}
                      control={<Checkbox
                                color="primary" 
                                icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 30 }} />}
                                checkedIcon={<CheckBoxIcon style={{ fontSize: 30 }} />}
                                /> 
                              }
                      label="月別"
                      labelPlacement="start"
                      onChange={this.onChangeCheckBox.bind(this)}
                      />

                    <FormControlLabel
                      name='checkBoxCompany'
                      checked={this.state.checkboxCompany}
                      control={<Checkbox
                        color="primary" 
                        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 30 }} />}
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 30 }} />}
                        /> 
                      }
                      label="取引"
                      labelPlacement="start"
                      onChange={this.onChangeCheckBox.bind(this)}
                      />
                  </Box>

                  <Box display='flex' className={classes.searchUnderZone}>
                    <InputLabel className={classes.InputLabel} style={{width:'80px'}}>取引先名</InputLabel>
                    {/*   コンボボックス切り替え    */}
                    {this.state.searchCompanyNameDisabled ? (
                      <Select
                        name="searchCompanyName"
                        variant="outlined"
                        size="small"
                        className={this.state.searchCompanyNameDisabled ? classes.pullDownDisabled : classes.pullDown }
                        style={{ width:'300px'}}
                        disabled={this.state.searchCompanyNameDisabled}
                        MenuProps={{style:{
                          height:'600px'
                        }}}
                        >
                      </Select>
                    ) : (
                      <Autocomplete
                        name="searchCompanyName"
                        size='small'
                        className={this.state.searchCompanyNameDisabled ? classes.AutocompleteDisabled : classes.Autocomplete}
                        options={companyOption}
                        ListboxProps={{style:{height:350 }}}
                        getOptionLabel={(option) => option.companyName}
                        disabled={this.state.searchCompanyNameDisabled ? true : false}
                        renderInput={
                          (params) => 
                          <TextField 
                            fullWidth
                            style={this.state.searchCompanyNameDisabled ? { backgroundColor: "#eeeeee", } : { backgroundColor: "#FFFFFF", }}
                            {...params} variant="outlined"
                          />
                        }
                        onChange={(event,value) => this.onChangeAutoCompletField(event,value)}
                      />
                      )
                    }
                  </Box>
                </Box>

              </Box>
            </Box>

            <Box>
              <Box>
                <Box display='flex' height={40}>
                  {this.getCurrentSearchCenter.bind(this)}
                </Box>

                <Box display='flex' className={classes.searchUnderZone}>
                  <Box display='flex'>
                    <InputLabel className={classes.InputLabel} style={{width:'100px'}}>取引先担当</InputLabel>

                    <TextField
                      name="searchCompanyPersonName"
                      variant="outlined"
                      size="small"
                      className={this.state.searchCompanyPersonDisabled ? classes.inputDisabled : classes.searchInputText}
                      value={this.state.searchCompanyPersonName}
                      onChange={this.onChangeSearchField.bind(this)}
                      disabled={this.state.searchCompanyPersonDisabled}/>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box>
              {this.getCurrentSearchRight()}
              <Box display='flex' className={classes.searchUnderZone}>
                    <InputLabel className={classes.InputLabel} style={{width:'150px'}}>受注担当</InputLabel>

                    <Select
                      name="searchPersonName"
                      variant="outlined"
                      size={"small"}
                      className={classes.pullDown}　
                      value={this.state.searchPersonName}
                      onChange={this.onChangeSearchField.bind(this)}
                      style={{ backgroundColor: "#FFFFFF", width:'300px'}}
                      error={this.state.searchPersonNameError}
                      disabled={this.state.searchPersonDisabled}
                      >
                      <MenuItem value="" key="">&ensp;</MenuItem>
                      {person_list}
                    </Select>
                  </Box>
            </Box>

            {/* 検索右側*/}
            <Box>
              <Box paddingTop='10%'>
                <Box width={90} display='inline-block'>
                  <Fab
                    className={classes.searchFabColor}
                    onClick={this.judgeSearchPattern.bind(this)}
                  >
                    <SearchOutlinedIcon style={{ fontSize: 30 }} />
                  </Fab>
                </Box>
                <Box width={70} display='inline-block'>
                  <Fab
                    className={classes.searchFabColor}
                    onClick={this.onClearSearchField.bind(this)}
                  >
                    <CancelPresentationIcon style={{ fontSize: 30 }} />
                  </Fab>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );

    // 一覧部分
    const _mstTbl = this.getCurrentMstTbl();

    return (
      <Box style={{ height: "100vh", width: "100vw" }}>
        <Header value={this.state.headerErrorMessage}/>
        <Box
          style={{
            height: "calc(100% - 96px)",
            width: "calc(100% - 32px)",
            padding: "16px",
            overflow: "auto"
          }}
        >
          <Box minWidth={975}>
            {titleDiv}
            {searchDiv}
            <Box  className={classes.searchResult}>
              <span>取引先数：</span>
              <span>{
                this.props.model_companyCount
                }</span>
              <span> 件</span>
            </Box>
            {_mstTbl}
          </Box>
        </Box>
      </Box>
    );
  }

  // 検索パターンの選別
  async judgeSearchPattern() {

    await this.setState({headerErrorMessage:""});

    if(this.state.checkboxYear){
      this.setState({currentTbl:'tblYear'});
      this.searchSalesYearPattern();
    }
    else if(this.state.checkboxMonth){
      this.setState({currentTbl:'tblMonth'});
      this.searchSalesMonthPattern();
    }
    else if(this.state.checkboxCompany){
      this.setState({currentTbl:'tblCompany'});
      this.searchSalesCompanyPattern();
    }
  }

  //　年別
  async searchSalesYearPattern() {
    const { dispatch } = this.props;
    var _fiscal = this.state.searchFiscal;
    var _startYear = this.state.searchStartYear || null;
    var _endYear = this.state.searchEndYear || null;
    const _companyName = this.state.searchCompanyName || null;
    const _companyPerson = this.state.searchCompanyPersonName || null;
    const _personName = this.state.searchPersonName || null;

    this.setState({searchUseDateCheck:false});
    if(_startYear > _endYear) {
      // 赤枠
      this.setState({searchUseDateCheck:true});
      return;
    }

    this.createMstTitleYearPattern(_startYear, _endYear, _companyName, _companyPerson, _personName);

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/sales-year/list',
      {
        pageIndex     : 0,
        pageSize      : 10000,
        fiscal        :_fiscal,           // 決算 
        startYear     :_startYear,        // 開始
        endYear       :_endYear,          // 終了 
        companyID     :_companyName,
        companyPerson :_companyPerson,
        personID      :_personName,
      },{
        param,
        withCredentials: true,
      }
    ).then( response => {
      let list = [];
      let totalAmount = 0;
      let orderCount = 0;
      
      if(response.data.list.length === 0) {
        dispatch(listSales(response.data.list));
      }
      else {
        list = response.data.list;
        list.forEach(v => {
          totalAmount += v.total;
          orderCount += v.count;
          if(_fiscal === 12){
            v.year = ((v.year * 1) + 1) + '/' + '1' + ' ～ ' + ((v.year * 1) + 1) + '/' + _fiscal
          }
          else{
            v.year = v.year + '/' + (_fiscal+1) + ' ～ ' + ((v.year * 1) + 1) + '/' + _fiscal
          }
        });
        list.push({year:'－',companyID:'－', companyPerson:'－', personID:'－', total:totalAmount, count:orderCount });
        dispatch(listSales(list));
      }
      dispatch(companyCountSales('－'));
    }).catch(e =>{
      console.log(e);
      this.setState({headerErrorMessage:"検索に失敗しました"});
      return;
    });
  }

  // 月別
  async searchSalesMonthPattern(){
    const { dispatch } = this.props;
    const _startYear = this.state.searchStartYearMonth || null;
    const _startMonth = this.state.searchStartMonth || null;

    const _endYear = this.state.searchEndYearMonth || null;
    const _endMonth = this.state.searchEndMonth || null

    const _companyName = this.state.searchCompanyName || null;
    const _companyPerson = this.state.searchCompanyPersonName || null;
    const _personName = this.state.searchPersonName || null;

    // 開始年月
    const _startDate = moment().set('year', _startYear).set('date', 1).set('month', _startMonth-1).format("YYYY-MM-DD");
    // 終了年
    const _endDate = moment().set('year', _endYear).set('month', _endMonth-1).endOf('month').format("YYYY-MM-DD");

    // TODO 期間チェック

    const param = Cookies.get('token');

    this.createMstTitleMonthPattern(_startYear, _startMonth, _endYear, _endMonth, _companyName, _companyPerson, _personName);

    await axios.post(
      REQUEST_PATH + 'api/sales-month/list',
      {
        pageIndex     : 0,
        pageSize      : 10000,
        startDate     : _startDate,      // 開始
        endDate       : _endDate,        // 終了 
        companyID     : _companyName,    // 取引先
        companyPerson : _companyPerson,　// 取引先担当
        personID      : _personName,     // 受注担当
        
      },{
        param,
        withCredentials: true,
      }
    ).then( response => {
      //dispatch(listSales(response.data.list));
      let list = [];
      let totalAmount = 0;
      let orderCount = 0;
      
      if(response.data.list.length === 0) {
        dispatch(listSales(response.data.list));
      }
      else {
        list = response.data.list;
        list.forEach(v => {
          totalAmount += v.total;
          orderCount += v.count;
        });

        list.push({month:'－',companyID:'－', companyPerson:'－', personID:'－', total:totalAmount, count:orderCount });
        dispatch(listSales(list));
      }
      dispatch(companyCountSales('－'));
    }).catch(e =>{
      console.log(e);
      this.setState({headerErrorMessage:"検索に失敗しました"});
      return;
    });
  }

  // 取引先別
  async searchSalesCompanyPattern() {
    const { dispatch } = this.props;
    const _year = this.state.searchStartYearMonth || null;
    const _fiscal = this.state.searchFiscal|| null;
    const _personName = this.state.searchPersonName || null;

    if(_year === null || _year === undefined || _year === '') {
        return
    }

    // 開始年月日、終了年月日
    const _startDate = moment().set('year', _year).set('date', 1).set('month', _fiscal).format("YYYY-MM-DD");
    const _endDate = moment().set('year', _year + 1).set('month', _fiscal-1).endOf('month').format("YYYY-MM-DD");

    // 表タイトル整形
    this.createMstTitleCompanyPattern(_year, _personName);

    const param = Cookies.get('token');
    
    await axios.post(
      REQUEST_PATH + 'api/sales-company/list',
      {
        pageIndex     : 0,
        pageSize      : 10000,
        startDate     : _startDate,      // 開始
        endDate       : _endDate,        // 終了
        personID      : _personName
      },{
        param,
        withCredentials: true,
      }
    ).then( response => {
      let list = [];
      let totalAmount = 0;
      let orderCount = 0;
      let _companyList = [];

      if(response.data.list.length === 0) {
        dispatch(listSales(response.data.list));
      }
      else {
        list = response.data.list;
        list.forEach(v => {
          totalAmount += v.total;
          v.year = _year;
          orderCount += v.count;

          if(_companyList.indexOf(v.companyID) === -1){
            _companyList.push(v.companyID);
          }

          if(_fiscal === 12){
            v.year = ((v.year * 1) + 1) + '/' + '1' + ' ～ ' + ((v.year * 1) + 1) + '/' + _fiscal
          }
          else{
            v.year = v.year + '/' + (_fiscal+1) + ' ～ ' + ((v.year * 1) + 1) + '/' + _fiscal
          }
        });
        list.push({year:'－',companyID:'－', companyPerson:'－', personID:'－', total:totalAmount, count:orderCount });
        dispatch(listSales(list));
      }

      dispatch(companyCountSales(_companyList.length));
    }).catch(e =>{
      console.log(e);
      this.setState({headerErrorMessage:"検索に失敗しました"});
      return;
    });
  }

  // 年別
  createMstTitleYearPattern (startYear, endYear, companyId, companyPerson, personId) {
    const person_list = this.state.person_list;
    const company_list = this.state.company_list;
    const fiscal = this.state.searchFiscal;
    var titleCompanyName = '全社合計';
    var titlePerson = '－';

    if(personId !== null) {
      person_list.forEach((v) => {
        if (v.personId == personId) {
          titlePerson = v.personName;
          return true;
        }
      });
    }
    
    if(companyId !== null) {
      company_list.forEach((v) => {
        if (v.companyId == companyId) {
          titleCompanyName = '　　取引先名：'+ v.companyName;
          return true;
        }
      });
    }

    let mstTitle = '';

    if(fiscal === 12) {
      mstTitle = ' '
      .concat((startYear * 1 + 1)).concat('年').concat('1月').concat('　～　').concat((endYear * 1 + 1)).concat('年').concat(fiscal).concat('月')
        .concat(titleCompanyName);
    } else {
      mstTitle = ' '
      .concat(startYear).concat('年').concat(fiscal+1).concat('月').concat('　～　').concat((endYear * 1 + 1)).concat('年').concat(fiscal).concat('月')
        .concat(titleCompanyName);
    }

    if(companyPerson !== null){
      mstTitle = mstTitle.concat('　　取引先担当：').concat(companyPerson);
    }

    if(personId !== null){
      mstTitle = mstTitle.concat('　　受注担当：').concat(titlePerson);
    }

    this.setState({title:mstTitle});
  }

  // 月別
  createMstTitleMonthPattern (startYear, startMonth,endYear,_endMonth ,companyId ,companyPerson, personId) {

    const person_list = this.state.person_list;
    const company_list = this.state.company_list;
    const titleCompanyPerson = companyPerson === null ? '－':companyPerson

    var titlePerson = '－';
    var mstTitle = '';

    // 受注担当
    if(personId !== null) {
      person_list.forEach((v) => {
        if (v.personId == personId) {
          titlePerson = v.personName;
          return true;
        }
      });
    }

    if(companyId !== null) {

      var titleCompanyName = '';
      // 取引先名
      company_list.forEach((v) => {
        if (v.companyId == companyId) {
          titleCompanyName = '　　取引先名：'　+ v.companyName;
          return true;
        }
      });
      
      mstTitle = 
      ' '
        .concat(startYear).concat('年').concat(startMonth).concat('月　～　')
          .concat(endYear).concat('年').concat(_endMonth).concat('月')
            .concat(titleCompanyName);
    } else {
      mstTitle = 
      ' '
        .concat(startYear).concat('年').concat(startMonth).concat('月　～　')
          .concat(endYear).concat('年').concat(_endMonth).concat('月')
    }

    if(companyPerson !== null){
      mstTitle = mstTitle.concat('　取引先担当：').concat(companyPerson);
    }

    if(personId !== null){
      mstTitle = mstTitle.concat('　受注担当：').concat(titlePerson);
    }

    this.setState({title:mstTitle});
  }

  // 取引先別
  createMstTitleCompanyPattern (year, personId) {
    const person_list = this.state.person_list;
    const fiscal = this.state.searchFiscal;

    var titlePerson = '－';
    let mstTitle = ' '

    // 受注担当
    if(personId !== null) {
      person_list.forEach((v) => {
        if (v.personId == personId) {
          titlePerson = v.personName;
          return true;
        }
      });
    }

    if(fiscal === 12){
      mstTitle = mstTitle.concat((year * 1) + 1).concat('年').concat('1月')
      .concat('　～　')
        .concat((year * 1) + 1).concat('年').concat(fiscal).concat('月')
    } else {
      mstTitle = mstTitle.concat(year).concat('年').concat((fiscal * 1) + 1).concat('月')
      .concat('　～　')
        .concat((year * 1) + 1).concat('年').concat(fiscal).concat('月')
    }

    if(personId !== null) {
      mstTitle = mstTitle.concat('　受注担当：').concat(titlePerson);
    }

    this.setState({title:mstTitle});
  }

  static get propTypes() {
    return {};
  }

  // Styleの設定
  static get styles() {
    return theme => ({

      searchInputText:{
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        lineheight: 2.5,
        width:'300px',
      },
      inputDisabled:{
        backgroundColor: "#eeeeee",
        borderRadius: 5,
        lineheight: 2.5,
        width:'300px',
      },
      
      // リストセル
      mstTblItemFirst: {
        border: "1px solid #b0bec5",
        //borderRight: "1px solid #525457",
        borderLeft: "1px solid #525457",
        // borderTop: "0",
        borderBottom: "0",
        textAlign: "center",
        width: 90,
        height: 40,
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B",
        display: "flex",
      },

      mstTblItem: {
        border: "1px solid #b0bec5",
        borderLeft: "0",
        // borderTop: "0",
        borderBottom: "0",
        width: 260,
        height: 40,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      
      checked: {},

      titleHeaderColor: {
        border: "1px solid #525457",
        backgroundColor: "#1976d2",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 20,
        fontWeight: "bold",
        borderRadius:"5px"
      },

      searchTbl: {
        border: "1px solid #525457",
        backgroundColor: "#bbdefb",
        color: "#4A4A4B",
        fontSize: 16,
        justifyContent: "space-between",
        display:'flex',
        //alignItems: "center",
        borderRadius:"5px"
      },
      mstTblFirst: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderLeft: "1px solid #525457",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: 90,
        height: 30,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },

      mstTblCol: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: 260,
        height: 30,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },

      mstTblColDouble: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        //borderLeft: "1px solid #b0bec5",
        borderLeft: "1px solid #525457",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        flexGrow: 1,
        textAlign: "center",
        justifyContent: "center",                                 
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B",
        paddingLeft:'15px',
        fontSize:'20px'
        },

      buttonColor: {
        width: 150,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        // color: "#E1E1E1"
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing:10
      },

      searchFabColor: {
        backgroundColor: "#2196f3",
        color: "#EEEEEE"
      },
      InputLabel:{
        //display:"inline",
        //marginRight:5
        paddingTop:'3%',
        textAlign:'right',
        marginRight:'10px',
      },

      searchUnderZone:{
        marginTop:'15px',
      },

      pullDown:{
        backgroundColor: "#FFFFFF",
        width: 130,
        borderRadius: 5,
        height:40,
        textAlign:'center'
        //maxHeight: "28px"
      },
      monthPullDown:{
        backgroundColor: "#FFFFFF",
        width: 140,
        borderRadius: 5,
        height:40,
        textAlign:'center'
        //maxHeight: "28px"
      },
      span:{
        lineHeight: '35px'
      },

      pullDownDisabled:{
        backgroundColor: "#eeeeee",
        width: 130,
        borderRadius: 5,
        height:40,
        textAlign:'center'
      },
      itemAlingnRight:{
        justifyContent: "flex-end",
        paddingRight: '1%',
      },
      itemTitlePaddingRight:{
        paddingRight: '1%',
      },
      itemAlingnLeft:{
        justifyContent: "left",
        paddingLeft: '1%',
      },
      itemTitlePaddingLeft:{
        paddingLeft: '1%',
      },
      searchResult:{
        textAlign:"left",
        marginBottom:"10px",
        paddingLeft:"5px",
        //fontSize:'1.5rem'
      },
      Autocomplete:{
        //backgroundColor: "#FFFFFF",
        width: 300,
        //borderRadius: 5,
        height:40,
        textAlign:'center'
      },
      AutocompleteDisabled:{
        backgroundColor: "#eeeeee",
        width: 300,
        borderRadius: 5,
        height:40,
        textAlign:'center'
      },
    });
  }
}

// redux/index.js プロパティ
const mapStateToProps = ({ sales: { list } }) => ({ model: list });
const mapStateToProps2 = ({ sales_companyCount: { companyCount } }) => ({ model_companyCount: companyCount });

const mapDispatchToProps = dispatch => ({ dispatch });

const { styles } = Component;

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  connect(mapStateToProps2, mapDispatchToProps),
)(Component);
