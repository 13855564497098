import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles, fade } from "@material-ui/core/styles";
import { Box, Button, Grid, Fab, Checkbox, TextField,Select,MenuItem,InputLabel } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "axios";
import EditIcon from '@material-ui/icons/Edit';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AlertDialog from "../components/Dialog";
import Header from "../header/Header";
import { moveTo, listLicense, pageCountLicense, currentPageLicense, hitsCountLicense } from "../actions";
import REQUEST_PATH from "../components/Common";
import Pagination from '@material-ui/lab/Pagination';
import { Alert } from '@material-ui/lab';
import Cookies from "js-cookie";

class Component extends React.Component {
  state = {
    // 検索項目
    searchLicenseName:'',
    searchExpiryDateStart:'',
    searchExpiryDateEnd:'',
    searchUpdatedDateStart:'',
    searchUpdatedDateEnd:'',
    searchAcquisitionDateStart:'',
    searchAcquisitionDateEnd:'',
    // フォーム
    inputForm: [],
    changeForm: null,
    // 非活性
    addLock: false,
    removeLock: true,
    registerLock: true,
    updateLock:true,
    deleteLock:true,
    // ヘルパーテキスト
    licenseIdHelperText:"",
    licenseNameHelperText:"",
     // エラー
     licenseIdError:false,
     licenseNameError:false,
    // エラーメッセージ
    errorMessage:'',

    searchExpiryDateCheck:false,
    successMessage:'',
  };

  constructor(props){
    super(props);
  }
  // render後、ボタン制御（遷移元からの復帰対策）
  componentDidMount() {
    this.checkState();
  }
  
  // 検索域のイベント
  onChangeSearchField(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  // 検索域のクリアボタン押下
  onClearSearchField() {
    this.setState(
      {
        searchLicenseName: "",
        searchUpdatedDateStart: "",
        searchUpdatedDateEnd: "",
        searchExpiryDateStart: "",
        searchExpiryDateEnd: "",
        searchAcquisitionDateStart: "",
        searchAcquisitionDateEnd: ""  
      });

      this.setState({ searchExpiryDateCheck:false });
      this.setState({ searchUpdatedDateCheck:false });
      this.setState({ searchAcquisitionDateCheck:false });

    this.forceUpdate();
  }

  /***********************************************************************************************
  * 追加時のフィールドにイベントが発生した際呼び出される関数
  * ここにエラー時のhelperTextの内容を記述する
  ***********************************************************************************************/
  onChangeAddField(evt, index) {
    const changeForm = this.state.inputForm.slice()[index];
    const val = evt.target.value;
    switch (evt.target.name) {

      case "license_Id": {
        changeForm.licenseId = val;

        if(isNaN(changeForm.licenseId)){
          this.setState({licenseIdHelperText:"半角数字以外が入力されています"});
          this.setState({licenseIdError:true});
          break;
        }

        if(changeForm.licenseId.length > 0 && changeForm.licenseId.length < 4) {
          this.setState({licenseIdHelperText:"桁数が足りません"});
          this.setState({licenseIdError:true});
          break;
        }

        if(changeForm.licenseId.length > 4){
          this.setState({licenseIdHelperText:"桁数が長すぎます"});
          this.setState({licenseIdError:true});
          break
        }

        this.setState({licenseIdHelperText:""});
        this.setState({licenseIdError:false});
        break;
      }

      case "license_Name": {
        changeForm.licenseName = val;
        if(changeForm.licenseName.length > 16 ){
          this.setState({licenseNameHelperText:"入力文字数が長すぎます"});
          this.setState({licenseNameError:true});
        } else {
          this.setState({licenseNameHelperText:""});
          this.setState({licenseNameError:false});
        }
        break;
      }
      case "expiry_Date": {
        changeForm.expiryDate = val;
        break;
      }
      case "updated_Date": {
        changeForm.updatedDate = val;
        break;
      }
      case "acquisition_Date": {
        changeForm.acquisitionDate = val;
        break;
      }
      default: {
        break;
      }
    }
    this.forceUpdate();
  }

  //編集時にイベントが発生した際呼び出される関数
  onChangeField(e) {
    const changeItem = this.state.changeForm;
    const val = e.target.value;

    switch (e.target.name) {
      case "license_Name": {
        changeItem.licenseName = val;
        break;
      }
      case "expiry_Date": {
        changeItem.expiryDate = val;
        break;
      }
      case "updated_Date": {
        changeItem.updatedDate = val;
        break;
      }
      case "acquisition_Date": {
        changeItem.acquisitionDate = val;
        break;
      }
      default: {
        break;
      }
    }
    this.forceUpdate();
  }

  handleToAboutPage() {
    const { dispatch } = this.props;
    dispatch(moveTo.Sample());
  }

  handleCheckAll(evt) {
    const { model } = this.props;

    // Fab-ボタンが活性の場合、選択させない
    if(!this.state.removeLock) {
      return;
    }

    for (let i in model) {
      model[i].checked = evt.target.checked;
    }
    this.forceUpdate();
    this.checkState();
  }

  //チェックボックスにチェックを入れた時に呼び出される関数
  handleCheck(evt) {
    const { model } = this.props;

    // Fab-ボタンが活性の場合、選択させない
    if(!this.state.removeLock) {
      return;
    }

    const _model = model.filter(({ licenseId: id }, index) => {
      return evt.target.name === id;
    })[0];

    if (_model) {
      _model.checked = evt.target.checked;
    }
    this.checkState();
    this.forceUpdate();
  }

  /*****************ページング対応**************/
  async pagenationFunction(obj,number){
    const { dispatch } = this.props;
    await dispatch(currentPageLicense(number));
    await this.search(true);
    await this.checkState();
    
  }
  /****************ページング対応END************/

  checkState() {
  const { model } = this.props;
  const inputItemNum = this.state.inputForm.slice().length;
  const checkItem = model.filter(_model => {
    return _model.checked;
  });

  if (checkItem && checkItem.length === 1 && inputItemNum === 0) {
    this.setState({ setColor:checkItem[0].colorCode});
    this.setState({ changeForm: { ...checkItem[0] } });

    this.setState({ updateLock:false });
    this.setState({ deleteLock:false});

    this.setState({ addLock: true });
    this.setState({ removeLock: true });
  } else {
    this.setState({ changeForm: null });
  }
  
  if (checkItem && checkItem.length > 1) {
    
    this.setState({ deleteLock:false});
    this.setState({ updateLock:true });

    this.setState({ addLock: true });
    this.setState({ removeLock: true });
  }

  if (checkItem && checkItem.length === 0) {
    this.setState({ updateLock:true });
    this.setState({ deleteLock:true});
    this.setState({ registerLock:true});

    this.setState({ addLock: false });
    this.setState({ removeLock: true });
  }
}


  handleAddForm() {
    const fieldList = this.state.inputForm.slice();
    fieldList.push({
      licenseId: "",
      licenseName: "",
      expiryDate: "",
      updatedDate: "",
      acquisitionDate: "",
    });
    this.setState({ registerLock :false});
    this.setState({ removeLock :false});
    this.setState({ addLock :true});
    this.setState({ inputForm: fieldList });
  }

  handleRemoveForm() {
    const fieldList = this.state.inputForm.slice();
    fieldList.pop();
    this.setState({ inputForm: fieldList });
    this.setState({ registerLock :true});
    this.setState({ removeLock :true});
    this.setState({ addLock :false});
    // エラー要素のリセット
    this.setState({licenseIdHelperText:''});
    this.setState({licenseIdError:false});
    this.setState({licenseNameHelperText:''});
    this.setState({licenseNameError:false});
  }
  getYearOptions = () => {

    const year_list = [];
    const year_current = new Date().getFullYear();
    const year_min = 1970;
    const year_max = year_current + 10;
    
    for(var i = year_min; i <year_max; i++){
      year_list.push(i);
    }
    const menuItems = [];

    for (const i in year_list) {
      menuItems.push(
        <MenuItem
          value={year_list[i]}
          key={year_list[i]}
          >
          {year_list[i]}
        </MenuItem>
      );
    }
    return menuItems;
  };

  // 入力行の追加
  addForm(license_id, license_name, expiry_date, updated_date, acquisition_date ,i) {
    const { classes } = this.props;
    const form = this.state.inputForm.slice()[i];

    return (
      <Box
        key={i}
        display={`flex`}
        justifyContent={`space-between`}
        flexGrow={1}
      >
        <Box className={classes.addMstTblItemFirst}>
          <Checkbox
            checked={true}
            onChange={this.handleCheck.bind(this)}
            inputProps={{ "aria-label": "checkbox" }}
            classes={{
              root: classes.root,
              checked: classes.checked
            }}
          />
        </Box>

        <Box className={classes.addMstTblItem}>
          <TextField
            id="licenseId"
            name="license_Id"
            label="免許ID"
            variant="outlined"
            size={"small"}
            value={form.licenseId}
            onChange={event => this.onChangeAddField(event, i)}
            style={{ backgroundColor: "#FFFFFF" }}
            error={this.state.licenseIdError}
            helperText={this.state.licenseIdHelperText}
          />
        </Box>
        <Box className={classes.addMstTblItem + ' ' + classes.itemAlingnLeft}>
          <TextField
            id="licenseName"
            name="license_Name"
            label="免許名"
            variant="outlined"
            size={"small"}
            value={form.licenseName}
            onChange={event => this.onChangeAddField(event, i)}
            error={this.state.licenseNameError}
            style={{ backgroundColor: "#FFFFFF" }}
            helperText={this.state.licenseNameHelperText}
          />
        </Box>
        <Box className={classes.addMstTblItem}>
          
          <TextField
            id="expiryDate"
            name="expiry_Date"
            variant="outlined"
            size={"small"}
            value={form.expiry_date}
            onChange={event => this.onChangeAddField(event, i)}
            style={{ backgroundColor: "#FFFFFF" }}
            type="date"
          />

        </Box>

        <Box className={classes.addMstTblItem}>
          <TextField
            id="updatedDate"
            name="updated_Date"
            //label="更新日"
            variant="outlined"
            size={"small"}
            value={form.updated_date}
            onChange={event => this.onChangeAddField(event, i)}
            style={{ backgroundColor: "#FFFFFF" }}
            type="date"
          />
        </Box>

        <Box className={classes.addMstTblItem}>
          <TextField
            id="acquisitionDate"
            name="acquisition_Date"
            //label="取得日"
            variant="outlined"
            size={"small"}
            value={form.acquisition_Date}
            onChange={event => this.onChangeAddField(event, i)}
            style={{ backgroundColor: "#FFFFFF" }}
            type="date"
          />
        </Box>
      </Box>
    );
  }

  /***********************************************************************************************
  * 検索結果のリスト表示機能、帰ってきた行数分だけPushを繰り返す。
  ***********************************************************************************************/
  getMstItemList = () => {
    const list = [];
    const { classes, model } = this.props;
    const changeItem = this.state.changeForm;

    for (const i in model) {
      const formIsSelected = changeItem && changeItem.licenseId === model[i].licenseId;
      list.push(
        <Box
          key={i}
          display={`flex`}
          justifyContent={`space-between`}
          flexGrow={1}
        >
          {formIsSelected ? (
            <>
              <Box className={classes.addMstTblItemFirst}>
                <Checkbox
                  key={model[i].licenseId}
                  name={model[i].licenseId}
                  checked={model[i].checked || false}
                  onChange={this.handleCheck.bind(this)}
                  inputProps={{ "aria-label": "checkbox" }}
                  classes={{
                    root: classes.root,
                    checked: classes.checked
                  }}
                />
              </Box>
              <Box className={classes.addMstTblItem}>{model[i].licenseId}</Box>
              <Box className={classes.addMstTblItem  + ' ' + classes.itemAlingnLeft}>
                <TextField
                  id="licenseName"
                  name="license_Name"
                  label="免許名"
                  variant="outlined"
                  size={"small"}
                  value={changeItem.licenseName}
                  onChange={event => this.onChangeField(event)}
                  style={{ backgroundColor: "#FFFFFF" }}
                  error={this.state.licenseNameError}
                />
              </Box>
              <Box className={classes.addMstTblItem}>
                <TextField
                  id="expiryDate"
                  name="expiry_Date"
                  //label="有効期限"
                  variant="outlined"
                  size={"small"}
                  value={changeItem.expiryDate}
                  onChange={event => this.onChangeField(event)}
                  style={{ backgroundColor: "#FFFFFF" }}
                  type="date"
                />
              </Box>
              <Box className={classes.addMstTblItem}>
                <TextField
                  id="updatedDate"
                  name="updated_Date"
                  //label="更新日"
                  variant="outlined"
                  size={"small"}
                  value={changeItem.updatedDate}
                  onChange={event => this.onChangeField(event)}
                  style={{ backgroundColor: "#FFFFFF" }}
                  type="date"
                />
              </Box>
              <Box className={classes.addMstTblItem}>
                <TextField
                  id="acquisitionDate"
                  name="acquisition_Date"
                  //label="取得日"
                  format={'DD/MM/YYYY'}
                  variant="outlined"
                  size={"small"}
                  value={changeItem.acquisitionDate}
                  onChange={event => this.onChangeField(event)}
                  style={{ backgroundColor: "#FFFFFF" }}
                  type="date"
                />
              </Box>
            </>
          ) : (
            <>
              <Box className={classes.mstTblItemFirst}>
                <Checkbox
                  key={model[i].licenseId}
                  name={model[i].licenseId}
                  checked={model[i].checked || false}
                  onChange={this.handleCheck.bind(this)}
                  inputProps={{ "aria-label": "checkbox" }}
                  classes={{
                    root: classes.root,
                    checked: classes.checked
                  }}
                />
              </Box>
              <Box className={classes.mstTblItem}>{model[i].licenseId}</Box>
              <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{model[i].licenseName}</Box>
              <Box className={classes.mstTblItem}>{model[i].expiryDate.replace( /-/g, '/' )}</Box>
              <Box className={classes.mstTblItem}>{model[i].updatedDate.replace( /-/g, '/' )}</Box>
              <Box className={classes.mstTblItem}>{model[i].acquisitionDate.replace( /-/g, '/' )}</Box>
            </>
          )}
        </Box>
      );
    }
    return <Box>{list}</Box>;
  };

  // タイトル域
  render() {
    const { classes } = this.props;
    const list = this.getMstItemList();
    const year_list =this.getYearOptions();

    const titleDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>
          <Box p={1} className={classes.titleHeaderColor}>
            免許名管理画面
          </Box>
        </Box>
      </Box>
    );

    //検索フォーム
    const searchDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>

        {/*display={`flex`}*/}
          <Box p={2} className={classes.searchTbl}>
            {/* 検索左側*/ }
            <Box className={classes.searchAreaLeft}>
              <Box style={{fontSize:'25px'}}>
                <InputLabel ref="<Box>" id="license_name_label" className={classes.InputLabel}>&ensp;&ensp;免許名</InputLabel>
                <TextField
                  labelId="license_name_label"
                  id="licenseNameLabel"
                  name="searchLicenseName"
                  variant="outlined"
                  size="small"
                  className={classes.searchInputText + ' ' + classes.inputLicensesSarch}
                  value={this.state.searchLicenseName}
                  onChange={this.onChangeSearchField.bind(this)}
                />
              </Box>

              <Box className={classes.under}>
                <InputLabel ref="<Box>"id="updated_Date_label" className={classes.InputLabel}>更新期間</InputLabel>
                <Select
                  labelId="updated_Date_label"
                  id="updatedDateStart"
                  name="searchUpdatedDateStart"
                  variant="outlined"
                  size={"small"}
                  className={classes.pullDown}　
                  value={this.state.searchUpdatedDateStart}
                  onChange={this.onChangeSearchField.bind(this)}
                  style={{ backgroundColor: "#FFFFFF" }}
                  error={this.state.searchUpdatedDateCheck}>
                    <MenuItem value="" key="">&ensp;</MenuItem>
                    {year_list}
                </Select>

                <Box className={classes.span} display="inline">&ensp;～&ensp;</Box>

                <Select
                  labelId="updated_Date_label_end"
                  id="updatedDateEnd"
                  name="searchUpdatedDateEnd"
                  variant="outlined"
                  size={"small"}
                  className={classes.pullDown}　
                  value={this.state.searchUpdatedDateEnd}
                  onChange={this.onChangeSearchField.bind(this)}
                  style={{ backgroundColor: "#FFFFFF" }}
                  error={this.state.searchUpdatedDateCheck}
                  >
                    <MenuItem value="" key="">&ensp;</MenuItem>
                    {year_list}
                </Select>
              </Box>
            </Box>

            {/* 検索中心*/ }
            <Box className={classes.searchAreaCenter}>
              <Box className="rightBlocl">
                  <InputLabel ref="<Box>"id="expiry_Date_label" className={classes.InputLabel}>有効期限</InputLabel>

                  <Select
                    labelId="expiry_Date_label_Start"
                    id="expiryDateStart"
                    name="searchExpiryDateStart"
                    variant="outlined"
                    size={"small"}
                    className={classes.pullDown}　
                    value={this.state.searchExpiryDateStart}
                    onChange={this.onChangeSearchField.bind(this)}
                    style={{ backgroundColor: "#FFFFFF" }}
                    error={this.state.searchExpiryDateCheck}
                    >

                    <MenuItem value="" key="">&ensp;</MenuItem>
                    {year_list}
                  </Select>

                  <Box className={classes.span} display="inline">&ensp;～&ensp;</Box>

                  <Select
                    labelId="expiry_Date_label_End"
                    id="expiryDateEnd"
                    name="searchExpiryDateEnd"
                    variant="outlined"
                    size={"small"}
                    className={classes.pullDown}　
                    value={this.state.searchExpiryDateEnd}
                    onChange={this.onChangeSearchField.bind(this)}
                    style={{ backgroundColor: "#FFFFFF" }}
                    error={this.state.searchExpiryDateCheck}
                    >

                    <MenuItem value="" key="">&ensp;</MenuItem>
                    {year_list}
                  </Select>
                </Box>
              <Box className={classes.under}>
                <InputLabel ref="<Box>"id="acquisition_Date_label" className={classes.InputLabel}>取得期間</InputLabel>

                <Select
                  labelId="acquisition_Date_label_Start"
                  id="acquisitionDateStart"
                  name="searchAcquisitionDateStart"
                  variant="outlined"
                  size={"small"}
                  className={classes.pullDown}　
                  value={this.state.searchAcquisitionDateStart}
                  onChange={this.onChangeSearchField.bind(this)}
                  style={{ backgroundColor: "#FFFFFF" }}
                  error={this.state.searchAcquisitionDateCheck}>

                    <MenuItem value="" key="">&ensp;</MenuItem>
                    {year_list}
                </Select>

                <Box className={classes.span} display="inline">&ensp;～&ensp;</Box>

                <Select
                  labelId="acquisition_Date_label_End"
                  id="acquisitionDateEnd"
                  name="searchAcquisitionDateEnd"
                  variant="outlined"
                  size={"small"}
                  className={classes.pullDown}　
                  value={this.state.searchAcquisitionDateEnd}
                  onChange={this.onChangeSearchField.bind(this)}
                  style={{ backgroundColor: "#FFFFFF" }}
                  error={this.state.searchAcquisitionDateCheck}>

                    <MenuItem value="" key="">&ensp;</MenuItem>
                    {year_list}
                </Select>
              </Box>
            </Box>

            {/* 検索右側*/}
            <Box className={classes.searchAreaRight} >

                <Box width={90} display='inline-block'>
                  <Fab
                    className={classes.searchFabColor}
                    onClick={this.search.bind(this,false)}
                  >
                    <SearchOutlinedIcon style={{ fontSize: 30 }} />
                  </Fab>
                </Box>
                <Box width={70} display='inline-block'>
                  <Fab
                    className={classes.searchFabColor}
                    onClick={this.onClearSearchField.bind(this)}
                  >
                    <CancelPresentationIcon style={{ fontSize: 30 }} />
                  </Fab>
                </Box>
                
            </Box>
          </Box>
        </Box>
      </Box>
    );

  /***********************************************************************************************
  * 画面のテーブルのヘッダ情報のJSXとpropsの設定です。
  * 検索結果を表示する1レコード文をlistとして宣言しており、(429行目)
  * propsにレコードの情報が入るようにmapしています。
  * 宣言のみなので、検索ボタンが押されてメソッドが走るまではヘッダ部分の描画になります。
  ***********************************************************************************************/
    const mstTbl = (
      <Box style={{ paddingBottom: "16px" }}>

        <Box
          style={{
            borderBottom: `1px solid #525457`,
            borderRadius:'5px'
          }}
        >
          <Box display={`flex`} justifyContent={`space-between`} flexGrow={1}>
            <Box className={classes.mstTblColFirst}>
              <Checkbox
                onChange={this.handleCheckAll.bind(this)}
                style={{ color: "#4A4A4B" }}
              />
            </Box>
            <Box className={classes.mstTblCol}>免許ID</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>免許名</Box>
            <Box className={classes.mstTblCol}>有効期限</Box>
            <Box className={classes.mstTblCol}>更新日</Box>
            <Box className={classes.mstTblCol}>取得日</Box>
          </Box>
          {list}
          {this.state.inputForm &&
            this.state.inputForm.map(
              ({ licenseId, licenseName, expiry_date, updated_date, acquisition_date }, i) =>
                this.addForm(licenseId, licenseName, expiry_date, updated_date, acquisition_date, i)
            )}
        </Box>

          {/*****************ページング対応**************/}
          <Box display="flex" justifyContent="center" marginTop="15px">
            <Box>
              <Pagination 
                count={this.props.model_pageCount} 
                color="primary" 
                onChange={(event,page) => this.pagenationFunction(event,page)}
                page={this.props.model_currentPage}
                />
            </Box>
          </Box>
          {/****************ページング対応END************/}
      </Box>
    );
  /***********************************************************************************************
  * 右下の追加削除ボタン
  * 関数名fubDivになっているが、FabDivの間違い？
  ***********************************************************************************************/
    const fubDiv = (
      <Box display={`flex`} flexGrow={1} justifyContent="flex-end" height={70}>
        <Box width={50}>
          <Fab
            size="small"
            className={classes.fabColor}
            onClick={this.handleAddForm.bind(this)}
            disabled={
              this.state.inputForm.slice().length !== 0 || this.state.addLock
            }
          >
            <AddIcon />
          </Fab>
        </Box>
        <Box width={50}>
          <Fab
            size="small"
            className={classes.fabColor}
            onClick={this.handleRemoveForm.bind(this)}
            disabled={this.state.inputForm.slice().length === 0}
          >
            <RemoveIcon />
          </Fab>
        </Box>
      </Box>
    );

  /***********************************************************************************************
  * 画面下部の「登録」「変更」「削除」のJSXとpropsの設定のメソッドです。
  ***********************************************************************************************/
    const buttonDiv = (
      <Grid container spacing={3} justify="center" style={{textAlign:"center"}}>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.insert.bind(this), '登録しますか？')}
            endIcon={<PostAddIcon />}
            disabled={this.state.registerLock}
          >
            登録
          </Button>
        </Grid>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.update.bind(this), '更新しますか？')}
            endIcon={<EditIcon />}
            disabled={this.state.updateLock}
          >
            変更
          </Button>
        </Grid>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.delete.bind(this), '削除しますか？')}
            endIcon={<DeleteIcon />}
            disabled={this.state.deleteLock}
          >
          
            削除
            
          </Button>
        </Grid>
      </Grid>
    );

    return (
      <Box style={{ height: "100vh", width: "100vw" }}>
        <Header value={this.state.errorMessage}/>
        <Alert 
          id="successAlert"
          style={{display:'none'}}
          className={classes.successAlertStyle}
          severity="success">
            {this.state.successMessage}
        </Alert>
        <Box
          style={{
            height: "calc(100% - 96px)",
            width: "calc(100% - 32px)",
            padding: "16px",
            overflow: "auto"
          }}
        >
          <Box minWidth={975}>
            {titleDiv}
            {searchDiv}
            {/****  検索ヒット数対応  ****/}
            <Box  className={classes.searchResult}>
              <span>検索結果：</span>
              <span>{this.props.model_hitsCount}</span>
              <span> 件</span>
            </Box>
            {mstTbl}
            {fubDiv}
            {buttonDiv}
          </Box>
          <AlertDialog/>
        </Box>
      </Box>
    );
  }

  opneDialog (func, message) {

    var Dialog = document.getElementById('dialogBox');
    var okButton = document.getElementById('dialogOkButton');
    var cancelButton = document.getElementById('dialogCancelButton');
 
    document.getElementById('dialogMessage').innerHTML= message;
 
    // ダイアログ表示
    Dialog.style.display = '';
    // OK 押下の処理
    okButton.onclick = func;
    // cancel　押下の処理
    cancelButton.onclick = function() {
     Dialog.style.display = 'none';
     };
   }

  /***********************************************************************************************
  * 検索ボタンがクリックされた時に実行されるメソッドです。
  * 一応検索フィールドに入力された際に入力チェックを行っており、入力謝りがあればreturnを返しています
  ***********************************************************************************************/
  async search(isPageNation) {

    const { dispatch } = this.props;

    // 検索条件の項目
    const _searchLicenseName = this.state.searchLicenseName || null;
    const _searchExpiryDateStart = this.state.searchExpiryDateStart || null;
    const _searchExpiryDateEnd = this.state.searchExpiryDateEnd || null;
    const _searchUpdatedDateStart = this.state.searchUpdatedDateStart || null;
    const _searchUpdatedDateEnd = this.state.searchUpdatedDateEnd || null;
    const _searchAcquisitionDateStart = this.state.searchAcquisitionDateStart || null;
    const _searchAcquisitionDateEnd = this.state.searchAcquisitionDateEnd || null;

    /*****************ページング対応**************/
    let _currentPage = 0; 
    if(isPageNation) {
      _currentPage = this.props.model_currentPage -1;
    }
    else {
      dispatch(currentPageLicense(1));
    }
    /****************ページング対応END************/


    await this.setState({errorMessage:''});

    // 相関チェック
    if(_searchExpiryDateStart > _searchExpiryDateEnd) {
      console.log("有効期限不正");
      this.setState({ searchExpiryDateCheck:true });
      return;
    }

    if(_searchUpdatedDateStart > _searchUpdatedDateEnd){
      console.log("更新期間不正");
      this.setState({ searchUpdatedDateCheck:true });
      return;
    }

    if(_searchAcquisitionDateStart > _searchAcquisitionDateEnd){
      console.log("取得期間不正");
      this.setState({ searchAcquisitionDateCheck:true });
      return;
    }

    const param = Cookies.get('token');

  await axios.post(
    REQUEST_PATH + 'api/license/list',
      {
        //pageIndex: 0,
        /*****************ページング対応**************/
        pageIndex: _currentPage,
        /****************ページング対応END************/
        pageSize: 20, //100,
        licenseName: _searchLicenseName,
        expiryDateStart: _searchExpiryDateStart === null 
          ? _searchExpiryDateStart : new Date().setFullYear(_searchExpiryDateStart),
        expiryDateEnd: _searchExpiryDateEnd === null 
          ? _searchExpiryDateEnd : new Date().setFullYear(_searchExpiryDateEnd),
        updatedDateStart: _searchUpdatedDateStart === null 
          ? _searchUpdatedDateStart : new Date().setFullYear(_searchUpdatedDateStart),
        updatedDateEnd: _searchUpdatedDateEnd === null 
          ? _searchUpdatedDateEnd : new Date().setFullYear(_searchUpdatedDateEnd),
        acquisitionDateStart: _searchAcquisitionDateStart === null 
          ? _searchAcquisitionDateStart : new Date().setFullYear(_searchAcquisitionDateStart),
        acquisitionDateEnd: _searchAcquisitionDateEnd === null 
        ? _searchAcquisitionDateEnd : new Date().setFullYear(_searchAcquisitionDateEnd),
      }
      ,{
        param,
        withCredentials: true,
      }
   
    ).then(response => {
      this.setState({ searchExpiryDateCheck:false });
      this.setState({ searchUpdatedDateCheck:false });
      this.setState({ searchAcquisitionDateCheck:false });

      /*****************ページング対応**************/
      dispatch(pageCountLicense(response.data.totalPageCount));
      /****************ページング対応END************/

      // ヒット件数
      dispatch(hitsCountLicense(response.data.totalCount));

      dispatch(listLicense(response.data.list));
    }).catch(e =>{
      // 失敗時
      this.setState({errorMessage:'検索に失敗しました'});
      console.log(e);
    });
  }
  /***********************************************************************************************
  * 登録ボタンをクリックされた時に実行されるメソッド
  * /api/license/createにJSON形式でデータを投げています
  * ここで入力フィールドチェックを行ってください。
  * 2020年2月5日時点では入力フォームが一つしかでないので入力謝りがあった際にはcontinue文抜けています
  * ヘッダーのメッセージ欄にエラーメッセージを投げてreturnしてメソッドを終了するのが適切だと思います。
  ***********************************************************************************************/
  async insert() {
    const fieldList = this.state.inputForm.slice();
    const { model } = this.props;
    const param = Cookies.get('token');

    await this.setState({errorMessage:''});

    if(this.validate(fieldList[0].licenseId, fieldList[0].licenseName,
      fieldList[0].expiryDate,fieldList[0].updatedDate,fieldList[0].acquisitionDate,)) {
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }

    for (let i in fieldList) {

      await axios.post(REQUEST_PATH + 'api/license/create', {
        licenseId: fieldList[i].licenseId,
        licenseName: fieldList[i].licenseName,
        expiryDate: fieldList[i].expiryDate,
        updatedDate: fieldList[i].updatedDate,
        acquisitionDate: fieldList[i].acquisitionDate,
      },{
        param,
        withCredentials: true,
      }).then(response => {
      model.push({
        licenseId: fieldList[i].licenseId,
        licenseName: fieldList[i].licenseName,
        expiryDate: fieldList[i].expiryDate,
        updatedDate: fieldList[i].updatedDate,
        acquisitionDate: fieldList[i].acquisitionDate,
        checked: false
      });

        fieldList.pop();
        this.setState({ inputForm: fieldList });
        this.openeSuccessAlert("登録しました");
      }).catch(e =>{
        // 失敗時
        this.setState({errorMessage:'検索に失敗しました'});
        console.log(e);
      });
    }
      this.forceUpdate();
      this.checkState();
      document.getElementById('dialogBox').style.display = 'none';
    }

  /***********************************************************************************************
  * 変更ボタンをクリックされた時に実行されるメソッド
  * /api/product-model/updateにJSON形式でデータを投げています
  * ここでも入力フィールドチェックを行ってください。
  ***********************************************************************************************/
  async update() {
    const changeItem = this.state.changeForm;
    const { model } = this.props;
    const param = Cookies.get('token');

    await this.setState({errorMessage:''});

    if(this.validate(changeItem.licenseId, changeItem.licenseName,
      changeItem.expiryDate,changeItem.updatedDate,changeItem.acquisitionDate,)) {
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }
    
    await axios.put(REQUEST_PATH + 'api/license/update', {
        licenseId: changeItem.licenseId,
        licenseName: changeItem.licenseName,
        expiryDate: changeItem.expiryDate,
        updatedDate: changeItem.updatedDate,
        acquisitionDate: changeItem.acquisitionDate
    },{
        param,
        withCredentials: true,
    }).then( response => {
      const _model = model.filter(({ licenseId: id }) => {
        return changeItem.licenseId === id;
      })[0];
      if (_model) {
        _model.licenseName = changeItem.licenseName;
        _model.expiryDate = changeItem.expiryDate;
        _model.updatedDate = changeItem.updatedDate;
        _model.acquisitionDate = changeItem.acquisitionDate;
        _model.checked = false;
      }
      this.checkState();
      this.setState({ changeForm: null });
      this.openeSuccessAlert("更新しました");
    }).catch(e =>{
      this.setState({errorMessage:'更新に失敗しました'});
      console.log(e)
    });

    document.getElementById('dialogBox').style.display = 'none';
    this.checkState();
}

/***********************************************************************************************
  * 削除ボタンをクリックされた時に実行されるメソッド
  ***********************************************************************************************/
  async delete() {
    const { model } = this.props;
    const deleteList = [];

    const param = Cookies.get('token');

    await this.setState({errorMessage:''});

    for (let i in model) {
        if (model[i].checked) {
          await axios.delete(
            REQUEST_PATH + 'api/license/delete/' + model[i].licenseId,
            {
              param,
              withCredentials: true,
            }).then( response => {
              deleteList.push(model[i].licenseId);
            }).catch(e =>{
              this.setState({errorMessage:'削除に失敗しました'});
              console.log(e)
            });       
        }
    }

　　if(deleteList.length > 0){
  　　deleteList.forEach(id => {
    　　const index = model.findIndex(v => v.licenseId === id);
    　　model.splice(index, 1);
      });
      this.openeSuccessAlert("削除しました");
    }
    this.checkState();
    this.forceUpdate();
    document.getElementById('dialogBox').style.display = 'none';
  }

  validate(licenseId , licenseName) {
    var isFailed = false;
    this.setState({licenseNameError:false});

    if(licenseId === null || licenseId === '' || licenseId === 'undefined' || 
      isNaN(licenseId)) {
      this.setState({licenseIdHelperText:"入力に誤りがあります"});
      this.setState({licenseIdError:true});
      isFailed = true;
    }

  if(licenseName === null || licenseName === '' || licenseName === 'undefined') {
      this.setState({licenseNameHelperText:"入力に誤りがあります"});
      this.setState({licenseNameError:true});
      isFailed = true;
    }
    return isFailed;
  }

  // 成功アラートの表示
  openeSuccessAlert(msg){

    document.getElementById('successAlert').style.display = '';
    this.setState({ successMessage: msg});

    // 2秒で消えるようにする。
    setTimeout(() => {
      document.getElementById('successAlert').style.display = 'none';
    },2000);
  }

  static get propTypes() {
    return {};
  }

  // Styleの設定
  static get styles() {
    return theme => ({
      inputLicensesSarch: {
        width: 470,
        marginLeft:"10px"
      },
      searchInputText:{
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        lineheight: 2.5,
      },
      mstTblItemFirst: {
        border: "1px solid #b0bec5",
        borderLeft: "1px solid #525457",
        borderBottom: "0",
        textAlign: "center",
        width: 70,
        flexGrow: 1
      },
      mstTblItem: {
        border: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      mstTblColorItem: {
        border: "1px solid #b0bec5",
        borderRight: "1px solid #525457",
        borderLeft: "0",
        borderBottom: "0",
        width: 280,
        flexGrow: 1,
        textAlign: "center",
        color: "#4A4A4B",
        fontWeight: 500,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
      },
      addMstTblItemFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        width: 70,
        height: 80,
        flexGrow: 1
      },
      addMstTblItem: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: 280,
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      addMstTblColorItem: {
        border: "1px solid #525457",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: 280,
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      root: {
        color: "#607d8b",
        "&$checked": {
          color: "#FF7200"
        }
      },
      checked: {},
      titleHeaderColor: {
        border: "1px solid #525457",
        backgroundColor: "#1976d2",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 20,
        fontWeight: "bold",
        borderRadius:"5px"
      },
      tblHeaderColor: {
        borderTop: "1px solid #525457",
        borderLeft: "0",
        borderRight: "1px solid #525457",
        backgroundColor: fade("#1565c0", 0.8),
        color: "#4A4A4B",
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold"
      },
      searchTbl: {
        border: "1px solid #525457",
        backgroundColor: "#bbdefb",
        color: "#4A4A4B",
        fontSize: 16,
        borderRadius:"5px"
      },
      mstTblColFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        textAlign: "center",
        width: 70,
        flexGrow: 1
      },
      mstTblCol: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      mstTblColLast: {
        border: "1px solid #525457",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      buttonColor: {
        width: 150,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        // color: "#E1E1E1"
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing:10
      },
      searchFabColor: {
        backgroundColor: "#2196f3",
        color: "#EEEEEE"
      },
      fabColor: {
        backgroundColor: "#26a69a",
        color: "#EEEEEE"
      },
      under:{
        marginTop: 15
      },
      searchAreaLeft:{
        display:'inline-block'
      },
      searchAreaCenter:{
        marginLeft: '5%',
        display:'inline-block'
      },
      searchAreaRight:{
        float:'right',
        paddingTop: '2%'
      },
      InputLabel:{
        display:"inline",
        marginRight:5
      },
      span:{
        fontSize:25,
        marginLeft:"10px"
      },
      pullDown:{
        backgroundColor: "#FFFFFF",
        width: 200,
        borderRadius: 5,
        height:40,
        marginLeft:"10px"
      },
      searchResult:{
        textAlign:"left",
        marginBottom:"10px",
        paddingLeft:"5px",
      },
      itemAlingnLeft:{
        justifyContent: "left",
        paddingLeft: '1%',
      },
      itemTitlePaddingLeft:{
        paddingLeft: '1%',
      },
      successAlertStyle: {
        position: 'relative',
        justifyContent: 'center',
        width: '100%',
        backgroundColor:'#80cbc4',
        fontSize:'1rem'
      },
    });
  }
}

// redux/index.js プロパティ
const mapStateToProps = ({ licenseMaster: { list } }) => ({ model: list });
const mapStateToProps2 = ({ licenseMaster_pageCount: {totalPageCount} }) => ({model_pageCount:totalPageCount});
const mapStateToProps3 = ({ licenseMaster_currentPage: {currentPage} }) => ({model_currentPage:currentPage});
const mapStateToProps4 = ({ licenseMaster_hitsCount: {hitsCount} }) => ({model_hitsCount:hitsCount});

const mapDispatchToProps = dispatch => ({ dispatch });
const { styles } = Component;

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  connect(mapStateToProps2,mapDispatchToProps),
  connect(mapStateToProps3,mapDispatchToProps),
  connect(mapStateToProps4,mapDispatchToProps),
)(Component);
