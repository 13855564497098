import { handleAction } from 'redux-actions';
import { listProductList, pageCountProduct, currentPageProduct, hitsCountProduct } from '../actions';

const defaultState = { list: [] };
const defaultState2 = { totalPageCount:0 };
const defaultState3 = { currentPage:1 };
const defaultState4 = { hitsCount:0 };

export const productListReducer = handleAction(listProductList, (state, { payload }) => ({ ...state, list: payload }), defaultState);

// ページネーション対応
export const ProductListReducerPageCount = handleAction(pageCountProduct, (state, { payload }) => ({ ...state, totalPageCount: payload }), defaultState2);
export const ProductListReducerCurrentPage = handleAction(currentPageProduct, (state, { payload }) => ({ ...state, currentPage: payload }), defaultState3);

// ヒット件数対応
export const ProductListReducerHitsCount = handleAction(hitsCountProduct, (state, { payload }) => ({ ...state, hitsCount: payload }), defaultState4);