import { push } from 'connected-react-router'
import { PAGES } from '../pages';

const fncs = Object.entries(PAGES).reduce((fncs, [name, { path }]) => {
    Object.assign(fncs, { [name]: (state) => push(path, state) });
    return fncs;
}, {});

export const moveTo = fncs;

