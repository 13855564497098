import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import CalendarApp from "../calendar/CalendarApp";
import ScheduleApp from "../schedule/ScheduleApp";
import TopPage from "../components/TopPage";
import Header from "../header/Header";

class Component extends React.Component {
  constructor(props) {
    super(props);

    this.state = { Component: CalendarApp,ShowMessage:"",MessageStatus:"ERR",CalendarDate:""};
  }

  selectCalendar1 (event) {
    this.setState({CalendarDate: event});
    this.setState({ Component: CalendarApp });
  }
  async selectCalendar2 (event) 
    {
      await this.setState({ CalendarDate: event});
      await this.setState({ Component:TopPage});
      await this.setState({ Component: ScheduleApp });
  }

  render() {
    const classes = this.props.classes;
    console.log(classes);

    const { Component } = this.state;
  
    return (
      <div className="main_div">
        <Header />
        <div className="calendar_div">
          <Component
            calendar1={this.selectCalendar1.bind(this)}
            calendar2={this.selectCalendar2.bind(this)}
            date={this.state.CalendarDate}
          />
        </div>
      </div>
    );
  }
  static get propTypes() {
    return {};
  }

  static get styles() {
    return theme => ({});
  }

  static get mapStateToProps() {
    return state => ({});
  }

  static get mapDispatchToProps() {
    return dispatch => ({ dispatch });
  }
}
// TODO 分割 ContainerとComponent
const { styles, mapStateToProps, mapDispatchToProps } = Component;
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
