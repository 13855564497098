import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles, fade } from "@material-ui/core/styles";
import { Box, Button, Grid, Fab, Checkbox, TextField,InputLabel } from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "axios";
import EditIcon from '@material-ui/icons/Edit';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Pagination from '@material-ui/lab/Pagination';
import Cookies from "js-cookie";

import Header from "../header/Header";
import AlertDialog from "../components/Dialog";
import TotalBillingDialog from "../components/OutPutTotalBillingExcelDialog";
import moment from "moment";
import { moveTo, listCompany, pageCountCompany, currentPageCompany, hitsCountCompany } from "../actions";
import { saveAs } from 'file-saver';
import REQUEST_PATH from "../components/Common";
import { Alert } from '@material-ui/lab';

import Scroll from 'react-scroll';


class Component extends React.Component {
  state = {
    // 検索項目
    searchCompanyName:'',
    searchCompanyPerson:'',
    searchPhoneNumber:'',

    // ボタン状態
    registerLock: false,
    updateLock:true,
    deleteLock:true,
    priceLock:true,
    outPutExcelLock:true,
    // エラーメッセージ
    errorMessage:'',

    successMessage:'',
  };

  constructor(props){
    super(props);

  }

  // render後、ボタン制御（遷移元からの復帰対策）
  componentDidMount() {
    this.checkState();
  }

  // 検索域のイベント
  onChangeSearchField(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  // 検索域のクリアボタン押下
  onClearSearchField() {

    this.setState(
      {
        searchCompanyName: "",
        searchCompanyPersonName: "",
        searchPhoneNumber: "",  
      });
    this.forceUpdate();
  }

  handleToAboutPage() {
    const { dispatch } = this.props;
    dispatch(moveTo.Sample());
  }

  handleCheckAll(evt) {
    const { model } = this.props;
    for (let i in model) {
      model[i].checked = evt.target.checked;
    }
    this.checkState();
    this.forceUpdate();
  }

  //チェックボックスにチェックを入れた時に呼び出される関数
  handleCheck(evt) {
    const { model } = this.props;

    for (let i in model) {
      model[i].checked = false;
    }

    const _model = model.filter(({ companyId: id }, index) => {
      return evt.target.name === id;
    })[0];
    if (_model) {
      _model.checked = evt.target.checked;
    }

    this.checkState();
    this.forceUpdate();
  }

  /*****************ページング対応**************/
  async pagenationFunction(obj,number) {
    const { dispatch } = this.props;

    await dispatch(currentPageCompany(number));
    await this.search(true);
    await this.checkState();
  }
  /****************ページング対応END************/

  checkState() {
    const { model } = this.props;
    const checkItem = model.filter(_model => {
      return _model.checked;
    });

    // チェックが0の活性その他非活性
    if (checkItem && checkItem.length === 0) {
      this.setState({ registerLock:false });
      this.setState({ updateLock:true });
      this.setState({ deleteLock:true });
      this.setState({ priceLock:true });
      this.setState({ outPutExcelLock:true });
    } 
    if (checkItem && checkItem.length === 1) {

      this.setState({ registerLock:true });
      this.setState({ outPutExcelLock:false });
      this.setState({ updateLock:false });
      this.setState({ deleteLock:false });
      this.setState({ priceLock:false });

    }

    if (checkItem && checkItem.length  > 1) {
      this.setState({ registerLock:true });
      this.setState({ updateLock:true });
      this.setState({ outPutExcelLock:true });
      this.setState({ deleteLock:false });
      this.setState({ priceLock:true });
    }
  }

  handleRemoveForm() {
    const fieldList = this.state.inputForm.slice();
    fieldList.pop();
    this.setState({ inputForm: fieldList });
    this.setState({ registerLock :true});
  }

  // 取引先入力画面の遷移
  moveToCompanyResister() {
    const { model } = this.props;
    const checkItem = model.filter(_model => {
      return _model.checked;
    })[0];
    if(checkItem) {
      this.props.history.push({ pathname: '/companyMasterRegist', state: { companyId: checkItem.companyId }});
    } else {
      this.props.history.push({ pathname: '/companyMasterRegist', state: { companyId: ''}});
    }
  }

  // 注文データ入力画面遷移(原本)
  moveToOrderResister() {
    const { model } = this.props;
    const checkItem = model.filter(_model => {
      return _model.checked;
    })[0];

    if(checkItem) {
      this.props.history.push({ pathname: '/orderRegist', state: { companyId: checkItem.companyId, companyName: checkItem.companyName }});
    } else {
      return
    }
  }

  /***********************************************************************************************
  * 検索結果のリスト表示機能、帰ってきた行数分だけPushを繰り返す。
  ***********************************************************************************************/
  getMstItemList = () => {
    const list = [];
    const { classes, model } = this.props;
    for (const i in model) {
      list.push(
        <Box
          key={i}
          display={`flex`}
          justifyContent={`space-between`}
          flexGrow={1}
        >
          <>
            <Box className={classes.mstTblItemFirst}>
              <Checkbox
                key={model[i].companyId}
                name={model[i].companyId}
                checked={model[i].checked || false}
                onChange={this.handleCheck.bind(this)}
                inputProps={{ "aria-label": "checkbox" }}
                classes={{
                  root: classes.root,
                  checked: classes.checked
                }}
              />
            </Box>
            <Box className={classes.mstTblItem + ' ' + classes.companyIdCell}>{model[i].companyId}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft + ' ' + classes.companyNameCell}>{model[i].companyName}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{model[i].personName}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{model[i].phoneNumber}</Box>
            <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft + ' ' + classes.mailAddressCell}>{model[i].mailAddress}</Box>
          </>
        </Box>
      );
    }
    return <Box>{list}</Box>;
  };

  // タイトル域
  render() {
    const { classes } = this.props;
    const list = this.getMstItemList();

    const titleDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>
          <Box p={1} className={classes.titleHeaderColor}>
            取引先一覧画面
          </Box>
        </Box>
      </Box>
    );

    //検索フォーム
    const searchDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>

        {/*display={`flex`}*/}
        <Box display={`flex`} p={2} className={classes.searchTbl}>
     
            {/* 検索左側*/ }
            
            <Box className={classes.searchAreaLeft}>
              <Box className={classes.searchFirstElement }>
                <Box display={`flex`}>
                  <InputLabel ref="<Box>" id="company_name_label" className={classes.InputLabel}>取引先名</InputLabel>
                  <TextField
                    labelId="company_name_label"
                    id="companyNameLabel"
                    name="searchCompanyName"
                    variant="outlined"
                    size="small"
                    className={classes.searchInputText + ' ' + classes.searchInputCompanyName}
                    value={this.state.searchCompanyName}
                    onChange={this.onChangeSearchField.bind(this)}
                  />
                </Box>
              </Box>
              
              <Box className={classes.searchElement }>
                <Box display={`flex`}>
                    <InputLabel ref="<Box>" id="company_person_name_label" className={classes.InputLabel}>取引先担当</InputLabel>
                  
                    <TextField
                    labelId="company_person_name_label"
                    id="companyPersonNameLabel"
                    name="searchCompanyPersonName"
                    variant="outlined"
                    size="small"
                    className={classes.searchInputText  + ' ' + classes.searchInputCompanyName}
                    value={this.state.searchCompanyPersonName}
                    onChange={this.onChangeSearchField.bind(this)}
                  />
                </Box>
              </Box>

              <Box className={classes.searchElement }>
                <Box display={`flex`}>
                  <InputLabel ref="<Box>" id="phone_number_label" className={classes.InputLabel}>電話番号</InputLabel>
                  <TextField
                    labelId="phone_number_label"
                    id="phoneNumberLabel"
                    name="searchPhoneNumber"
                    variant="outlined"
                    size="small"
                    className={classes.searchInputText  + ' ' + classes.searchInputCompanyName}
                    value={this.state.searchPhoneNumber}
                    onChange={this.onChangeSearchField.bind(this)}
                  />
                </Box>
              </Box>
            </Box>

            {/* 検索右側*/}
            <Box className={classes.searchAreaRight} >

                <Box width={90} display='inline-block'>
                  <Fab
                    className={classes.searchFabColor}
                    onClick={this.search.bind(this,false)}
                  >
                    <SearchOutlinedIcon style={{ fontSize: 30 }} />
                  </Fab>
                </Box>
                <Box width={70} display='inline-block'>
                  <Fab
                    className={classes.searchFabColor}
                    onClick={this.onClearSearchField.bind(this)}
                  >
                    <CancelPresentationIcon style={{ fontSize: 30 }} />
                  </Fab>
                </Box>
                
            </Box>
          </Box>
        </Box>
      </Box>
    );

  /***********************************************************************************************
  * 画面のテーブルのヘッダ情報のJSXとpropsの設定です。
  * 検索結果を表示する1レコード文をlistとして宣言しており、(429行目)
  * propsにレコードの情報が入るようにmapしています。
  * 宣言のみなので、検索ボタンが押されてメソッドが走るまではヘッダ部分の描画になります。
  ***********************************************************************************************/
    const mstTbl = (
      <Box style={{ paddingBottom: "16px" }}>

        <Box
          style={{
            // border: `1px solid #525457`,
            borderBottom: `1px solid #525457`,
            borderRadius:'5px'
          }}
        >
          <Box display={`flex`} justifyContent={`space-between`} flexGrow={1}>
            <Box className={classes.mstTblColFirst}>
              <Checkbox
                checked={false}
                //onChange={this.handleCheckAll.bind(this)}
                style={{ color: "#4A4A4B" }}
              />
            </Box>
            <Box className={classes.mstTblCol + ' ' + classes.companyIdCell}>取引先ID</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft + ' ' + classes.companyNameCell}>取引先名</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>取引先担当</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>電話番号</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft + ' ' + classes.mailAddressCell}>メールアドレス</Box>
          </Box>
          {list}
        </Box>
          {/*****************ページング対応**************/}
          <Box display="flex" justifyContent="center" marginTop="15px"> 
            <Box>
              <Pagination 
                count={this.props.model_pageCount} 
                color="primary" 
                onChange={(event,page) => this.pagenationFunction(event,page)}
                page={this.props.model_currentPage}
                />
            </Box>
          </Box>
          {/****************ページング対応END************/}
      </Box>
    );

  /***********************************************************************************************
  * 画面下部の「登録」「変更」「削除」「単価入力入力」のJSXとpropsの設定のメソッドです。
  ***********************************************************************************************/
    const buttonDiv = (
      <Grid container spacing={3} /*justify="center"*/ style={{textAlign:'center'}}>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.moveToCompanyResister.bind(this)}
            endIcon={<PostAddIcon />}
            disabled={this.state.registerLock}
          >
            登録
          </Button>
        </Grid>
        <Grid item xs={2} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            //onClick={this.update.bind(this)}
            endIcon={<EditIcon />}
            disabled={this.state.updateLock}
            onClick={this.moveToCompanyResister.bind(this)}
          >
            変更
          </Button>
        </Grid>
        <Grid item xs={2} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.delete.bind(this), '削除しますか？')}
            endIcon={<DeleteIcon />}
            disabled={this.state.deleteLock}
          >
          
            削除
            
          </Button>
        </Grid>

        <Grid item xs={2} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.moveToOrderResister.bind(this)}
            disabled={this.state.priceLock}
          >
          
            単価入力
            
          </Button>
        </Grid>

        <Grid item xs={2} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.outPutExcelButton}
            onClick={this.opneTotalBillingDialog.bind(this,this.outPutTatalBillingExcel.bind(this)) }
            disabled={this.state.outPutExcelLock}
          >
            請求書発行
            
          </Button>
        </Grid>
      </Grid>
    );

    return (
      <Box style={{ height: "100vh", width: "100vw" }}>
        <Header value={this.state.errorMessage}/>
        <Alert 
          id="successAlert"
          style={{display:'none'}}
          className={classes.successAlertStyle}
          severity="success">
            {this.state.successMessage}
        </Alert>
        <Box
          style={{
            height: "calc(100% - 96px)",
            width: "calc(100% - 32px)",
            padding: "16px",
            overflow: "auto"
          }}
        >
          <Box minWidth={975}>
            {titleDiv}
            {searchDiv}
            {/****  検索ヒット数対応  ****/}
            <Box  className={classes.searchResult}>
              <span>検索結果：</span>
              <span>{this.props.model_hitsCount}</span>
              <span> 件</span>
            </Box>
            {mstTbl}
            {buttonDiv}
          </Box>
          <AlertDialog/>
          <TotalBillingDialog/>
        </Box>
      </Box>
    );
  }

  async outPutTatalBillingExcel() {

    const { model } = this.props;
    const checkItem = model.filter(_model => {
      return _model.checked;
    })[0];
    const _companyId = checkItem.companyId;
    const _companyName = checkItem.companyName;
    var billingExcelDate = document.getElementById('billingExcelDate');

    if( billingExcelDate.value === '' || billingExcelDate.value === null || billingExcelDate.value === undefined){
      console.log("期間不正");
      return;
    }

    const startYear =  moment(billingExcelDate.value).year();
    const startMonth =  moment(billingExcelDate.value).month() + 1;
    const _startDate = moment(startYear+'-'+startMonth+'-01').format('YYYY/MM/DD');
    const _endDate = moment(_startDate).endOf('month').format("YYYY/MM/DD");
    const payment = await this.getPayment(_companyId);
    let _paymentDate = null;
    const _fileName = '合計請求書.xlsm';
    const _downloadFileName = '【ご請求書'+　startMonth+'月度】' + _companyName + '様.xlsm'

    const param = Cookies.get('token');
    
    if(((payment * 1) % 2) === 0) {
      _paymentDate = moment(_endDate).add(payment, "days").endOf('month').format("YYYY-MM-DD");
    } else {
      const addMonth = Math.floor(payment / 30);
      const addDays = (payment * 1) - (addMonth * 30);
      _paymentDate = moment(_endDate).add(addDays, "days").add(addMonth, "month").format("YYYY-MM-DD");
    }

    await axios.get(REQUEST_PATH + 'api/excel-total-billing/list/',{
      params: { 
        companyId     : checkItem.companyId,
        startDate     : this.getDateJstFormat(new Date(_startDate)),
        endDate       : this.getDateJstFormat(new Date(_endDate)),
        paymentDate   : this.getDateJstFormat(new Date(_paymentDate)),
        fileName      : _fileName,
      },
      responseType: "blob",
      param,
      withCredentials: true,
    }).then(response => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      });
      // ファイル名デコード
      //const contentDisposition = response.headers["content-disposition"];
      //const fileName = this.getFileName(contentDisposition);
      // ファイルのダウンロード
      saveAs(blob, _downloadFileName);

    }).catch(e => {
      console.log(e);
      this.setState({ errorMessage: "帳票出力に失敗しました" });
    });
    
    document.getElementById('totalBillingDialog').style.display = 'none'; 
  }

  // ファイル名のデコード 
  getFileName(contentDisposition) { 
    let fileName = contentDisposition.substring(
      contentDisposition.indexOf("''") + 2,
      contentDisposition.length
      );

    //デコードするとスペースが"+"になるのでスペースへ置換します
    fileName = decodeURI(fileName).replace(/\+/g, " ");

    return fileName;
    
  }

  // 日本時間変換
  getDateJstFormat(date){
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    return date;
  }

  opneDialog (func, message) {

    var Dialog = document.getElementById('dialogBox');
    var okButton = document.getElementById('dialogOkButton');
    var cancelButton = document.getElementById('dialogCancelButton');
 
    document.getElementById('dialogMessage').innerHTML= message;
 
    // ダイアログ表示
    Dialog.style.display = '';
    // OK 押下の処理
    okButton.onclick = func;
    // cancel　押下の処理
    cancelButton.onclick = function() {
     Dialog.style.display = 'none';
     };
   }

   opneTotalBillingDialog (func) {
    var Dialog = document.getElementById('totalBillingDialog');
    var okButton = document.getElementById('outPutTatalBillingExcel');
    var cancelButton = document.getElementById('outPutTatalBillingExcelCancel');

    // ダイアログ表示
    Dialog.style.display = '';
    // OK 押下の処理
    okButton.onclick = func;
    // cancel　押下の処理
    cancelButton.onclick = function() {
     Dialog.style.display = 'none';
     };
   }

   async getPayment(pram) {

    var payment = 30;
    const param = Cookies.get('token');
    await axios.post(
      REQUEST_PATH + 'api/company/list',
      {
        withCredentials: true,
        param,
        pageIndex: 0,
        pageSize: 100,
        companyId: pram,
      },
      {
        param,
        withCredentials: true,
      }
    ).then(response => {
      // 成功時
      const result = response.data.list[0];
      payment = result.paymentSite;
    }).catch(e => {
      console.log(e);
    });
    return payment;
  }

  /***********************************************************************************************
  * 検索ボタンがクリックされた時に実行されるメソッドです。
  * 一応検索フィールドに入力された際に入力チェックを行っており、入力謝りがあればreturnを返しています
  ***********************************************************************************************/
  async search(isPageNation) {

    const { dispatch } = this.props;

    // 検索条件の項目
    const _searchCompanyName = this.state.searchCompanyName || null;
    const _searchCompanyPersonName = this.state.searchCompanyPersonName || null;
    const _searchPhoneNumber = this.state.searchPhoneNumber || null;

    /*****************ページング対応**************/
    let _currentPage = 0; 
    if(isPageNation) {
      _currentPage = this.props.model_currentPage -1;
    }
    else {
      dispatch(currentPageCompany(1));
    }
    /****************ページング対応END************/

    this.setState({errorMessage:''});

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/company/list',
      {
        //pageIndex: 0,
        /*****************ページング対応**************/
        pageIndex: _currentPage,
        /****************ページング対応END************/
        pageSize: 20, // 1000
        companyName: _searchCompanyName,
        personName: _searchCompanyPersonName,
        phoneNumber: _searchPhoneNumber,
        
      },
      {
        param,
        withCredentials: true,
      }
    ).then(response => {
      /*****************ページング対応**************/
      dispatch(pageCountCompany(response.data.totalPageCount));
      /****************ページング対応END************/

      // ヒット件数
      dispatch(hitsCountCompany(response.data.totalCount));

      dispatch(listCompany(response.data.list));
    }).catch(e => {
      // 失敗時
      this.setState({ errorMessage: '検索に失敗しました' });
      console.log(e);
    });
  }


  /***********************************************************************************************
    * 削除ボタンをクリックされた時に実行されるメソッド
  ***********************************************************************************************/
   
  async delete() {
    const { model } = this.props;
    const deleteList = [];

    this.setState({ errorMessage: '' });
  
    const param = Cookies.get('token');
    for (let i in model) {
      if (model[i].checked) {
        await axios.delete(
          REQUEST_PATH +  'api/company/delete/' + model[i].companyId,
          {
            param,
            withCredentials: true,
          }
          ).then(response => {
          deleteList.push(model[i].companyId);
        }).catch(e => {
          this.setState({ errorMessage: '削除に失敗しました' });
          console.log(e)
        });
      }
    }

    if (deleteList.length > 0) {
      deleteList.forEach(id => {
        const index = model.findIndex(v => v.model === id);
        model.splice(index, 1);
      });
      this.openeSuccessAlert("削除しました");
    }

    this.checkState();
    this.forceUpdate();
    document.getElementById('dialogBox').style.display = 'none';
  }

  // 成功アラートの表示
  openeSuccessAlert(msg){

    document.getElementById('successAlert').style.display = '';
    this.setState({ successMessage: msg});

    // 2秒で消えるようにする。
    setTimeout(() => {
      document.getElementById('successAlert').style.display = 'none';
    },2000);
  }

  static get propTypes() {
    return {};
  }

  // Styleの設定
  static get styles() {
    return theme => ({

      searchInputText:{
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        lineheight: 2.5,
        width:'340px',
        marginLeft:10
      },

      searchInputCompanyName:{
        width:'300px',
        marginLeft:10
      },

      mstTblItemFirst: {
        border: "1px solid #b0bec5",
        borderLeft: "1px solid #525457",
        // borderTop: "0",
        borderBottom: "0",
        textAlign: "center",
        width: 70,
        flexGrow: 1
      },
      mstTblItem: {
        border: "1px solid #b0bec5",
        borderLeft: "0",
        // borderTop: "0",
        borderBottom: "0",
        width: 220,
        flexGrow: 1,
        display: "flex",
        //textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },

      root: {
        color: "#607d8b",
        "&$checked": {
          color: "#FF7200"
        }
      },
      checked: {},

      titleHeaderColor: {
        border: "1px solid #525457",
        backgroundColor: "#1976d2",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 20,
        fontWeight: "bold",
        borderRadius:"5px"
      },
      searchTbl: {
        border: "1px solid #525457",
        backgroundColor: "#bbdefb",
        color: "#4A4A4B",
        fontSize: 16,
        justifyContent: "space-between",
        display:'flex',
        //alignItems: "center",
        borderRadius:"5px"
      },
      mstTblColFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        textAlign: "center",
        width: 70,
        flexGrow: 1
      },
      mstTblCol: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: 220,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },

      buttonColor: {
        width: 150,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        // color: "#E1E1E1"
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing:10
      },

      outPutExcelButton: {
        width: 180,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        // color: "#E1E1E1"
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing:10
      },

      searchFabColor: {
        backgroundColor: "#2196f3",
        color: "#EEEEEE"
      },

      searchAreaLeft:{
        //display:'inline-block',
        paddingTop:5,
        display: 'flex',
        justifyContent: 'space-between'
      },

      InputLabel:{
        //display:"inline",
        //display: 'flex',
        paddingTop: '2.5%',
       marginLeft:10
      },

      searchElement:{
        //marginLeft:'5%',
      },

      searchFirstElement:{
        marginLeft:15,
      },
      searchResult:{
        textAlign:"left",
        marginBottom:"10px",
        paddingLeft:"5px",
        //fontSize:'1.5rem'
      },
      itemAlingnLeft:{
        justifyContent: "left",
        paddingLeft: '1%',
      },
      itemTitlePaddingLeft:{
        paddingLeft: '1%',
      },
      companyIdCell:{
        width:'100px'
      },
      companyNameCell:{
        width:'350px'
      },
      mailAddressCell:{
        width:'350px'
      },
      successAlertStyle: {
        position: 'relative',
        justifyContent: 'center',
        width: '100%',
        backgroundColor:'#80cbc4',
        fontSize:'1rem'
      },
    });
  }
}

// redux/index.js プロパティ
const mapStateToProps = ({ companyMaster: { list } }) => ({ model: list });
const mapStateToProps2 = ({ companyMaster_pageCount: {totalPageCount} }) => ({model_pageCount:totalPageCount});
const mapStateToProps3 = ({ companyMaster_currentPage: {currentPage} }) => ({model_currentPage:currentPage});
const mapStateToProps4 = ({ companyMaster_hitsCount: {hitsCount} }) => ({model_hitsCount:hitsCount});

const mapDispatchToProps = dispatch => ({ dispatch });
const { styles } = Component;

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  connect(mapStateToProps2,mapDispatchToProps),
  connect(mapStateToProps3,mapDispatchToProps),
  connect(mapStateToProps4,mapDispatchToProps)
)(Component);
