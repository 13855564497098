import { handleAction } from 'redux-actions';
import { listOrder, pageCountOrder, currentPageOrder, hitsCountOrder } from '../actions';

const defaultState = { list: [] };
const defaultState2 = { totalPageCount:0 };
const defaultState3 = { currentPage:1 };
const defaultState4 = { hitsCount:0 };

export const orderReducer = handleAction(listOrder, (state, { payload }) => ({ ...state, list: payload }), defaultState);

// ページネーション対応
export const orderReducerPageCount = handleAction(pageCountOrder, (state, { payload }) => ({ ...state, totalPageCount: payload }), defaultState2);
export const orderReducerCurrentPageOrder = handleAction(currentPageOrder, (state, { payload }) => ({ ...state, currentPage: payload }), defaultState3);
// ヒット件数対応
export const orderReducerHitsCountOrder = handleAction(hitsCountOrder, (state, { payload }) => ({ ...state, hitsCount: payload }), defaultState4);
