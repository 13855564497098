import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { moveTo } from '../actions';

class Component extends React.Component {

  goToTop() {
    const { dispatch } = this.props;
    dispatch(moveTo.Top());
  }

  render() {

    return (
      <div>
        <span>404</span>
        <button onClick={this.goToTop.bind(this)}>top</button>
      </div>
    )
  }

  static get propTypes() {
    return {};
  }

  static get styles() {
    return (theme) => ({})
  }

  static get mapStateToProps() {
    return (state) => ({});
  }

  static get mapDispatchToProps() {
    return (dispatch) => ({ dispatch });
  }
}

// TODO 分割 ContainerとComponent
const { styles, mapStateToProps, mapDispatchToProps } = Component;
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(Component);