import { handleAction } from 'redux-actions';
import { listLicense, pageCountLicense, currentPageLicense, hitsCountLicense } from '../actions';

const defaultState = { list: [] };
const defaultState2 = { totalPageCount:0 };
const defaultState3 = { currentPage:1 };
const defaultState4 = { hitsCount:0 };

export const licenseMasterReducer = handleAction(listLicense, (state, { payload }) => ({ ...state, list: payload }), defaultState);
// ページネーション対応
export const licenseMasterReducerPageCount = handleAction(pageCountLicense, (state, { payload }) => ({ ...state, totalPageCount: payload }), defaultState2);
export const licenseMasterReducerCurrentPage = handleAction(currentPageLicense, (state, { payload }) => ({ ...state, currentPage: payload }), defaultState3);
// ヒット件数対応
export const licenseMasterReducerHitsCount = handleAction(hitsCountLicense, (state, { payload }) => ({ ...state, hitsCount: payload }), defaultState4);

