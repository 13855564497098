import { createAction } from 'redux-actions';

const LIST_PRODUCT_LIST = `LIST_PRODUCT_LIST`;

const PAGE_COUNT_PRODUCT = `PAGE_COUNT_PRODUCT`;
const CURRENT_PAGE_PRODUCT = `CURRENT_PAGE_PRODUCT`;
const HITS_COUNT_PRODUCT = `HITS_COUNT_PRODUCT`;

export const listProductList = createAction(LIST_PRODUCT_LIST, (list) => (list));

// ページネーション対応
export const pageCountProduct = createAction(PAGE_COUNT_PRODUCT, (totalPageCount) => (totalPageCount));
export const currentPageProduct = createAction(CURRENT_PAGE_PRODUCT, (currentPage) => (currentPage));

// ヒット件数対応
export const hitsCountProduct = createAction(HITS_COUNT_PRODUCT, (hitsCount) => (hitsCount));