import React from "react";
import { connect } from "react-redux";
import { withStyles, fade } from "@material-ui/core/styles";
import { compose } from "redux";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


class Component extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Dialog id="dialogBox" open={true} style={{display: 'none'}}>
          <DialogContent　id='dialogMessage' style={{width: '280px'}}></DialogContent>
          <DialogActions>
            <Button id='dialogOkButton'color="primary">
              はい
            </Button>
            <Button id='dialogCancelButton' color="primary" autoFocus>
              いいえ
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  static get propTypes() {
    return {};
  }

  static get styles() {
    return theme => ({
 
    });
  }

  static get mapStateToProps() {
    return ({ auth, user }) => ({ auth, user });
  }

  static get mapDispatchToProps() {
    return dispatch => ({ dispatch });
  }
}

// TODO 分割 ContainerとComponent
const { styles, mapStateToProps, mapDispatchToProps } = Component;
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
