import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles, fade } from "@material-ui/core/styles";
import { Box, Button, Grid, Fab, Checkbox, TextField,Select,MenuItem,InputLabel } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "axios";
import EditIcon from '@material-ui/icons/Edit';
import PostAddIcon from '@material-ui/icons/PostAdd';
import moment from "moment";

import Header from "../header/Header";
import AlertDialog from "../components/Dialog";

import { moveTo, listProductStock } from "../actions";
import REQUEST_PATH from "../components/Common";

import { Alert } from '@material-ui/lab';
import Cookies from "js-cookie";

class Component extends React.Component {
  state = {
    // 検索項目
    searchProductType: "",
    searchModel: "",
    searchRegisterDateStart:'',
    searchRegisterDateEnd:'',

    // 検索域エラー
    searchRegisterDateCheck:false,
    inputForm: [],
    changeForm: null,
    // ボタン
    addLock: false,
    removeLock: true,
    registerLock: true,
    updateLock:true,
    deleteLock:true,
// ヘルパーテキスト
    stockNumberHelperText:'',
    registerDateHelperText:'',
    // エラー
    stockNumberError:false,
    registerDateError:false,
   
    // プルダウンリスト
    product_type: [],
    model_list:[],
    // エラーメッセージ
    errorMessage:'',

    successMessage:'',
  };

  constructor(props){
    super(props);
    this.getProductTypeTable();
  }

  // render後、ボタン制御（遷移元からの復帰対策）
  componentDidMount() {
    this.checkState();
  }

  async getProductTypeTable(){
    const param = Cookies.get('token');
    await axios.post(
      REQUEST_PATH + 'api/product-type/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({product_type:response.data.list});
    }).catch(e => {
      // 失敗時
      console.log(e);
    });
    
    await axios.post(
      REQUEST_PATH + 'api/product-model/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({model_list:response.data.list});
    }).catch(e => {
      // 失敗時
      console.log(e);
    });
  }
  onChangeSearchField(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  onClearSearchField() {
    this.setState({
      searchProductType: "",
      searchModel: "",
      searchRegisterDateStart:'',
      searchRegisterDateEnd:'',
      searchRegisterDateCheck:false 
    });

    this.forceUpdate();
  }

  /***********************************************************************************************
  * 追加時のフィールドにイベントが発生した際呼び出される関数
  * ここにエラー時のhelperTextの内容を記述する
  ***********************************************************************************************/
  onChangeAddField(evt, index) {
    const changeForm = this.state.inputForm.slice()[index];
    const val = evt.target.value;
    switch (evt.target.name) {
      case "stock": {
        changeForm.stockNumber = val;

        if(isNaN(changeForm.stockNumber)){
          this.setState({stockNumberHelperText:"半角数字以外が入力されています"});
          this.setState({stockNumberError:true});
          break;
        }

        if(changeForm.stockNumber.length === 0){
          this.setState({stockNumberHelperText:"在庫数を入力してください"});
          this.setState({stockNumberError:true});
          break;
        }

        this.setState({stockNumberHelperText:""});
        this.setState({stockNumberError:false});

        break;
      }
      case "registerDate": {
        changeForm.registerDate = val;
        break;
      }
      case "productType": {
        changeForm.type = val;
        break;
      }
      case "model": {
        changeForm.model = val;
      }
      default: {
        // ignore
          break;
      }
    }
    this.forceUpdate();
  }

  //編集時にイベントが発生した際呼び出される関数
  onChangeField(e) {
    const changeItem = this.state.changeForm;
    const val = e.target.value;

    switch (e.target.name) {
      case "stock": {
        changeItem.stockNumber = val;

        if(isNaN(changeItem.stockNumber)){
          this.setState({stockNumberHelperText:"半角数字以外が入力されています"});
          this.setState({stockNumberError:true});
          break;
        }

        if(changeItem.stockNumber.length === 0){
          this.setState({stockNumberHelperText:"在庫数を入力してください"});
          this.setState({stockNumberError:true});
          break;
        }

        this.setState({stockNumberHelperText:""});
        this.setState({stockNumberError:false});

        break;
      }
      case "registerDate": {
        changeItem.registerDate = val;
        break;
      }
      case "productType": {
        changeItem.type = val;
        break;
      }
      default: {
        // ignore
        break;
      }
    }
    this.forceUpdate();
  }

  handleToAboutPage() {
    const { dispatch } = this.props;
    dispatch(moveTo.Sample());
  }

  handleCheckAll(evt) {
    const { stock } = this.props;

    // Fab-ボタンが活性の場合、選択させない
    if(!this.state.removeLock) {
      return;
    }

    for (let i in stock) {
      stock[i].checked = evt.target.checked;
    }

    this.checkState();
    this.forceUpdate();
  }
  //チェックボックスにチェックを入れた時に呼び出される関数
  handleCheck(evt) {
    const { stock } = this.props;
    
    // Fab-ボタンが活性の場合、選択させない
    if(!this.state.removeLock) {
      return;
    }

    const _stock = stock.filter(({ productStockIndex: id },index) => {
      return evt.target.name === String(id);
    })[0];
    if (_stock) {
      _stock.checked = evt.target.checked;
    }

    if (!_stock.checked) {
          // エラーリセット
    this.setState({stockNumberHelperText:""});
    this.setState({stockNumberError:false});
    this.setState({registerDateHelperText:""});
    this.setState({registerDateError:false});
    }

    this.checkState();
    this.forceUpdate();
  }

  checkState() {
    const { stock } = this.props;
    const inputItemNum = this.state.inputForm.slice().length;
    const checkItem = stock.filter(_stock => {
      return _stock.checked;
    });
    if (checkItem && checkItem.length === 1 && inputItemNum === 0) {
      this.setState({ changeForm: { ...checkItem[0] } });
      this.setState({ updateLock:false });
    } else {
      this.setState({ changeForm: null });
      this.setState({ updateLock:true });
    }

    if (checkItem && checkItem.length > 0) {
      this.setState({ addLock: true });
      this.setState({ deleteLock:false});
      if (!this.state.registerLock){
        this.setState({ deleteLock:true});
      }
    } else {
      this.setState({ addLock: false });
      this.setState({ deleteLock:true});
    }
  }

  clearCheckState() {
    this.setState({ updateLock:true });
    this.setState({ registerLock:true});
    this.setState({ deleteLock:true});

    this.setState({ addLock: false });
    this.setState({ removeLock:true});
  }

  handleAddForm() {
    const fieldList = this.state.inputForm.slice();
    fieldList.push({
      productStockIndex: "",
      type: "01",
      model: "00",
      stockNumber: "",
      registerDate: moment().format('YYYY-MM-DD')
    });

    this.setState({ registerLock :false});
    this.setState({ removeLock :false});
    this.setState({ addLock :true});
    this.setState({ inputForm: fieldList });
  }

  handleRemoveForm() {
    const fieldList = this.state.inputForm.slice();
    fieldList.pop();
    this.setState({ inputForm: fieldList });

    this.setState({ registerLock :true});
    this.setState({ removeLock :true});
    this.setState({ addLock :false});

    // エラーリセット
    this.setState({stockNumberHelperText:""});
    this.setState({stockNumberError:false});
    this.setState({registerDateHelperText:""});
    this.setState({registerDateError:false});
  }

  // 種別プルダウンで使用
  getProductTypeOptions = () => {
    const product_type = this.state.product_type;
    const menuItems = [];
    for (const i in product_type) {
      menuItems.push(
        <MenuItem
          value={product_type[i].productType}
          key={product_type[i].productType}
          >
          {product_type[i].productType+":"+product_type[i].productName}
        </MenuItem>
      );
    }
    return menuItems;
  };

  // 機種プルダウンで使用
  getProductModelOptions = () => {
    const product_model = this.state.model_list;
    const menuItems = [];
    for (const i in product_model) {
      menuItems.push(
        <MenuItem
          value={product_model[i].model}
          key={product_model[i].model}
          >
          {product_model[i].model+":"+product_model[i].modelName}
        </MenuItem>
      );
    }
    return menuItems;
  };
  
  getYearOptions = () => {

    const year_list = [];
    const year_current = new Date().getFullYear();
    const year_min = 1970;
    const year_max = year_current + 10;
    
    for(var i = year_min; i <year_max; i++){
      year_list.push(i);
    }
    const menuItems = [];

    for (const i in year_list) {
      menuItems.push(
        <MenuItem
          value={year_list[i]}
          key={year_list[i]}
          >
          {year_list[i]}
        </MenuItem>
      );
    }
    return menuItems;
  };

  addForm(productStockIndex, type, model, stockNumber, registerDate, i) {
    const { classes } = this.props;
    const form = this.state.inputForm.slice()[i];
    const productTypeListMenu = this.getProductTypeOptions();
    const productModelListMenu = this.getProductModelOptions();

    return (
      <Box
        key={i}
        display={`flex`}
        justifyContent={`space-between`}
        flexGrow={1}
      >
        <Box className={classes.addMstTblItemFirst}>
          <Checkbox
            checked={true}
            onChange={this.handleCheck.bind(this)}
            inputProps={{ "aria-label": "checkbox" }}
            classes={{
              root: classes.root,
              checked: classes.checked
            }}
          />
        </Box>
        <Box className={classes.addMstTblItem + ' ' + classes.itemAlingnLeft}>
          <Select
            id="productType"
            name="productType"
            variant="outlined"
            size={"small"}
            value={form.type}
            onChange={event => this.onChangeAddField(event,i)}
            style={{ backgroundColor: "#FFFFFF" }}
          >
            {productTypeListMenu}
          </Select>
        </Box>
        <Box className={classes.addMstTblItem + ' ' + classes.itemAlingnLeft}>
          <Select
            id="model"
            name="model"
            variant="outlined"
            size={"small"}
            value={form.model}
            onChange={event => this.onChangeAddField(event,i)}
            style={{ backgroundColor: "#FFFFFF" }}
          >
            {productModelListMenu}
          </Select>
        </Box>
        <Box className={classes.addMstTblItem}>
           <TextField
            id="stock"
            name="stock"
            label="在庫数"
            variant="outlined"
            size={"small"}
            value={form.stock}
            onChange={event => this.onChangeAddField(event, i)}
            style={{ backgroundColor: "#FFFFFF" }}
            error={this.state.stockNumberError}
            helperText={this.state.stockNumberHelperText}
          />
        </Box>
        <Box className={classes.addMstTblItem}>
          <TextField
            id="registerDate"
            name="registerDate"
            type="date"
            variant="outlined"
            size={"small"}
            value={form.registerDate}
            onChange={event => this.onChangeAddField(event, i)}
            style={{ backgroundColor: "#FFFFFF" }}
            error={this.state.registerDateError}
            helperText={this.state.registerDateHelperText}
          />
        </Box>
      </Box>
    );
  }

  /***********************************************************************************************
  * 検索結果のリスト表示機能、帰ってきた行数分だけPushを繰り返す。
  ***********************************************************************************************/
  getMstItemList = () => {
    const list = [];
    const { classes, stock } = this.props;
    const changeItem = this.state.changeForm;
    const productTypeListMenu = this.getProductTypeOptions();
    //表示用に免許種別名を設定
     for (const i in stock){
         for(const j in this.state.product_type){
           if(stock[i].type === this.state.product_type[j].productType){
             stock[i].productTypeName = this.state.product_type[j].productName;
             break;
           }
         }
     }
      //表示用に免許種別名を設定
      for (const i in stock){
        for(const j in this.state.model_list){
          if(stock[i].model === this.state.model_list[j].model){
            stock[i].productModelName = this.state.model_list[j].modelName;
            break;
          }
        }
    }
    for (const i in stock) {
       const formIsSelected = changeItem && changeItem.productStockIndex === stock[i].productStockIndex;
      list.push(
        <Box
          key={i}
          display={`flex`}
          justifyContent={`space-between`}
          flexGrow={1}
        >
          {formIsSelected ? (
            <>
              <Box className={classes.addMstTblItemFirst}>
                <Checkbox
                  key={stock[i].productStockIndex}
                  name={stock[i].productStockIndex}
                  checked={stock[i].checked || false}
                  onChange={this.handleCheck.bind(this)}
                  inputProps={{ "aria-label": "checkbox" }}
                  classes={{
                    root: classes.root,
                    checked: classes.checked
                  }}
                />
              </Box>
              <Box className={classes.addMstTblItem  + ' ' + classes.itemAlingnLeft}>
                {stock[i].productTypeName}
              </Box>
              <Box className={classes.addMstTblItem + ' ' + classes.itemAlingnLeft}>
                {stock[i].productModelName}
              </Box>
              <Box className={classes.addMstTblItem}>
                <TextField
                  id="stock"
                  name="stock"
                  label="在庫数"
                  variant="outlined"
                  size={"small"}
                  value={changeItem.stockNumber}
                  onChange={event => this.onChangeField(event)}
                  style={{ backgroundColor: "#FFFFFF" }}
                  error={this.state.stockNumberError}
                  helperText={this.state.stockNumberHelperText}
                  />
              </Box>
              <Box className={classes.addMstTblColorItem}>
              <TextField
                 id="registerDate"
                 name="registerDate"
                 type="date"
                 variant="outlined"
                 size={"small"}
                 value={changeItem.registerDate}
                 onChange={event => this.onChangeField(event)}
                 style={{ backgroundColor: "#FFFFFF" }}
                 error={this.state.registerDateError}
                 helperText={this.state.registerDateHelperText}
                />
              </Box>
            </>
          ) : (
            <>
              <Box className={classes.mstTblItemFirst}>
                <Checkbox
                  key={stock[i].productStockIndex}
                  name={stock[i].productStockIndex}
                  checked={stock[i].checked || false}
                  onChange={this.handleCheck.bind(this)}
                  inputProps={{ "aria-label": "checkbox" }}
                  classes={{
                    root: classes.root,
                    checked: classes.checked
                  }}
                />
              </Box>
              <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{stock[i].productTypeName}</Box>
              <Box className={classes.mstTblItem + ' ' + classes.itemAlingnLeft}>{stock[i].productModelName}</Box>
              <Box className={classes.mstTblItem}>{stock[i].stockNumber}</Box> 
              <Box className={classes.mstTblItem}>{stock[i].registerDate.replace( /-/g, '/' )}</Box>
            </>
          )}
        </Box>
      );
    }
    return <Box>{list}</Box>;
  };

  render() {
    const { classes } = this.props;
    const list = this.getMstItemList();
    const productTypeListMenu = this.getProductTypeOptions();
    const productModelListMenu = this.getProductModelOptions();
    const year_list =this.getYearOptions();

    const titleDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>
          <Box p={1} className={classes.titleHeaderColor}>
            在庫数管理画面
          </Box>
        </Box>
      </Box>
    );

    //検索フォーム
    const searchDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px" }}>
        <Box flexGrow={1}>
          <Box display={`flex`} p={2} className={classes.searchTbl}>
            {/*　左 */}
            
              <Box display={`flex`} className={classes.searchLbl}>
                <InputLabel className={classes.searchLabel}>種別名</InputLabel>
                <Select
                  name="searchProductType"
                  variant="outlined"
                  size={"small"}
                  className={classes.pullDown}
                  value={this.state.searchProductType}
                  onChange={this.onChangeSearchField.bind(this)}
                >
                  <MenuItem value="" key="">&ensp;</MenuItem>
                  {productTypeListMenu}
                </Select>
              </Box>
              <Box display={`flex`} className={classes.searchLbl}>
                <InputLabel ref="<Box>" className={classes.searchModelLabel}>機種名</InputLabel>
                  <Select
                    name="searchModel"
                    variant="outlined"
                    size={"small"}
                    className={classes.pullDown}
                    value={this.state.searchModel}
                    onChange={this.onChangeSearchField.bind(this)}
                  >
                    <MenuItem value="" key="">&ensp;</MenuItem>
                    {productModelListMenu}
                  </Select>
              </Box>
          
            {/* 中央 */}
            <Box display='flex' className={classes.searchRegister}>
              <InputLabel ref="<Box>" className={classes.searchLabelRegisterDate}>登録期間</InputLabel>
              <Select
                name="searchRegisterDateStart"
                variant="outlined"
                size={"small"}
                className={classes.pullDown + ' '+ classes.searcRegisterDateText}　
                value={this.state.searchRegisterDateStart}
                onChange={this.onChangeSearchField.bind(this)}
                error={this.state.searchRegisterDateCheck}
                >
                  <MenuItem value="" key="">&ensp;</MenuItem>
                  {year_list}
              </Select>
            </Box>

            <Box className={classes.searchSpan}>&ensp;～&ensp;</Box>

            <Select
              name="searchRegisterDateEnd"
              variant="outlined"
              size={"small"}
              className={classes.pullDown + ' '+ classes.searcRegisterDateText}　
              value={this.state.searchRegisterDateEnd}
              onChange={this.onChangeSearchField.bind(this)}
              error={this.state.searchRegisterDateCheck}
              >
                <MenuItem value="" key="">&ensp;</MenuItem>
                {year_list}
            </Select>

            <Box flexGrow={1}>

            <Box>
              <Box display={`flex`} flexGrow={1} justify="left" className={classes.search}>
                <Box width={90}>
                  <Fab
                    className={classes.searchFabColor}
                    onClick={this.search.bind(this)}
                  >
                    <SearchOutlinedIcon style={{ fontSize: 30 }} />
                  </Fab>
                </Box>
                <Box width={70}>
                  <Fab
                    className={classes.searchFabColor}
                    onClick={this.onClearSearchField.bind(this)}
                  >
                    <CancelPresentationIcon style={{ fontSize: 30 }} />
                  </Fab>
                </Box>
              </Box>
            </Box>
          </Box>
          </Box>
        </Box> 
      </Box>
    );

  /***********************************************************************************************
  * 画面のテーブルのヘッダ情報のJSXとpropsの設定です。
  * 検索結果を表示する1レコード文をlistとして宣言しており、(429行目)
  * propsにレコードの情報が入るようにmapしています。
  * 宣言のみなので、検索ボタンが押されてメソッドが走るまではヘッダ部分の描画になります。
  ***********************************************************************************************/
    const mstTbl = (
      <Box style={{ paddingBottom: "16px" }}>
        <Box
          style={{
            borderBottom: `1px solid #525457`,
            borderRadius:'5px'
          }}
        >
          <Box display={`flex`} justifyContent={`space-between`} flexGrow={1}>
            <Box className={classes.mstTblColFirst}>
              <Checkbox
                onChange={this.handleCheckAll.bind(this)}
                style={{ color: "#4A4A4B" }}
              />
            </Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>種別</Box>
            <Box className={classes.mstTblCol + ' ' + classes.itemTitlePaddingLeft}>機種</Box>
            <Box className={classes.mstTblCol}>在庫数</Box>
            <Box className={classes.mstTblColLast}>登録日</Box>
          </Box>
          {list}
          {this.state.inputForm &&
            this.state.inputForm.map(
              ({ productStockIndex, type, model, stockNumber,registerDate }, i) =>
                this.addForm(productStockIndex, type, model, stockNumber,registerDate , i)
            )}
        </Box>
      </Box>
    );
  /***********************************************************************************************
  * 右下の追加削除ボタン
  ***********************************************************************************************/
    const fubDiv = (
      <Box display={`flex`} flexGrow={1} justifyContent="flex-end" height={70}>
        <Box width={50}>
          <Fab
            size="small"
            className={classes.fabColor}
            onClick={this.handleAddForm.bind(this)}
            disabled={this.state.addLock}
          >
            <AddIcon />
          </Fab>
        </Box>
        <Box width={50}>
          <Fab
            size="small"
            className={classes.fabColor}
            onClick={this.handleRemoveForm.bind(this)}
            disabled={this.state.removeLock}
          >
            <RemoveIcon />
          </Fab>
        </Box>
      </Box>
    );

  /***********************************************************************************************
  * 画面下部の「登録」「変更」「削除」のJSXとpropsの設定のメソッドです。
  ***********************************************************************************************/
    const buttonDiv = (
      <Grid container spacing={3} justify="center" style={{textAlign:'center'}}>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.insert.bind(this), '登録しますか？')}
            endIcon={<PostAddIcon />}
            disabled={this.state.registerLock}
          >
            登録
          </Button>
        </Grid>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.update.bind(this), '更新しますか？')}
            endIcon={<EditIcon />}
            disabled={this.state.updateLock}
          >
            変更
          </Button>
        </Grid>
        <Grid item xs={3} justify='flex-end'>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneDialog.bind(this, this.delete.bind(this), '削除しますか？')}
            endIcon={<DeleteIcon />}
            disabled={this.state.deleteLock}
          >
          
            削除
            
          </Button>
        </Grid>
      </Grid>
    );

    return (
      <Box style={{ height: "100vh", width: "100vw" }}>
        <Header value={this.state.errorMessage}/>
        <Alert 
          id="successAlert"
          style={{display:'none'}}
          className={classes.successAlertStyle}
          severity="success">
            {this.state.successMessage}
        </Alert>
        <Box
          style={{
            height: "calc(100% - 96px)",
            width: "calc(100% - 32px)",
            padding: "16px",
            overflow: "auto"
          }}
        >
          <Box minWidth={975}>
            {titleDiv}
            {searchDiv}
            {mstTbl}
            {fubDiv}
            {buttonDiv}
          </Box>
          <AlertDialog/>
        </Box>
      </Box>
    );
  }

  opneDialog (func, message) {

    var Dialog = document.getElementById('dialogBox');
    var okButton = document.getElementById('dialogOkButton');
    var cancelButton = document.getElementById('dialogCancelButton');
 
    document.getElementById('dialogMessage').innerHTML= message;
 
    // ダイアログ表示
    Dialog.style.display = '';
    // OK 押下の処理
    okButton.onclick = func;
    // cancel　押下の処理
    cancelButton.onclick = function() {
     Dialog.style.display = 'none';
     };
   }

  /***********************************************************************************************
  * 検索ボタンがクリックされた時に実行されるメソッドです。
  * 一応検索フィールドに入力された際に入力チェックを行っており、入力謝りがあればreturnを返しています
  ***********************************************************************************************/
  async search() {

    const { dispatch } = this.props;

    // 検索項目
    const _searchProductType = this.state.searchProductType || null;
    const _searchModel = this.state.searchModel || null;
    const _searchRegisterDateStart = this.state.searchRegisterDateStart || null;
    const _searchRegisterDateEnd = this.state.searchRegisterDateEnd || null;
    this.setState({searchRegisterDateCheck:false });

    // 相関チェック
    if(_searchRegisterDateStart > _searchRegisterDateEnd) {
      console.log("有効期限不正");
      this.setState({ searchRegisterDateCheck:true });
      return;
    }

    // ヘッダーエラーの初期化
    await this.setState({errorMessage:''});

    const param = Cookies.get('token');
    
    await axios.post(
      REQUEST_PATH + 'api/product-stock/list',
      {
        pageIndex: 0,
        pageSize: 100,
        type: _searchProductType,
        model: _searchModel,
        registerDateStart:_searchRegisterDateStart === null 
          ? _searchRegisterDateStart : new Date().setFullYear(_searchRegisterDateStart),
        registerDateEnd:_searchRegisterDateEnd === null 
          ? _searchRegisterDateEnd :new Date().setFullYear(_searchRegisterDateEnd)
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      dispatch(listProductStock(response.data.list));
    }).catch(e =>{
      // 失敗時
      console.log(e);
      this.setState({errorMessage:'検索に失敗しました'});
    });
  }
  
  /***********************************************************************************************
  * 登録ボタンをクリックされた時に実行されるメソッド
  ***********************************************************************************************/
  async insert() {
    const fieldList = this.state.inputForm.slice();

    await this.setState({errorMessage:''});

    const checkResult =this.validate(
      fieldList[0].type, 
      fieldList[0].model,
      fieldList[0].stockNumber,
      fieldList[0].registerDate);

    if(checkResult) {
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }

    const param = Cookies.get('token');
    
    for (let i in fieldList) {
      await axios.post(REQUEST_PATH + 'api/product-stock/create', {
          //productStockIndex: productStockMaxIndex,
          type: fieldList[i].type,
          model: fieldList[i].model,
          stockNumber: fieldList[i].stockNumber,
          registerDate: fieldList[i].registerDate
        },{
          param,
          withCredentials: true,
        }).then( response => {

          this.setState({ inputForm: [] });
          this.openeSuccessAlert('登録しました');
      }).catch(e =>{
        console.log(e);
        this.setState({errorMessage:'登録に失敗しました'});
      });
    }

    // 再検索
    this.search();
    this.forceUpdate();
    // ボタン
    this.clearCheckState();
    document.getElementById('dialogBox').style.display = 'none';
  }

  /***********************************************************************************************
  * 変更ボタンをクリックされた時に実行されるメソッド
  ***********************************************************************************************/
  async update() {
    const changeItem = this.state.changeForm;
    const { stock } = this.props;

    await this.setState({errorMessage:''});

    const checkResult =this.validate(
      changeItem.type, 
      changeItem.model,
      changeItem.stockNumber,
      changeItem.registerDate);

    if(checkResult) {
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }

    const param = Cookies.get('token');

    await axios.put(REQUEST_PATH + 'api/product-stock/update', {
        productStockIndex: changeItem.productStockIndex,
        type: changeItem.type,
        model: changeItem.model,
        stockNumber: changeItem.stockNumber,
        registerDate: changeItem.registerDate
      },{
        param,
        withCredentials: true,
      }).then( response => {
        
        const _stock = stock.filter(({ productStockIndex: id }) => {
          return changeItem.productStockIndex === id;
        })[0];
        if (_stock) {
          _stock.productStockIndex = changeItem.productStockIndex;
          _stock.type = changeItem.type;
          _stock.model = changeItem.model;
          _stock.stockNumber = changeItem.stockNumber;
          _stock.registerDate = changeItem.registerDate
          _stock.checked = false;
        }
        this.checkState();
        this.setState({ changeForm: null });

        this.openeSuccessAlert('更新しました');
    }).catch(e =>{
      console.log(e);
      this.setState({errorMessage:'更新に失敗しました'});
    });
    document.getElementById('dialogBox').style.display = 'none';
  }

  /***********************************************************************************************
  * 削除ボタンをクリックされた時に実行されるメソッド
  ***********************************************************************************************/
  async delete() {
    const { stock } = this.props;
    const deleteList = [];

    await this.setState({errorMessage:''});

    const param = Cookies.get('token');
    
    for (let i in stock) {
      if (stock[i].checked) {
        await axios.delete(
          REQUEST_PATH + 'api/product-stock/delete/' + stock[i].productStockIndex,{
            param,
            withCredentials: true,
          }
        ).then( response => {
          deleteList.push(stock[i].productStockIndex);
        }).catch(e =>{
          console.log(e);
          this.setState({errorMessage:'削除に失敗しました'});
        });       
      }
    }

    if(deleteList.length > 0){
      deleteList.forEach(id => {
        const index = stock.findIndex(v => v.productStockIndex === id);
        stock.splice(index, 1);
      });
      this.openeSuccessAlert('削除しました');
    }
    this.checkState();
    this.forceUpdate();

    document.getElementById('dialogBox').style.display = 'none';
  }

  // ダイアログ表示
  opneDialog (func, message) {

    var Dialog = document.getElementById('dialogBox');
    var okButton = document.getElementById('dialogOkButton');
    var cancelButton = document.getElementById('dialogCancelButton');
 
    document.getElementById('dialogMessage').innerHTML= message;
 
    // ダイアログ表示
    Dialog.style.display = '';
    // OK 押下の処理
    okButton.onclick = func;
    // cancel　押下の処理
    cancelButton.onclick = function() {
      Dialog.style.display = 'none';
    };
  }

  // チェック関数
  // 画面ごとに引数を返すこと
  validate(type , model, stockNumber, registerDate) {
    var isFailed = false;
    this.setState({stockNumberHelperText:""});
    this.setState({stockNumberError:false});
    this.setState({registerDateHelperText:""});
    this.setState({registerDateError:false});

    if(type === null || type === '' || type === 'undefined') {
      isFailed = true;
    }

    if(model === null || model === '' || model === 'undefined') {
      isFailed = true;
    }

    if(stockNumber === null || stockNumber === '' || stockNumber === 'undefined' 
      || isNaN(stockNumber)) {

      this.setState({stockNumberHelperText:"入力に誤りがあります"});
      this.setState({stockNumberError:true});
      isFailed = true;
    }

    if(registerDate === null || registerDate === '' || registerDate === 'undefined') {
      isFailed = true;
      this.setState({registerDateHelperText:"入力に誤りがあります"});
      this.setState({registerDateError:true});
    }

    return isFailed;
  }

  // 成功アラートの表示
  openeSuccessAlert(msg){

    document.getElementById('successAlert').style.display = '';
    this.setState({ successMessage: msg});

    // 2秒で消えるようにする。
    setTimeout(() => {
      document.getElementById('successAlert').style.display = 'none';
    },2000);
  }

  static get propTypes() {
    return {};
  }

  // Styleの設定
  static get styles() {
    return theme => ({

      mstTblItemFirst: {
        border: "1px solid #b0bec5",
        borderLeft: "1px solid #525457",
        // borderTop: "0",
        borderBottom: "0",
        textAlign: "center",
        width: 70,
        flexGrow: 1
      },
      mstTblItem: {
        border: "1px solid #b0bec5",
        borderLeft: "0",
        // borderTop: "0",
        borderBottom: "0",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },

      mstTblColorItem: {
        border: "1px solid #b0bec5",
        borderRight: "1px solid #525457",
        borderLeft: "0",
        // borderTop: "0",
        borderBottom: "0",
        width: 280,
        flexGrow: 1,
        textAlign: "center",
        color: "#4A4A4B",
        fontWeight: 500,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
      },
      addMstTblItemFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        width: 70,
        height: 80,
        flexGrow: 1
      },
      addMstTblItem: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: 280,
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      addMstTblColorItem: {
        border: "1px solid #525457",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: 280,
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },

      root: {
        color: "#607d8b",
        "&$checked": {
          color: "#FF7200"
        }
      },
      checked: {},

      titleHeaderColor: {
        border: "1px solid #525457",
        backgroundColor: "#1976d2",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 20,
        fontWeight: "bold",
        borderRadius:"5px"
      },
      tblHeaderColor: {
        borderTop: "1px solid #525457",
        borderLeft: "0",
        borderRight: "1px solid #525457",
        backgroundColor: fade("#1565c0", 0.8),
        color: "#4A4A4B",
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold"
      },
      searchTbl: {
        border: "1px solid #525457",
        backgroundColor: "#bbdefb",
        color: "#4A4A4B",
        fontSize: 16,
        //justifyContent: "left",
        //justifyContent:`space-between`,
        alignItems: "center",
        borderRadius:"5px"
      },
      mstTblColFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        textAlign: "center",
        width: 70,
        flexGrow: 1
      },
      mstTblCol: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      mstTblColLast: {
        border: "1px solid #525457",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        width: 280,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      buttonColor: {
        width: 150,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        // color: "#E1E1E1"
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing:10
      },
      searchFabColor: {
        backgroundColor: "#2196f3",
        color: "#EEEEEE",
        textAlign:"left",
        
      },
      fabColor: {
        backgroundColor: "#26a69a",
        color: "#EEEEEE"
      },
      pullDown:{
        backgroundColor: "#FFFFFF",
        width: 250,
        borderRadius: 5,
        height:40,
        marginLeft:10,
        //maxHeight: "28px"
      },
      searchLabel:{
        paddingTop:'4%',
        //marginRight:1,
      },
      searchModelLabel:{
        paddingTop:'4%'
        
      },
      searchLabelRegisterDate:{
        marginLeft:15,
        paddingTop:'4%'
      },
      searcRegisterDateText:{
        textAlign:'center',
      },
      searchSpan:{
        fontSize: 25,
        marginLeft:10
      },
      searchModelName:{
        marginLeft:30
      },
      search:{
        justifyContent: 'flex-end',
      },
      searchLbl:{
      width:350
      },
      searchRegister:{
      //width:800
      },
      itemAlingnLeft:{
        justifyContent: "left",
        paddingLeft: '1%',
      },
      itemTitlePaddingLeft:{
        paddingLeft: '1%',
      },
      successAlertStyle: {
        position: 'relative',
        justifyContent: 'center',
        width: '100%',
        backgroundColor:'#80cbc4',
        fontSize:'1rem'
      },
    });
  }
}

const mapStateToProps = ({ productStock: { list } }) => ({ stock: list });
const mapDispatchToProps = dispatch => ({ dispatch });
const { styles } = Component;

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
