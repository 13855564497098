import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Fab, Checkbox, TextField, Select, MenuItem, InputBase } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Header from "../header/Header";
import Switch from "react-ios-switch";
import moment from "moment";
import axios from "axios";
import { moveTo, orderRegist } from "../actions";
import AlertDialog from "../components/Dialog";
import OutPutExcelDialog from "../components/OutPutExcelDialog";
import { saveAs } from 'file-saver';
import REQUEST_PATH from "../components/Common";
import PostAddIcon from '@material-ui/icons/PostAdd';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Cookies from "js-cookie";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  }
});

class Component extends React.Component {

  state = {
    //注文データ
    orderNumber: "",
    orderNumberDate: '',
    orderNumberLastFour: '',
    orderDate: "",
    orderStatus: "",
    orderFormal: false,
    estimated: false,
    orderCancel: false,
    shipped: false,
    collected: false,
    billed: false,
    paymented: false,
    companyID: "",
    personID: "",
    shipmentPersonID:"",
    companyPerson: "",
    projectName: "",
    useStartDate: "",
    useEndDate: "",
    shippingDate: "",
    shippingMethod: "",
    deliveryDate: "",
    collectDate: "",
    collectMethod: "",
    billingDate: "",
    paymentDate: "",
    delivaryLocation: "",
    total: 0,
    totalInTax: 0,
    remarks: "",
    // ヘルパーテキスト 注文データ
    orderDateHelperText: "",
    orderNumberHelperText: "",
    orderNumberDateHelperText: '',
    orderNumberLastFourHelperText: '',
    companyPersonHelperText: "",
    projectNameHelperText: "",
    useStartDateHelperText: "",
    useEndDateHelperText: "",
    shippingDateHelperText: "",
    deliveryDateHelperText: "",
    collectDateHelperText: "",
    billingDateHelperText: "",
    paymentDateHelperText: "",
    delivaryLocationHelperText: "",
    // 赤枠 注文データ
    orderDateError: false,
    orderNumberError: false,
    orderNumberDateError: false,
    orderNumberLastFourError: false,
    companyPersonError: false,
    projectNameError: false,
    useStartDateError: false,
    useEndDateError: false,
    shippingDateError: false,
    shippingMethodError: false,
    deliveryDateError: false,
    collectDateError: false,
    collectMethodError: false,
    billingDateError: false,
    paymentDateError: false,
    delivaryLocationError: false,
    shipmentPersonIDError:false,
    //見積データ
    estimateTable: [],
    // 見積エラー時
    typeEstimateError: false,
    modelEstimateError: false,
    useStartDateEstimateError: false,  // 使用開始
    useEndDateEstimateError: false,    // 使用終了
    daysEstimateError: false,          // 日数
    quantityEstimateError: false,      // 数量
    unitEstimateError: false,          // 単位
    priceEstimateError: false,         // 単価
    amountEstimateError: false,        // 金額

    //トグルボタン非活性制御
    orderFormalDisable: false,
    estimatedDisable: true,
    orderCancelDisable: false,
    shippedDisable: true,
    collectedDisable: true,
    billedDisable: true,
    paymentedDisable: true,

    //エラーメッセージ
    headerErrorMessage: "",

    successMessage:'',

    // 登録変更フラグ
    isUpdate: false,

    // 原本モー
    isOriginalMode: false,
    // 取引先画面からパラメータ
    pramCompanyId: '',

    company_list:[],

    searchCompanyName:'',
    isFirst: 'FIRST',
  };
  constructor(props) {
    super(props);
    //const _orderNumber = this.props.location.state.orderNumber;
    const _companyId = this.props.location.state.companyId;
    const _companyName = this.props.location.state.companyName;

    // 注文一覧からの繊維
    /*if (!(_orderNumber === null) && !(_orderNumber === undefined) && !(_orderNumber === '')) {
      this.search(_orderNumber);
    }*/
    
    // 取引先担当
    this.getCompanyListMenus();

    // T遷移
    const { history: { location: { state = {} } } } = this.props;
    const { orderNumber = `` } = state;
    if(!(orderNumber === null) && !(orderNumber === undefined) && !(orderNumber === '')){
      this.search(orderNumber);
    }

    // 取引先Id
    if (!(_companyId === null) && !(_companyId === undefined) && !(_companyId === '')) {
      console.log(_companyId);
      // 原本モードに切り替える
      this.state.isOriginalMode = true;
      this.state.pramCompanyId = _companyId;
      this.state.orderNumberDate = '00000000';
      this.state.orderNumberLastFour = ('00' + this.state.pramCompanyId).slice(-4);
      // コンボボックス
      this.state.searchCompanyName = _companyName;
    }

    // 各リスト関連の取得
    // 受注担当
    this.getPersonListMenus();

    // 出荷方法、回収方法
    this.getPickupMethodListMenus();
    // 種別
    this.getProductTypeListMenus();
    // 機種
    this.getProductModelListMenus();
    // 単位
    this.getUnitListMenus();

    // 消費税の取得
    this.getTax();
    this.forceUpdate();
  }

  /**       コンボボックス対応       */
  onChangeAutoCompletField(e,value, type) {

    if(this.state.company_list.length === 0) {
      return
    }

    switch(type) {

      case 'SEARCH' : // searchメソッドから呼び出される場合
          const _companyList = this.state.company_list;
          const targertName = _companyList.filter(v => { return v.companyId === value });
          this.setState({searchCompanyName : targertName[0].companyName});
        break;
      
      case 'SELECT' : // onChangeイベント（リスト選択時）
          if(value === null || value === undefined || value ===  ''){
            break
          }
          this.setState({searchCompanyName:value.companyName});
          this.setState({companyID:value.companyID});
          break;

      case 'INPUT'  : // onInputChangeイベント（テキスト変更時、state更新時）
          if(e === null){
            break;
          }

          if(e.type === 'blur') { // フォーカスを失った場合
            break;
          }
          if(value === null || value === undefined || value ===  ''){
            this.setState({searchCompanyName:value});
            this.setState({companyID:value});
            break
          }
          this.setState({searchCompanyName:value});
          this.setState({companyIDError:false});
          break;
    }
  }

  // 商品機種の取得
  async getProductModelListMenus() {

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/product-model/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ model_list: response.data.list });
    }).catch(e => {
      console.log(e);
    });
  };

  // 商品種別の取得
  async getProductTypeListMenus() {

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/product-type/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
       this.setState({ product_type: response.data.list });
    }).catch(e => {
      console.log(e);
    });

    this.forceUpdate();
  }

  // 輸送方法の取得
  async getPickupMethodListMenus() {

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/pickup/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ pickup_method_list: response.data.list });
    }).catch(e => {
      console.log(e);
    });
  }

  // 受注担当の取得
  async getPersonListMenus() {

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/person/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ person_list: response.data.list });
    }).catch(e => {
      console.log(e);
    });
  }

  // 取引先の取得
  async getCompanyListMenus() {

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/company/list',
      {
        pageIndex: 0,
        pageSize: 1000,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ company_list: response.data.list });
    }).catch(e => {
      console.log(e);
    });

    this.forceUpdate();
  }

  // 単位の取得
  async getUnitListMenus() {

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/unit/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ unit_list: response.data.list });
    }).catch(e => {
      console.log(e);
    });
  }

  // 消費税の取得
  async getTax() {

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/tax/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ tax: response.data.list[0].tax });
    }).catch(e => {
      console.log(e);
    });
  }

  //注文番号を取得する処理をAPI叩いて取得する必要がある
  componentDidMount() {
    const now = moment();
    const today = now.format('YYYY-MM-DD');
    
    if (this.state.isOriginalMode) {
      this.setState({ companyID: this.state.pramCompanyId });
      this.search('00' + this.state.orderNumberDate + this.state.orderNumberLastFour);
    } else {
      this.setState({ orderNumberDate: now.format('YYYYMMDD') })
    }
    this.setState({ orderDate: today });
    this.setState({ useStartDate: today });
    this.setState({ useEndDate: today });
    this.setState({ shippingDate: today });
    this.setState({ shippingDate: today });
    this.setState({ deliveryDate: today });
    this.setState({ collectDate: today });
    this.setState({ billingDate: today });
    this.setState({ paymentDate: today });
  }

  /************************************************************ 
  *注文データイベントハンドラー
  *入力値チェック
  ************************************************************/
  onChangeField(evt) {

    const val = evt.target.value;

    switch (evt.target.name) {
      case 'orderDate': {
        this.setState({ orderDate: val });

        if (val === null || val === '' || val === 'undefined') {
          this.setState({ orderDateError: true });
          this.setState({ orderDateHelperText: '有効な日付を入力してください' });
          break;
        }

        this.setState({ orderDateError: false });
        this.setState({ orderDateHelperText: '' });
        break;
      }
      case 'orderNumberDate': {
        this.setState({ orderNumberDate: val });

        if (isNaN(val)) {
          this.setState({ orderNumberDateHelperText: '例:YYYYMMDD' });
          this.setState({ orderNumberDateError: true });
          break;
        }

        if (val.length < 8) {
          this.setState({ orderNumberDateHelperText: '例:YYYYMMDD' });
          this.setState({ orderNumberDateError: true });
          break;
        }

        if (val.length > 8) {
          this.setState({ orderNumberDateHelperText: '例:YYYYMMDD' });
          this.setState({ orderNumberDateError: true });
          break;
        }

        this.setState({ orderNumberDateHelperText: '' });
        this.setState({ orderNumberDateError: false });
        break;
      }
      case 'orderNumberLastFour': {
        this.setState({ orderNumberLastFour: val });

        if (isNaN(val)) {
          this.setState({ orderNumberLastFourHelperText: '4桁:0001' });
          this.setState({ orderNumberLastFourError: true });
          break;
        }

        if (val.length < 4) {
          this.setState({ orderNumberLastFourHelperText: '4桁:0001' });
          this.setState({ orderNumberLastFourError: true });
          break;
        }

        if (val.length > 4) {
          this.setState({ orderNumberLastFourHelperText: '4桁:0001' });
          this.setState({ orderNumberLastFourError: true });
          break;
        }

        this.setState({ orderNumberLastFourHelperText: '' });
        this.setState({ orderNumberLastFourError: false });
        break;
      }
      case 'personID': {
        this.setState({ personID: val });
        this.setState({ personIDError: false });
        break;
      }
      case 'shipmentPersonID': {
        this.setState({ shipmentPersonID: val });
        this.setState({ shipmentPersonIDError: false });
        break;
      }
      case 'companyID': {
        this.setState({ companyID: val });
        this.setState({ companyIDError: false });
        break;
      }
      case 'companyPerson': {
        this.setState({ companyPerson: val });

        if (val.length > 32) {
          this.setState({ companyPersonHelperText: '名前が長すぎます' });
          this.setState({ companyPersonError: true });
          break;
        }

        this.setState({ companyPersonHelperText: '' });
        this.setState({ companyPersonError: false });
        break;
      }
      case 'projectName': {
        this.setState({ projectName: val });

        if (val.length > 128) {
          this.setState({ projectNameHelperText: '案件名が長すぎます' });
          this.setState({ projectNameError: true });
          break;
        }

        this.setState({ projectNameHelperText: '' });
        this.setState({ projectNameError: false });
        break;
      }
      case 'useStartDate': {

        this.setState({ useStartDate: val });

        if (val === null || val === '' || val === 'undefined') {
          this.setState({ useStartDateError: true });
          this.setState({ useStartDateHelperText: '有効な日付を入力してください' })
          break;
        }

        this.setState({ useStartDateError: false });
        this.setState({ useStartDateHelperText: '' });
        this.setState({ useEndDate: val });
        this.setState({ useEndDateError: false });
        this.setState({ useEndDateHelperText: '' });

        this.setState({ collectDate: val });
        this.setState({ collectDateError: false });
        this.setState({ collectDateHelperText: '' });
        break;
      }
      case 'useEndDate': {

        this.setState({ useEndDate: val });
        if (val === null || val === '' || val === 'undefined') {
          this.setState({ useEndDateError: true });
          this.setState({ useEndDateHelperText: '有効な日付を入力してください' })
          break;
        }

        this.setState({ useEndDateError: false });
        this.setState({ useEndDateHelperText: '' });

        this.setState({ collectDate: val });
        this.setState({ collectDateError: false });
        this.setState({ collectDateHelperText: '' });
        break;
      }
      case 'deliveryDate': {

        this.setState({ deliveryDate: val });

        if (val === null || val === '' || val === 'undefined') {
          this.setState({ deliveryDateError: true });
          this.setState({ deliveryDateHelperText: '有効な日付を入力してください' });
          break;
        }

        this.setState({ deliveryDateError: false });
        this.setState({ deliveryDateHelperText: '' });
        break;
      }
      case 'shippingDate': {
        this.setState({ shippingDate: val });

        if (val === null || val === '' || val === 'undefined') {
          this.setState({ shippingDateError: true });
          this.setState({ shippingDateHelperText: '有効な日付を入力してください' });
          break;
        }

        this.setState({ shippingDateError: false });
        this.setState({ shippingDateHelperText: '' });
        break;
      }
      case 'shippingMethod': {
        this.setState({ shippingMethod: val });
        this.setState({ shippingMethodError: false });
        break;
      }
      case 'billingDate': {

        this.setState({ billingDate: val });

        if (val === null || val === '' || val === 'undefined') {
          this.setState({ billingDateError: true });
          this.setState({ billingDateHelperText: '有効な日付を入力してください' })
          break;
        }

        this.setState({ billingDateError: false });
        this.setState({ billingDateHelperText: '' });
        break;
      }
      case "collectDate": {

        this.setState({ collectDate: val });

        if (val === null || val === '' || val === 'undefined') {
          this.setState({ collectDateError: true });
          this.setState({ collectDateHelperText: '有効な日付を入力してください' })
          break;
        }

        this.setState({ collectDateError: false });
        this.setState({ collectDateHelperText: '' });
        break;
      }
      case 'collectMethod': {
        this.setState({ collectMethod: val });
        this.setState({ collectMethodError: false });
        break;
      }
      case 'paymentDate': {

        this.setState({ paymentDate: val });

        if (val === null || val === '' || val === 'undefined') {
          this.setState({ paymentDateError: true });
          this.setState({ paymentDateHelperText: '有効な日付を入力してください' })
          break;
        }

        this.setState({ paymentDateError: false });
        this.setState({ paymentDateHelperText: '' })
        break;
      }
      case "delivaryLocation": {
        this.setState({ delivaryLocation: val });

        if (val.length > 128) {
          this.setState({ delivaryLocationError: true });
          this.setState({ delivaryLocationHelperText: '納品場所名が長すぎます' });
          break;
        }

        this.setState({ delivaryLocationError: false });
        this.setState({ delivaryLocationHelperText: '' });
        break;
      }
      case 'remarks': {
        this.setState({ remarks: val });
      }
      default: {
        break;
      }
    }
  };

  // ステータスボタンの制御
  orderFormalChecked(evt) {

    if (evt) {
      this.setState({ orderFormal: evt });
      this.setState({ estimatedDisable: false });
      this.setState({ shippedDisable: false });
      this.setState({ collectedDisable: false });
      this.setState({ billedDisable: false });
      this.setState({ paymentedDisable: false });
    } else {
      this.setState({ orderFormal: evt });
      this.setState({ estimatedDisable: true });
      this.setState({ shippedDisable: true });
      this.setState({ collectedDisable: true });
      this.setState({ billedDisable: true });
      this.setState({ paymentedDisable: true });
    }
  }

  // 見積データ入力イベント
  onChangeEstimateCell(evt, i) {
    const changeForm = this.state.estimateTable.slice()[i];
    const val = evt.target.value;

    switch (evt.target.name) {
      case "productType": {
        changeForm.productType = val;

        // 商品名がなしの場合、データを登録しない為、単価を0にし、合計金額に含めない
        if(val === '00'){
          changeForm.price = 0;
          changeForm.amount = changeForm.days * changeForm.quantity * changeForm.price;
          // 小計
          this.setState({ total: changeForm.amount });
          // 合計
          this.setState({ total: this.calcTotalAmount() });
        }


        break;
      }
      case "model": {
        changeForm.model = val;
        break;
      }
      case "outsourcingShow": {
        changeForm.outsourcingShow = evt.target.checked;
        break;
      }
      case "calendarShow": {
        changeForm.calendarShow = evt.target.checked;
        break;
      }
      case "useStartDate": {
        changeForm.useStartDate = val;
        const className = document.getElementsByClassName('useStartDateIndex' + i)[0];
        if (val === null || val === '' || val === 'undefined') {
          className.style.backgroundColor = '#ff8a80';
          break;
        }

        className.style.backgroundColor = '';

        // 日数自動反映
        const startMoment = moment(changeForm.useStartDate);
        const endMoment = moment(changeForm.useEndDate);
        changeForm.days = Math.abs(startMoment.diff(endMoment,'days')) + 1;
        // 小計
        changeForm.amount = changeForm.days * changeForm.quantity * changeForm.price;
        // 合計
        this.setState({ total: this.calcTotalAmount() });
        break;
      }
      case "useEndDate": {
        changeForm.useEndDate = val;
        const className = document.getElementsByClassName('useEndDateIndex' + i)[0];
        if (val === null || val === '' || val === 'undefined') {
          this.setState({ useEndEstimateError: true });
          className.style.backgroundColor = '#ff8a80';
          break;
        }
        className.style.backgroundColor = '';

        // 日数自動反映
        const startMoment = moment(changeForm.useStartDate);
        const endMoment = moment(changeForm.useEndDate);
        changeForm.days = Math.abs(startMoment.diff(endMoment,'days')) + 1;

        // 小計
        changeForm.amount = changeForm.days * changeForm.quantity * changeForm.price;
        // 合計
        this.setState({ total: this.calcTotalAmount() });
        break;
      }
      case "days": {

        if (isNaN(val)) {
          break;
        }
        changeForm.days = val;

        // 小計
        changeForm.amount = changeForm.days * changeForm.quantity * changeForm.price;
        // 合計
        this.setState({ total: this.calcTotalAmount() });
        break;
      }
      case "quantity": {

        if (isNaN(val)) {
          break;
        }
        changeForm.quantity = val;
        // 小計
        changeForm.amount = changeForm.days * changeForm.quantity * changeForm.price;
        // 合計
        this.setState({ total: this.calcTotalAmount() });
        break;
      }
      case "unit": {
        changeForm.unit = val;
        break;
      }
      case "price": {

        // 表示金額を修正
        const _price = Number(val.replace(/,/g, ''));

        if (isNaN(_price)) {
          break;
        }
        changeForm.price = _price;

        changeForm.amount = changeForm.days * changeForm.quantity * changeForm.price;
        // 小計
        this.setState({ total: changeForm.amount });
        // 合計
        this.setState({ total: this.calcTotalAmount() });
        break;
      }
      case "amount": {
        changeForm.amount = val;
        // 小計
        changeForm.amount = changeForm.days * changeForm.quantity * changeForm.price;
        // 合計
        this.setState({ total: this.calcTotalAmount() });
        break;
      }
      default: {
        break;
      }

    }
    this.forceUpdate();
  }

  // 請求合計の算出
  calcTotalAmount() {
    const _estimateTable = this.state.estimateTable;
    var _total = 0;
    var _totalInTax = 0;
    const productTypeList = this.state.product_type;
    const _tax = this.state.tax;

    for(const i in _estimateTable) {
      var isInTax = false;
      _total += _estimateTable[i].amount;

      productTypeList.forEach((v) => {
        if(v.productType === _estimateTable[i].productType && v.isInTax === '1'){
          isInTax = true;
          return;
        }
      });

      if(isInTax) {
        _totalInTax += _estimateTable[i].amount + (_estimateTable[i].amount * _tax / 100);
      } else {
        _totalInTax += _estimateTable[i].amount; 
      }
    }

    // 
    this.setState({ totalInTax: _totalInTax })
    return _total;
  }

  //リストメニュー描画
  // 種別
  getProductTypeOptions = () => {
    const product_type = this.state.product_type;
    const menuItems = [];
    for (const i in product_type) {
      menuItems.push(
        <MenuItem
          value={product_type[i].productType}
          key={product_type[i].productType}
        >
          {product_type[i].productName}
        </MenuItem>
      );
    }
    return menuItems;
  };
  // 機種
  getProductModelOptions = () => {
    const product_model = this.state.model_list;
    const menuItems = [];
    for (const i in product_model) {
      menuItems.push(
        <MenuItem
          value={product_model[i].model}
          key={product_model[i].model}
        >
          {product_model[i].modelName}
        </MenuItem>
      );
    }
    return menuItems;
  };
  // 受注担当
  getPersonListOptions = () => {
    const personList = this.state.person_list;
    const menuItems = [];
    for (const i in personList) {
      menuItems.push(
        <MenuItem
          value={personList[i].personId}
          key={personList[i].personId}
        >
          {personList[i].personName}
        </MenuItem>
      );
    }
    return menuItems;
  };
  // 出荷方法、回収方法
  getPickupMethodOptions = () => {
    const pickupMethodList = this.state.pickup_method_list;
    const menuItems = [];
    for (const i in pickupMethodList) {
      menuItems.push(
        <MenuItem
          value={pickupMethodList[i].pickupId}
          key={pickupMethodList[i].pickupId}
        >
          {pickupMethodList[i].pickupMethod}
        </MenuItem>
      );
    }
    return menuItems;
  };
  // 取引先
  getCompanyListOptions = () => {
    const companyList = this.state.company_list;
    const menuItems = [];
    for (const i in companyList) {
      menuItems.push(
        <MenuItem
          value={companyList[i].companyId}
          key={companyList[i].companyId}
        >
          {companyList[i].companyId + '： ' + companyList[i].companyName}
        </MenuItem>
      );
    }
    return menuItems;
  };
  // 単位
  getUnitListOptions = () => {
    const unitList = this.state.unit_list;
    const menuItems = [];
    for (const i in unitList) {
      menuItems.push(
        <MenuItem
          value={unitList[i].unitId}
          key={unitList[i].unitId}
        >
          {unitList[i].unit}
        </MenuItem>
      );
    }
    return menuItems;
  };

  cancel() {
    const { dispatch } = this.props;
    if (this.state.isOriginalMode) {
      dispatch(moveTo.CompanyMaster());
    } else {
      dispatch(moveTo.Order());
    }
  }


    //注文番号をstateに渡してmoveToする
    moveToScan(){
      this.props.history.push({ pathname: '/statusInput', state: { orderNumber:'01'+this.state.orderNumberDate + this.state.orderNumberLastFour }});
    }

    //データの1レコード記述
    renderRows(productType,model,outsourcingShow,calendarShow,useStartDate,useEndDate,days,quantity,unit,price,amount,i){
      const { classes } = this.props;
      const row = this.state.estimateTable.slice()[i];
      const productTypeListMenu = this.getProductTypeOptions();
      const productModelListMenu = this.getProductModelOptions();
      const unitListMenu = this.getUnitListOptions();

      return(
      <Box style={{ minWidth:"1411px" }}>
        <Box display={`flex`} justifyContent={`stretch`} flexGrow={1} style={{height:'30px' ,fontSize:'15px'}}>

          <Box className={classes.mstTblItemFirst} width={'4%'} display={`flex`} justifyContent={`center`} alignItems="center">
            {/*<Box>{this.state.estimateTable[i].estimateIndex}</Box>*/}
            <Box>{i + 1}</Box>
          </Box>
          <Box className={classes.mstTblItem + ' productTypeIndex' + i} width={'12%'}>
            <Select
              id="productType"
              name="productType"
              fullWidth
              disableUnderline
              value={row.productType}
              onChange={event => { this.onChangeEstimateCell(event, i) }}
            >
              {productTypeListMenu}
            </Select>
          </Box>
          <Box className={classes.mstTblItem + ' modelIndex' + i} width={'12%'}>
            <Select
              id="model"
              name="model"
              fullWidth
              disableUnderline
              value={row.model}
              onChange={event => { this.onChangeEstimateCell(event, i) }}
            >
              {productModelListMenu}
            </Select>
          </Box>
          <Box className={classes.mstTblItem + ' outsourcingShowIndex' + i} width={'6%'}>
            <Checkbox
              id="outsourcingShow"
              name="outsourcingShow"
              onChange={event => { this.onChangeEstimateCell(event, i) }}
              checked={row.outsourcingShow}
            />
          </Box>
          <Box className={classes.mstTblItem + ' calendarShowIndex' + i} width={'12%'}>
            <Checkbox
              id="calendarShow"
              name="calendarShow"
              onChange={event => { this.onChangeEstimateCell(event, i) }}
              checked={row.calendarShow}
            />
          </Box>
          <Box className={classes.mstTblItem + ' useStartDateIndex' + i} width={'12%'}>
            <InputBase
              id="useStartDate"
              name="useStartDate"
              type="date"
              fullWidth
              inputProps={{ useStyles, style: { paddingLeft: '10%' } }}
              value={row.useStartDate}
              onChange={event => { this.onChangeEstimateCell(event, i) }}
            />
          </Box>
          <Box className={classes.mstTblItem + ' useEndDateIndex' + i} width={'12%'}>
            <InputBase
              id="useEndDate"
              name="useEndDate"
              type="date"
              fullWidth
              inputProps={{ style: { paddingLeft: '10%' } }}
              value={row.useEndDate}
              onChange={event => { this.onChangeEstimateCell(event, i) }}
            />
          </Box>
          <Box className={classes.mstTblItem + ' daysIndex' + i} width={'4%'}>
            <InputBase
              id="days"
              name="days"
              fullWidth
              inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' } }}
              value={row.days}
              onChange={event => { this.onChangeEstimateCell(event, i) }}
            />
          </Box>
          <Box className={classes.mstTblItem + ' quantityIndex' + i} width={'4%'}>
            <InputBase
              id="quantity"
              name="quantity"
              fullWidth
              inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' } }}
              value={row.quantity}
              onChange={event => { this.onChangeEstimateCell(event, i) }}
            />
          </Box>
          <Box className={classes.mstTblItem + ' unitIndex' + i} width={'4%'}>
            <Select
              id="unit"
              name="unit"
              fullWidth
              disableUnderline
              value={row.unit}
              onChange={event => { this.onChangeEstimateCell(event, i) }}
            >
              {unitListMenu}
            </Select>
          </Box>
          <Box className={classes.mstTblItem + ' priceIndex' + i} width={'9%'}>
            <InputBase
              id="price"
              name="price"
              inputProps={{ style: { textAlign: 'right', paddingRight: '10%', paddingLeft: '10%' } }}
              value={row.price.toLocaleString()}
              onChange={event => { this.onChangeEstimateCell(event, i) }}
            />
          </Box>
          <Box className={classes.mstTblItem + ' amountIndex' + i} width={'9%'}>
            <InputBase
              id="amount"
              name="amount"
              fullWidth
              inputProps={{ style: { textAlign: 'right', paddingRight: '10%', paddingLeft: '10%' } }}
              value={row.amount.toLocaleString()}
              onChange={event => { this.onChangeEstimateCell(event, i) }}
            />
          </Box>
          <Box className={classes.mstTblItemEnd + ' amountIndex' + i} width={'9%'}>
            <InputBase
              id="amountIntax"
              name="amount"
              fullWidth
              inputProps={{ style: { textAlign: 'right', paddingRight: '10%', paddingLeft: '10%' } }}
              value={this.calcAmountInTax(row.amount,row.productType).toLocaleString()}
              onChange={event => { this.onChangeEstimateCell(event, i) }}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  // 税込金額計算
  calcAmountInTax(amount,currentType) {
    var result = amount;
    const productTypeList = this.state.product_type;
    var isInTax = false;
    productTypeList.forEach((v) => {
      if(v.productType === currentType && v.isInTax === '1'){
        isInTax = true;
      }
    });

    if(isInTax){
      result = amount + (amount * this.state.tax / 100);
    }

    return  Math.floor(result);
  }

  addNewRow() {
    const estimateTable = this.state.estimateTable.slice();
    let _useStartDate = this.state.useStartDate;
    let _useEndDate = this.state.useEndDate;

    if(_useStartDate === '' || _useStartDate === undefined || _useStartDate === null) {
      _useStartDate = moment().format('YYYY-MM-DD');
    }

    if(_useEndDate === '' || _useEndDate === undefined || _useEndDate === null) {
      _useEndDate = moment().format('YYYY-MM-DD');
    }

    estimateTable.push({
      estimateIndex: null,
      orderNumber: null,
      productType: "01",
      model: "01",
      outsourcingShow: false,
      calendarShow: false,
      useStartDate: _useStartDate,
      useEndDate: _useEndDate,
      quantity: '',
      days: 0,
      unit: "01",
      price: 0,
      amount: 0,
    });
    this.setState({ estimateTable: estimateTable });
  }

  removeRow() {
    const estimateTable = this.state.estimateTable.slice();
    estimateTable.pop();
    this.setState({ estimateTable: estimateTable });
  }

  //画面描画
  render() {

    const personListMenu = this.getPersonListOptions();
    const companyListMenu = this.getCompanyListOptions();
    const pickupMethodListMenu = this.getPickupMethodOptions();

    /**       コンボボックス対応       */
    const _companyList = this.state.company_list;
    let companyOption = [];

    _companyList.forEach(v => {
      let tmp = {};
      tmp.companyID = v.companyId;
      tmp.companyName = v.companyName;
      companyOption.push(tmp);
    });

    // TODO
    if(_companyList.length !== 0 && this.state.isFirst === 'FIRST' && this.state.isUpdate){
      this.onChangeAutoCompletField('', this.state.companyID, 'SEARCH');
      this.setState({isFirst:''});
    }
    /**                              */

    const { classes } = this.props;
    //サブヘッダ
    const titleDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px", minWidth: "1411px" }}   >
        <Box flexGrow={1}>
          <Box p={1} className={classes.titleHeaderColor}>
            {this.state.isOriginalMode ? '注文入力画面（原本）' : '注文入力画面'}
          </Box>
        </Box>
      </Box>
    );

    //注文データ
    const orderData = (
      <Box display={'flex'} style={{ minWidth: "1411px", marginBottom: "1%" }}>
        <Box flexGrow={1} style={{ width: "70%", borderRadius: "5px", border: "1px solid #bdbdbd" }} marginRight={'1%'} padding={'10px'}>
          <Box display={'flex'} marginTop={"1%"}>
            <Box display="flex" width={'9%'} justifyContent="center" alignItems="center">
              <Box>注文日</Box>
            </Box>
            <Box width={'24%'}>
              <TextField
                name="orderDate"
                id="orderDate"
                label="注文日"
                fullWidth
                variant="outlined"
                size="small"
                type="date"
                value={this.state.orderDate}
                onChange={event => this.onChangeField(event)}
                helperText={this.state.orderDateHelperText}
                error={this.state.orderDateError}
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' } }}
              />
            </Box>
            <Box display="flex" width={'9%'} justifyContent="center" alignItems="center">
              <Box>注文番号</Box>
            </Box>
            <Box display="flex" width={'24%'} >
              <TextField
                id="orderNumberDate"
                name="orderNumberDate"
                //fullWidth
                style={{ width: '150px' }}
                variant="outlined"
                size="small"
                value={this.state.orderNumberDate}
                className={classes.textField}
                onChange={this.state.isUpdate ? '' : event => this.onChangeField(event)}
                InputLabelProps={{ shrink: true }}
                helperText={this.state.orderNumberDateHelperText}
                error={this.state.orderNumberDateError}
                disabled={this.state.isUpdate || this.state.isOriginalMode ? true : false}
              />

              <Box display="flex" width={'9%'} justifyContent="center" alignItems="center">
                <Box>ー</Box>
              </Box>
              <TextField
                id="orderNumberLastFour"
                name="orderNumberLastFour"
                style={{ width: '100px' }}
                variant="outlined"
                size="small"
                value={this.state.orderNumberLastFour}
                className={classes.textField}
                onChange={this.state.isUpdate ? '' : event => this.onChangeField(event)}
                InputLabelProps={{ shrink: true }}
                helperText={this.state.orderNumberLastFourHelperText}
                error={this.state.orderNumberLastFourError}
                disabled={this.state.isUpdate || this.state.isOriginalMode ? true : false}
              />
            </Box>
            <Box display="flex" width={'10%'} justifyContent="center" alignItems="center">
              <Box>担当者</Box>
            </Box>
            <Box width={'24%'}>
              <Select
                id="personID"
                name="personID"
                fullWidth
                defaultValue="001"
                value={this.state.personID}
                style={{ backgroundColor: "#FFFFFF" }}
                onChange={event => this.onChangeField(event)}
                error={this.state.personIDError}
              >
                {personListMenu}
              </Select>
            </Box>
          </Box>
          <Box display={'flex'} marginTop={"1%"}>
            <Box display="flex" width={'9%'} justifyContent="center" alignItems="center">
              <Box>取引先名</Box>
            </Box>
            <Box width={'23%'}>
              {/*<Select
                id="companyID"
                name="companyID"
                fullWidth
                size={"small"}
                MenuProps={{style:{
                  height:'600px'
                }}}
                value={this.state.companyID}
                style={{ backgroundColor: "#FFFFFF" }}
                onChange={event => this.onChangeField(event)}
                error={this.state.companyIDError}
                disabled={this.state.isOriginalMode ? true : false}
              >
                {companyListMenu}
              </Select>
              */}

              {/********コンボボックス対応**********/}
              
              <Autocomplete
                name="AutocompleteCompanyID"
                size='small'
                options={companyOption}
 
                ListboxProps={{style:{height:350 }}}
                getOptionLabel={(option) => option.companyName}
                disabled={this.state.isOriginalMode ? true : false}
                value={this.state.searchCompanyName}
                inputValue={this.state.searchCompanyName}
                renderInput={
                  (params) => 
                  <TextField 
                    name="companyID" 
                    fullWidth
                    style={{ backgroundColor: "#FFFFFF", }}
                    error={this.state.companyIDError}
                    {...params} variant="outlined"
                  />
                }
                onChange={(event,value) => this.onChangeAutoCompletField(event, value, 'SELECT')}
                onInputChange={(event,value) => this.onChangeAutoCompletField(event, value, 'INPUT')}
              />
              {/**********************************/}
            </Box>
            <Box display="flex" width={'10%'} justifyContent="center" alignItems="center">
              <Box>取引先担当</Box>
            </Box>
            <Box width={'24%'}>
              <TextField
                id="companyPerson"
                name="companyPerson"
                label="取引先担当"
                fullWidth
                variant="outlined"
                size="small"
                value={this.state.companyPerson}
                className={classes.textField}
                helperText={this.state.companyPersonHelperText}
                error={this.state.companyPersonError}
                onChange={event => this.onChangeField(event)}
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            <Box display="flex" width={'10%'} justifyContent="center" alignItems="center">
              <Box>出荷担当</Box>
            </Box>
            <Box width={'24%'}>
              <Select
                id="shipmentPersonID"
                name="shipmentPersonID"
                fullWidth
                defaultValue="001"
                value={this.state.shipmentPersonID}
                style={{ backgroundColor: "#FFFFFF" }}
                onChange={event => this.onChangeField(event)}
                error={this.state.shipmentPersonIDError}
              >
                {personListMenu}
              </Select>
            </Box>
          </Box>
          <Box display={'flex'} marginTop={"1%"}>
            <Box display="flex" width={'9%'} justifyContent="center" alignItems="center">
              <Box>案件名</Box>
            </Box>
            <Box width={'91%'}>
              <TextField
                id="projectName"
                name="projectName"
                label="案件名"
                fullWidth
                variant="outlined"
                size="small"
                value={this.state.projectName}
                onChange={event => this.onChangeField(event)}
                className={classes.textField}
                helperText={this.state.projectNameHelperText}
                error={this.state.projectNameError}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>
          <Box display={'flex'} marginTop={"1%"} alignItems="center" >
            <Box width={'9%'} justifyContent="center" Style={"text-align:center;"}>
              <Box>
                使用期間開始
                          </Box>
            </Box>
            <Box width={'22%'}>
              <TextField
                id="useStartDate"
                name="useStartDate"
                label="使用期間開始"
                fullWidth
                type="date"
                variant="outlined"
                size="small"
                value={this.state.useStartDate}
                helperText={this.state.useStartDateHelperText}
                error={this.state.useStartDateError}
                className={classes.textField}
                onChange={event => this.onChangeField(event)}
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' } }}
              />
            </Box>
            <Box width={'9%'} justifyContent="center" Style={"text-align:center;"}>
              <Box>
                〜　終了
                            </Box>
            </Box>
            <Box width={'22%'}>
              <TextField
                id="useEndDate"
                name="useEndDate"
                label="使用期間終了"
                fullWidth
                type="date"
                variant="outlined"
                size="small"
                value={this.state.useEndDate}
                helperText={this.state.useEndDateHelperText}
                error={this.state.useEndDateError}
                className={classes.textField}
                onChange={event => this.onChangeField(event)}
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' } }}
              />
            </Box>
            <Box width={'9%'} justifyContent="center" Style={"text-align:center;"}>
              <Box>
                納品日
                            </Box>
            </Box>
            <Box width={'22%'}>
              <TextField
                id="deliveryDate"
                name="deliveryDate"
                label="納品日"
                fullWidth
                type="date"
                variant="outlined"
                size="small"
                value={this.state.deliveryDate}
                helperText={this.state.deliveryDateHelperText}
                error={this.state.deliveryDateError}
                onChange={event => this.onChangeField(event)}
                className={classes.textField}
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' } }}
              />
            </Box>
          </Box>
          <Box display={'flex'} marginTop={"1%"}>
            <Box display={'flex'} width={'100%'} >
              <Box width={'91%'}>
                <Box display={'flex'} justifyContent="center" alignItems="center">
                  <Box width={'10%'} justifyContent="center" alignItems="center" Style={"text-align:center;"}>
                    <Box>
                      出荷日
                            </Box>
                  </Box>
                  <Box width={'22%'}>
                    <TextField
                      id="shippingDate"
                      name="shippingDate"
                      label="出荷日"
                      fullWidth
                      type="date"
                      variant="outlined"
                      size="small"
                      value={this.state.shippingDate}
                      helperText={this.state.shippingDateHelperText}
                      error={this.state.shippingDateError}
                      onChange={event => this.onChangeField(event)}
                      className={classes.textField}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' } }}
                    />
                  </Box>
                  <Box width={'12%'} justifyContent="center" Style={"text-align:center;"}>
                    <Box>
                      出荷方法
                            </Box>
                  </Box>
                  <Box width={'22%'}>
                    <Select
                      id="shippingMethod"
                      name="shippingMethod"
                      fullWidth
                      size="small"
                      value={this.state.shippingMethod}
                      style={{ backgroundColor: "#FFFFFF" }}
                      onChange={event => this.onChangeField(event)}
                      error={this.state.shippingMethodError}
                    >
                      {pickupMethodListMenu}

                    </Select>
                  </Box>
                  <Box width={'12%'} justifyContent="center" Style={"text-align:center;"}>
                    <Box>
                      請求日
                            </Box>
                  </Box>
                  <Box width={'22%'}>
                    <TextField
                      id="billingDate"
                      name="billingDate"
                      label="請求日"
                      fullWidth
                      type="date"
                      variant="outlined"
                      size="small"
                      value={this.state.billingDate}
                      helperText={this.state.billingDateHelperText}
                      error={this.state.billingDateError}
                      className={classes.textField}
                      onChange={event => this.onChangeField(event)}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' } }}
                    />
                  </Box>
                </Box>
                <Box display={'flex'} marginTop={"1%"} justifyContent="center" alignItems="center">
                  <Box width={'10%'} justifyContent="center" alignItems="center" Style={"text-align:center;"}>
                    <Box>
                      回収日
                            </Box>
                  </Box>
                  <Box width={'22%'}>
                    <TextField
                      id="collectDate"
                      name="collectDate"
                      label="回収日"
                      fullWidth
                      type="date"
                      variant="outlined"
                      size="small"
                      value={this.state.collectDate}
                      helperText={this.state.collectDateHelperText}
                      error={this.state.collectDateError}
                      onChange={event => this.onChangeField(event)}
                      className={classes.textField}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' } }}
                    />
                  </Box>
                  <Box width={'12%'} justifyContent="center" Style={"text-align:center;"}>
                    <Box>
                      回収方法
                            </Box>
                  </Box>
                  <Box width={'22%'}>
                    <Select
                      id="collectMethod"
                      name="collectMethod"
                      fullWidth
                      size="small"
                      style={{ backgroundColor: "#FFFFFF" }}
                      value={this.state.collectMethod}
                      onChange={event => this.onChangeField(event)}
                      error={this.state.collectMethodError}
                    >
                      {pickupMethodListMenu}
                    </Select>
                  </Box>
                  <Box width={'12%'} justifyContent="center" Style={"text-align:center;"}>
                    <Box>
                      入金日
                            </Box>
                  </Box>
                  <Box width={'22%'}>
                    <TextField
                      id="paymentDate"
                      name="paymentDate"
                      label="入金日"
                      fullWidth
                      type="date"
                      variant="outlined"
                      size="small"
                      value={this.state.paymentDate}
                      helperText={this.state.paymentDateHelperText}
                      error={this.state.paymentDateError}
                      onChange={event => this.onChangeField(event)}
                      className={classes.textField}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' } }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box width={'80px'} paddingLeft="2%" paddingRight="2%">
                <Button
                  variant={`contained`}
                  className={classes.button}
                  fullWidth
                  disabled={this.state.isOriginalMode ? true : false}
                  onClick={this.calcPaymentAndBilling.bind(this)}
                >
                  請求日入金日入力
                       </Button>
              </Box>
            </Box>
          </Box>
          <Box display={'flex'} marginTop={"1%"}>
            <Box display="flex" width={'9%'} justifyContent="center" alignItems="center">
              <Box>納品場所</Box>
            </Box>

            <Box width={'91%'}>
              <TextField
                id="delivaryLocation"
                name="delivaryLocation"
                label="納品場所"
                fullWidth
                variant="outlined"
                size="small"
                value={this.state.delivaryLocation}
                helperText={this.state.delivaryLocationHelperText}
                error={this.state.delivaryLocationError}
                onChange={event => this.onChangeField(event)}
                className={classes.textField}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>

          <Box display={'flex'} marginTop={"1%"}>
            <Box display="flex" width={'9%'} justifyContent="center" alignItems="center">
              <Box>備考</Box>
            </Box>
            <Box width={'91%'}>
              <TextField
                id="remarks"
                name='remarks'
                label="備考"
                multiline
                fullWidth
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={this.state.remarks}
                onChange={event => this.onChangeField(event)}
              />
            </Box>
          </Box>
        </Box>

        <Box flexGrow={1} style={{ width: "26%", borderRadius: "5px", border: "1px solid #bdbdbd" }} marginLeft={'1%'} padding={'10px'}>
          <Box className={classes.titleHeaderColor} style={{ padding: "1%" }}>
            注文ステータス
                </Box>
          <Box display={'flex'} marginTop={"5%"} justifyContent="center" alignItems="center">
            <Box width={'60%'} style={{ textAlign: "center" }}>
              本注文
                  </Box>
            <Box width={'40%'}>
              <Switch
                id="orderFormal"
                name="orderFormal"
                checked={this.state.orderFormal}
                disabled={this.orderFormal || this.state.orderFormalDisable}
                onChange={checked => { this.orderFormalChecked(checked) }}
              />
            </Box>
          </Box>
          <Box display={'flex'} marginTop={"5%"} justifyContent="center" alignItems="center">
            <Box width={'60%'} style={{ textAlign: "center" }}>
              注文キャンセル
                  </Box>
            <Box width={'40%'}>
              <Switch
                id="orderCancel"
                name="orderCancel"
                checked={this.state.orderCancel}
                disabled={this.state.orderCancelDisable}
                onChange={checked => { this.setState({ orderCancel: checked }) }}
              />
            </Box>
          </Box>
          <Box className={classes.titleHeaderColor} marginTop="10%" padding="1%">
            処理ステータス
                </Box>
          <Box display={'flex'} marginTop={"5%"} justifyContent="center" alignItems="center">
            <Box width={'17%'} style={{ textAlign: "center" }}>
              見積
                  </Box>
            <Box width={'17%'}>
              <Switch
                id="estimated"
                name="estimated"
                checked={this.state.estimated}
                disabled={this.orderFormal || this.state.estimatedDisable || this.state.orderCancel }
                onChange={checked => { this.setState({ estimated: checked }) }}
              />
            </Box>
            <Box width={'16%'} style={{ textAlign: "center" }}>
              出荷
                  </Box>
            <Box width={'17%'}>
              <Switch
                id="shipped"
                name="shipped"
                checked={this.state.shipped}
                disabled={this.orderFormal || this.state.shippedDisable || this.state.orderCancel}
                onChange={checked => { this.setState({ shipped: checked }) }}
              />
            </Box>
            <Box width={'16%'} style={{ textAlign: "center" }}>
              回収
                  </Box>
            <Box width={'17%'}>
              <Switch
                id="collected"
                name="collected"
                checked={this.state.collected}
                disabled={this.orderFormal || this.state.collectedDisable || this.state.orderCancel}
                onChange={checked => { this.setState({ collected: checked }) }}
              />
            </Box>
          </Box>
          <Box display={'flex'} marginTop={"5%"} justifyContent="center" alignItems="center">
            <Box width={'16%'} style={{ textAlign: "center" }}>
              請求
                  </Box>
            <Box width={'17%'}>
              <Switch
                id="billed"
                name="billed"
                checked={this.state.billed}
                disabled={this.orderFormal || this.state.billedDisable || this.state.orderCancel}
                onChange={checked => { this.setState({ billed: checked }) }}
              />
            </Box>
            <Box width={'16%'} style={{ textAlign: "center" }}>
              入金
                  </Box>
            <Box width={'17%'}>
              <Switch
                id="paymented"
                name="paymented"
                checked={this.state.paymented}
                disabled={this.orderFormal || this.state.paymentedDisable || this.state.orderCancel}
                onChange={checked => { this.setState({ paymented: checked }) }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    )
    //見積データヘッダ
    const orderListHeader = (
      <Box>
        <Box
          style={{
            // border: `1px solid #525457`,
            borderRadius: '5px',
            minWidth: "1411px"
          }}
        >
          <Box display={`flex`} justifyContent={`stretch`} flexGrow={1}>
            <Box className={classes.mstTblColFirst} width={'4%'}>No</Box>
            <Box className={classes.mstTblCol} width={'12%'}>種別</Box>
            <Box className={classes.mstTblCol} width={'12%'}>機種名</Box>
            <Box className={classes.mstTblCol} width={'6%'}>外注表示</Box>
            <Box className={classes.mstTblColDouble} width={'40%'}>
              <Box style={{ borderBottom: "1px solid #b0bec5" }} >
                使用期間
                </Box>
              <Box display={`flex`}>
                <Box className={classes.mstTblChildrenCol} width={'30%'}>
                  カレンダー表示
                  </Box>
                <Box className={classes.mstTblChildrenCol} width={'30%'}>
                  開始
                  </Box>
                <Box className={classes.mstTblChildrenCol} width={'30%'}>
                  終了
                  </Box>
                <Box className={classes.mstTblChildrenColLast} width={'10%'}>
                  日数
                  </Box>
              </Box>
            </Box>
            <Box className={classes.mstTblCol} width={'4%'}>数量</Box>
            <Box className={classes.mstTblCol} width={'4%'}>単位</Box>
            <Box className={classes.mstTblCol} width={'9%'}>単価</Box>
            <Box className={classes.mstTblCol} width={'9%'}>金額</Box>
            <Box className={classes.mstTblColLast} width={'9%'}>税込金額</Box>
          </Box>
        </Box>
      </Box>
    )

    //フッター
    const orderListFooter = (
      <Box style={{
        minWidth: "1411px", height: "1px", borderRadius: "1px 1px 5px 5px",
        borderBottom: "1px solid #525457", borderLeft: "1px solid #525457", borderRight: "1px solid #525457",
      }}>
        <Box display={`flex`} >
          <Box className={classes.mstTblEnd} width={'4%'} height={'1px'}></Box>
          <Box className={classes.mstTblEnd} width={'12%'}></Box>
          <Box className={classes.mstTblEnd} width={'12%'}></Box>
          <Box className={classes.mstTblEnd} width={'6%'}></Box>
          <Box className={classes.mstTblEnd} width={'12%'}></Box>
          <Box className={classes.mstTblEnd} width={'12%'}></Box>
          <Box className={classes.mstTblEnd} width={'12%'}></Box>
          <Box className={classes.mstTblEnd} width={'4%'}></Box>
          <Box className={classes.mstTblEnd} width={'4%'}></Box>
          <Box className={classes.mstTblEnd} width={'4%'}></Box>
          <Box className={classes.mstTblEnd} width={'9%'}></Box>
          <Box className={classes.mstTblEnd} width={'9%'}></Box>
        </Box>

      </Box>

    )
    const remarksDiv = (
      <Box display={'flex'}  style={{ minWidth: "1411px", marginBottom: "1%", marginTop: "1%", }}>

        <Box width="40%" justifyContent="space-between" paddingLeft="5%">
          <Box display={`flex`} flexGrow={1} justifyContent="space-between" height={45}>
            <Box width={300}>
              <Button
                variant={`contained`}
                width="100px"
                className={classes.buttonColor}
                style={{ letterSpacing: "0px" }}
                //disabled={this.state.isOriginalMode ? true : false}
                onClick={this.referencePeriod.bind(this)}
              >
                期間入力
              </Button>
            </Box>

            <Box width={300}>
              <Button
                variant={`contained`}
                width="100px"
                className={classes.buttonColor}
                style={{ letterSpacing: "0px" }}
                disabled={this.state.isOriginalMode ? true : false}
                onClick={this.getOriginalPrice.bind(this)}
              >
                単価取得
              </Button>
            </Box>

            <Box width={300}>
              <Button
                variant={`contained`}
                className={classes.buttonColor}
                style={{ letterSpacing: "0px" }}
                disabled={this.state.isOriginalMode ? true : false}
                onClick={this.outPutOriginalDataExcel.bind(this)}
              >
                原本確認
              </Button>
            </Box>
          </Box>
        </Box>

        <Box width="60%" display={`flex`} justifyContent="flex-end">
          <Box width="400px" height={30} display={`flex`} borderBottom={1}>
            <Box fontSize="1em" >
              請求合計金額：
            </Box>
            <Box fontSize="1em" textAlign='right' width='200px' >
              {this.state.total.toLocaleString()}
            </Box>
            <Box fontSize="1em">
              円
            </Box>
          </Box>
          <Box width="400px" height={30} display={`flex`} borderBottom={1}>

            <Box fontSize="1em">
              請求合計金額(税込)：
            </Box>
            <Box fontSize="1em" textAlign='right' width='200px'>
              {this.state.totalInTax.toLocaleString()}
            </Box>

            <Box fontSize="1em">
              円
            </Box>
          </Box>
        </Box>


      </Box>
    );

    const addButton = (

      <Box style={{ marginTop: "15px" }}>
        <Box display={`flex`} flexGrow={1} justifyContent="flex-end" height={45}>
          <Box width={50}>
            <Fab
              size="small"
              className={classes.fabColor}
              onClick={this.addNewRow.bind(this)}  
            >
              <AddIcon />
            </Fab>
          </Box>
        </Box>
      </Box>
    );

    const buttonDiv = (
      <Grid container spacing={3} justify="center">
        <Grid item xs={12}></Grid>
        <Grid item xs={3} style={{ textAlign: "center" }}>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            style={{ letterSpacing: "0px" }}
            disabled={this.state.isOriginalMode ? true : false}
            onClick={this.moveToScan.bind(this)}
          >
            データスキャン
              </Button>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "center" }}>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            endIcon={<PostAddIcon />}
            onClick={this.opneDialog.bind(this, this.state.isUpdate ? this.update.bind(this) : this.insert.bind(this), '登録しますか？')}
          >
            登録
              </Button>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "center" }}>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            endIcon={<ExitToApp />}
            onClick={this.cancel.bind(this)}
          >
            戻る
          </Button>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "center" }}>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={this.opneOutPutDialog.bind(this, this.outPutExcel.bind(this))}
            disabled={this.state.isOriginalMode ? true : false}
          >
            書類発行
          </Button>
        </Grid>
      </Grid>
    );

    return (
      <Box style={{ height: "100vh", width: "100vw" }}>
        <Header value={this.state.headerErrorMessage} />
        <Alert 
          id="successAlert"
          style={{display:'none'}}
          className={classes.successAlertStyle}
          severity="success">
            {this.state.successMessage}
        </Alert>
        <Box
          style={{
            height: "calc(100% - 96px)",
            width: "calc(100% - 32px)",
            padding: "16px",
            overflow: "auto"
          }}
        >
          <Box minWidth={975}>
            {titleDiv}
            {orderData}

            <Box height={300} overflow={'scroll'}>
              {orderListHeader}
              {this.state.estimateTable &&
                this.state.estimateTable.map(
                  ({ productType, model, outsourcingShow, calendarShow, useStartDate, useEndDate, days, quantity, unit, price, amount }, i) =>
                    this.renderRows(productType, model, outsourcingShow, calendarShow, useStartDate, useEndDate, days, quantity, unit, price, amount, i)
                )}
              {orderListFooter}
            </Box>
            {addButton}
            {remarksDiv}
            {buttonDiv}
          </Box>
          <AlertDialog />
          <OutPutExcelDialog />
        </Box>
      </Box>
    );
  }

  /***********************************************************************************************
  * 注文一覧画面の変更ボタンから遷移した場合
  ***********************************************************************************************/
  async search(pram) {

    // 注文番号を分解
    const orderNumberLastFour = pram.substr(10);
    const orderNumberDate = pram.substr(2, 8);

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/order/list',
      {
        pageIndex: 0,
        pageSize: 100,
        orderNumber: pram,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      // 成功時
      const result = response.data.list[0];
      this.setState({ orderNumberDate: orderNumberDate });
      this.setState({ orderNumberLastFour: orderNumberLastFour });

      this.setState({ orderDate: result.orderDate });                               // 注文日
      this.setState({ orderFormal: result.orderFormal === '1' ? true : false });    // 本注文
      this.setState({ estimated: result.estimated === '1' ? true : false });        // 見積
      this.setState({ orderCancel: result.orderCancel === '1' ? true : false });    // 注文キャンセル
      this.setState({ shipped: result.shipped === '1' ? true : false });            // 出荷済
      this.setState({ collected: result.collected === '1' ? true : false });        // 回収済
      this.setState({ billed: result.billed === '1' ? true : false });              // 請求済
      this.setState({ paymented: result.paymented === '1' ? true : false });        // 入金済
      this.setState({ companyID: result.companyID });                               // 取引先ID
      // this.onChangeAutoCompletField('',result.companyID, 'SEARCH');                 // 取引先ID(コンボボックス表示用)
      this.setState({ companyPerson: result.companyPerson });                       // 取引先担当
      this.setState({ personID: result.personID });                                 // 受注担当ID
      this.setState({ shipmentPersonID: result.shipmentPerson });                   // 出荷担当ID
      this.setState({ projectName: result.projectName });                           // 案件名
      this.setState({ useStartDate: result.useStartDate });                         // 使用日開始
      this.setState({ useEndDate: result.useEndDate });                             // 使用日終了
      this.setState({ shippingDate: result.shippingDate });                         // 出荷日
      this.setState({ deliveryDate: result.deliveryDate });                         // 納品日
      this.setState({ collectDate: result.collectDate });                           // 回収日
      this.setState({ billingDate: result.billingDate });                           // 請求日
      this.setState({ paymentDate: result.paymentDate });                           // 入金日
      this.setState({ shippingMethod: result.shippingMethod });                     // 出荷方法
      this.setState({ collectMethod: result.collectMethod });                       // 回収方法
      this.setState({ delivaryLocation: result.deliveryPlace });                    // 納品場所
      this.setState({ total: result.total });                                       // 合計
      this.setState({ totalInTax: result.totalInTax });                             // 税込合計
      this.setState({ remarks: result.remarks });                                   // 備考
      this.setState({ isUpdate: true });
      // 見積データの検索
      this.searchEstimateData(pram);
      this.forceUpdate();
    }).catch(e => {
      console.log(e);
    });

    this.orderFormalChecked(this.state.orderFormal);
  }

  /***********************************************************************************************
  * 見積データの取得
  ***********************************************************************************************/
  async searchEstimateData(pram) {

    const { dispatch } = this.props;

    const param = Cookies.get('token');
    // 注文番号を分解
    await axios.post(
      REQUEST_PATH + 'api/estimate/list/' + pram,
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      // 成功時
      // propsに追加
      dispatch(orderRegist(response.data.list));

      this.setState({ estimateTable: response.data.list });
      this.forceUpdate();
    }).catch(e => {
      console.log(e);
    });;
  }
  //API処理記述
  async insert() {
    var _orderNumber = null;

    await this.errorStateClear();

    if (this.state.isOriginalMode) {
      _orderNumber = "00" + this.state.orderNumberDate + this.state.orderNumberLastFour
    } else {
      _orderNumber = "01" + this.state.orderNumberDate + this.state.orderNumberLastFour
    }

    if (this.validateOrderData(_orderNumber)) {
      this.setState({ headerErrorMessage: "入力に誤りがあります" });
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }

    // 見積データチェック
    if (this.validateEstimateData()) {
      this.setState({ headerErrorMessage: "入力に誤りがあります" });
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }

    const param = Cookies.get('token');

    await axios.post(REQUEST_PATH + 'api/order/create', {
      orderNumber: _orderNumber,                        // 注文番号
      orderDate: this.state.orderDate,                  // 注文日
      orderDisplayStatus: this.getOrderDisplayStatus(), // 注文表示ステータス 
      orderFormal: this.state.orderFormal ? "1" : "0",   // 本注文
      estimated: this.state.estimated ? "1" : "0",      // 見積
      orderCancel: this.state.orderCancel ? "1" : "0",  // 注文キャンセル
      shipped: this.state.shipped ? "1" : "0",           // 出荷済
      collected: this.state.collected ? "1" : "0",      // 回収済
      billed: this.state.billed ? "1" : "0",            // 請求済
      paymented: this.state.paymented ? "1" : "0",       // 入金済
      companyID: this.state.companyID,                  // 取引先ID
      companyPerson: this.state.companyPerson,           // 取引先担当
      personID: this.state.personID,                     // 受注担当ID
      shipmentPerson:this.state.shipmentPersonID,       // 出荷担当ID
      projectName: this.state.projectName,               // 案件名
      useStartDate: this.state.useStartDate,             // 使用日開始
      useEndDate: this.state.useEndDate,                 // 使用日終了
      shippingDate: this.state.shippingDate,             // 出荷日
      deliveryDate: this.state.deliveryDate,             // 納品日
      collectDate: this.state.collectDate,               // 回収日
      billingDate: this.state.billingDate,               // 請求日
      paymentDate: this.state.paymentDate,               // 入金日
      shippingMethod: this.state.shippingMethod,         // 出荷方法
      collectMethod: this.state.collectMethod,           // 回収方法
      deliveryPlace: this.state.delivaryLocation,        // 納品場所
      total: this.state.total,                           // 合計
      totalInTax: this.state.totalInTax,                 // 税込み合計
      remarks: this.state.remarks                        // 備考
    },{
      param,
      withCredentials: true,
    }).then(response => {
      this.forceUpdate();
      // 更新フラグを変更
      this.setState({ isUpdate: true });
      // 見積データの登録
      this.insertEstimateTable(_orderNumber);
    }).catch(e => {
      console.log(e);
      this.setState({ headerErrorMessage: "注文データの登録に失敗しました" });
      return;
    });
    document.getElementById('dialogBox').style.display = 'none';
  }

  //API処理記述
  async update() {
    var _orderNumber = null;

    await this.errorStateClear();

    // 注文番号
    if (this.state.isOriginalMode) {
      _orderNumber = "00" + this.state.orderNumberDate + this.state.orderNumberLastFour
    } else {
      _orderNumber = "01" + this.state.orderNumberDate + this.state.orderNumberLastFour
    }

    // 注文データチェック
    if (this.validateOrderData(_orderNumber)) {
      this.setState({ headerErrorMessage: "入力に誤りがあります" });
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }

    // 見積データチェック
    if (this.validateEstimateData()) {
      this.setState({ headerErrorMessage: "入力に誤りがあります" });
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }

    const param = Cookies.get('token');

    await axios.put(REQUEST_PATH + 'api/order/update', {
      orderNumber: _orderNumber,                        // 注文番号
      orderDate: this.state.orderDate,                  // 注文日
      orderDisplayStatus: this.getOrderDisplayStatus(), // 注文表示ステータス 
      orderFormal: this.state.orderFormal ? "1" : "0",   // 本注文
      estimated: this.state.estimated ? "1" : "0",      // 見積
      orderCancel: this.state.orderCancel ? "1" : "0",  // 注文キャンセル
      shipped: this.state.shipped ? "1" : "0",           // 出荷済
      collected: this.state.collected ? "1" : "0",      // 回収済
      billed: this.state.billed ? "1" : "0",            // 請求済
      paymented: this.state.paymented ? "1" : "0",       // 入金済
      companyID: this.state.companyID,                  // 取引先ID
      companyPerson: this.state.companyPerson,           // 取引先担当
      personID: this.state.personID,                     // 受注担当ID
      shipmentPerson:this.state.shipmentPersonID,       // 出荷担当ID
      projectName: this.state.projectName,               // 案件名
      useStartDate: this.state.useStartDate,             // 使用日開始
      useEndDate: this.state.useEndDate,                 // 使用日終了
      shippingDate: this.state.shippingDate,             // 出荷日
      deliveryDate: this.state.deliveryDate,             // 納品日
      collectDate: this.state.collectDate,               // 回収日
      billingDate: this.state.billingDate,               // 請求日
      paymentDate: this.state.paymentDate,               // 入金日
      shippingMethod: this.state.shippingMethod,         // 出荷方法
      collectMethod: this.state.collectMethod,           // 回収方法
      deliveryPlace: this.state.delivaryLocation,        // 納品場所
      total: this.state.total,                           // 合計
      totalInTax: this.state.totalInTax,                 // 税込み合計
      remarks: this.state.remarks                        // 備考
    },{
      param,
      withCredentials: true,
    }).then(response => {
      this.forceUpdate();
      // 見積データの登録
      this.insertEstimateTable(_orderNumber);
    }).catch(e => {
      console.log(e);
      this.setState({ headerErrorMessage: "注文データの更新に失敗しました" });
      return;
    });
    document.getElementById('dialogBox').style.display = 'none';
  }

  // エラーステート初期化
  async errorStateClear() {

    const _estimateTable =  this.state.estimateTable;
    this.setState({ headerErrorMessage: "" });

    this.setState({ orderDateHelperText: '' });
    this.setState({ orderNumberDateHelperText: '' });
    this.setState({ orderNumberLastFourHelperText: '' });
    this.setState({ companyPersonHelperText: '' });
    this.setState({ projectNameHelperText: '' });
    this.setState({ useStartDateHelperText: '' });
    this.setState({ useEndDateHelperText: '' });
    this.setState({ shippingDateHelperText: '' });
    this.setState({ deliveryDateHelperText: '' });
    this.setState({ collectDateHelperText: '' });
    this.setState({ billingDateHelperText: '' });
    this.setState({ paymentDateHelperText: '' });
    this.setState({ delivaryLocationHelperText: '' });

    // 赤枠
    this.setState({ orderDateError: false });
    this.setState({ orderDateError: false });
    this.setState({ orderNumberDateError: false });
    this.setState({ orderNumberLastFourError: false });
    this.setState({ projectNameError: false });
    this.setState({ useStartDateError: false });
    this.setState({ useEndDateError: false });
    this.setState({ shippingDateError: false });
    this.setState({ deliveryDateError: false });
    this.setState({ collectDateError: false });
    this.setState({ billingDateError: false });
    this.setState({ paymentDateError: false });
    this.setState({ delivaryLocationError: false });

    this.setState({ personIDError: false });
    this.setState({ shipmentPersonIDError: false });

    // TODO 見積データのエラ項目クリア
    var className = null;
    _estimateTable.forEach((v, i) => {
      // 種別
      className = document.getElementsByClassName('productTypeIndex' + i)[0];
      className.style.backgroundColor = '';
      // 機種
      className = document.getElementsByClassName('modelIndex' + i)[0];
      className.style.backgroundColor = '';
      // 使用開始日
      className = document.getElementsByClassName('useStartDateIndex' + i)[0];
      className.style.backgroundColor = '';
      // 使用終了日
      className = document.getElementsByClassName('useEndDateIndex' + i)[0];
      className.style.backgroundColor = '';
      // 単位
      className = document.getElementsByClassName('unitIndex' + i)[0];
      className.style.backgroundColor = '';
      className = document.getElementsByClassName('quantityIndex' + i)[0];
      className.style.backgroundColor = '';
    });
  }

  /************************************************************ 
  *データエントリー時stateチェック
  *エラーの場合trueを返す
  ************************************************************/
  validateOrderData(_orderNumber) {
    var isFailed = false;
    const s = this.state; // 省略

    // 注文番号
    if (_orderNumber === null || _orderNumber === '' || _orderNumber === 'undefined' || isNaN(_orderNumber)) {
      isFailed = true;
      this.setState({ orderNumberDateError: true });
      this.setState({ orderNumberDateHelperText: '例:YYYYMMMDD' });
      this.setState({ orderNumberLastFourError: true });
      this.setState({ orderNumberLastFourHelperText: '4桁:0001' });
    }
    // 注文日
    if (s.orderDate === null || s.orderDate === '' || s.orderDate === 'undefined') {
      isFailed = true;
      this.setState({ orderDateError: true });
      this.setState({ orderDateHelperText: '入力に誤りがあります' });
    }
    // 受注担当ID
    if (s.personID === null || s.personID === '' || s.personID === 'undefined') {
      isFailed = true;
      this.setState({ personIDError: true });
    }
    // 出荷担当ID
    /* 
    if (s.shipmentPersonID === null || s.shipmentPersonID === '' || s.shipmentPersonID === 'undefined') {
      isFailed = true;
      this.setState({ shipmentPersonIDError: true });
    }*/

    // 取引先ID
    if (s.companyID === null || s.companyID === '' || s.companyID === 'undefined') {
      isFailed = true;
      this.setState({ companyIDError: true });
    }
    // 取引先担当
    if (s.companyPerson === null || s.companyPerson === '' || s.companyPerson === 'undefined') {
      isFailed = true;
      this.setState({ companyPersonError: true });
      this.setState({ companyPersonHelperText: '取引先担当を入力してください' });
    }
    // 案件名
    if (s.projectName === null || s.projectName === '' || s.projectName === 'undefined') {
      isFailed = true;
      this.setState({ projectNameError: true });
      this.setState({ projectNameHelperText: '案件名を入力してください' });
    }
    // 使用日開始
    if (s.useStartDate === null || s.useStartDate === '' || s.useStartDate === 'undefined') {
      isFailed = true;
      this.setState({ useStartDateError: true });
      this.setState({ useStartDateHelperText: '有効な日付を入力してください' });
    }
    // 使用日終了
    if (s.useEndDate === null || s.useEndDate === '' || s.useEndDate === 'undefined') {
      isFailed = true;
      this.setState({ useEndDateError: true });
      this.setState({ useEndDateHelperText: '有効な日付を入力してください' });
    }
    // 納品日
    if (s.deliveryDate === null || s.deliveryDate === '' || s.deliveryDate === 'undefined') {
      isFailed = true;
      this.setState({ deliveryDateError: true });
      this.setState({ deliveryDateHelperText: '有効な日付を入力してください' });
    }
    // 出荷日
    if (s.shippingDate === null || s.shippingDate === '' || s.shippingDate === 'undefined') {
      isFailed = true;
      this.setState({ shippingDateError: true });
      this.setState({ shippingDateHelperText: '有効な日付を入力してください' });
    }
    // 出荷方法
    if (s.shippingMethod === null || s.shippingMethod === '' || s.shippingMethod === 'undefined') {
      isFailed = true;
      this.setState({ shippingMethodError: true });
    }
    // 回収日
    if (s.collectDate === null || s.collectDate === '' || s.collectDate === 'undefined') {
      isFailed = true;
      this.setState({ collectDateError: true });
      this.setState({ collectDateHelperText: '有効な日付を入力してください' });
    }
    // 回収方法
    if (s.collectMethod === null || s.collectMethod === '' || s.collectMethod === 'undefined') {
      isFailed = true;
      this.setState({ collectMethodError: true });
    }
    // 請求日
    if (s.billingDate === null || s.billingDate === '' || s.billingDate === 'undefined') {
      isFailed = true;
      this.setState({ billingDateError: true });
      this.setState({ billingDateHelperText: '有効な日付を入力してください' });
    }
    // 入金日
    if (s.paymentDate === null || s.paymentDate === '' | s.paymentDate === 'undefined') {
      isFailed = true;
      this.setState({ paymentDateError: true });
      this.setState({ paymentDateHelperText: '有効な日付を入力してください' });
    }
    // 納品場所
    if (s.delivaryLocation === null || s.delivaryLocation === '' || s.delivaryLocation === 'undefined') {
      isFailed = true;
      this.setState({ delivaryLocationError: true });
      this.setState({ delivaryLocationHelperText: '納品場所を入力してください' });
    }
    // 合計
    if (isNaN(s.total)) {
      isFailed = true;
    }
    // 税込み合計
    if (isNaN(s.totalInTax)) {
      isFailed = true;
    }

    // 範囲チェック
    if (!isFailed) {
      isFailed = this.validateOrderDataRange(
        _orderNumber,         // 注文番号
        s.companyPerson,      // 取引先担当
        s.projectName,        // 案件名
        s.delivaryLocation,   // 納品場所
        s.useStartDate,       // 使用日開始
        s.useEndDate,         // 使用日終了
        s.shippingDate,       // 出荷日
        s.deliveryDate,       // 納品日
        s.collectDate,        // 回収日
        s.billingDate,        // 請求日
        s.paymentDate         // 入金日
      );
    }

    return isFailed;
  }

  // 注文データ範囲チェック
  validateOrderDataRange(_orderNumber, companyPerson, projectName, delivaryLocation, useStartDate,
    useEndDate, shippingDate, deliveryDate, collectDate, billingDate, paymentDate) {
    var isFailed = false;

    if (_orderNumber.length !== 14) {
      isFailed = true;
      this.setState({ orderNumberDateError: true });
      this.setState({ orderNumberDateHelperText: '例:YYYYMMMDD' });
      this.setState({ orderNumberLastFourError: true });
      this.setState({ orderNumberLastFourHelperText: '4桁:0001' });
    }

    // 取引先担当
    if (companyPerson.length > 32) {
      isFailed = true;
      this.setState({ companyPersonError: true });
      this.setState({ companyPersonHelperText: '名前が長すぎます' });
    }
    // 案件名
    if (projectName.length > 128) {
      isFailed = true;
      this.setState({ projectNameError: true });
      this.setState({ projectNameHelperText: '案件名が長すぎます' });
    }
    // 納品場所
    if (delivaryLocation.length > 128) {
      isFailed = true;
      this.setState({ delivaryLocationError: true });
      this.setState({ delivaryLocationHelperText: '納品場所名が長すぎます' });
    }

    // 日付の相関チェック
    const _shippingDate = moment(shippingDate);
    const _deliveryDate = moment(deliveryDate);
    const _useStartDate = moment(useStartDate);
    const _useEndDate = moment(useEndDate);
    const _collectDate = moment(collectDate);
    //const _billingDate = moment(billingDate);
    //const _paymentDate = moment(paymentDate);

    // 出荷日
    if (_shippingDate.isAfter(_deliveryDate)) {  // 出荷日より納品日が早い
      isFailed = true;

      this.setState({ shippingDateError: true });
      this.setState({ shippingDateHelperText: '期間に誤りがあります' });
      this.setState({ deliveryDateError: true });
      this.setState({ deliveryDateHelperText: '期間に誤りがあります' });
    }
    if (_shippingDate.isAfter(_useStartDate)) {  // 出荷日より使用開始日が早い
      isFailed = true;

      this.setState({ shippingDateError: true });
      this.setState({ shippingDateHelperText: '期間に誤りがあります' });
      this.setState({ useStartDate: true });
      this.setState({ useStartDateError: '期間に誤りがあります' });
    }
    if (_shippingDate.isAfter(_useEndDate)) {    // 出荷日より使用終了日が早い
      isFailed = true;

      this.setState({ shippingDateError: true });
      this.setState({ shippingDateHelperText: '期間に誤りがあります' });
      this.setState({ useEndDate: true });
      this.setState({ useEndDateError: '期間に誤りがあります' });
    }
    if (_shippingDate.isAfter(_collectDate)) {   // 出荷日より回収日が早い
      isFailed = true;

      this.setState({ shippingDateError: true });
      this.setState({ shippingDateHelperText: '期間に誤りがあります' });
      this.setState({ collectDateError: true });
      this.setState({ collectDateHelperText: '期間に誤りがあります' });
    }
    /*if (_shippingDate.isAfter(_billingDate)) {   // 出荷日より請求日が早い
      isFailed = true;

      this.setState({ shippingDateError: true });
      this.setState({ shippingDateHelperText: '期間に誤りがあります' });
      this.setState({ billingDateError: true });
      this.setState({ billingDateHelperText: '期間に誤りがあります' });
    }
    if (_shippingDate.isAfter(_paymentDate)) {   // 出荷日より入金日が早い
      isFailed = true;

      this.setState({ shippingDateError: true });
      this.setState({ shippingDateHelperText: '期間に誤りがあります' });
      this.setState({ paymentDateError: true });
      this.setState({ paymentDateHelperText: '期間に誤りがあります' });
    }*/

    // 納品日
    if (_deliveryDate.isAfter(_useStartDate)) {  // 納品日より使用開始日が早い
      isFailed = true;

      this.setState({ deliveryDateError: true });
      this.setState({ deliveryDateHelperText: '期間に誤りがあります' });
      this.setState({ useStartDateError: true });
      this.setState({ useStartDateHelperText: '期間に誤りがあります' });
    }
    if (_deliveryDate.isAfter(_useEndDate)) {    // 納品日より使用終了日が早い
      isFailed = true;

      this.setState({ deliveryDateError: true });
      this.setState({ deliveryDateHelperText: '期間に誤りがあります' });
      this.setState({ useEndDateError: true });
      this.setState({ useEndDateHelperText: '期間に誤りがあります' });
    }
    if (_deliveryDate.isAfter(_collectDate)) {   // 納品日より回収日が早い
      isFailed = true;

      this.setState({ deliveryDateError: true });
      this.setState({ deliveryDateHelperText: '期間に誤りがあります' });
      this.setState({ collectDateError: true });
      this.setState({ collectDateHelperText: '期間に誤りがあります' });
    }
    /*if (_deliveryDate.isAfter(_billingDate)) {   // 納品日より請求日が早い
      isFailed = true;

      this.setState({ deliveryDateError: true });
      this.setState({ deliveryDateHelperText: '期間に誤りがあります' });
      this.setState({ billingDateError: true });
      this.setState({ billingDateHelperText: '期間に誤りがあります' });
    }
    if (_deliveryDate.isAfter(_paymentDate)) {   // 納品日より入金日が早い
      isFailed = true;

      this.setState({ deliveryDateError: true });
      this.setState({ deliveryDateHelperText: '期間に誤りがあります' });
      this.setState({ paymentDateError: true });
      this.setState({ paymentDateHelperText: '期間に誤りがあります' });
    }*/

    // 使用開始日
    if (_useStartDate.isAfter(_useEndDate)) {    // 使用開始日より使用終了日が早い
      isFailed = true;

      this.setState({ useStartDateError: true });
      this.setState({ useStartDateHelperText: '期間に誤りがあります' });
      this.setState({ useEndDateError: true });
      this.setState({ useEndDateHelperText: '期間に誤りがあります' });
    }
    if (_useStartDate.isAfter(_collectDate)) {   // 使用開始日より回収日が早い
      isFailed = true;

      this.setState({ useStartDateError: true });
      this.setState({ useStartDateHelperText: '期間に誤りがあります' });
      this.setState({ collectDateError: true });
      this.setState({ collectDateHelperText: '期間に誤りがあります' });
    }
    /*if (_useStartDate.isAfter(_billingDate)) {   // 使用開始日より請求日が早い
      isFailed = true;

      this.setState({ useStartDateError: true });
      this.setState({ useStartDateHelperText: '期間に誤りがあります' });
      this.setState({ billingDateError: true });
      this.setState({ billingDateHelperText: '期間に誤りがあります' });
    }
    if (_useStartDate.isAfter(_paymentDate)) {   // 使用開始日より入金日が早い
      isFailed = true;

      this.setState({ useStartDateError: true });
      this.setState({ useStartDateHelperText: '期間に誤りがあります' });
      this.setState({ paymentDateError: true });
      this.setState({ paymentDateHelperText: '期間に誤りがあります' });
    }*/

    // 使用終了日
    if (_useEndDate.isAfter(_collectDate)) {   // 使用終了日より回収日が早い
      isFailed = true;

      this.setState({ useEndDateError: true });
      this.setState({ useEndDateHelperText: '期間に誤りがあります' });
      this.setState({ collectDateError: true });
      this.setState({ collectDateHelperText: '期間に誤りがあります' });
    }
    /*if (_useEndDate.isAfter(_billingDate)) {   // 使用終了日より請求日が早い
      isFailed = true;

      this.setState({ useEndDateError: true });
      this.setState({ useEndtDateHelperText: '期間に誤りがあります' });
      this.setState({ billingDateError: true });
      this.setState({ billingDateHelperText: '期間に誤りがあります' });
    }
    if (_useEndDate.isAfter(_paymentDate)) {   // 使用終了日より入金日が早い
      isFailed = true;

      this.setState({ useEndDateError: true });
      this.setState({ useEndtDateHelperText: '期間に誤りがあります' });
      this.setState({ paymentDateError: true });
      this.setState({ paymentDateHelperText: '期間に誤りがあります' });
    }*/

    /*// 回収日
    if (_collectDate.isAfter(_billingDate)) {   // 回収日より請求日が早い
      isFailed = true;

      this.setState({ collectDateError: true });
      this.setState({ collectDateHelperText: '期間に誤りがあります' });
      this.setState({ billingDateError: true });
      this.setState({ billingDateHelperText: '期間に誤りがあります' });
    }
    if (_collectDate.isAfter(_paymentDate)) {   // 回収より入金日が早い
      isFailed = true;

      this.setState({ collectDateError: true });
      this.setState({ collectDateHelperText: '期間に誤りがあります' });
      this.setState({ paymentDateError: true });
      this.setState({ paymentDateHelperText: '期間に誤りがあります' });
    }
    // 請求日
    if (_billingDate.isAfter(_paymentDate)) {   // 請求日より入金日が早い
      isFailed = true;

      this.setState({ billingDateError: true });
      this.setState({ billingDateHelperText: '期間に誤りがあります' });
      this.setState({ paymentDateError: true });
      this.setState({ paymentDateHelperText: '期間に誤りがあります' });
    }*/

    return isFailed;
  }

  // 見積の引数チェックを行う。
  validateEstimateData() {
    var isFailed = false;

    //見積テーブル
    const _estimateTable = this.state.estimateTable;
    var className = null;

    _estimateTable.forEach((v, i) => {
      // 種別
      if (v.productType === null || v.productType === '' || v.productType === 'undefined') {
        isFailed = true;
        className = document.getElementsByClassName('productTypeIndex' + i)[0];
        className.style.backgroundColor = '#ff8a80';
      }
      // 機種
      if (v.model === null || v.model === '' || v.model === 'undefined') {
        isFailed = true;
        className = document.getElementsByClassName('modelIndex' + i)[0];
        className.style.backgroundColor = '#ff8a80';
      }
      // 使用開始日
      if (v.useStartDate === null || v.useStartDate === '' || v.useStartDate === 'undefined') {
        isFailed = true;
        className = document.getElementsByClassName('useStartDateIndex' + i)[0];
        className.style.backgroundColor = '#ff8a80';
      }
      // 使用終了日
      if (v.useEndDate === null || v.useEndDate === '' || v.useEndDate === 'undefined') {
        isFailed = true;
        className = document.getElementsByClassName('useEndDateIndex' + i)[0];
        className.style.backgroundColor = '#ff8a80';
      }
      // 単位
      if (v.unit === null || v.useEndDate === '' || v.useEndDate === 'undefined') {
        isFailed = true;
        className = document.getElementsByClassName('unitIndex' + i)[0];
        className.style.backgroundColor = '#ff8a80';
      }

      // TODO 数量
      if (v.quantity === null || v.quantity === '' || v.quantity === 'undefined') {
        isFailed = true;
        className = document.getElementsByClassName('quantityIndex' + i)[0];
        className.style.backgroundColor = '#ff8a80';
      }
    });

    if (!isFailed) {
      isFailed = this.validateEstimateDataRange();
    }
    return isFailed;
  }

  // 見積の範囲チェック
  validateEstimateDataRange() {
    var isFailed = false;
    const self = this;
    const _estimateTable = this.state.estimateTable; //見積テーブル
    var className = null;

    // 使用日の相関チェック
    _estimateTable.forEach((v, i) => {
      const _useStartDate = moment(v.useStartDate);
      const _useEndDate = moment(v.useEndDate);

      // 使用開始日より使用終了日が早い
      if (_useStartDate.isAfter(_useEndDate)) {
        isFailed = true;

        className = document.getElementsByClassName('useStartDateIndex' + i)[0];
        className.style.backgroundColor = '#ff8a80';
        className = document.getElementsByClassName('useEndDateIndex' + i)[0];
        className.style.backgroundColor = '#ff8a80';
      }
    });

    if (!isFailed) {
      // 注文データの使用期間と見積データの使用期間を比較
      _estimateTable.forEach((v, i) => {
        const _useStartDateOrder = moment(self.state.useStartDate);   // 注文データの使用開始
        const _useEndDateOrder = moment(self.state.useEndDate);       // 注文データの使用終了
        const _useStartDateEstimate = moment(v.useStartDate);         // 見積データの使用開始
        const _useEndDateEstimate = moment(v.useEndDate);             // 見積データの使用終了

        // 注文データ使用開始日より見積データ使用開始日が早い
        if (_useStartDateOrder.isAfter(_useStartDateEstimate)) {
          isFailed = true;

          self.setState({ useStartDateError: true });
          className = document.getElementsByClassName('useStartDateIndex' + i)[0];
          className.style.backgroundColor = '#ff8a80';
        }

        // 注文データ使用終了日より見積データ使用終了日が遅い
        if (_useEndDateOrder.isBefore(_useEndDateEstimate)) {
          isFailed = true;

          self.setState({ useEndDateError: true });
          className = document.getElementsByClassName('useEndDateIndex' + i)[0];
          className.style.backgroundColor = '#ff8a80';
        }
      });
    }

    return isFailed;
  }

  // 見積データの登録
  async insertEstimateTable(orderNumber) {
    const { model } = this.props;
    const estimateTable = this.state.estimateTable;
    const now = moment();
    var insertList = [];
    var updateList = [];
    var deleteList = [];
    let isFailed = false;

    // 新規登録行の抽出
    insertList = estimateTable.filter((ev) => {
      // 注文番号が付与されていない
      return ev.orderNumber === null && !(ev.productType === '00');
    });

    // 削除行
    estimateTable.forEach((ev) => {
      model.forEach((mv) => {
        if (mv.estimateIndex === ev.estimateIndex && !(ev.productType === '00')) {
          updateList.push(ev);
        }
        else if (mv.estimateIndex === ev.estimateIndex && ev.productType === '00') {
          deleteList.push(ev);
        }
      });
    });

    const param = Cookies.get('token');

    // 新規登録
    if (insertList.length !== 0) {

      for (let i in insertList) {
        await axios.post(REQUEST_PATH + 'api/estimate/create', {
          orderNumber: orderNumber,
          createDate: now.format("YYYY-MM-DD"),
          productType: insertList[i].productType,
          model: insertList[i].model,
          outsourcingShow: insertList[i].outsourcingShow ? "true" : "false",
          calendarShow: insertList[i].calendarShow ? true : false,
          useStartDate: insertList[i].useStartDate,
          useEndDate: insertList[i].useEndDate,
          quantity: insertList[i].quantity,
          days: insertList[i].days,
          unit: insertList[i].unit,
          price: insertList[i].price,
          amount: insertList[i].amount,
        },{
          param,
          withCredentials: true,
        }).then(response => {

        }).catch(e => {
          console.log(e);
          this.setState({ headerErrorMessage: "見積データの登録に失敗しました" });
          isFailed = true;
          return;
        });
      }
    }

    // 更新
    if (updateList.length !== 0) {

      for (let i in updateList) {
        await axios.put(REQUEST_PATH + 'api/estimate/update', {
          estimateIndex: updateList[i].estimateIndex,
          orderNumber: orderNumber,
          createDate: now.format("YYYY-MM-DD"),
          productType: updateList[i].productType,
          model: updateList[i].model,
          outsourcingShow: updateList[i].outsourcingShow ? "true" : "false",
          calendarShow: updateList[i].calendarShow ? true : false,
          useStartDate: updateList[i].useStartDate,
          useEndDate: updateList[i].useEndDate,
          quantity: updateList[i].quantity,
          days: updateList[i].days,
          unit: updateList[i].unit,
          price: updateList[i].price,
          amount: updateList[i].amount
        },{
          param,
          withCredentials: true,
        }).then(response => {

        }).catch(e => {
          console.log(e);
          isFailed = true;
          this.setState({ headerErrorMessage: "見積データの更新に失敗しました" });
          return;
        });
      }
    }

    // 削除
    if (deleteList.length !== 0) {
      for (let i in deleteList) {
        await axios.delete(
          REQUEST_PATH + 'api/estimate/delete/' + deleteList[i].estimateIndex,{
            param,
            withCredentials: true,
          }
        ).then(response => {
          //this.forceUpdate();
        }).catch(e => {
          console.log(e);
          isFailed = true;
          this.setState({ headerErrorMessage: "見積データの削除に失敗しました" });
          return;
        });;
      }
    }

    if(!isFailed) {
      this.openeSuccessAlert("登録しました");
    }

    this.searchEstimateData(orderNumber);
  }

  // 注文表示ステータスの取得
  getOrderDisplayStatus() {
    const s = this.state;

    // 注文キャンセル
    if (s.orderCancel) {
      return '01';
    }
    // 仮注文
    if (!s.orderCancel && !s.orderFormal) {
      return '02';
    }
    // 本注文
    if (!s.orderCancel && s.orderFormal &&
      !s.estimated && !s.shipped && !s.collected && !s.billed && !s.paymented) {
      return '03';
    }
    // 入金済
    if (!s.orderCancel && s.orderFormal &&
      s.paymented) {
      return '08';
    }
    // 請求済
    if (!s.orderCancel && s.orderFormal &&
      !s.paymented && s.billed) {
      return '07';
    }
    // 回収済
    if (!s.orderCancel && s.orderFormal &&
      !s.paymented && !s.billed && s.collected) {
      return '06';
    }
    // 出荷済
    if (!s.orderCancel && s.orderFormal &&
      !s.paymented && !s.billed && !s.collected && s.shipped) {
      return '05';
    }
    // 見積済
    if (!s.orderCancel && s.orderFormal &&
      !s.paymented && !s.billed && !s.collected && !s.shipped && s.estimated) {
      return '04';
    }
  }

  // 単価反映
  async getOriginalPrice () {
      // 原本から見積を取得
      const self = this;
      const pram = '00' + '00000000' + ('00' + self.state.companyID).slice(-4);
      var responseList = [];
      const _estimateTable = self.state.estimateTable;
  
      await this.setState({ headerErrorMessage: "" });

      const param = Cookies.get('token');
      await axios.post(
        REQUEST_PATH + 'api/estimate/list/' + pram,
        {
          pageIndex: 0,
          pageSize: 100,
        },{
          param,
          withCredentials: true,
        }
      ).then(response => {
        // 成功時
        responseList = response.data.list;
        this.forceUpdate();
      }).catch(e => {
        console.log(e);
        this.setState({ headerErrorMessage: '原本データがありません' });
        return;
      });
  
      if (responseList.length !== 0) {
        _estimateTable.forEach((ev, i) => {
  
          responseList.forEach((rv) => {
            var form = self.state.estimateTable.slice()[i];
  
            // daysが文字列になってくる
            if (ev.productType === rv.productType && ev.model === rv.model && ev.days * 1 === rv.days) {
              form.price = rv.price;
              form.amount = form.days * form.quantity * form.price;
            }
          });
        });
      }
      else {
        this.setState({ headerErrorMessage: '原本データがありません' });
      }

      await this.setState({ total: this.calcTotalAmount() });
      this.forceUpdate();
  }

  // 使用期間の反映
  referencePeriod() {

    const self = this;
    const _estimateTable = self.state.estimateTable;

    if(self.state.useStartDate === '' || self.state.useStartDate === undefined || self.state.useStartDate === null){
      return
    }

    if(self.state.useEndDate === '' || self.state.useEndDate === undefined || self.state.useEndDate === null){
      return
    }

    _estimateTable.forEach((ev, i) => {
      var form = self.state.estimateTable.slice()[i];

      form.useStartDate = self.state.useStartDate;
      form.useEndDate = self.state.useEndDate;
    });

    // 日数自動反映
    _estimateTable.forEach((v, i) => {
      var form = self.state.estimateTable.slice()[i];
      const startMoment = moment(form.useStartDate );
      const endMoment = moment(form.useEndDate);
      form.days = Math.abs(startMoment.diff(endMoment,'days')) + 1;

      // 小計のタイミング修正
      form.amount = form.days * form.quantity * form.price;
    });

    self.setState({ total: self.calcTotalAmount() });
    self.forceUpdate();
  }

  // 帳票出力（見積書、請求書、注文書）
  async outPutExcel() {

    const _orderNumber = "01" + this.state.orderNumberDate + this.state.orderNumberLastFour;    
    const orderRadio = document.getElementById('order_excel');
    const estimateRadio = document.getElementById('estimate_excel');
    const billdingRadio = document.getElementById('billding_excel');
    const deliveryRadio = document.getElementById('delivery_excel');
    const billdingDetailRadio = document.getElementById('billding_detail_excel');

    var _fileName = null;
    var _documentType = null;
    let downloadFileName = null;

    await this.setState({ headerErrorMessage: "" });

    if(orderRadio.checked){
      _fileName = '注文書.xlsm';
      _documentType = orderRadio.name;
      downloadFileName　= '【ご注文書】' + this.state.searchCompanyName + '様.xlsm';
    }
    else if(estimateRadio.checked) {
      _fileName = '見積書.xlsm';
      _documentType = estimateRadio.name;
      downloadFileName　= '【お見積り】' + this.state.searchCompanyName + '様.xlsm';
    }
    else if(billdingRadio.checked) {
      _fileName = '請求書.xlsm';
      _documentType = billdingRadio.name;
      downloadFileName　= '【ご請求書】' + this.state.searchCompanyName + '様.xlsm';
    }
    else if(billdingDetailRadio.checked) { 
      _fileName = '請求書.xlsm'; // 請求書と同一フォーマット
      _documentType = billdingDetailRadio.name;
      downloadFileName　= '【ご請求明細書】' + this.state.searchCompanyName + '様.xlsm';
    }
    else if(deliveryRadio.checked) {
      _fileName = '納品書.xlsm';
      _documentType = deliveryRadio.name;

      // 納品書用のメソッドを用意
      this.outPutDeliveryExcel(_orderNumber,_fileName);

      // ダイアログ閉じる
      document.getElementById('outPutExcelDialogBox').style.display = 'none';
      return;
    }

    const param = Cookies.get('token');

    await axios.get(REQUEST_PATH + 'api/excel/list/' + _orderNumber, {
      params: { 
        fileName    : _fileName,
        documentType: _documentType,
      },
      responseType: "blob",
      param,
      withCredentials: true,
    }).then(response => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      });
      // ファイル名デコード
      //const contentDisposition = response.headers["content-disposition"];
      //const fileName = this.getFileName(contentDisposition);
      // ファイルのダウンロード
      saveAs(blob, downloadFileName);
    }).catch(e => {
      console.log(e);
      this.setState({ headerErrorMessage: "帳票出力に失敗しました" });
      return;
    });

    // ダイアログ閉じる
    document.getElementById('outPutExcelDialogBox').style.display = 'none'
  }

  // ファイル名のデコード 
  getFileName(contentDisposition) { 
    let fileName = contentDisposition.substring(
      contentDisposition.indexOf("''") + 2,
      contentDisposition.length
      );

    //デコードするとスペースが"+"になるのでスペースへ置換します
    fileName = decodeURI(fileName).replace(/\+/g, " ");

    return fileName;
  }

  // 納品書出力
  async outPutDeliveryExcel(_orderNumber, _fileName) {

    const estimate_table = this.state.estimateTable;
    var model_list = [];
    const downloadFileName　= '【ご納品書】' + this.state.searchCompanyName + '様.xlsm';

    // 機種取得
    estimate_table.forEach( v => {
      if(v.model !== '00') {
        model_list.push(v.model);
      }
    });

    // 重複機種を除去
    var notDuplicationModel = model_list.filter( (v, i, self) => {
      return self.indexOf(v) === i;
    });

    const param = Cookies.get('token');

    for (const i in notDuplicationModel) {

      const _model = notDuplicationModel[i];
      await axios.get(REQUEST_PATH + 'api/excel-delivery/list/', {
        params: { 
          orderNumber : _orderNumber,
          model       : _model,
          fileName    : _fileName,
        },
        responseType: "blob",
        param,
        withCredentials: true,
      }).then(response => {
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
        });
        // ファイル名デコード
        //const contentDisposition = response.headers["content-disposition"];
        //const fileName = this.getFileName(contentDisposition);
        // ファイルのダウンロード
        saveAs(blob, downloadFileName);
        // 機種単位なのでファイル名は検討
      }).catch(e => {
        console.log(e);
        this.setState({ headerErrorMessage: "帳票出力に失敗しました" });
        return;
      });
    }
  }
  
  // 原本データ出力
  async outPutOriginalDataExcel() {

    const _companyId = this.state.companyID;

    await this.setState({ headerErrorMessage: "" });
    
    if(_companyId === null || _companyId === undefined || _companyId === '' ) {
      this.setState({companyIDError:true});
      return;
    }

    const _orderNumber = '00' + '00000000' + ('00' + _companyId).slice(-4);
    const _fileName = '見積書.xlsm';
    const _documentType = '_ESTIMATE_';
    const downloadFileName = '原本_' +　this.state.searchCompanyName + '様.xlsm';

    const param = Cookies.get('token');

    await axios.get(REQUEST_PATH + 'api/excel/list/' + _orderNumber, {
      params: { 
        fileName    : _fileName,
        documentType: _documentType,
      },
      responseType: "blob",
      param,
      withCredentials: true,
    }).then(response => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      });
      // ファイル名デコード
      //const contentDisposition = response.headers["content-disposition"];
      //const fileName = this.getFileName(contentDisposition);
      // ファイルのダウンロード
      saveAs(blob, downloadFileName);
    }).catch(e => {
      console.log(e);
      this.setState({ headerErrorMessage: "原本データの取得に失敗しました" });
      return;
    });
  }

  // 入金日、請求日の計算
  async calcPaymentAndBilling() {

    const _companyId = this.state.companyID;

    await this.setState({ headerErrorMessage: '' });

    if (_companyId === null || _companyId === undefined || _companyId === '') {

      this.setState({ headerErrorMessage: '取引先を選択してください' });

      return;
    }

    const param = Cookies.get('token');
    
    await axios.post(
      REQUEST_PATH + 'api/company/list',
      {
        pageIndex: 0,
        pageSize: 100,
        companyId: _companyId
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {

      const paymentSite = response.data.list[0].paymentSite;
      const _billingDate = moment(this.state.collectDate).endOf('month').format("YYYY-MM-DD");
      let _paymentDate;

      if(((paymentSite * 1) % 2) === 0) {
        _paymentDate = moment(_billingDate).add(paymentSite, "days").endOf('month').format("YYYY-MM-DD");
      } else {
        const addMonth = Math.floor(paymentSite / 30);
        const addDays = (paymentSite * 1) - (addMonth * 30);
        _paymentDate = moment(_billingDate).add(addDays, "days").add(addMonth, "month").format("YYYY-MM-DD");
      }
      this.setState({ paymentDate: _paymentDate });
      this.setState({ billingDate: _billingDate });

    }).catch(e => {
      console.log(e);
      this.setState({ headerErrorMessage: '入金サイトの取得に失敗しました' });
    });
  }

  // ダイアログ表示
  opneDialog(func, message) {

    var Dialog = document.getElementById('dialogBox');
    var okButton = document.getElementById('dialogOkButton');
    var cancelButton = document.getElementById('dialogCancelButton');

    document.getElementById('dialogMessage').innerHTML = message;

    // ダイアログ表示
    Dialog.style.display = '';
    // OK 押下の処理
    okButton.onclick = func;
    // cancel　押下の処理
    cancelButton.onclick = function () {
      Dialog.style.display = 'none';
    };
  }

  opneOutPutDialog(func) {

    var Dialog = document.getElementById('outPutExcelDialogBox');
    var outPutExcelButton = document.getElementById('outPutExcel');
    var outPutExcelCancelButton = document.getElementById('outPutExcelCancel');

    
    //document.getElementById('dialogMessage').innerHTML = message;

    // ダイアログ表示
    Dialog.style.display = '';
    // ラジオ初期選択状態
    
    // 書類発行 押下の処理
    outPutExcelButton.onclick = func;
    // もどる　押下の処理
    outPutExcelCancelButton.onclick = function () {
      Dialog.style.display = 'none';
    };
  }

  // 成功アラートの表示
  openeSuccessAlert(msg){

    document.getElementById('successAlert').style.display = '';
    this.setState({ successMessage: msg});

    // 2秒で消えるようにする。
    setTimeout(() => {
      document.getElementById('successAlert').style.display = 'none';
    },2000);
  }
  
  //JSS Style記述
  static get styles() {
    return theme => ({
      titleHeaderColor: {
        border: "1px solid #525457",
        backgroundColor: "#1976d2",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 20,
        fontWeight: "bold",
        borderRadius: "5px"
      },
      buttonColor: {
        width: 160,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        // color: "#E1E1E1"
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing: 10
      },
      addMstTblItem: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderTop: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#EEFAFF",
        width: 280,
        height: 80,
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      mstTblItem: {
        border: "1px solid #b0bec5",
        borderLeft: "0",
        // borderTop: "0",
        borderBottom: "0",
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      mstTblItemEnd: {
        borderRight: "1px solid #525457",
        borderTop: "1px solid #b0bec5",
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      mstTblEnd: {
        border: "1px solid #b0bec5",
        borderLeft: "0",
        borderTop: "0",
        borderBottom: "0",
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        color: "#4A4A4B"
      },
      mstTblItemFirst: {
        border: "1px solid #b0bec5",
        borderLeft: "1px solid #525457",
        // borderTop: "0",
        borderBottom: "0",
        textAlign: "center",
        flexGrow: 1
      },
      mstTblColFirst: {
        border: "1px solid #525457",
        borderRight: "1px solid #b0bec5",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        borderRadius: "5px 0px 0px 0px"
      },
      mstTblCol: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      mstTblColDouble: {
        border: `1px solid #525457`,
        borderRight: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        flexGrow: 1,
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      mstTblChildrenCol: {
        borderRight: "1px solid #b0bec5",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        flexGrow: 1,
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      mstTblChildrenColLast: {
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        flexGrow: 1,
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B"
      },
      mstTblColLast: {
        border: "1px solid #525457",
        borderLeft: "0",
        borderBottom: "0",
        backgroundColor: "#C8E6C9",
        flexGrow: 1,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "Bold",
        color: "#4A4A4B",
        borderRadius: "0px 5px 0px 0px"
      },
      fabColor: {
        backgroundColor: "#26a69a",
        color: "#EEEEEE"
      },
      estimateError: {

        backgroundColor: '#f44336'
      },
      successAlertStyle: {
        position: 'relative',
        justifyContent: 'center',
        width: '100%',
        backgroundColor:'#80cbc4',
        fontSize:'1rem'
      },
    });
  }
}
const { styles } = Component;
const mapStateToProps = ({ orderRegist: { list } }) => ({ model: list });
const mapDispatchToProps = dispatch => ({ dispatch });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);