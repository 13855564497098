import React from 'react';

import './App.css';
import Root from './components/RootFrame';
import Routes from './containers/Routes';

class App extends React.Component {
  render() {
    return (
      <Root>
        <Routes />
      </Root>
    )
  }
}

export default App;

/**
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import CalendarApp from "./CalendarApp";
import ScheduleApp from "./ScheduleApp";

const styles = {
  card: {
    margin: 48,
    height: 750
  }
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { Component: CalendarApp };
  }

  selectCalendar1() {
    this.setState({ Component: CalendarApp });
  }
  selectCalendar2 = () => this.setState({ Component: ScheduleApp });

  render() {
    const classes = this.props.classes;
    console.log(classes);

    const { Component } = this.state;

    return (
      <div>
        <Card className={classes.card}>
          <CardContent>
            <div className="calendar_div">
              <Component
                calendar1={() => {
                  this.selectCalendar1();
                }}
                calendar2={() => {
                  this.selectCalendar2();
                }}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(App);
*/
