import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { moveTo } from '../actions';

class Component extends React.Component {

  back() {
    const { dispatch } = this.props;
    dispatch(moveTo.Top());
  }

  render() {
    const { history: { location: { state = {} } } } = this.props;
    const { orderNumber = `` } = state;
    return (
      <div className="main_div">
        <div>
          {orderNumber ? `注文番号${orderNumber}の`: ``}注文詳細画面
          </div>
        <button onClick={this.back.bind(this)}>戻る</button>
      </div>
    );
  }
  static get propTypes() {
    return {};
  }

  static get styles() {
    return theme => ({});
  }

  static get mapStateToProps() {
    return state => ({});
  }

  static get mapDispatchToProps() {
    return dispatch => ({ dispatch });
  }
}
const { styles, mapStateToProps, mapDispatchToProps } = Component;
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
