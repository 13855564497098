import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, TextField} from "@material-ui/core";
import Header from "../header/Header";
import axios from "axios";
import { moveTo} from "../actions";
import AlertDialog from "../components/Dialog";
import REQUEST_PATH from "../components/Common";
import PostAddIcon from '@material-ui/icons/PostAdd';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Cookies from "js-cookie";

class Component extends React.Component {

    state = {
        headerErrorMessage:"",
        userId:"",
        username:"",
        password:"",
        confirmPassword:"",
        helperText2:"",
        helperText3:"",
    }

    constructor(props) {
        super(props);
    }



    backButton(){
      const { dispatch } = this.props;
      dispatch(moveTo.UserList());
    }

    onChangePassword(event){
      switch(event.target.name){
        case "password" :{
          
          if(event.target.value.length === 0){
            this.setState({helperText2:""});
          }
          else if(event.target.value.match(/^(?=.*?[a-z])(?=.*?\d)[a-zA-Z\d]{8,12}$/g) === null &&
          event.target.value.match(/^(?=.*?[a-z])(?=.*?[!-\/:-@[-`{-~])[!-~]{8,12}$/g) === null){
            this.setState({helperText2:"パスワードの条件を満たしていません。"});
          }
          else{
            this.setState({helperText2:""});
          }
          this.setState({password:event.target.value})
          break;
        }
        case "confirmPassword" :{
          if(event.target.value.length === 0){
            this.setState({helperText3:""});
          }
          else if(event.target.value.match(/^(?=.*?[a-z])(?=.*?\d)[a-zA-Z\d]{8,12}$/g) === null &&
          event.target.value.match(/^(?=.*?[a-z])(?=.*?[!-\/:-@[-`{-~])[!-~]{8,12}$/g) === null){
            this.setState({helperText3:"パスワードの条件を満たしていません。"});
          }
          else{
            this.setState({helperText3:""});
          }
          this.setState({confirmPassword:event.target.value})
          break;
        }
      }
      this.forceUpdate();
    }

    async createUser(){
      const param = Cookies.get('token');
      if(this.state.password !== this.state.confirmPassword){
        this.setState({headerErrorMessage:"新規パスワードが一致しません。"});
        return;
      }
      if(this.state.helperText2.length > 0 || this.state.helperText3.length > 0 ){
        this.setState({headerErrorMessage:"パスワードの条件を満たしていません。"});
        return;
      }
      await axios.post(REQUEST_PATH+'api/user/create', {
        userId: this.state.userId,
        user: this.state.username,
        password: window.btoa(this.state.username+":"+this.state.confirmPassword)
      },{
        param,
        withCredentials: true,
      }).then( response => {
       
    }).catch(e =>{
      this.setState({headerErrorMessage:"登録に失敗しました。"});
      console.log(e);
      return;
    });
    const { dispatch } = this.props;
      dispatch(moveTo.UserList());
  }
    
    render(){

        const { classes } = this.props;
        //サブヘッダ
        const titleDiv = (
            <Box display={`flex`} style={{ paddingBottom: "16px", minWidth: "1411px" }}   >
              <Box flexGrow={1}>
                <Box p={1} className={classes.titleHeaderColor}>
                  ユーザー登録画面
                </Box>
              </Box>
            </Box>
          );
        
        const buttonDiv = (
            <Box textAlign="center">
              <Box display="flex" marginLeft="40%" marginRight="40%">
                <Button
                  variant={`contained`}
                  className={classes.buttonColor}
                  endIcon={<PostAddIcon />}
                  onClick={this.createUser.bind(this)}
                >
                  登録
                </Button>
                <Box width="20%">

                </Box>
                <Button
                  variant={`contained`}
                  className={classes.buttonColor}
                  endIcon={<ExitToApp />}
                  onClick={this.backButton.bind(this)}
                >
                  戻る
                </Button>
              </Box>
              </Box>
          );
        
        const inputDiv = (
            <Box flexGrow={1} style={{borderRadius: "5px", border: "1px solid #bdbdbd" }} padding={'10px'} >
                <Box width="60%" marginLeft="20%" marginRight="20%" marginTop="20px">
                    <Box display={'flex'} alignItems="center">
                        <Box width="40%">
                            ユーザーID
                        </Box>
                        <Box width="10%">
                            ：
                        </Box>
                        <Box width="50%">
                        <TextField
                          className={classes.inputStyle}
                          type="text"
                          name="userID"
                          label="userID"
                          variant="outlined"
                          size={"medium"}
                          fullWidth
                          value={this.state.userId}
                          onChange={event => this.setState({userId:event.target.value})}
                        />
                        </Box>
                    </Box>
                </Box>
                <Box width="60%" marginLeft="20%" marginRight="20%" marginTop="20px">
                    <Box display={'flex'} alignItems="center">
                        <Box width="40%">
                            ユーザー名
                        </Box>
                        <Box width="10%">
                            ：
                        </Box>
                        <Box width="50%">
                        <TextField
                          className={classes.inputStyle}
                          type="text"
                          name="username"
                          label="username"
                          variant="outlined"
                          size={"medium"}
                          fullWidth
                           value={this.state.username}
                           onChange={event => this.setState({username:event.target.value})}
                        />
                        </Box>
                    </Box>
                </Box>
                <Box width="60%" marginLeft="20%" marginRight="20%" marginTop="20px">
                    <Box display={'flex'} alignItems="center">
                        <Box width="40%">
                            パスワード
                        </Box>
                        <Box width="10%">
                            ：
                        </Box>
                        <Box width="50%">
                        <TextField
                          className={classes.inputStyle}
                          type="password"
                          name="password"
                          label="password"
                          variant="outlined"
                          size={"medium"}
                          fullWidth
                           value={this.state.password}
                           onChange={event => this.onChangePassword(event)}
                           helperText={this.state.helperText2}
                          error={this.state.helperText2.length > 0}
                        />
                        </Box>
                    </Box>
                </Box>
                <Box width="60%" marginLeft="20%" marginRight="20%"　marginTop="20px" marginBottom="20px">
                    <Box display={'flex'} alignItems="center">
                        <Box width="40%">
                            パスワード（再入力）
                        </Box>
                        <Box width="10%">
                            ：
                        </Box>
                        <Box width="50%">
                        <TextField
                          className={classes.inputStyle}
                          type="password"
                          name="confirmPassword"
                          label="confirmPassword"
                          variant="outlined"
                          size={"medium"}
                          fullWidth
                          value={this.state.confirmPassword}
                          onChange={event => this.onChangePassword(event)}
                          helperText={this.state.helperText3}
                          error={this.state.helperText3.length > 0}
                        />
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
        
        return (
        <Box style={{ height: "100vh", width: "100vw" }}>
            <Header value={this.state.headerErrorMessage} />
            <Box
              style={{
                height: "calc(100% - 96px)",
                width: "calc(100% - 32px)",
                padding: "16px",
                overflow: "auto"
              }}
            >
              <Box minWidth={975}>
                {titleDiv}
                <Box style={{ display: 'flex' }} minWidth={975}>
                </Box>
                <Box minWidth={975} >
                    {inputDiv}
                    {buttonDiv}
                </Box>
              </Box>
              <AlertDialog />
            </Box>
        </Box >
        )
        }

      //JSS Style記述
  static get styles() {
    return theme => ({
      sideItem: {
        backgroundColor: "#FFFFFF",
        width: "24.8%",
        borderRadius: 5,
        lineheight: 2.5,
        alignItems: "center"
      },
      productItem: {
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        lineheight: 2.5,
        alignItems: "center"
      },
      groupItem: {
        backgroundColor: "#FFFFFF",
        alignItems: "center"
      },
      titleHeaderColor: {
        border: "1px solid #525457",
        backgroundColor: "#1976d2",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 20,
        fontWeight: "bold",
        borderRadius: "5px"
      },
      buttonColor: {
        width: 160,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing: 10,
        marginTop: 20
      },
      slctItem: {
        height: "30%",
      },
    });
  }
    
}
const { styles } = Component;
const mapStateToProps = ({ orderRegist: { list } }) => ({ model: list });
const mapDispatchToProps = dispatch => ({ dispatch });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);