import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, TextField, Select, MenuItem} from "@material-ui/core";
import Header from "../header/Header";
import moment from "moment";
import axios from "axios";
import { moveTo} from "../actions";
import AlertDialog from "../components/Dialog";
import REQUEST_PATH from "../components/Common";

import PostAddIcon from '@material-ui/icons/PostAdd';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { Alert } from '@material-ui/lab';
import Cookies from "js-cookie";
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  }
});

const person_list = [
  {
    person_id: "001",
    person_name: "岡田"
  },
  {
    person_id: "002",
    person_name: "谷原"
  }
]

class Component extends React.Component {


  state = {
    //注文データ
    type: "",
    model: "",
    spareNumber: "",
    number: "",
    typeGroup: "",
    modelGroup: "",
    spareNumberGroup: "",
    numberGroup: "",
    serialNumber: "",
    productName: "",
    licenseId: "",
    owner: "",
    procureDay: "",
    procureStore: "",
    price: 0,
    repairStartDate: "",
    repairDestination: "",
    repairEndDate: "",
    remarks: "",
    projectName: "",
    rentalStartDate: "",
    rentalEndDate: "",
    shippingDate: "",
    shippingMethod: "",
    deliveryDate: "",
    collectDate: "",
    collectMethod: "",
    billingDate: "",
    paymentDate: "",
    productType: "",
    //修理情報
    repairStartDate: "",
    repairDestination: "",
    repairEndDate: "",
    remarks: "",

    // 赤枠
    typeError: false,
    modelError: false,
    spareNumberError: false,
    numberError: false,
    groupNumberError: false,
    typeGroupError: false,
    modelGroupError: false,
    spareNumberGroupError: false,
    numberGroupError: false,
    serialNumberError: false,
    productNameError: false,
    licenseIdError: false,
    ownerError: false,
    procureDayError: false,
    procureStoreError: false,
    priceError: false,
    repairStartDateError: false,
    repairDestinationError: false,
    repairEndDateError: false,
    remarksError: false,

    // ヘルパーテキスト
    productNumberHelperText: '',
    typeHelperText: '',
    modelHelperText: '',
    spareNumberHelperText: '',
    numberHelperText: '',
    groupNumberHelperText: '',
    typeGroupHelperText: '',
    modelGroupHelperText: '',
    spareNumberGroupHelperText: '',
    numberGroupHelperText: '',
    serialNumberHelperText: '',
    productNameHelperText: '',
    licenseIdHelperText: '',
    ownerHelperText: '',
    procureDayHelperText: '',
    procureStoreHelperText: '',
    priceHelperText: '',
    repairStartDateHelperText: '',
    repairDestinationHelperText: '',
    repairEndDateHelperText: '',
    remarksHelperText: '',

    // 登録変更フラグ
    isUpdate: false,
    //エラーメッセージ
    headerErrorMessage: "",

    successMessage:'',

    //見積データ
    estimateTable: [],

    license:[],
    searchLicenseName:'',
    isFirst: 'FIRST',
  };

  constructor(props) {
    super(props);
    this.forceUpdate();
    this.getTypeMenu();
    this.getModelMenu();
    this.getTypeGroupMenu();
    this.getModelGroupMenu();
    this.getLicenseIdMenu();
    this.getOwnerMenu();
    const pram = this.props.location.state.productNumber;
    if (!(pram === null) && !(pram === 'undefined') && !(pram === '')) {
      this.search(pram);
    }
  }

  // 入力項目のステート更新
  onChangeSearchField(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  /**       コンボボックス対応       */
  onChangeAutoCompletField(e,value, type) {

    if(this.state.license.length === 0) {
      return
    }

    switch(type) {

      case 'SEARCH' : // searchメソッドから呼び出される場合
          const _licenseList = this.state.license;
          const targertName = _licenseList.filter(v => { return v.licenseId === value });
          this.setState({searchLicenseName : targertName[0].licenseName});
        break;
      
      case 'SELECT' : // onChangeイベント（リスト選択時）
          if(value === null || value === undefined || value ===  ''){
            break
          }
          this.setState({searchLicenseName:value.licenseName});
          this.setState({licenseId:value.licenseId});
          break;

      case 'INPUT'  : // onInputChangeイベント（テキスト変更時、state更新時）
          if(e === null){
            break;
          }

          if(e.type === 'blur') { // フォーカスを失った場合
            break;
          }
          if(value === null || value === undefined || value ===  ''){
            this.setState({searchLicenseName:value});
            this.setState({licenseId:value});
            break
          }
          this.setState({searchLicenseName:value});
          this.setState({licenseIdError:false});
          break;
    }
  }

  //DBからselectを持ってきている
  async getTypeMenu() {
    const param = Cookies.get('token');
    await axios.post(
      REQUEST_PATH + 'api/product-type/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ type_list: response.data.list });
    }).catch(e => {
      console.log(e);
    });;
  };

  //商品番号-機種の取得
  async getModelMenu() {
    const param = Cookies.get('token');
    await axios.post(
      REQUEST_PATH + 'api/product-model/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ model_list: response.data.list });
    }).catch(e => {
      console.log(e);
    });
  };

  //所属番号-種別の取得
  async getTypeGroupMenu() {
    const param = Cookies.get('token');
    await axios.post(
      REQUEST_PATH + 'api/product-type/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ type_list: response.data.list });
    }).catch(e => {
      console.log(e);
    });
  };

  //所属-機種の取得
  async getModelGroupMenu() {
    const param = Cookies.get('token');
    await axios.post(
      REQUEST_PATH + 'api/product-model/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ model_list: response.data.list });
    }).catch(e => {
      console.log(e);
    });
  };

  //免許IDの取得
  async getLicenseIdMenu() {
    const param = Cookies.get('token');
    await axios.post(
      REQUEST_PATH + 'api/license/list',
      {
        pageIndex: 0,
        pageSize: 10000,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ license: response.data.list });
    }).catch(e => {
      console.log(e);
    });
  };

  //所有者の取得
  async getOwnerMenu() {
    const param = Cookies.get('token');
    await axios.post(
      REQUEST_PATH + 'api/owner/list',
      {
        pageIndex: 0,
        pageSize: 100,
      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      this.setState({ owner_list: response.data.list });
    }).catch(e => {
      console.log(e);
    });
  };


  //イベントハンドラ記述

  onChangeField(evt) {

    const val = evt.target.value;

    switch (evt.target.name) {
      case "type": {
        this.setState({ type: val });
        this.setState({ typeError: false });
        break;
      }
      case "model": {
        this.setState({ model: val });
        this.setState({ modelError: false });
        break;
      }
      case "spareNumber": {

        this.setState({ spareNumber: val });
        if (isNaN(val)) {
          this.setState({ spareNumberError: true });
          this.setState({ spareNumberHelperText: '例：00' });
          break;
        }
        if (val.length !== 2) {
          this.setState({ spareNumberError: true });
          this.setState({ spareNumberHelperText: '例：00' });
          break;
        }
        this.setState({ spareNumberError: false });
        this.setState({ spareNumberHelperText: '' });
        break;
      }
      case "number": {
        this.setState({ number: val });
        if (isNaN(val)) {
          this.setState({ numberError: true });
          this.setState({ numberHelperText: '例：0001' });
          break;
        }
        if (val.length !== 4) {
          this.setState({ numberError: true });
          this.setState({ numberHelperText: '例：0001' });
          break;
        }

        this.setState({ numberError: false });
        this.setState({ numberHelperText: '' });
        break;
      }
      case "typeGroup": {
        this.setState({ typeGroup: val });
        this.setState({ typeGroupError: false });
        break;
      }
      case "modelGroup": {
        this.setState({ modelGroup: val });
        this.setState({ modelGroupError: false });
        break;
      }
      case "spareNumberGroup": {
        this.setState({ spareNumberGroup: val });
        
        if (isNaN(val)) {

          this.setState({ spareNumberGroupError: true });
          this.setState({ spareNumberGroupHelperText: '例：00' });
          break;
        }
        if (val.length !== 2) {
          this.setState({ spareNumberGroupError: true });
          this.setState({ spareNumberGroupHelperText: '例：00' });
          break;
        }

        this.setState({ spareNumberGroupError: false });
        this.setState({ spareNumberGroupHelperText: '' });
        break;
      }
      case "numberGroup": {
        this.setState({ numberGroup: val });
        if (isNaN(val)) {

          this.setState({ numberGroupError: true });
          this.setState({ numberGroupHelperText: '例：0000' });
          break;
        }
        if (val.length !== 4) {
          this.setState({ numberGroupError: true });
          this.setState({ numberGroupHelperText: '例：0000' });
          break;
        }

        this.setState({ numberGroupError: false });
        this.setState({ numberGroupHelperText: '' });
        break;
      }
      case "serialNumber": {
        this.setState({ serialNumber: val });
        if (val.length > 12) {
          this.setState({ serialNumberError: true });
          this.setState({ serialNumberHelperText: '入力文字数が多いです' });
          break;
        }
        this.setState({ serialNumberError: false });
        this.setState({ serialNumberHelperText: '' });
        break;
      }
      case "productName": {
        this.setState({ productName: val });
        if (val.length > 32) {
          this.setState({ productNameError: true });
          this.setState({ productNameHelperText: '入力文字数が多いです' });
          break;
        }
        this.setState({ productNameError: false });
        this.setState({ productNameHelperText: '' });
        break;
      }
      /*case "licenseId": {
        this.setState({ licenseId: val });
        this.setState({ licenseIdError: false });
        break;
      }*/
      case "owner": {
        this.setState({ owner: val });
        this.setState({ ownerError: false });
        break;
      }
      case "procureDay": {
        this.setState({ procureDay: val });
        break;
      }
      case "procureStore": {
        this.setState({ procureStore: val });
        if (val.length > 32) {
          this.setState({ procureStoreError: true });
          this.setState({ procureStoreHelperText: '入力文字数が多いです' });
          break;
        }
        this.setState({ procureStoreError: false });
        this.setState({ procureStoreHelperText: '' });
        break;
      }
      case "price": {

        const _price = Number(val.replace(/,/g, ''));
        
        if (isNaN(_price)) {
          this.setState({ price: val });
          this.setState({ priceError: true });
          this.setState({ priceHelperText: '例：1000 (半角数字)' });
          break;
        }

        this.setState({ price: _price });
        this.setState({ priceError: false });
        this.setState({ priceHelperText: '' });
        break;
      }
      case "repairStartDate": {
        this.setState({ repairStartDate: val });
        break;
      }
      case "repairDestination": {
        this.setState({ repairDestination: val });
        if (val.length > 32) {
          this.setState({ repairDestinationError: true });
          this.setState({ repairDestinationHelperText: '入力文字数が多いです' });
          break;
        }
        this.setState({ repairDestinationError: false });
        this.setState({ repairDestinationHelperText: '' });
        break;
      }
      case "repairEndDate": {
        this.setState({ repairEndDate: val });
        break;
      }
      case "remarks": {
        this.setState({ remarks: val });
        if (val.length > 128) {
          this.setState({ remarksError: true });
          this.setState({ remarksHelperText: '入力文字数が多いです' });
          break;
        }
        this.setState({ remarksError: false });
        this.setState({ remarksHelperText: '' });
        break;
      }
      default: {
        break;
      }
    }
  };


  //リストメニュー描画
  getProductTypeOptions = () => {
    const product_type = this.state.type_list;
    const menuItems = [];
    for (const i in product_type) {
      menuItems.push(
        <MenuItem
          value={product_type[i].productType}
          key={product_type[i].productType}
        >
          {product_type[i].productType + ':' + product_type[i].productName}
        </MenuItem>
      );
    }
    return menuItems;
  };

  getProdutModelOptions = () => {
    const product_model = this.state.model_list;
    const menuItems = [];
    for (const i in product_model) {
      menuItems.push(
        <MenuItem
          value={product_model[i].model}
          key={product_model[i].model}
        >
          {product_model[i].model + ':' + product_model[i].modelName}
        </MenuItem>
      );
    }
    return menuItems;
  };

  getProductTypeGroupOptions = () => {
    const product_type = this.state.type_list;
    const menuItems = [];
    for (const i in product_type) {
      menuItems.push(
        <MenuItem
          value={product_type[i].productType}
          key={product_type[i].productType}
        >
          {product_type[i].productType + ':' + product_type[i].productName}
        </MenuItem>
      );
    }
    return menuItems;
  };

  getProductModelGroupOptions = () => {
    const product_model = this.state.model_list;
    const menuItems = [];
    for (const i in product_model) {
      menuItems.push(
        <MenuItem
          value={product_model[i].model}
          key={product_model[i].model}
        >
          {product_model[i].model + ':' + product_model[i].modelName}
        </MenuItem>
      );
    }
    return menuItems;
  };

  getLicenseIdOptions = () => {
    const license = this.state.license;
    const menuItems = [];
    for (const i in license) {
      menuItems.push(
        <MenuItem
          value={license[i].licenseId}
          key={license[i].licenseId}
        >
          {license[i].licenseId + ':' + license[i].licenseName}
        </MenuItem>
      );
    }
    return menuItems;
  };

  getOwnerOptions = () => {
    const owner = this.state.owner_list;
    const menuItems = [];
    for (const i in owner) {
      menuItems.push(
        <MenuItem
          value={owner[i].ownerId}
          key={owner[i].ownerId}
        >
          {owner[i].ownerId + ':' + owner[i].owner}
        </MenuItem>
      );
    }
    return menuItems;
  };


  addNewRow() {
    const estimateTable = this.state.estimateTable.slice();
    estimateTable.push({
      estimateIndex: this.state.estimateTable.length + 1,
      type: "01",
      model: "01",
      calendarDisplay: false,
      useStartDate: moment().format('YYYY-MM-DD'),
      useEndDate: moment().format('YYYY-MM-DD'),
      quantity: 0,
      days: 0,
      unit: "個",
      price: 0,
      amount: 0
    });
    this.setState({ estimateTable: estimateTable });
  }

  removeRow() {
    const estimateTable = this.state.estimateTable.slice();
    estimateTable.pop();
    this.setState({ estimateTable: estimateTable });
  }


  // 使用履歴画面遷移
  moveToUsedHistory() {
    const productNmb = this.state.type + this.state.model + this.state.spareNumber + this.state.number
    this.props.history.push({ pathname: '/UsedHistory', state: { productNumber: productNmb } });
  }

  // 修理履歴画面遷移
  moveToRepairHistory() {
    const productNmb = this.state.type + this.state.model + this.state.spareNumber + this.state.number
    this.props.history.push({ pathname: '/RepairHistory', state: { productNumber: productNmb } });
  }

  //画面描画
  render() {

    const { classes } = this.props;
    // const personListMenu = this.getPersonListOptions();
    const typeListMenu = this.getProductTypeOptions();
    const modelListMenu = this.getProdutModelOptions();
    const typeListGroupMenu = this.getProductTypeGroupOptions();
    const typeModelGroupMenu = this.getProductModelGroupOptions();
    const licenseIdMenu = this.getLicenseIdOptions();
    const ownerMenu = this.getOwnerOptions();

    /**       コンボボックス対応       */
    const _licenseList = this.state.license;
    let licenseOption = [];

    _licenseList.forEach(v => {
      let tmp = {};
      tmp.licenseId = v.licenseId;
      tmp.licenseName = v.licenseName;
      licenseOption.push(tmp);
    });

    // TODO
    if(_licenseList.length !== 0 && this.state.isFirst === 'FIRST' && this.state.isUpdate){
      this.onChangeAutoCompletField('', this.state.licenseId, 'SEARCH');
      this.setState({isFirst:''});
    }
    /**                              */

    //サブヘッダ
    const titleDiv = (
      <Box display={`flex`} style={{ paddingBottom: "16px", minWidth: "1411px" }}   >
        <Box flexGrow={1}>
          <Box p={1} className={classes.titleHeaderColor}>
            商品入力画面
              </Box>
        </Box>
      </Box>
    );


    //入力域
    //商品コード product_number
    const productCode = (

      <Box display={'flex'} style={{ minWidth: "1411px", marginBottom: "1%" }}>
        <Box flexGrow={1} style={{ width: "70%", borderRadius: "5px", border: "1px solid #bdbdbd" }} marginRight={'1%'} padding={'10px'}>
          <Box></Box>
          <Box>商品コード</Box>

          <Box className={classes.productItem} display="flex" marginTop={"1%"}>

            <Box display="flex" width={'8%'} justifyContent="center" alignItems="center">
              <Box>商品種別</Box>
            </Box>
            <Box width={'17%'} height={'5%'}>
              <Select
                style={{ height: 40 }}
                name="type"
                id="type"
                label="商品種別"
                fullWidth
                variant="outlined"
                size="small"
                value={this.state.type}
                onChange={this.state.isUpdate ? '' : this.onChangeField.bind(this)}
                disabled={this.state.isUpdate}
                error={this.state.typeError}
              >
                {typeListMenu}
              </Select>
            </Box>

            <Box display="flex" width={'8%'} justifyContent="center" alignItems="center">
              <Box>機種番号</Box>
            </Box>

            <Box width={'17%'}>
              <Select
                style={{ height: 40 }}
                name="model"
                id="model"
                label="機種番号"
                fullWidth
                variant="outlined"
                size="small"
                value={this.state.model}
                onChange={this.state.isUpdate ? '' : this.onChangeField.bind(this)}
                disabled={this.state.isUpdate}
                error={this.state.modelError}
              >
                {modelListMenu}
              </Select>
            </Box>


            <Box display="flex" width={'8%'} justifyContent="center" alignItems="center">
              <Box>予備番号</Box>
            </Box>
            <Box width={'17%'}>
              <TextField
                name="spareNumber"
                id="spareNumber"
                //label="予備番号"
                fullWidth
                variant="outlined"
                size="small"
                type="imput"
                value={this.state.spareNumber}
                onChange={this.state.isUpdate ? '' : this.onChangeField.bind(this)}
                disabled={this.state.isUpdate}
                error={this.state.spareNumberError}
                helperText={this.state.spareNumberHelperText}
              />
            </Box>

            <Box display="flex" width={'8%'} justifyContent="center" alignItems="center">
              <Box>識別番号</Box>
            </Box>
            <Box width={'17%'}>
              <TextField
                name="number"
                id="number"
                // label="識別番号"
                fullWidth
                variant="outlined"
                size="small"
                type="imput"
                value={this.state.number}
                onChange={this.state.isUpdate ? '' : this.onChangeField.bind(this)}
                disabled={this.state.isUpdate}
                error={this.state.numberError}
                helperText={this.state.numberHelperText}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    )

    //所属コード group_number
    const groupCode = (

      <Box display={'flex'} style={{ minWidth: "1411px", marginBottom: "1%" }}>
        <Box flexGrow={1} style={{ width: "70%", borderRadius: "5px", border: "1px solid #bdbdbd" }} marginRight={'1%'} padding={'10px'}>

          <Box>
            所属コード
          </Box>

          <Box className={classes.groupItem} display="flex" marginTop={"1%"}>

            <Box display="flex" width={'8%'} justifyContent="center" alignItems="center">
              <Box>商品種別</Box>
            </Box>
            <Box width={'17%'} >
              <Select
                style={{ height: 40 }}
                name="typeGroup"
                id="typeGroup"
                label="商品種別"
                fullWidth
                variant="outlined"
                size="small"
                value={this.state.typeGroup}
                onChange={event => this.onChangeField(event)}
                error={this.state.typeGroupError}
              >
                {typeListGroupMenu}
              </Select>
            </Box>

            <Box display="flex" width={'8%'} justifyContent="center" alignItems="center">
              <Box>機種番号</Box>
            </Box>
            <Box width={'17%'}>
              <Select
                style={{ height: 40 }}
                name="modelGroup"
                id="modelGroup"
                label="機種番号"
                fullWidth
                variant="outlined"
                size="small"
                value={this.state.modelGroup}
                onChange={event => this.onChangeField(event)}
                error={this.state.modelGroupError}
              >
                {typeModelGroupMenu}
              </Select>
            </Box>

            <Box display="flex" width={'8%'} justifyContent="center" alignItems="center">
              <Box>予備番号</Box>
            </Box>
            <Box width={'17%'}>
              <TextField
                name="spareNumberGroup"
                id="spareNumberGroup"
                //label="予備番号"
                fullWidth
                variant="outlined"
                size="small"
                type="imput"
                value={this.state.spareNumberGroup}
                onChange={event => this.onChangeField(event)}
                error={this.state.spareNumberGroupError}
                helperText={this.state.spareNumberGroupHelperText}
              />
            </Box>

            <Box display="flex" width={'8%'} justifyContent="center" alignItems="center">
              <Box>識別番号</Box>
            </Box>
            <Box width={'17%'}>
              <TextField
                name="numberGroup"
                id="numberGroup"
                // label="識別番号"
                fullWidth
                variant="outlined"
                size="small"
                type="imput"
                value={this.state.numberGroup}
                onChange={event => this.onChangeField(event)}
                error={this.state.numberGroupError}
                helperText={this.state.numberGroupHelperText}
              />
            </Box>
          </Box>
        </Box >
      </Box >
    )

    //製造番号
    const serialNumber = (
      <Box className={classes.sideItem}>
        <Box display={'flex'} style={{ minWidth: "79", marginBottom: "1%" }}>
          <Box flexGrow={1} style={{ width: "80%", borderRadius: "5px", border: "1px solid #bdbdbd" }} marginRight={'1%'} padding={'10px'}>
            <Box>製造番号</Box>

            <Box display={'flex'}>
              <Box display="flex" width={'20%'} justifyContent="center" alignItems="center"></Box>
              <Box width={'70%'}>
                <TextField
                  name="serialNumber"
                  id="serialNumber"
                  // label="商品種別"
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="imput"
                  value={this.state.serialNumber}
                  onChange={event => this.onChangeField(event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={this.state.serialNumberError}
                  helperText={this.state.serialNumberHelperText}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )

    //商品名
    const productName = (
      <Box className={classes.sideItem}>
        <Box display={'flex'} style={{ minWidth: "79", marginBottom: "1%" }}>
          <Box flexGrow={1} style={{ width: "80%", borderRadius: "5px", border: "1px solid #bdbdbd" }} marginRight={'1%'} padding={'10px'}>
            <Box>商品名</Box>

            <Box display={'flex'}>
              <Box display="flex" width={'20%'} justifyContent="center" alignItems="center"></Box>
              <Box width={'70%'}>
                <TextField
                  name="productName"
                  id="productName"
                  // label="商品種別"
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="imput"
                  value={this.state.productName}
                  onChange={event => this.onChangeField(event)}
                  error={this.state.productNameError}
                  helperText={this.state.productNameHelperText}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )

    //免許番号
    const licenseId = (
      <Box className={classes.sideItem}>
        <Box display={'flex'} style={{ minWidth: "79", marginBottom: "1%" }}>
          <Box flexGrow={1} style={{ width: "80%", borderRadius: "5px", border: "1px solid #bdbdbd" }} marginRight={'1%'} padding={'10px'}>
            <Box>免許番号</Box>

            <Box display={'flex'}>
            <Box display="flex" width={'20%'} justifyContent="center" alignItems="center"></Box>
              <Box width={'70%'}>
                
                {/*
                <Select
                  style={{ height: 40 }}
                  name="licenseId"
                  id="licenseId"
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={this.state.licenseId}
                  onChange={event => this.onChangeField(event)}
                  error={this.state.licenseIdError}
                  MenuProps={{style:{
                    height:'600px'
                  }}}
                >
                  {licenseIdMenu}
                </Select>
                */}
                <Autocomplete
                name="AutocompleteLicenseId"
                size='small'
                options={licenseOption}
                ListboxProps={{style:{height:350 }}}
                getOptionLabel={(option) => option.licenseName}
                value={this.state.searchCompanyName}
                inputValue={this.state.searchLicenseName}
                renderInput={
                  (params) => 
                  <TextField 
                    name="licenseId" 
                    fullWidth
                    style={{ backgroundColor: "#FFFFFF", }}
                    error={this.state.licenseIdError}
                    {...params} variant="outlined"
                  />
                }
                onChange={(event,value) => this.onChangeAutoCompletField(event, value, 'SELECT')}
                onInputChange={(event,value) => this.onChangeAutoCompletField(event, value, 'INPUT')}
              />
              {/**********************************/}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )


    //所有区分
    const owner = (
      <Box className={classes.sideItem}>
        <Box display={'flex'} style={{ minWidth: "79", marginBottom: "1%" }}>
          <Box flexGrow={1} style={{ width: "80%", borderRadius: "5px", border: "1px solid #bdbdbd" }} marginRight={'1%'} padding={'10px'}>
            <Box>所有区分</Box>
            <Box display={'flex'}>
            <Box display="flex" width={'20%'} justifyContent="center" alignItems="center"></Box>
              <Box width={'70%'}>
                <Select
                  style={{ height: 40 }}
                  name="owner"
                  id="owner"
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={this.state.owner}
                  onChange={event => this.onChangeField(event)}
                  error={this.state.ownerError}
                >
                  {ownerMenu}
                </Select>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )

    //購入情報
    const procureDay = (

      <Box display={'flex'} style={{ minWidth: "1411px", marginBottom: "1%", marginTop: "15px" }}>
        <Box flexGrow={1} style={{ width: "70%", borderRadius: "5px", border: "1px solid #bdbdbd" }}
          marginRight={'1%'} padding={'10px'}>
          <Box></Box>
          <Box>購入情報<Box display={'flex'} marginTop={"1%"}></Box></Box>

          <Box display={'flex'} marginBottom={"1%"}>
            <Box display="flex" width={'9%'} justifyContent="center" alignItems="center">
              <Box>購入日</Box>
            </Box>
            <Box width={'22%'}>
              <TextField
                name="procureDay"
                id="procureDay"
                //label="購入日"
                fullWidth
                variant="outlined"
                size="small"
                style={{ height: "5px" }}
                type="date"
                value={this.state.procureDay}
                onChange={event => this.onChangeField(event)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ style: { textAlign: 'center', paddingRight: '1%', paddingLeft: '5%' } }}
                error={this.state.procureDayError}
              />
            </Box>

            <Box display="flex" width={'9%'} justifyContent="center" alignItems="center">
              <Box>購入先</Box>
            </Box>
            <Box width={'22%'}>
              <TextField
                name="procureStore"
                id="procureStore"
                // label="購入先"
                fullWidth
                variant="outlined"
                size="small"
                style={{ height: "5px" }}
                type="imput"
                value={this.state.procureStore}
                onChange={event => this.onChangeField(event)}
                InputLabelProps={{
                  shrink: true,
                }}
                //inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' } }}
                error={this.state.procureStoreError}
                helperText={this.state.procureStoreHelperText}
              />
            </Box>
            <Box display="flex" width={'9%'} justifyContent="center" alignItems="center">
              <Box>購入金額</Box>
            </Box>
            <Box width={'22%'}>
              <TextField
                name="price"
                id="price"
                // label="購入金額"
                fullWidth
                variant="outlined"
                size="small"
                style={{ height: "5px" }}
                type="imput"
                value={this.state.price.toLocaleString()}
                onChange={event => this.onChangeField(event)}
                //placeholder="円"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '10%', placeholder: '円' } }}
                error={this.state.priceError}
                helperText={this.state.priceHelperText}
              />
            </Box>
            <Box className={classes.yenItem}>
              <Box fontSize="1.2em" paddingRight="30%" marginTop="25%" placeholder="円">
                円
               </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )


    //修理情報
    const repairData = (

      <Box display={'flex'} style={{ minWidth: "1411px", marginBottom: "5px" }}>
        <Box flexGrow={1} style={{ width: "70%", borderRadius: "5px", border: "1px solid #bdbdbd" }} marginRight={'1%'} padding={'10px'}>
          <Box></Box>
          <Box>修理情報<Box display={'flex'} marginTop={"1%"}></Box></Box>

          <Box display={'flex'} marginTop={"1%"}>
            <Box display="flex" width={'9%'} justifyContent="center" alignItems="center">
              <Box>修理開始日</Box>
            </Box>
            <Box width={'24%'}>
              <TextField
                name="repairStartDate"
                id="repairStartDate"
                // label="修理開始日"
                fullWidth
                variant="outlined"
                size="small"
                style={{ height: "5px" }}
                type="date"
                value={this.state.repairStartDate}
                error={this.state.repairStartDateError}
                onChange={event => this.onChangeField(event)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' } }}
              />
            </Box>
            <Box display="flex" width={'9%'} justifyContent="center" alignItems="center">
              <Box>取引先</Box>
            </Box>
            <Box width={'24%'}>
              <TextField
                id="repairDestination"
                name="repairDestination"
                // label="取引先"
                fullWidth
                variant="outlined"
                size="small"
                type="input"
                value={this.state.repairDestination}
                className={classes.textField}
                onChange={event => this.onChangeField(event)}
                InputLabelProps={{
                  shrink: true,
                }}
                //inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' } }}
                error={this.state.repairDestinationError}
                helperText={this.state.repairDestinationHelperText}
              />
            </Box>
          </Box>
          <Box display={'flex'} marginTop={"1%"}>
            <Box display="flex" width={'9%'} justifyContent="center" alignItems="center">
              <Box>修理終了日</Box>
            </Box>
            <Box width={'24%'}>
              <TextField
                name="repairEndDate"
                id="repairEndDate"
                // label="修理終了日"
                fullWidth
                variant="outlined"
                size="small"
                style={{ height: "5px" }}
                type="date"
                value={this.state.repairEndDate}
                error={this.state.repairEndDateError}
                helperText={this.state.repairEndDateHelperText}
                onChange={event => this.onChangeField(event)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' } }}
              />
            </Box>
            <Box display="flex" width={'9%'} justifyContent="center" alignItems="center">
              <Box>備考</Box>
            </Box>
            <Box width={'55%'}>
              <TextField
                id="remarks"
                name="remarks"
                // label="備考"
                fullWidth
                variant="outlined"
                size="small"
                type="input"
                value={this.state.remarks}
                className={classes.textField}
                onChange={event => this.onChangeField(event)}
                InputLabelProps={{
                  shrink: true,
                }}
                //inputProps={{ style: { textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' } }}
                error={this.state.remarksError}
                helperText={this.state.remarksHelperText}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    )

    //ボタン
    const buttonDiv = (
      <Grid container spacing={3} justify="center">
        <Grid item xs={2} style={{ textAlign: "center" }}>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            endIcon={<PostAddIcon />}
            onClick={this.opneDialog.bind(this, this.state.isUpdate ? this.update.bind(this) : this.insert.bind(this), '商品情報を登録しますか？')}
          >
            商品登録
              </Button>
        </Grid>

        <Grid item xs={2} style={{ textAlign: "center" }}>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            endIcon={<PostAddIcon />}
            disabled={!this.state.isUpdate}
            onClick={this.opneDialog.bind(this, this.repairInsert.bind(this), '修理情報を登録しますか？')}
          >
            修理登録
              </Button>
        </Grid>

        
        <Grid item xs={2} style={{ textAlign: "center" }}>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            endIcon={<ExitToApp />}
            onClick={this.cancel.bind(this)}
          >
            戻る
              </Button>
        </Grid>
        <Grid item xs={2} style={{ textAlign: "center" }}>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={!this.state.isUpdate ? '' : this.moveToRepairHistory.bind(this)}
            disabled={!this.state.isUpdate}
          >
            修理履歴
          </Button>
        </Grid>

        <Grid item xs={2} style={{ textAlign: "center" }}>
          <Button
            variant={`contained`}
            className={classes.buttonColor}
            onClick={!this.state.isUpdate ? '' : this.moveToUsedHistory.bind(this)}
            disabled={!this.state.isUpdate}
          >
            使用履歴
          </Button>
        </Grid>
      </Grid>
    );

    return (
      <Box style={{ height: "100vh", width: "100vw" }}>
        <Header value={this.state.headerErrorMessage} />
        <Alert 
          id="successAlert"
          style={{display:'none'}}
          className={classes.successAlertStyle}
          severity="success">
            {this.state.successMessage}
        </Alert>
        <Box
          style={{
            height: "calc(100% - 96px)",
            width: "calc(100% - 32px)",
            padding: "16px",
            overflow: "auto"
          }}
        >
          <Box minWidth={975}>
            {titleDiv}
            {productCode}
            {groupCode}
            <Box style={{ display: 'flex' }} minWidth={975}>
              {serialNumber}
              {productName}
              {licenseId}
              {owner}
            </Box>
            <Box minWidth={975} >
              {procureDay}
              {repairData}
            </Box>

            {buttonDiv}
          </Box>
          <AlertDialog />
        </Box>
      </Box >

    );
  }

 // ダイアログ表示
 opneDialog(func, message) {

  var Dialog = document.getElementById('dialogBox');
  var okButton = document.getElementById('dialogOkButton');
  var cancelButton = document.getElementById('dialogCancelButton');

  document.getElementById('dialogMessage').innerHTML = message;

  // ダイアログ表示
  Dialog.style.display = '';
  // OK 押下の処理
  okButton.onclick = func;
  // cancel　押下の処理
  cancelButton.onclick = function () {
    Dialog.style.display = 'none';
  };
}

  /***********************************************************************************************
  * 商品一覧画面の変更ボタンから遷移した場合
  ***********************************************************************************************/
  async search(pram) {

    const param = Cookies.get('token');

    await axios.post(
      REQUEST_PATH + 'api/product/list',
      {
        pageIndex: 0,
        pageSize: 100,
        productNumber: pram,

      },{
        param,
        withCredentials: true,
      }
    ).then(response => {
      // 成功時
      const result = response.data.list[0];

      this.setState({ productNumber: result.productNumber });
      this.setState({ type: result.type });
      this.setState({ model: result.model });
      this.setState({ spareNumber: result.spareNumber });
      this.setState({ number: result.number });

      this.setState({ groupNumber: result.groupNumber });
      this.setState({ typeGroup: result.typeGroup });
      this.setState({ modelGroup: result.modelGroup });
      this.setState({ spareNumberGroup: result.spareNumberGroup });
      this.setState({ numberGroup: result.numberGroup });

      this.setState({ serialNumber: result.serialNumber });
      this.setState({ productName: result.productName });
      this.setState({ licenseId: result.licenseId });
      this.setState({ owner: result.owner });
      this.setState({ status: result.status });

      this.setState({ procureDay: result.procureDay });
      this.setState({ procureStore: result.procureStore });
      this.setState({ price: result.price });



      this.setState({ isUpdate: true });
      this.forceUpdate();
    }).catch(e => {
      // 失敗時
      this.setState({ headerErrorMessage: "商品の取得に失敗しました" });
      console.log(e);
    });;
  }


  /***********************************************************************************************
  * 登録ボタンをクリックされた時に実行されるメソッド
  ***********************************************************************************************/
  async insert() {

    await this.setState({ headerErrorMessage: "" });
    await this.errorStateClear();

    const _type = this.state.type || null;
    const _model = this.state.model || null;
    const _spareNumber = this.state.spareNumber || null;
    const _number = this.state.number || null;
    const _productNumber = this.state.type + this.state.model + this.state.spareNumber + this.state.number || null;
    const _typeGroup = this.state.typeGroup || null;
    const _modelGroup = this.state.modelGroup || null;
    const _spareNumberGroup = this.state.spareNumberGroup || null;
    const _numberGroup = this.state.numberGroup || null;
    const _groupNumber = this.state.typeGroup + this.state.modelGroup + this.state.spareNumberGroup + this.state.numberGroup || null;
    const _serialNumber = this.state.serialNumber || null;
    const _productName = this.state.productName || null;
    const _licenseId = this.state.licenseId || null;
    const _owner = this.state.owner || null;
    const _status = this.state.status;
    const _procureDay = this.state.procureDay;
    const _procureStore = this.state.procureStore;
    const _price = this.state.price;
    const _repairStartDate = this.state.repairStartDate;
    const _repairDestination = this.state.repairDestination;
    const _repairEndDate = this.state.repairEndDate;
    const _remarks = this.state.remarks;


    const isFailed = this.validateMust(
      _productNumber,//商品番号
      _type,
      _model,
      _spareNumber,
      _number,
      _groupNumber, //所属番号
      _typeGroup,
      _modelGroup,
      _spareNumberGroup,
      _numberGroup,
      _serialNumber,
      _productName,
      _licenseId,
      _owner,
      _procureDay,
      _procureStore,
      _price,
    );

    if (isFailed) {
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }
    const param = Cookies.get('token');
    await axios.post(REQUEST_PATH + 'api/product/create',
      {
        pageIndex: 0,
        pageSize: 100,
        productNumber: _productNumber,
        type: _type,
        model: _model,
        spareNumber: _spareNumber,
        number: _number,
        groupNumber: _groupNumber,
        typeGroup: _typeGroup,
        modelGroup: _modelGroup,
        spareNumberGroup: _spareNumberGroup,
        numberGroup: _numberGroup,
        serialNumber: _serialNumber,
        productName: _productName,
        licenseId: _licenseId,
        owner: _owner,
        status: '01',
        procureDay: _procureDay,
        procureStore: _procureStore,
        price: _price,
        repairStartDate: _repairStartDate,
        repairDestination: _repairDestination,
        repairEndDate: _repairEndDate,
        remarks: _remarks,
      },{
        param,
        withCredentials: true,
      }).then(response => {
        this.openeSuccessAlert("商品を登録しました");
        this.setState({ isUpdate: true });
      }).catch(e => {
        // 失敗時
        console.log(e);
        this.setState({ headerErrorMessage: "商品の登録に失敗しました" });
        return;
      });
      document.getElementById('dialogBox').style.display = 'none';
  };

  //修理
  async repairInsert() {

    const _productNumber = this.state.type + this.state.model + this.state.spareNumber + this.state.number || null;
    const _repairStartDate = this.state.repairStartDate;
    const _repairDestination = this.state.repairDestination;
    const _repairEndDate = this.state.repairEndDate;
    const _remarks = this.state.remarks;

    await this.errorRepairStateClear();

    if(_repairStartDate === '' || _repairStartDate === null || _repairStartDate === undefined) {
      this.setState({repairStartDateError:true});
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }

    if(_repairEndDate === '' || _repairEndDate === null || _repairEndDate === undefined) {
      this.setState({repairEndDateError:true});
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }
    
    if(_repairDestination === '' || _repairDestination === null || _repairDestination === undefined) {
      this.setState({repairDestinationError:true});
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }
    else {
      if(_repairDestination.length > 32) {

        this.setState({repairDestinationHelperText:'入力文字列が多いです'});
        this.setState({repairDestinationError:true});
        document.getElementById('dialogBox').style.display = 'none';
        return
      }
    }

    if(_remarks !== '' || _repairDestination !== null || _repairDestination !== undefined) {
      if(_remarks.length > 128) {

        this.setState({remarksHelperText:'入力文字列が多いです'});
        this.setState({remarksError:true});
        document.getElementById('dialogBox').style.display = 'none';
        return;
      }
    }


    const param = Cookies.get('token');
    await axios.post(REQUEST_PATH + 'api/repair/create', {
      pageIndex: 0,
      pageSize: 100,
      productNumber: _productNumber,
      repairStartDate: _repairStartDate,
      repairDestination: _repairDestination,
      repairEndDate: _repairEndDate,
      remarks: _remarks,
    },{
      param,
      withCredentials: true,
    }).then(response => {
      this.openeSuccessAlert("修理情報を登録しました");
      this.forceUpdate();
    }).catch(e => {
      // 失敗時
      console.log(e);
      this.setState({ headerErrorMessage: "修理情報の登録に失敗しました" });
      return;
    });
    document.getElementById('dialogBox').style.display = 'none';
  };

  /***********************************************************************************************
    * 変更ボタンをクリックされた時に実行されるメソッド
    * /api/product/updateにJSON形式でデータを投げています
    * ここでも入力フィールドチェックを行ってください。
    ***********************************************************************************************/

  async update() {

    const _type = this.state.type || null;
    const _model = this.state.model || null;
    const _spareNumber = this.state.spareNumber || null;
    const _number = this.state.number || null;
    const _productNumber = this.state.type + this.state.model + this.state.spareNumber + this.state.number || null;
    const _typeGroup = this.state.typeGroup || null;
    const _modelGroup = this.state.modelGroup || null;
    const _spareNumberGroup = this.state.spareNumberGroup || null;
    const _numberGroup = this.state.numberGroup || null;
    const _groupNumber = this.state.typeGroup + this.state.modelGroup + this.state.spareNumberGroup + this.state.numberGroup || null;
    const _serialNumber = this.state.serialNumber || null;
    const _productName = this.state.productName || null;
    const _licenseId = this.state.licenseId || null;
    const _owner = this.state.owner || null;
    //const _status= this.state.status;
    const _procureDay = this.state.procureDay || null;
    const _procureStore = this.state.procureStore || null;
    const _price = this.state.price || null;

    //修理
    const _repairStartDate = this.state.repairStartDate;
    const _repairDestination = this.state.repairDestination;
    const _repairEndDate = this.state.repairEndDate;
    const _remarks = this.state.remarks;

    await this.setState({ headerErrorMessage: "" });
    await this.errorStateClear();

    const isFailed = this.validateMust(
      _productNumber,//商品番号
      _type,
      _model,
      _spareNumber,
      _number,
      _groupNumber, //所属番号
      _typeGroup,
      _modelGroup,
      _spareNumberGroup,
      _numberGroup,
      _serialNumber,
      _productName,
      _licenseId,
      _owner,
      _procureDay,//購入日
      _procureStore,
      _price,
    );

    if (isFailed) {
      document.getElementById('dialogBox').style.display = 'none';
      return;
    }

    const param = Cookies.get('token');

    await axios.put(REQUEST_PATH + 'api/product/update',
      {
        productNumber: _productNumber,
        type: _type,
        model: _model,
        spareNumber: _spareNumber,
        number: _number,
        groupNumber: _groupNumber,
        typeGroup: _typeGroup,
        modelGroup: _modelGroup,
        spareNumberGroup: _spareNumberGroup,
        numberGroup: _numberGroup,
        serialNumber: _serialNumber,
        productName: _productName,
        licenseId: _licenseId,
        owner: _owner,
        status: '01',
        procureDay: _procureDay,
        procureStore: _procureStore,
        price: _price,

        //   repairStartDate: _repairStartDate,
        // repairDestination: _repairDestination,
        // repairEndDate: _repairEndDate,
        // remarks: _remarks,
      },{
        param,
        withCredentials: true,
      }).then(response => {
        // 成功時
        this.openeSuccessAlert("商品を更新しました");
        this.forceUpdate();
      }).catch(e => {
        // 失敗時
        console.log(e);
        this.setState({ headerErrorMessage: "商品の更新に失敗しました" });
        return;
      });
      document.getElementById('dialogBox').style.display = 'none';
    }

  // キャンセルボタン押下時
  cancel() {
    const { dispatch } = this.props;
    dispatch(moveTo.ProductList());
  }
  // エラーステート初期化
  async errorStateClear() {
    this.setState({ typeError: false });
    this.setState({ modelError: false });
    this.setState({ spareNumberError: false });
    this.setState({ numberError: false });
    this.setState({ groupNumberError: false });
    this.setState({ typeGroupError: false });
    this.setState({ modelGroupError: false });
    this.setState({ spareNumberGroupError: false });
    this.setState({ numberGroupError: false });
    this.setState({ serialNumberError: false });
    this.setState({ productNameError: false });
    this.setState({ licenseIdError: false });
    this.setState({ ownerError: false });
    this.setState({ procureDayError: false });
    this.setState({ procureStoreError: false });
    this.setState({ priceError: false });

    this.setState({ spareNumberHelperText: '' });
    this.setState({ numberHelperText: '' });
    this.setState({ spareNumberGroupHelperText: '' });
    this.setState({ numberGroupHelperText: '' });
    this.setState({ serialNumberHelperText: '' });
    this.setState({ productNameHelperText: '' });
    this.setState({ procureStoreHelperText: '' });
    this.setState({ priceHelperText: '' });
  }

    // エラーステート初期化
  async errorRepairStateClear() {

    const _repairStartDate = this.state.repairStartDate;
    const _repairDestination = this.state.repairDestination;
    const _repairEndDate = this.state.repairEndDate;
    const _remarks = this.state.remarks;

    this.setState({ repairStartDateError: false });
    this.setState({ repairEndDateError: false });
    this.setState({ repairDestinationError: false });
    this.setState({ remarksError: false });

    this.setState({ repairDestinationHelperText: '' });
    this.setState({ remarksHelperText: '' });
  }
  
  // 必須チェック処理
  validateMust(productNumber, type, model, spareNumber, number, groupNumber, typeGroup, modelGroup,
    spareNumberGroup, numberGroup, serialNumber, productName, licenseId, owner) {
    var isFailed = false;

    if (productNumber === null || productNumber === 'undefined' || productNumber === '' || isNaN(productNumber)) {
      isFailed = true;
    }
    if (type === null || type === 'undefined' || type === '') {
      isFailed = true;
      this.setState({ typeError: true });
    }
    if (model === null || model === 'undefined' || model === '') {
      isFailed = true;
      this.setState({ modelError: true });
    }
    if (spareNumber === null || spareNumber === 'undefined' || spareNumber === '' || isNaN(spareNumber)) {
      isFailed = true;
      this.setState({ spareNumberError: true });
      this.setState({spareNumberHelperText: '例：00'});
    }
    if (number === null || number === 'undefined' || number === '' || isNaN(number)) {
      isFailed = true;
      this.setState({ numberError: true });
      this.setState({numberHelperText: '例：0001'});
    }
    if (groupNumber === null || groupNumber === 'undefined' || groupNumber === '' || isNaN(groupNumber)) {
      isFailed = true;
      this.setState({ groupNumberError: true });
    }
    if (typeGroup === null || typeGroup === 'undefined' || typeGroup === '' ) {
      isFailed = true;
      this.setState({ typeGroupError: true });
    }
    if (modelGroup === null || modelGroup === 'undefined' || modelGroup === '' ) {
      isFailed = true;
      this.setState({ modelGroupError: true });
    }
    if (spareNumberGroup === null || spareNumberGroup === 'undefined' || spareNumberGroup === '' || isNaN(spareNumberGroup)) {
      isFailed = true;
      this.setState({ spareNumberGroupError: true });
      this.setState({spareNumberGroupHelperText: '例：00'});
    }
    if (numberGroup === null || numberGroup === 'undefined' || numberGroup === '' || isNaN(numberGroup)) {
      isFailed = true;
      this.setState({ numberGroupError: true });
      this.setState({ numberGroupHelperText: '例：0000'});
    }
    if (serialNumber === null || serialNumber === 'undefined' || serialNumber === '' ) {
      isFailed = true;
      this.setState({ serialNumberError: true });
      this.setState({ serialNumberHelperText: '入力してください'});
    }
    if (productName === null || productName === 'undefined' || productName === '') {
      isFailed = true;
      this.setState({ productNameError: true });
      this.setState({ productNameHelperText: '入力してください'});
    }
    if (licenseId === null || licenseId === 'undefined' || licenseId === '' || isNaN(licenseId)) {
      isFailed = true;
      this.setState({ licenseIdError: true });
    }
    if (owner === null || owner === 'undefined' || owner === '' || isNaN(owner)) {
      isFailed = true;
      this.setState({ ownerError: true });
    }

    if (!isFailed) {
      isFailed = this.validateRange(

        productNumber,
        spareNumber,
        number,
        groupNumber,
        spareNumberGroup,
        numberGroup,
        serialNumber,
        productName,
      );

    }

    return isFailed;
  }

  // 範囲チェック
  validateRange(productNumber, spareNumber, number, groupNumber, spareNumberGroup, numberGroup, serialNumber, productName) {
    var isFailed = false;

    // 呼び出し
    if (productNumber.length !== 10) {
      isFailed = true;
    }
    if (spareNumber.length !== 2) {
      isFailed = true;
      this.setState({ spareNumberError: true });
      this.setState({ spareNumberHelperText: '例：00' });
    }
    if (number.length !== 4) {
      isFailed = true;
      this.setState({ numberError: true });
      this.setState({ spareNumberHelperText: '例：0001' });
    }
    if (groupNumber.length !== 10) {
      isFailed = true;
      this.setState({ groupNumberError: true });
    }
    if (spareNumberGroup.length !== 2) {
      isFailed = true;
      this.setState({ spareNumberGroupError: true });
      this.setState({ spareNumberGroupHelperText: '例：00' });
    }
    if (numberGroup.length !== 4) {
      isFailed = true;
      this.setState({ numberGroupError: true });
      this.setState({ numberGroupHelperText: '例：0000' });
    }
    if (serialNumber.length > 12) {
      isFailed = true;
      this.setState({ serialNumberError: true });
      this.setState({ spareNumberHelperText: '入力文字数が多いです' });
    }
    if (productName.length > 32) {
      isFailed = true;
      this.setState({ productNameError: true });
      this.setState({ productNameHelperText: '入力文字数が多いです' });
    }
    return isFailed;
  }

  // 成功アラートの表示
  openeSuccessAlert(msg){

    document.getElementById('successAlert').style.display = '';
    this.setState({ successMessage: msg});

    // 2秒で消えるようにする。
    setTimeout(() => {
      document.getElementById('successAlert').style.display = 'none';
    },2000);
  }

  //JSS Style記述
  static get styles() {
    return theme => ({
      sideItem: {
        backgroundColor: "#FFFFFF",
        width: "24.8%",
        borderRadius: 5,
        lineheight: 2.5,
        alignItems: "center"
      },
      productItem: {
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        lineheight: 2.5,
        alignItems: "center"
      },
      groupItem: {
        backgroundColor: "#FFFFFF",
        alignItems: "center"
      },
      titleHeaderColor: {
        border: "1px solid #525457",
        backgroundColor: "#1976d2",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 20,
        fontWeight: "bold",
        borderRadius: "5px"
      },
      buttonColor: {
        width: 180,
        fontSize: 18,
        fontWeight: "bold",
        background: "linear-gradient(180deg, #53A0FA 0%, #0050FF 100%)",
        color: "#FFFFFF",
        borderRadius: 50,
        letterSpacing: 10,
        marginTop: 20
      },
      slctItem: {
        height: "30%",
      },
      successAlertStyle: {
        position: 'relative',
        justifyContent: 'center',
        width: '100%',
        backgroundColor:'#80cbc4',
        fontSize:'1rem'
      },
    });
  }

}
const { styles } = Component;
const mapStateToProps = ({ productInput: { list } }) => ({ model: list });
const mapDispatchToProps = dispatch => ({ dispatch });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Component);