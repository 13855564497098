import { handleAction } from 'redux-actions';
import { listUsedHistory, pageCountUsedHistory, currentPageUsedHistory } from '../actions';

const defaultState = { list: [] };
const defaultState2 = { totalPageCount:0 };
const defaultState3 = { currentPage:1 };


export const usedHistoryReducer = handleAction(listUsedHistory, (state, { payload }) => ({ ...state, list: payload }), defaultState);

// ページネーション対応
export const usedHistoryReducerPageCount = handleAction(pageCountUsedHistory, (state, { payload }) => ({ ...state, totalPageCount: payload }), defaultState2);
export const usedHistoryReducerCurrentPage = handleAction(currentPageUsedHistory, (state, { payload }) => ({ ...state, currentPage: payload }), defaultState3);